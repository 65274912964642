import FlowTask from './flowTask'
import FlowGateway from './flowGateway'

class FlowSubprocess extends FlowTask {
  constructor(rawSubprocess) {
    super()
    if (!rawSubprocess) return
    const { Flow, Key } = rawSubprocess

    this.key = Key
    this.flow = (Flow || []).map(flow => ({
      key: flow.Key,
      tasks: (flow.Tasks || []).map(task => ({
        ...new FlowTask(task),
        ...(task.Gateway ? { gateway: new FlowGateway(task.Gateway) } : {})
      }))
    }))
  }
}

export default FlowSubprocess
