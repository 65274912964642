import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import PropTypes from 'prop-types'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { RedirectRenderPath } from 'components/shared'
import { DetailsContainer } from 'components/Schedules/Details'
import { SchedulesContainer } from 'components/Schedules'
import { MonitoringCodes } from '../models'

const Schedules = ({ viewDetail }) => (
  <Routes>
    <Route exact element={<SchedulesContainer />} path="/" />
    {viewDetail ? (
      <Route element={<DetailsContainer showAvatar={false} />} path=":id" />
    ) : null}
    <Route element={<RedirectRenderPath renderPath="/schedules/" />} path="*" />
  </Routes>
)

export default withAITracking(
  AppInsightsReactPlugin,
  Schedules,
  `${MonitoringCodes.PAGE}Schedules`
)

Schedules.propTypes = {
  viewDetail: PropTypes.bool.isRequired
}
