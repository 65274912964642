import React from 'react'
import { Box, Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

import { Header, NoOverflowGrid } from './styles'

const Loader = () => <Header>
    <Grid container alignItems="center" wrap="nowrap">
      <NoOverflowGrid container item wrap="nowrap">
        <Box marginRight={2}>
          <Skeleton height={55} variant="circular" width={55} />
        </Box>
        <Box width="100%">
          <Skeleton height={16} width="80%" />
          <Skeleton height={10} width="40%" />
        </Box>
      </NoOverflowGrid>
    </Grid>
  </Header>

export default Loader
