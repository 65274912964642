import styled from '@emotion/styled'

const LabelWrapper = styled.span`
  background-color: ${props => props.theme.palette.grey[800]};
  font-weight: 500;
  color: #fff;
  font-size: ${props => props.theme.typography.caption.fontSize};
  padding: 4px 10px;
  border-radius: 6px;
  line-height: ${props => props.theme.typography.caption.lineHeight};
  letter-spacing: ${props => props.theme.typography.caption.letterSpacing};
  white-space: nowrap;

  &.info {
    background-color: ${props => props.theme.palette.info.light};
  }

  &.error {
    background-color: ${props => props.theme.palette.error.dark};
  }

  &.success {
    background-color: ${props => props.theme.palette.success.main};
  }

  &.warning {
    background-color: ${props => props.theme.palette.warning.main};
  }
`

export default LabelWrapper
