import styled from '@emotion/styled'

export const StyledNotificationWrapper = styled.div`
  margin-top: 20px;
  ${props => (props.siteLayoutMiniSideBar ? 'justify-content: center;' : '')}
`

export const StyledMenuItemTitle = styled.div`
  color: ${({ theme }) => theme.sideBar.color};
  padding: 10px;
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
