import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material'
import PropTypes from 'prop-types'

import SiteContext from 'context/siteContext'
import Loader from './Loader'
import {
  NoOverflowGrid,
  StyledAvatar,
  StyledGridHeaderContent,
  StyledGridTitleContainer,
  StyledTitle,
  StyledWrapper
} from './styles'

function getInitials(fullName, piiMask) {
  const [name, lastName] = fullName.split(' ')
  return piiMask || !name[0]
    ? 'XX'
    : `${name[0]}${lastName && lastName[0]}`.toUpperCase()
}

const ArrowButton = ({ Icon, isDisabled, to }) => {
  const linkProps = isDisabled ? {} : { component: Link, to }

  return (
    <IconButton {...linkProps} disabled={isDisabled}>
      <Icon />
    </IconButton>
  )
}

ArrowButton.propTypes = {
  Icon: PropTypes.instanceOf(Object).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired
}

const SiteCustomMainHeader = ({ items }) => {
  const { pathname, search } = useLocation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [
    {
      mainHeader: {
        headSubtitleContent,
        id,
        piiMask,
        showAvatar,
        subtitle,
        title
      },
      loading
    }
  ] = useContext(SiteContext)

  const routes = pathname.split('/')
  const path = routes.slice(0, routes.length - 1).join('/')
  const returnPath = routes.slice(0, 2).join('/')
  const newTitle = piiMask ? '********' : title

  const canGoPrevious = !loading && currentIndex !== -1 && currentIndex - 1 >= 0
  const canGoNext =
    !loading && currentIndex !== -1 && currentIndex + 1 <= items.length - 1

  useEffect(() => {
    if (id && items?.length)
      setCurrentIndex(items.findIndex(({ id: itemId }) => itemId === id))
  }, [id, items])

  return (
    <StyledWrapper>
      {loading || !title ? (
        <Loader />
      ) : (
        <StyledGridHeaderContent
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Button
              color="secondary"
              component={Link}
              startIcon={<ChevronLeftIcon />}
              to={`${returnPath}/${search}`}
              variant="contained"
            >
              Back to results
            </Button>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <StyledGridTitleContainer
              container
              alignItems="center"
              justifyContent="end"
            >
              <Grid item>
                <ArrowButton
                  Icon={ChevronLeftIcon}
                  isDisabled={!canGoPrevious}
                  to={`${path}/${items[currentIndex - 1]?.id}${search}`}
                />
              </Grid>
              {showAvatar && (
                <Grid item>
                  <StyledAvatar>{getInitials(newTitle, piiMask)}</StyledAvatar>
                </Grid>
              )}
              <NoOverflowGrid item>
                <StyledTitle>
                  <Typography variant="h1">{newTitle}</Typography>

                  {!headSubtitleContent ? (
                    <Typography color="textSecondary" variant="body2">
                      {subtitle}
                    </Typography>
                  ) : (
                    headSubtitleContent
                  )}
                </StyledTitle>
              </NoOverflowGrid>
              <Grid item>
                <ArrowButton
                  Icon={ChevronRightIcon}
                  isDisabled={!canGoNext}
                  to={`${path}/${items[currentIndex + 1]?.id}${search}`}
                />
              </Grid>
            </StyledGridTitleContainer>
          </Grid>
        </StyledGridHeaderContent>
      )}
    </StyledWrapper>
  )
}

SiteCustomMainHeader.defaultProps = {
  items: []
}

SiteCustomMainHeader.propTypes = {
  items: PropTypes.arrayOf(Object)
}

export default SiteCustomMainHeader
