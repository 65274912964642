import React from 'react'

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material'
import autocompleteAnimation from 'assets/autocomplete.gif'
import autosuggestionsAnimation from 'assets/autosuggestions.gif'

const AutoComplete = () => (
    <div style={{ display: 'grid', overflowY: 'auto', height: '100%' }}>
      <Grid container style={{ padding: 50 }}>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h4">
                    <b>Advanced Searching</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    The advanced search allows you to build strucured queries to
                    search for Leads/Persons.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5">
                    <b>How to use</b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ol>
                  <li>
                    Once accessing the Person/Lead view, <b>click</b> on the
                    Text Box above the result list.
                  </li>
                  <li>
                    Lead Marketplace will offer a list of<b> auto-complete </b>
                    suggestions that will change according to what you type.
                  </li>
                  <li>
                    Press <b>enter</b> to run your search.
                  </li>
                  <li>
                    The applied filters will be also shown at the classic filter
                    section.
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Bellow we can see the auto complete in action:
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: 30 }} xs={12}>
                <img
                  alt="Autocomplete Demonstration"
                  src={autocompleteAnimation}
                  style={{ width: '70vw' }}
                />
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5">
                  <b>Understanding advanced searching</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  A simple search consists of a <i>field</i>, followed by an
                  <i>operator</i>, followed by one or more values.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <i>E.g. &quot;First Name&quot; EQUAL TO SHELDON</i>
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5">
                  <b> Parts of a filter</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <b>Field:</b> A Person/Lead field we wish to apply a filter
                    against. For example: <i>First Name, Afid, State, etc</i>.
                    <ul>
                      <li>
                        <b>Custom Field:</b> A custom field of a Person/Lead we
                        wish to apply a filter against, it is followed after the
                        field and a colon punctuation mark(:). For example:
                        <i>Lead fields:SRC</i>.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Operator:</b> words which compare the value of a field on
                    its left with one or more values on its right. For example:
                    <i>EQUAL TO ,IN, ETC</i>. <br />
                    In some cases, there are operators which does not require
                    any value. For example: <i>ACCEPTED, REJECTED, etc</i>.
                  </li>
                  <li>
                    <b>Value(s):</b> the content the operator will use to
                    compare against the field. In some cases, more than one
                    value can be entered.
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5">
                    <b>Types of auto-completes</b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <b>Fields:</b> as you type, it will show the operators
                    available. For example, if we type <i>F</i> it will show
                    &nbsp;<i>First Name</i> as a suggested field for filtering
                  </li>
                  <li>
                    <b>Operators:</b> as you type, it will show the operators
                    available. For example, if we type <i>E</i> it will show
                    &nbsp;<i>EQUAL TO</i> as a suggested field for filtering
                  </li>
                  <li>
                    <b>Values:</b> as you type, it will show the values
                    available. For example, if we type <i>M</i> it will show
                    &nbsp;<i>medicareadvantage</i> as a suggested value for
                    Source Code filtering
                  </li>
                  <li>
                    <b>Suggested automatic filters:</b> as you type, it will
                    show a set of suggested filters. For example, if we type
                    <i> Leonard</i> it will show
                    <i>&quot;First Name&quot; EQUAL TO Leonard</i>
                  </li>
                  <li>
                    <b>Multiple suggested automatic filters:</b> with more than
                    one value, it will show suggested filters in a single row.
                    For example, if we type
                    <i> 411877 omp</i> it will show
                    <i>
                      &quot;AFID&quot; EQUAL TO 411877 AND &quot;Source
                      Code&quot; EQUAL TO omp
                    </i>
                    <Grid item style={{ paddingTop: 30 }} xs={12}>
                      <img
                        alt="Autoggestions Demonstration"
                        src={autosuggestionsAnimation}
                        style={{ width: '70vw' }}
                      />
                    </Grid>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5">
                    <b>Adding more than one filter</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    In case we wish to add more filters, once we finish entering
                    our first filter we type space then a list of suggestions
                    (like when we start entering our first filter) will be
                    displayed and we would be able to start entering a new
                    criteria.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <i>
                      E.g. &quot;First Name&quot; EQUAL TO SHELDON AND
                      &quot;Last Name Name&quot; EQUAL TO COOPER
                    </i>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5">
                    <b>Operators</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 30 }} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer componet={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Operator</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Expected Values</TableCell>
                        <TableCell>Example</TableCell>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Between</TableCell>
                          <TableCell>
                            Checks if the field, which is numeric, is between a
                            range of two values.
                          </TableCell>
                          <TableCell>
                            The operator expects two values.
                          </TableCell>
                          <TableCell>
                            <i> &quot;Age&quot; Between 60,70</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Greater Than</TableCell>
                          <TableCell>
                            Checks if the field, which is numeric, is greater
                            than a value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Age&quot; Greater Than 70</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Greater or equal to</TableCell>
                          <TableCell>
                            Checks if the field, which is numeric, is greater or
                            equal to a value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Age&quot; Greater or equal to 70</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Less Than</TableCell>
                          <TableCell>
                            Checks if the field, which is numeric, is less than
                            a value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Age&quot; Less Than 70</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Less or equal to</TableCell>
                          <TableCell>
                            Checks if the field, which is numeric, is less or
                            equal to a value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Age&quot; Less or equal to 70</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Equal to</TableCell>
                          <TableCell>
                            Checks if the field, is equal to a value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;State&quot; Equal to NY</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Not Equal to</TableCell>
                          <TableCell>
                            Checks if the field, is not equal to a value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;State&quot; Not Equal to FL</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Is not empty</TableCell>
                          <TableCell>
                            Checks if the field, which is a text, is not empty.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;State&quot; Is not empty</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Starts with</TableCell>
                          <TableCell>
                            Checks if the field, which is a text, starts with a
                            value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Soure Code&quot; Starts with colonial</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Ends with</TableCell>
                          <TableCell>
                            Checks if the field, which is a text, ends with a
                            value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Soure Code&quot; Ends with penn</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Contains</TableCell>
                          <TableCell>
                            Checks if the field, which is a text, contains a
                            value.
                          </TableCell>
                          <TableCell>The operator expects one value.</TableCell>
                          <TableCell>
                            <i> &quot;Soure Code&quot; Contains lonialpen</i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>In</TableCell>
                          <TableCell>
                            Checks if the field is under a list of values
                          </TableCell>
                          <TableCell>
                            The operator expects from one to many values.
                          </TableCell>
                          <TableCell>
                            <i>
                              &quot;Soure Code&quot; In medicaresupplement,moomp
                            </i>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Not in</TableCell>
                          <TableCell>
                            Checks if the field is not under a list of values
                          </TableCell>
                          <TableCell>
                            The operator expects from one to many values.
                          </TableCell>
                          <TableCell>
                            <i>&quot;State&quot; Not In FL,KS</i>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )

AutoComplete.propTypes = {}

export default AutoComplete
