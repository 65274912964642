import axios from 'axios'
import { AppInsightsHelper } from '@tranzact/platform_react-components'
import { MonitoringCodes } from 'models/monitoring'

const gatewayResource = 'entities'
const repositoryBaseUrl = process.env.REACT_APP_MACKEY_URL

const mapArrayTo = (array, Type = window.required()) => {
  if (Array.isArray(array)) return array.map(item => new Type(item))

  return []
}

const getResponseData =
  ({ isOdata, isArray, ...otherProps }) =>
  response => {
    const { otherValues, mapTo: MapTo, useRaw } = otherProps
    const { data: rawData } = response

    if (isOdata) {
      return {
        data: mapArrayTo(rawData.Data || rawData.value, MapTo),
        count: rawData.Count || rawData['@odata.count'] || 0
      }
    }

    const data =
      (!useRaw && (rawData.Data || rawData.data || rawData.value)) || rawData

    if (!MapTo) return data
    if (otherValues) return new MapTo({ ...data, ...otherValues })

    return isArray ? mapArrayTo(data, MapTo) : new MapTo(data)
  }

const parseApiUrl = ({ apiPath, action, query }) =>
  `${apiPath}/${action || ''}${query ? `?${query}` : ''}`

const validate = (isOdata, mapTo) => {
  const msg = 'OData endpoints require a class for mapping.'

  if (isOdata && !mapTo) {
    AppInsightsHelper?.trackError(`${MonitoringCodes.ERR}Repository: ${msg}`)
    throw new Error(msg)
  }
}

class Repository {
  getApiPath = useGateway => {
    const baseUrl = this.newBaseUrl || repositoryBaseUrl
    const resource = useGateway ? gatewayResource : this.resource

    return `${baseUrl}api/${resource}`
  }

  handleError = err => {
    throw err
  }

  async get({ action, signal, cancelSource, query, ...otherProps }) {
    const { params, mapTo, useGateway, config, isOdata } = otherProps
    const apiPath = this.getApiPath(useGateway)
    const url = parseApiUrl({ apiPath, action, query })

    validate(isOdata, mapTo)

    return axios
      .get(url, {
        params,
        config,
        ...(signal ? { signal } : {})
      })
      .then(getResponseData(otherProps))
      .catch(this.handleError)
  }

  async post({ action, payload, config }) {
    const apiPath = this.getApiPath()

    return axios
      .post(parseApiUrl({ apiPath, action }), payload, config)
      .catch(this.handleError)
  }

  async update({ action, payload }) {
    const apiPath = this.getApiPath()
    const url = action ? parseApiUrl({ apiPath, action }) : this.getApiPath()

    return axios.put(url, payload).catch(this.handleError)
  }

  async remove({ action, payload, config }) {
    const apiPath = this.getApiPath()

    return axios
      .delete(parseApiUrl({ apiPath, action }), payload, config)
      .catch(this.handleError)
  }
}

export default Repository
