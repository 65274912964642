import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { differenceInDays } from 'date-fns'

import {
  RecurrenceType,
  ScheduleFormField,
  ScheduleFormTopOptions
} from 'models'
import getHasValidDateFormat from 'utils/getHasValiDateFormat'
import getHasValidTimeFormat from 'utils/getHasValidTimeFormat'
import { getDate } from 'utils/validateScheduleForm'
import getUtcTimeZonedDiff from 'utils/getUtcTimeZonedDiff'

const {
  BetweenHourEnd,
  BetweenHourStart,
  DailyRepeatingDays,
  DistributionCid,
  DistributionPid,
  EndDate,
  EndTime,
  HourlyRepeatingHours,
  MonthlyDays,
  MonthlyMonths,
  Name,
  NumberOfTopResults,
  SelectedFilterName,
  StartDate,
  StartTime,
  WeeklyDays
} = ScheduleFormField
const { Daily, Hourly, Weekly, Monthly } = RecurrenceType

const timeZeroDate = new Date()
timeZeroDate.setHours(0, 0, 0, 0)

const padNumber = (number, maxLength = 2, fillString = '0') =>
  number.toString().padStart(maxLength, fillString)

// eslint-disable-next-line arrow-body-style
const getCommonDateValidation = value => {
  return value instanceof Date && !Number.isNaN(value.getTime())
}

const getHasValidDate = value => {
  const day = padNumber(value.getDate())
  const month = padNumber(value.getMonth() + 1)
  const year = value.getFullYear()

  return getHasValidDateFormat(`${month}/${day}/${year}`)
}

const getHasValidDateTime = (value, startDate) => {
  const hours = padNumber(value.getHours())
  const minutes = padNumber(value.getMinutes())
  const newDateTimeText = `${hours}:${minutes}`

  return (
    getHasValidTimeFormat(newDateTimeText) &&
    getUtcTimeZonedDiff(getDate(startDate, value)) > 5
  )
}

export default yupResolver(
  yup.object().shape({
    [Name]: yup.string().required('Name is required'),
    [SelectedFilterName]: yup.string().test(
      'Favorite filter is required',
      'Favorite filter is required',
      (
        value,
        {
          options: {
            parent: { selectedFilter }
          }
        }
      ) => selectedFilter.length
    ),
    [DistributionPid]: yup.string().required('PID is required'),
    [DistributionCid]: yup.string().required('CID is required'),
    [NumberOfTopResults]: yup
      .string()
      .nullable()
      .test(
        'Has valid top of results',
        'Top leads is required',
        (
          value,
          {
            options: {
              parent: { distributeResults }
            }
          }
        ) => (distributeResults === ScheduleFormTopOptions.Top ? !!value : true)
      ),
    [DailyRepeatingDays]: yup
      .string()
      .nullable()
      .test(
        'Has valid repeating days',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption }
            }
          }
        ) => (recurrenceOption === Daily ? !!value : true)
      ),
    [HourlyRepeatingHours]: yup
      .string()
      .nullable()
      .test(
        'Has valid repeating hours',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption, usesBetween }
            }
          }
        ) => (recurrenceOption === Hourly && !usesBetween ? !!value : true)
      ),
    [BetweenHourEnd]: yup
      .string()
      .nullable()
      .test(
        'Has valid between hour end',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption, usesBetween }
            }
          }
        ) => (recurrenceOption === Hourly && usesBetween ? !!value : true)
      ),
    [BetweenHourStart]: yup
      .string()
      .nullable()
      .test(
        'Has valid between hour start',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption, usesBetween }
            }
          }
        ) => (recurrenceOption === Hourly && usesBetween ? !!value : true)
      ),
    [WeeklyDays]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'Has valid weekly days',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption }
            }
          }
        ) => (recurrenceOption === Weekly ? !!value.length : true)
      ),
    [MonthlyMonths]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'Has valid monthly months',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption }
            }
          }
        ) => (recurrenceOption === Monthly ? !!value.length : true)
      ),
    [MonthlyDays]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'Has valid monthly days',
        'Value is required',
        (
          value,
          {
            options: {
              parent: { recurrenceOption }
            }
          }
        ) => (recurrenceOption === Monthly ? !!value.length : true)
      ),
    [StartDate]: yup
      .date()
      .nullable()
      .test(
        'Has valid start date',
        'Should be equal or greater than current date',
        value =>
          getCommonDateValidation(value) &&
          getHasValidDate(value) &&
          differenceInDays(value, new Date()) >= 0
      )
      .transform(value => (getCommonDateValidation(value) ? value : null)),
    [StartTime]: yup
      .date()
      .nullable()
      .test(
        'Has valid start time',
        'Should be greater than 5 minutes of New York time',
        (
          value,
          {
            options: {
              parent: { startDate }
            }
          }
        ) =>
          getCommonDateValidation(value) &&
          getCommonDateValidation(startDate) &&
          getHasValidDateTime(value, startDate)
      )
      .transform(value => (getCommonDateValidation(value) ? value : null)),
    [EndDate]: yup
      .date()
      .nullable()
      .test(
        'Has valid end date',
        'End Date is required',
        (
          value,
          {
            options: {
              parent: { addEndDate, recurrenceOption }
            }
          }
        ) =>
          addEndDate && recurrenceOption !== RecurrenceType.Once
            ? getCommonDateValidation(value) && getHasValidDate(value)
            : true
      )
      .test(
        'Has to be equal or higher than start date',
        'End Date cannot be lower than Start Date.',
        (
          value,
          {
            options: {
              parent: { addEndDate, recurrenceOption, startDate }
            }
          }
        ) =>
          addEndDate && recurrenceOption !== RecurrenceType.Once
            ? getCommonDateValidation(value) &&
              getCommonDateValidation(startDate) &&
              getDate(value, timeZeroDate) >= getDate(startDate, timeZeroDate)
            : true
      )
      .transform(value => (getCommonDateValidation(value) ? value : null)),
    [EndTime]: yup
      .date()
      .nullable()
      .test(
        'Has valid end date',
        'End Time is required',
        (
          value,
          {
            options: {
              parent: { addEndDate, recurrenceOption }
            }
          }
        ) =>
          addEndDate && recurrenceOption !== RecurrenceType.Once
            ? getCommonDateValidation(value) && getHasValidDate(value)
            : true
      )
      .test(
        'Has to be equal or higher than start date',
        'End Time cannot be lower than Start Date.',
        (
          value,
          {
            options: {
              parent: {
                addEndDate,
                recurrenceOption,
                startDate,
                startTime,
                endDate
              }
            }
          }
        ) =>
          addEndDate && recurrenceOption !== RecurrenceType.Once
            ? getCommonDateValidation(value) &&
              getCommonDateValidation(endDate) &&
              getCommonDateValidation(startDate) &&
              getCommonDateValidation(startTime) &&
              getDate(endDate, value) > getDate(startDate, startTime)
            : true
      )
      .transform(value => (getCommonDateValidation(value) ? value : null))
  })
)
