import styled from '@emotion/styled'
import { Grid, Paper } from '@mui/material'

export const RootGrid = styled(Grid)`
  overflow: hidden;
  padding: 20px;
  height: 100%;
`

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  color: #fff;
`

export const StyledPaper = styled(Paper)`
  background-color: transparent;
  box-shadow: none;
  flex-direction: row;
  margin: 0;
  overflow: hidden;
  > .MuiPaper-root {
    flex: 1;
    overflow: hidden;
  }
`
