import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { favorites, filters, results } from 'store/modules'
import Header from './Header'

const { getSelectedLabel } = favorites.selectors
const { getFetching: loadingResults } = results.selectors
const { clear, remove, saveMany, updateAdvanced } = filters.actions

const {
  getAll,
  getCustomFields,
  getFetching: loadingFilters,
  getFields,
  getFieldTypes,
  getFiltersQuery,
  getOperators
} = filters.selectors

const mapStateToProps = state => ({
  customFields: getCustomFields(state),
  fields: getFields(state),
  fieldTypes: getFieldTypes(state),
  filters: getAll(state),
  filtersQuery: getFiltersQuery(state),
  loadingFilters: loadingFilters(state),
  loadingResults: loadingResults(state),
  operators: getOperators(state),
  selectedFavoriteLabel: getSelectedLabel(state)
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearFilters: clear,
      removeFilter: remove,
      saveFilters: saveMany,
      updateSearch: updateAdvanced
    },
    dispatch
  )

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer
