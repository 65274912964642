import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { EntityLayout, useHotjar } from '@tranzact/platform_react-components'

import UserSettingsContext from 'context/userSettings'
import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import ToolbarContainer from './Toolbar'
import { HeaderContainer } from './Header'
import ResultsContainer from './Results'
import PaginationContainer from './Pagination/PaginationContainer'

const Persons = ({ entity, userRole }) => {
  const [clearedQuery, setClearedQuery] = useState('original')
  const { piiMask } = userRole
  const { createFavorite, deleteFavorite, listFavorites, viewDetail } =
    userRole.actions.person

  // TODO: We should replace this when we define the standar error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const userSettingsContext = useContext(UserSettingsContext)

  const { identifyHotjar } = useHotjar()

  const handleOnClearedQuery = () => {
    setClearedQuery('cleared')
    if (clearedQuery === 'cleared') setClearedQuery('original')
  }

  useEffect(() => {
    if (HotjarMetadataHelper.turnOnFlags(['lmkt_personSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }
  }, [identifyHotjar, logger])

  return (
    <EntityLayout
      header={
        <HeaderContainer
          clearedQuery={clearedQuery}
          isDeleteFavoriteAllowed={deleteFavorite}
          isFavoriteListAllowed={listFavorites}
          isSaveAllowed={createFavorite}
          placeholder="Search by Id"
          onClearedQuery={handleOnClearedQuery}
        />
      }
      results={
        <ResultsContainer
          pagination={
            <PaginationContainer
              location="bottom"
              rowsName={entity}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          }
          piiMask={piiMask}
          viewDetail={viewDetail}
        />
      }
      toolbar={
        <ToolbarContainer
          pagination={<PaginationContainer location="top" rowsName={entity} />}
        />
      }
      userSettingsContext={userSettingsContext}
    />
  )
}

Persons.propTypes = {
  entity: PropTypes.string.isRequired,
  userRole: PropTypes.instanceOf(Object).isRequired
}

export default Persons
