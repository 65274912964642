const sourceFlowKey = {
  campaign: 'campaign',
  sourcecode: 'sourceCode',
  cid: 'cid'
}

const getRawFilteredSourceFlows = (rawSourceFlows, filters) =>
  rawSourceFlows.reduce((result, item) => {
    let itemClone = {
      ...item
    }

    for (let i = 0; i < filters.length; i += 1) {
      if (!itemClone) break

      const { field, operator, value } = filters[i]
      const propertyKey = sourceFlowKey[field.path.toLowerCase()]
      const itemValue = itemClone[propertyKey]
      const operatorId = operator.id
      const filterValueTrimmed = value.trim()

      if (
        (operatorId === 'eq' && itemValue !== filterValueTrimmed) ||
        (operatorId === 'ne' && itemValue === filterValueTrimmed)
      )
        itemClone = undefined
    }
    if (itemClone) result.push(itemClone)
    return result
  }, [])

export default getRawFilteredSourceFlows
