import React from 'react'
import PropTypes from 'prop-types'

import {
  EntityToolbar,
  PrimaryButton
} from '@tranzact/platform_react-components'

import { setKey } from 'services/sessionStorage'

const Toolbar = ({ entity, presets, onChangeDate, ...otherProps }) => {
  const { onClick, isAddButtonActionAllowed } = otherProps

  const button = isAddButtonActionAllowed && (
    <PrimaryButton
      label="+ Add Schedule"
      variant="contained"
      onClick={onClick}
    />
  )

  const handleOnChangeDate = dateset => {
    const { selectedValue, from, to } = dateset

    setKey(`${entity}.presetDate`, { selectedValue, from, to })
    onChangeDate(dateset)
  }

  return (
    <EntityToolbar
      datePresetProps={{
        presets,
        onChangeDate: handleOnChangeDate
      }}
      {...otherProps}
      options={[]}
      specialAction={button}
    />
  )
}

Toolbar.defaultProps = {
  isAddButtonActionAllowed: true
}

Toolbar.propTypes = {
  entity: PropTypes.string.isRequired,
  isAddButtonActionAllowed: PropTypes.bool,
  presets: PropTypes.arrayOf(Object).isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

export default Toolbar
