import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import SystemTimeZone from './systemTimeZone'

const { timeZone } = SystemTimeZone

const getStatus = ({ isSuccessful, failures, canceled }) => {
  const jobEnded = isSuccessful !== null

  if (!jobEnded) {
    return canceled ? 'Canceling' : 'Running'
  }

  return !isSuccessful || failures
    ? 'Completed with errors'
    : (canceled && 'Canceled') || 'Completed'
}

class Job {
  constructor(job) {
    if (!job) return
    this.id = job.id
    this.description = `${job.type} started by ${job.user} at ${format(
      zonedTimeToUtc(job.created, timeZone),
      'MM/dd/yyyy HH:mm:ss'
    )}`
    this.type = job.type
    this.status = getStatus(job)
    this.owner = job.owner
    this.user = job.user
    this.created = zonedTimeToUtc(job.created, timeZone)
    this.createdWithFormat = format(this.created, 'MM/dd/yyyy HH:mm:ss')
    this.canceled = job.canceled || false
    this.scheduleId = job.scheduleId || null
    this.schedule = null
    this.ended = job.ended && zonedTimeToUtc(job.ended, timeZone)
    this.duration = job.duration
    this.totalProcessed = job.total || 0
    this.totalSuccess = job.successes || 0
    this.totalFailures = job.failures || 0
    this.details = []

    if (job.context) {
      this.filename = job.context.file
      this.errorFile = job.context.errorfile
      this.filter = job.context.filter
    }
  }
}

export default Job
