import { gql } from 'graphql-request'

const PERSON_DETAILS_QUERY = gql`
  query GetPersonDetails($wherePerson: PersonFilterInput) {
    persons(where: $wherePerson) {
      nodes {
        updated
        created
        id
        firstName
        lastName
        gender
        email
        address {
          line1
          line2
          city
          county
          state
          zipcode
        }
        addresses {
          id
          line1
          line2
          county
          city
          state
          zipcode
          preferred
        }
        phone {
          phoneNumber
        }
        phones {
          id
          phoneNumber
          preferred
        }
        dateOfBirth
        fields {
          id
          key
          value
        }
      }
    }
  }
`

export default PERSON_DETAILS_QUERY
