import { AppInsightsHelper } from '@tranzact/platform_react-components'
import { MonitoringCodes } from 'models/monitoring'
import Repository from './repository'

class Fields extends Repository {
  newBaseUrl = process.env.REACT_APP_MACKEY_URL

  resource = 'entities'

  async find(type) {
    try {
      const fields = await this.get({
        action: 'command',
        params: {
          commandText: `field?$select=Key&$filter=type eq '${type}'&$orderby=Key`
        },
        isArray: true,
        useGateway: true
      })

      return fields.map(field => field.Key)
    } catch (err) {
      AppInsightsHelper?.trackError(`${MonitoringCodes.ERR}Api_Fields: ${err}`)
      this.handleError(err)
      return []
    }
  }
}

export default Fields
