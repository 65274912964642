import { v1 as uuid } from 'uuid'
import { format as formatFns } from 'date-fns'

import { JobType } from 'models'
import buildCronExpression from './buildCronExpression'

// TODO: Evaluate date-fns for this scenario
const getDateWithOffSet = date =>
  new Date(date - date.getTimezoneOffset() * 60000)

// TODO: Change this to a simpler approach
const getUtcDate = (date, time) => {
  const formattedDate = formatFns(date, 'yyyy-MM-dd')
  const formattedTime = formatFns(time, 'HH:mm')

  return `${formattedDate}T${formattedTime}:00.000Z`
}

const templateMethods = {
  [JobType.Distribution]({ query, target: { cid, pid } }) {
    return {
      data: {
        query,
        operations: [
          {
            path: '/target/fields',
            op: 'remove'
          },
          {
            value: [
              {
                key: 'pid',
                value: pid
              }
            ],
            path: '/target/fields',
            op: 'add'
          },
          {
            value: cid,
            path: '/target/cid',
            op: 'replace'
          }
        ],
        userRoot: false,
        root: null
      }
    }
  },
  [JobType.Update]({ query }) {
    return { data: query }
  }
}

const buildScheduleRequest = ({ schedule, buildProcessQueryRequest }) => {
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    recurrenceOption,
    selectedFilterName,
    selectedFilter,
    distributeResults,
    numberOfTopResults,
    jobType,
    distributionSystem,
    distributionCid,
    distributionPid,
    name,
    enabled,
    stopped,
    notes,
    addEndDate,
    created,
    hourlyWeekDays,
    repeatingHours,
    repeatingDays,
    weeklyDays,
    monthlyMonths,
    monthlyDays,
    usesBetween,
    betweenHourStart,
    betweenHourEnd
  } = schedule

  const cron = buildCronExpression({
    recurrenceOption,
    startDate,
    startTime,
    hourlyWeekDays,
    repeatingHours,
    repeatingDays,
    weeklyDays,
    monthlyMonths,
    monthlyDays,
    usesBetween,
    betweenHourStart,
    betweenHourEnd
  })
  const filters = {
    timeFilters: null,
    whereFilters: selectedFilter,
    pagination: { sortBy: 'Timeline/Started', desc: 'desc', skip: '' },
    top: numberOfTopResults ?? ''
  }
  const query = buildProcessQueryRequest(filters)
  const template = templateMethods[jobType]({
    query,
    target: {
      selected: distributionSystem,
      cid: distributionCid,
      pid: distributionPid
    }
  })

  const request = {
    type: jobType,
    description: name,
    startDate: getUtcDate(startDate, startTime),
    endDate: endDate ? getUtcDate(endDate, endTime) : null,
    dateTime: getDateWithOffSet(new Date()),
    enabled,
    stopped,
    commands: [
      {
        id: uuid(),
        cronExpression: cron,
        timeout: 6000,
        template: JSON.stringify(template),
        context: {
          schedule: JSON.stringify({
            ...schedule,
            details: {
              name,
              selectedFilterName,
              selectedFilter,
              notes,
              jobType,
              distributeResults,
              numberOfTopResults,
              distributionParameters: {
                selected: distributionSystem,
                cid: distributionCid,
                pid: distributionPid
              },
              enabled,
              stopped
            },
            recurrence: {
              recurrenceOption,
              hourlyWeekDays,
              repeatingHours,
              repeatingDays,
              weeklyDays,
              monthlyMonths,
              monthlyDays,
              usesBetween,
              betweenHourStart,
              betweenHourEnd
            },
            dates: {
              startDate: formatFns(startDate, 'yyyy-MM-dd'),
              startTime: formatFns(startTime, 'HH:mm'),
              endDate: endDate ? formatFns(endDate, 'yyyy-MM-dd') : null,
              endTime: endTime ? formatFns(endTime, 'HH:mm') : null,
              addEndDate,
              created
            }
          })
        }
      }
    ]
  }
  return request
}

export default buildScheduleRequest
