import { v1 as uuid } from 'uuid'

import { AppInsightsHelper } from '@tranzact/platform_react-components'
// eslint-disable-next-line import/no-extraneous-dependencies
import { parser } from '@tranzact/lead-query-interpreter'

import { MonitoringCodes } from 'models/monitoring'

const getQueryValues = (valueOptions, queryValue, operatorId) => {
  const values = queryValue.split(',')

  if (valueOptions) {
    return values.map(value => {
      const foundOption = valueOptions.find(
        ({ label, operators }) =>
          label.toLowerCase() === value.toLowerCase() &&
          operators.includes(operatorId)
      )

      return foundOption || value
    })
  }
  return values
}

const evaluateInterpreterResult = ({ text, findField, findOperator }) => {
  const result = parser(text)
  const queries = []
  const response = { successful: false, message: '', data: null }

  try {
    if (result && result.successful) {
      result.data.forEach(r => {
        const field = findField(r.keyword.replace(/['"]+/g, ''))
        const qry = {
          id: uuid(),
          field,
          operator: findOperator(r.operator, field),
          value: r.value,
          value2: '',
          values: [],
          displayLabel: r.customField
            ? `${r.customField} ${r.operator} ${r.value}`
            : `${r.keyword} ${r.operator} ${r.value}`,
          evaluated: r,
          customField: r.customField
        }
        if (qry.operator.valueType === 'multi') {
          qry.value = ''
          qry.values = getQueryValues(
            qry.field?.valueOptions,
            r.value,
            qry.operator.id
          )
        } else if (qry.operator.valueType === 'dual') {
          const [value, value2] = r.value.split(',')

          qry.value = value
          qry.value2 = value2
        }

        queries.push(qry)
      })

      return { ...response, successful: true, data: queries }
    }

    return { ...response, message: result.error }
  } catch (err) {
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_EvaluateInterpreterResult: ${err}`
    )

    return { ...response, message: result.error }
  }
}

export default evaluateInterpreterResult
