import { connect } from 'react-redux'

import { filters, results, ui } from 'store/modules'
import SiteLayout from './Site'

const { getEntity } = ui.selectors
const { getFilterPreset } = filters.selectors
const { getResults } = results.selectors

const mapStateToProps = state => ({
  entity: getEntity(state),
  filterPreset: getFilterPreset(state),
  items: getResults(state)
})

const SiteContainer = connect(mapStateToProps)(SiteLayout)

export default SiteContainer
