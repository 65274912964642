import { connect } from 'react-redux'

import { ui } from 'store/modules'
import Jobs from './Jobs'

const { getEntity, getUserRole } = ui.selectors

const mapStateToProps = state => ({
  entity: getEntity(state),
  userRole: getUserRole(state)
})

const JobsContainer = connect(mapStateToProps)(Jobs)

export default JobsContainer
