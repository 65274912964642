import { connect } from 'react-redux'

import { ui } from 'store/modules'
import Leads from './Leads'

const { getEntity, getUserRole } = ui.selectors

const mapStateToProps = state => ({
  entity: getEntity(state),
  userRole: getUserRole(state)
})

const LeadsContainer = connect(mapStateToProps)(Leads)

export default LeadsContainer
