export const lightBlueColor = '#7EA6E0'
export const defaultColor = '#FFFFFF'
export const darkColor = '#424242'
export const failureColor = 'red'
export const ActivityNodeWidth = 160
export const ActivityNodeHeight = 120
export const ActivityNodeStrokeWidth = 4

export const EventNodeSize = 42
export const EventNodeInnerSize = EventNodeSize - 6
export const EventNodeSymbolSize = EventNodeInnerSize - 14
export const EventNodeStrokeWidthIsEnd = 4

export const GatewayNodeSize = 50
export const GatewayNodeSymbolSize = 25

export const BPMN_ACTIVITY_TYPE_ENUM = {
  service: 1
}
export const BPMN_EVENT_DIMENSION_TYPE = {
  automatic: 1,
  distribution: 2,
  end: 2,
  manual: 1,
  scheduled: 1,
  file: 1,
  start: 1
}
export const BPMN_EVENT = {
  automatic: 'automatic',
  distribution: 'distribution',
  end: 'end',
  manual: 'manual',
  scheduled: 'scheduled',
  start: 'start',
  file: 'file',
  task: 'task'
}
export const BPMN_EVENT_TYPE = {
  automatic: 2,
  distribution: 2,
  end: 1,
  manual: 4,
  scheduled: 3,
  file: 5,
  start: 1
}
export const BPMN_GATEWAY_TYPE = {
  inclusive: 1,
  exclusive: 2
}
export default {
  BPMN_ACTIVITY_TYPE_ENUM,
  BPMN_EVENT_DIMENSION_TYPE,
  BPMN_EVENT,
  BPMN_EVENT_TYPE,
  BPMN_GATEWAY_TYPE
}
