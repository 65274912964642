import { put, call, select, takeLatest, all } from 'redux-saga/effects'

import fixResults from 'utils/fixResults'

import {
  filters as filtersModule,
  notifications as notificationsModule,
  results as resultsModule,
  ui as uiModule
} from 'store/modules'
import {
  autoCompleteRepository,
  favoritesRepository,
  configurationsRepository,
  fieldsRepository,
  sourceFlowsRepository,
  jobsRepository,
  leadsRepository,
  personsRepository,
  notificationsRepository,
  schedulesRepository
} from 'api'
import { NotificationsType } from 'models'

const { selectors: filtersSelector } = filtersModule
const { actions: notificationsActions, actionTypes: notificationsActionTypes } =
  notificationsModule
const { actions: uiActions, selectors: uiSelectors } = uiModule
const { selectors: resultsSelector } = resultsModule

export function* saveFavorite(favorite) {
  const entity = yield select(uiSelectors.getEntity)

  const payload = {
    entity: `${entity}s`,
    payload: favorite
  }

  yield call([favoritesRepository, favoritesRepository.save], payload)
}

export function* fetchDefaultConfiguration(entity) {
  const configuration = yield call(
    [
      configurationsRepository,
      configurationsRepository.findDefaultConfiguration
    ],
    entity
  )

  return configuration
}

export function* fetchFiltersCommonConfiguration() {
  const configuration = yield call([
    configurationsRepository,
    configurationsRepository.findCommonFilters
  ])

  return configuration
}

export function* fetchCustomFieldsConfiguration(entity) {
  const configuration = yield call(
    [fieldsRepository, fieldsRepository.find],
    entity
  )

  return configuration
}

export function* filterLeads({
  filters,
  pagination: { rowsPerPage, page },
  sort: { by, isAsc }
}) {
  const skip = rowsPerPage * page

  const filteredLeads = yield call(
    [leadsRepository, leadsRepository.find],
    filters,
    { top: rowsPerPage, skip, sortBy: by, desc: isAsc ? 'asc' : 'desc' }
  )

  return filteredLeads
}

export function* filterPersons({
  filters,
  pagination: { rowsPerPage, page },
  sort: { by, isAsc }
}) {
  const skip = rowsPerPage * page

  const filteredLeads = yield call(
    [personsRepository, personsRepository.find],
    filters,
    { top: rowsPerPage, skip, sortBy: by, desc: isAsc ? 'asc' : 'desc' }
  )

  return filteredLeads
}

export function* filterJobs({ end, filters, start }) {
  const filteredLeads = yield call([jobsRepository, jobsRepository.find], {
    end,
    filters,
    start
  })

  return filteredLeads
}

export function* filterSchedules({ end, filters, start }) {
  const filteredSchedules = yield call(
    [schedulesRepository, schedulesRepository.find],
    { end, filters, start }
  )

  return filteredSchedules
}

export function* filterSourceFlows({ filters }) {
  const filteredSourceFlows = yield call(
    [sourceFlowsRepository, sourceFlowsRepository.find],
    { filters }
  )

  const advanced = yield select(filtersSelector.getAdvancedFilter)
  const sort = yield select(resultsSelector.getSort)
  const resultsBySearch = filteredSourceFlows.filter(({ id }) =>
    id.includes(advanced?.value || '')
  )
  const fixedResults = fixResults({
    results: resultsBySearch,
    sort
  })

  return fixedResults
}

export function* fetchAutoComplete(label) {
  const value = yield call(
    [autoCompleteRepository, autoCompleteRepository.findValues],
    label
  )
  return value
}

export function* fetchNotifications(action) {
  const { userName } = action.payload

  try {
    const [userNotifications, allNotifications] = yield all([
      call(
        [notificationsRepository, notificationsRepository.findByUserName],
        userName
      ),
      call(
        [notificationsRepository, notificationsRepository.findByUserName],
        'All'
      )
    ])

    yield put(
      notificationsActions.set(userNotifications, NotificationsType.User)
    )
    yield put(notificationsActions.set(allNotifications, NotificationsType.All))
  } catch (error) {
    yield put(
      uiActions.showAlert({
        error,
        message: `Oops!, something went wrong fetching notifications.`
      })
    )
  }
}

export function* cancelJob(id) {
  const filteredLeads = yield call([jobsRepository, jobsRepository.cancel], id)

  return filteredLeads
}

export function* updateLeads(payload) {
  yield call([leadsRepository, leadsRepository.recalculate], payload)
}

export function* distributeLeads(payload) {
  yield call([leadsRepository, leadsRepository.save], payload)
}

const apiSaga = [takeLatest(notificationsActionTypes.FETCH, fetchNotifications)]

export default apiSaga
