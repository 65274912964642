import normalize from 'utils/normalizer'

export const dataNormalizedAction = ({ feature }) => ({
  type: `${feature}/DATA_NORMALIZED`,
  meta: { feature }
})

const normalizeMiddleware = ({ dispatch }) => next => action => {
  if (action.type.includes('SET') && action.meta && action.meta.normalizeKey) {
    dispatch(dataNormalizedAction({ feature: action.meta.feature }))

    const normalizedData = normalize(action.payload, action.meta.normalizeKey)
    dispatch({
      ...action,
      payload: normalizedData,
      meta: { normalizeKey: null }
    })
  } else {
    next(action)
  }
}

export default normalizeMiddleware
