import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formatDistanceToNow } from 'date-fns'
import {
  CreateRounded as CreateIcon,
  PlaylistAddCheckRounded as PlaylistAddCheckIcon,
  SendRounded as SendIcon,
  ThumbUp as ThumbUpIcon,
  Phone as PhoneIcon,
  Assignment as AssigmentIcon,
  Casino as CasinoIcon,
  PostAdd as PostAddIcon,
  Update as UpdateIcon,
  Share as ShareIcon,
  Delete as ScrubIcon
} from '@mui/icons-material'

import Overview from 'components/Leads/Overview'
import { BoldText } from 'components/ui'
import { CommonDetails } from 'components/shared'
import { leadsRepository, activitiesRepository } from 'api'
import { LeadPersonActivityDetailLabel } from 'models'
import calculateLeadPersonActivityDetailValue from 'utils/calculateLeadPersonActivityDetailValue'
import getNormalizedActivityErrors from 'utils/getNormalizedActivityErrors'
import { usePersonDetails } from 'hooks'

const IconDictionary = {
  lead: CreateIcon,
  certificate: PlaylistAddCheckIcon,
  distribution: SendIcon,
  bestcustomer: ThumbUpIcon,
  prospect: PhoneIcon,
  policy: AssigmentIcon,
  probability: CasinoIcon,
  application: PostAddIcon,
  update: UpdateIcon,
  share: ShareIcon,
  scrub: ScrubIcon
}
export const Details = ({ entity, items, showAlert, userRole }) => {
  const [lead, setLead] = useState(null)
  const { details: person } = usePersonDetails(lead?.personId)

  const {
    piiMask,
    actions: {
      lead: { viewActivityWarning }
    }
  } = userRole

  async function fetchLeadInfo(id) {
    const leadInfo = await leadsRepository.findOne(id)

    setLead(leadInfo)
  }

  async function fetchActivityInfo(id) {
    const personId = await leadsRepository.findPersonId(id)
    const activityInfo = await activitiesRepository.findByLead(personId, id)

    const activityList = activityInfo.map(
      ({
        communication,
        date,
        details: { startDate, endDate, ...details },
        errors,
        title,
        ...activity
      }) => {
        const fields = Object.keys(details).filter(
          field => details[field] !== undefined
        )
        const { isSuccessful } = details

        return {
          ...activity,
          additionalInfo: !piiMask && communication,
          date: new Date(date),
          details: fields.map(field => ({
            label: `${LeadPersonActivityDetailLabel[field] || 'Unknown'}:`,
            value: calculateLeadPersonActivityDetailValue({
              details,
              field
            }),
            onClick: ['sharedLeadId', 'originLeadId'].includes(field)
              ? () => {
                  window.open(`/leads/${details[field]}`, '_blank')
                }
              : null
          })),
          errors: getNormalizedActivityErrors({
            errors,
            isSuccessful,
            viewActivityWarning
          }),
          expandableKeys: ['Warning'],
          icon: IconDictionary[activity.type],
          isSuccessful: details.isSuccessful,
          title: <BoldText text={title} />
        }
      }
    )

    return activityList
  }

  const tabs = lead &&
    person && [
      {
        label: 'Details',
        content: <Overview lead={lead} person={person} piiMask={piiMask} />
      }
    ]

  return (
    <CommonDetails
      entity={entity}
      items={items}
      piiMask={piiMask}
      showAlert={showAlert}
      subtitle={`Added ${
        lead &&
        formatDistanceToNow(lead.date, {
          addSuffix: true,
          includeSeconds: true
        })
      }`}
      tabs={tabs}
      title={person?.fullName || ''}
      onFetchActivity={fetchActivityInfo}
      onLoadInfo={fetchLeadInfo}
    />
  )
}

Details.defaultProps = {
  items: [],
  userRole: {
    piiMask: false
  }
}

Details.propTypes = {
  entity: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  showAlert: PropTypes.func.isRequired,
  userRole: PropTypes.instanceOf(Object)
}

export default React.memo(Details)
