import { routeRegexType } from 'models'

const { appEntityPathRegex, errorPathRegex } = routeRegexType
const defaultPath = process.env.REACT_APP_DEFAULT_PATH

const getNavigateTo = (pathname, renderPath, search) => {
  const [, searchFrom] = search.split('from=')
  const [appEntityPath, matchedEntityPath] =
    appEntityPathRegex.exec(searchFrom) || []

  if (matchedEntityPath) return appEntityPath

  const hasErrorRenderPath = errorPathRegex.test(pathname)
  const to = `${renderPath}?from=${
    (hasErrorRenderPath && searchFrom
      ? searchFrom
      : `${pathname || ''}${searchFrom || ''}`) || defaultPath
  }`
  return to
}

export default getNavigateTo
