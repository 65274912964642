import { SourceFlows } from 'models'
import getRawFilteredSourceFlows from 'utils/getRawFilteredSourceFlows'
import Repository from './repository'

class SourcesFlows extends Repository {
  resource = 'sourcefiltersets'

  find = async ({ filters }) => {
    const results = await this.get({
      isArray: true,
      ...(!filters?.length ? { mapTo: SourceFlows } : {})
    })

    if (!filters?.length) return results

    // TODO: remove this function when filter can be passed to API
    return getRawFilteredSourceFlows(results, filters).map(
      result =>
        new SourceFlows({
          ...result,
          campaign: result.campaign,
          sourceCode: result.sourceCode,
          cid: result.cid
        })
    )
  }
}

export default SourcesFlows
