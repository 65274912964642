import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import LifeCycle from 'components/shared/LifeCycle'
import { LeadsContainer } from 'components/Leads'
import { DetailsContainer } from 'components/Leads/Details'
import { RedirectRenderPath } from 'components/shared'
import { MonitoringCodes } from '../models'

const Leads = ({ viewDetail, viewFlow }) => (
  <Routes>
    <Route exact element={<LeadsContainer />} path="/" />
    {viewFlow ? (
      <Route element={<LifeCycle />} path="lifecycle/:leadId" />
    ) : null}
    {viewDetail ? <Route element={<DetailsContainer />} path=":id" /> : null}
    <Route element={<RedirectRenderPath renderPath="/leads/" />} path="*" />
  </Routes>
)

Leads.propTypes = {
  viewDetail: PropTypes.bool.isRequired,
  viewFlow: PropTypes.bool.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  Leads,
  `${MonitoringCodes.PAGE}Leads`
)
