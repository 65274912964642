import { DefaultConfiguration, Favorite } from 'models'
import Repository from './repository'

const lmSetupEndpointQuery = `endpoint=${process.env.REACT_APP_LM_SETUP_ENDPOINT}`

const getBaseQuerySet = key => ({
  query: lmSetupEndpointQuery,
  params: {
    parameters: `key=${key}`
  }
})

class Configurations extends Repository {
  resource = 'configurations'

  findConfig = async (key, MapTo, isToArray = true) => {
    try {
      const configuration = await this.get({
        ...getBaseQuerySet(key)
      })
      if (!configuration) return isToArray ? [] : {}
      const result = configuration
      if (!MapTo) return result

      return isToArray ? result.map(item => new MapTo(item)) : new MapTo(result)
    } catch {
      return isToArray ? [] : null
    }
  }

  findDefaultConfiguration = entity =>
    this.findConfig(`${entity}.default`, DefaultConfiguration, false)

  findPermissions = () => this.findConfig('role.permissions')

  findCommonFilters = () => this.findConfig('filters.common')

  findDateField = async entity => this.findConfig(`${entity}.fields.date`)

  // TODO: Move job favorites to another API repository
  findJobStats = async () => {
    const stats = await this.findConfig('job.stats')
    return stats.map(jobFavorite => new Favorite(jobFavorite))
  }

  findJobTypes = () => this.findConfig('job.types')

  getSourceFlowColumnIds = () => this.findConfig('source.searchColumnIds')
}

export default Configurations
