import * as go from 'gojs'

import { failureColor, lightBlueColor } from '../constants'

export function getTooltipTemplate($) {
  // define the appearance of tooltips, shared by various templates
  const tooltiptemplate = $(
    'ToolTip',
    $(
      go.Panel,
      'Auto',
      {
        background: 'white'
      },
      $(
        go.Shape,
        'Annotation', // A left bracket shape
        {
          cursor: 'pointer',
          fromSpot: go.Spot.Left,
          strokeWidth: 2
        },
        new go.Binding('stroke', '', ({ hasError, isSelected }) => {
          const withError = hasError !== undefined && hasError

          if (isSelected === null)
            return withError ? failureColor : lightBlueColor
          return withError || !isSelected ? failureColor : 'green'
        })
      ),
      $(
        go.TextBlock,
        { margin: new go.Margin(20, 20, 20, 30), width: 250 },
        new go.Binding('text', 'comments').makeTwoWay()
      )
    )
  )
  return tooltiptemplate
}

export default { getTooltipTemplate }
