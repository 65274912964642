import * as go from 'gojs'

import {
  lightBlueColor,
  ActivityNodeStrokeWidth,
  ActivityNodeWidth,
  ActivityNodeHeight
} from '../constants'
import {
  getLMUrlCursor,
  getPanelShapeColor,
  getTextBlockColor,
  getDefaultLayout,
  handleErrorColor,
  openLMUrl
} from '../helper'
import { getTaskScriptIconTemplate } from './taskScriptIcon'
import { getTooltipTemplate } from './tooltip'

export function getSubProcessGroupTemplate($) {
  // ------------------------------------------  private process Node Template Map   ----------------------------------------------
  // sub-process panel
  function makeSubprocessPanel() {
    return $(
      go.Panel,
      'Horizontal',
      {
        alignment: go.Spot.MiddleBottom,
        alignmentFocus: go.Spot.MiddleBottom
      },
      $('SubGraphExpanderButton', {
        alignment: go.Spot.Right,
        margin: 5,
        click: (e, button) => {
          let group = button.part
          if (group instanceof go.Adornment) group = group.adornedPart
          if (!(group instanceof go.Group)) return
          const groupDiagram = group.diagram
          if (groupDiagram === null) return
          const cmd = groupDiagram.commandHandler
          e.handled = true

          groupDiagram.startTransaction('expand-collapse')
          if (group.isSubGraphExpanded) cmd.collapseSubGraph(group)
          else cmd.expandSubGraph(group)
          groupDiagram.commitTransaction('expand-collapse')
        }
      })
    ) // end activity markers horizontal panel
  }

  const subProcessGroupTemplate = $(
    go.Group,
    'Spot',
    {
      locationSpot: go.Spot.Center,
      locationObjectName: 'PH',
      isSubGraphExpanded: false,
      layout: getDefaultLayout($)
    },
    {
      click: openLMUrl
    },
    new go.Binding('itemArray', 'boundaryEventArray'),
    new go.Binding('toolTip', 'comments', () => getTooltipTemplate($)),
    $(
      go.Panel,
      'Auto',
      $(
        go.Shape,
        'RoundedRectangle',
        {
          name: 'PH',
          stroke: lightBlueColor,
          strokeWidth: ActivityNodeStrokeWidth,
          minSize: new go.Size(ActivityNodeWidth, ActivityNodeHeight),
          portId: '',
          fromLinkable: true,
          toLinkable: true,
          cursor: 'pointer',
          fromSpot: go.Spot.RightSide,
          toSpot: go.Spot.LeftSide
        },
        getPanelShapeColor(),
        new go.Binding('stroke', 'hasError', handleErrorColor)
      ),
      getTaskScriptIconTemplate($),
      $(
        go.Panel,
        'Vertical',
        {
          margin: 3
        },
        $(
          go.TextBlock, // label
          new go.Binding('text', 'text').makeTwoWay(),
          getLMUrlCursor(),
          getTextBlockColor()
        ),
        $(
          go.TextBlock, // the center text
          {
            alignment: go.Spot.Bottom,
            font: 'bold 13px sans-serif',
            textAlign: 'center',
            margin: 10,
            maxLines: 1,
            overflow: go.TextBlock.OverflowEllipsis
          },
          new go.Binding('text', 'detail', s => `(${s})`).makeTwoWay(),
          getLMUrlCursor(),
          getTextBlockColor()
        ),
        // create a placeholder to represent the area where the contents of the group are
        $(go.Placeholder, {
          padding: new go.Margin(5, 5)
        }),
        makeSubprocessPanel() // sub-process button panel
      ) // end Vertical Panel
    )
  ) // end Group

  return subProcessGroupTemplate
}

export default { getSubProcessGroupTemplate }
