import { all, put, call, select, takeLatest } from 'redux-saga/effects'

import { filters as filtersModule, results, ui } from 'store/modules'
import { EntityType } from 'models'
import getGUIDValidation from 'utils/getGuidValidation'
import { getKey } from 'services/sessionStorage'
import FetchingType from 'models/fetchingType'
import { buildDates, FormatTypes, OptionsTypes } from 'utils/datePresets'
import getValidFilterDate from 'utils/getValidFiltersDate'
import {
  fetchAutoComplete,
  fetchCustomFieldsConfiguration,
  fetchDefaultConfiguration,
  fetchFiltersCommonConfiguration,
  filterJobs,
  filterLeads,
  filterPersons,
  filterSchedules,
  filterSourceFlows
} from './api'

const { actions, actionTypes, selectors } = filtersModule

const getResults = {
  Lead: filterLeads,
  Job: filterJobs,
  Schedule: filterSchedules,
  Source: filterSourceFlows,
  Person: filterPersons
}

const getUpdatedPreset = (presets, format = '') => {
  const [preset] = presets
  const {
    filter: { field, operator, value, value2 },
    id,
    selectedValue
  } = preset
  const isDateRange = selectedValue === OptionsTypes.DateRange

  const { from, to } = buildDates({
    format,
    option: selectedValue,
    from: isDateRange ? getValidFilterDate(value) : null,
    to: isDateRange ? getValidFilterDate(value2) : null
  })

  return {
    field,
    id,
    operator,
    selectedValue,
    value: from,
    value2: to
  }
}

const getPresetValues = (presets, format) => {
  if (presets?.length) return getUpdatedPreset(presets, format)
  const { from: value, to: value2 } = buildDates({
    format,
    option: OptionsTypes.Last30Days,
    from: new Date()
  })
  return { value, value2 }
}

// TODO: move to LM then update tests
const getDefaultAdvancedFilter = entity => ({
  field: {
    path:
      entity === EntityType.Job || entity === EntityType.Schedule
        ? 'RowKey'
        : 'Id',
    type: 'guid'
  },
  operator: {
    id: 'eq',
    query:
      entity === EntityType.Job || entity === EntityType.Schedule
        ? "[path] eq '[value]'"
        : '[path] eq [value]'
  },
  value: null
})

const getInitialValues = (presets, entity) =>
  presets.map(preset => {
    const presetCopy = { ...preset }
    const { selectedValue, format } = preset

    // TODO: In the future if we have more presets we will need to ad a switch or dictionary to apply
    // a specific logic per preset. For now we only have DatePreset
    if (selectedValue) {
      const sessionPresetDate = getKey(`${entity}.presetDate`) || {}
      const currentSelectedValue =
        sessionPresetDate?.selectedValue || selectedValue
      const isDateRange = currentSelectedValue === OptionsTypes.DateRange

      const { from, to } = buildDates({
        format,
        option: currentSelectedValue,
        from:
          (isDateRange ? getValidFilterDate(sessionPresetDate?.from) : null) ||
          new Date(),
        to:
          (isDateRange ? getValidFilterDate(sessionPresetDate?.to) : null) ||
          new Date()
      })

      presetCopy.filter.value = from
      presetCopy.filter.value2 = to
      presetCopy.selectedValue = currentSelectedValue
    }
    return presetCopy
  })

const getResultsSort = columns => columns.find(column => column.defaultSort)

const getHandledCustomPresets = customPresets =>
  customPresets.map(customPreset => {
    const customPresetCopy = { ...customPreset }
    const { selectedValue, filter } = customPresetCopy
    if (selectedValue && filter?.field)
      customPresetCopy.filter.value = selectedValue
    return customPresetCopy
  })

const getFiltersResult = (advanced, allFilters, entity) => {
  const [, RowKey] = advanced?.value.split('@') ?? []

  return !RowKey
    ? (allFilters.length && {
        filters: allFilters.filter(
          ({ id }) => id !== `filter-${entity.toLowerCase()}presetdate`
        )
      }) ||
        {}
    : (getGUIDValidation(RowKey) && {
        filters: [{ ...advanced, value: RowKey }]
      }) ||
        {}
}

export function* getFilters() {
  const { getPreset, getDefault, getAll, getCustomPresets } = selectors
  const [rawPresets, rawCustomPresets, rawFilters, rawCustomFilters] =
    yield all([
      select(getPreset),
      select(getCustomPresets),
      select(getDefault),
      select(getAll)
    ])

  const presets = rawPresets.map(rawPreset => rawPreset.filter)
  const customPresets =
    rawCustomPresets
      ?.map(preset => preset.filter)
      .filter(filter => filter?.value) || []

  return {
    filters: [...presets, ...customPresets, ...rawFilters, ...rawCustomFilters],
    presets
  }
}

export function* applyFilters() {
  const entity = yield select(ui.selectors.getEntity)

  try {
    yield put(results.actions.fetching())

    const presets = []
    const allFilters = []
    const advanced = yield select(selectors.getAdvancedFilter)
    if (!advanced) {
      const { filters, presets: defaultPresets } = yield call(getFilters)
      const filterPresets = yield select(selectors.getPreset)

      presets.push(...(filterPresets.length ? filterPresets : defaultPresets))
      allFilters.push(...filters)
    } else allFilters.push(advanced)
    const pagination = yield select(results.selectors.getPagination)
    const sort = yield select(results.selectors.getSort)
    const by = yield select(results.selectors.getSortByColumnQueryName)

    switch (entity) {
      case EntityType.Source: {
        const filteredFlows = yield call(
          getResults[entity],
          getFiltersResult(advanced, allFilters, entity)
        )

        yield put(
          results.actions.set({
            items: filteredFlows,
            total: filteredFlows.length
          })
        )
        break
      }
      case EntityType.Schedule:
      case EntityType.Job: {
        const { value, value2 } = getPresetValues(
          presets,
          FormatTypes.shortdate
        )

        const filteredResults = yield call(getResults[entity], {
          start: value,
          end: value2,
          ...getFiltersResult(advanced, allFilters, entity)
        })

        yield put(
          results.actions.set({
            items: filteredResults,
            total: filteredResults.length
          })
        )
        break
      }
      default: {
        const updatedPresets = presets?.length ? [getPresetValues(presets)] : []

        const filteredLeads = yield call(getResults[entity], {
          filters: [
            ...(getFiltersResult(advanced, allFilters, entity)?.filters || []),
            ...updatedPresets
          ],
          pagination,
          sort: { by, isAsc: sort.isAsc }
        })

        yield put(
          results.actions.set({
            items: filteredLeads.data,
            total: filteredLeads.count
          })
        )

        break
      }
    }
  } catch (error) {
    yield put(
      ui.actions.showAlert({
        error,
        message: `Oops!, something went wrong filtering ${entity}.`
      })
    )
  } finally {
    yield put(results.actions.fetched())
  }
}

export function* getFieldsWithAutoComplete(fields) {
  const fieldsCopy = fields.slice()

  for (let i = 0; i < fieldsCopy.length; i += 1) {
    const field = fieldsCopy[i]

    if (field.useDynamicValues) {
      const autocomplete = yield call(fetchAutoComplete, field.label)

      if (autocomplete) {
        if (!field.valueOptions) field.valueOptions = []
        const { operators, values } = autocomplete

        field.valueOptions.push(
          ...values.map(label => ({
            isUnique: true,
            label,
            operators,
            value: label
          }))
        )
      }
    }
  }

  return fieldsCopy
}

export function* setUpDefault() {
  const entity = yield select(ui.selectors.getEntity)

  try {
    yield put(results.actions.fetching())

    const {
      columns,
      customPresets,
      defaultFilters,
      fields,
      filterPreset,
      presets
    } = yield call(fetchDefaultConfiguration, entity.toLowerCase())

    const fieldsWithAutoComplete = yield* getFieldsWithAutoComplete(fields)

    yield put(
      actions.updateConfiguration({
        fields: fieldsWithAutoComplete
      })
    )

    yield put(actions.setPreset(getInitialValues(presets, entity)))

    yield put(actions.setCustomPresets(getHandledCustomPresets(customPresets)))

    yield put(results.actions.setColumns(columns))

    yield put(results.actions.setSortBy(getResultsSort(columns)?.name ?? ''))

    yield put(actions.setDefault(defaultFilters))

    yield put(actions.setAdvanced(getDefaultAdvancedFilter(entity)))

    yield put(actions.setFilterPreset(filterPreset))
  } catch (error) {
    yield put(
      ui.actions.showAlert({
        error,
        message: `Oops!, something went wrong loading filters default configuration.`
      })
    )
  } finally {
    yield put(actions.fetched(FetchingType.default))
  }
}

export function* setUpCommonConfiguration() {
  const filterFieldTypes = yield select(selectors.getFieldTypes)
  const filterOperators = yield select(selectors.getOperators)

  if (!filterFieldTypes.length && !filterOperators.length) {
    try {
      yield put(actions.fetching())

      const { fieldTypes, operators } = yield call(
        fetchFiltersCommonConfiguration
      )

      yield put(
        actions.updateConfiguration({
          fieldTypes,
          operators
        })
      )
    } catch (error) {
      yield put(
        ui.actions.showAlert({
          error,
          message: `Oops!, something went wrong loading filters common configuration.`
        })
      )
    } finally {
      yield put(actions.fetched(FetchingType.common))
    }
  }
}

export function* setUpCustomFieldsConfiguration() {
  const entity = yield select(ui.selectors.getEntity)

  try {
    yield put(actions.fetching())

    const customFields = yield call(fetchCustomFieldsConfiguration, entity)

    yield put(
      actions.updateConfiguration({
        customFields
      })
    )
  } catch (error) {
    yield put(
      ui.actions.showAlert({
        error,
        message: `Oops!, something went wrong loading filters custom fields configuration.`
      })
    )
  } finally {
    yield put(actions.fetched(FetchingType.custom))
  }
}

export function* handleFetch() {
  const isFetching = yield select(selectors.getFetching)

  if (!isFetching) yield put(actions.apply())
}

const filtersSaga = [
  takeLatest(actionTypes.APPLY, applyFilters),
  takeLatest(actionTypes.FETCH_DEFAULT, setUpDefault),
  takeLatest(actionTypes.FETCH_COMMON_CONFIGURATION, setUpCommonConfiguration),
  takeLatest(
    actionTypes.FETCH_CUSTOM_FIELDS_CONFIGURATION,
    setUpCustomFieldsConfiguration
  ),
  takeLatest(actionTypes.FETCHED, handleFetch)
]

export default filtersSaga
