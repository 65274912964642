const NAME = 'favorites'

const actionTypes = {
  RESET: `${NAME}/RESET`,
  SELECT_FOR_FILTERS: `${NAME}/SELECT`,
  SET_SELECTED_LABEL: `${NAME}/SET_SELECTED_LABEL`
}

const actions = {
  selectForFilters: ({ filters, label }) => ({
    type: actionTypes.SELECT_FOR_FILTERS,
    payload: { filters, label }
  }),
  setSelectedLabel: label => ({
    type: actionTypes.SET_SELECTED_LABEL,
    payload: { label }
  }),
  reset: () => ({
    type: actionTypes.RESET
  })
}

export const initialState = {
  selectedItemLabel: ''
}

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.RESET:
      return initialState

    case actionTypes.SET_SELECTED_LABEL:
      return { ...state, selectedItemLabel: payload.label }

    default:
      return state
  }
}

const getSelectedLabel = state => state[NAME].selectedItemLabel

const selectors = {
  getSelectedLabel
}

const favorites = {
  namespace: NAME,
  initialState,
  actionTypes,
  actions,
  reducer,
  selectors
}

export default favorites
