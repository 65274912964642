import getUtcTimeZonedDiff from './getUtcTimeZonedDiff'

export const getDate = (date, time) => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hour = time.getHours()
  const min = time.getMinutes()

  return new Date(year, month, day, hour, min, 0, 0)
}

const getStartTimeValidation = (clearErrors, { startDate, startTime }) => {
  const hasValidTime = !Number.isNaN((startDate && startTime).getTime())
  const getHasStartTimeZoneDiffLower = () => {
    const date = getDate(startDate, startTime)
    return getUtcTimeZonedDiff(date) <= 5
  }
  if (hasValidTime && getHasStartTimeZoneDiffLower()) {
    const errorMessage = 'Should be greater than 5 minutes of New York time'
    return { 'start-time': errorMessage }
  }
  clearErrors.push('start-time')
  return {}
}
const getEndDateValidation = (clearErrors, schedule) => {
  const { addEndDate, endDate, endTime, startDate, startTime } = schedule
  let errorMessage = ''
  if (addEndDate) {
    if (!endDate || !endTime)
      errorMessage = 'End Date and End Time are required.'
    else {
      const start = getDate(startDate, startTime)
      const end = getDate(endDate, endTime)
      if (end < start)
        errorMessage = 'End date cannot be lower than start date.'
    }
  } else clearErrors.push('endDate', 'endTime')
  if (!errorMessage) clearErrors.push('start-end-date')
  return errorMessage ? { 'start-end-date': errorMessage } : {}
}

const validateScheduleForm = schedule => {
  const clearErrors = []
  const { addEndDate, endDate, endTime, startDate, startTime } = schedule
  const startTimeValidation = getStartTimeValidation(clearErrors, {
    startDate,
    startTime
  })
  const endDateValidation = getEndDateValidation(clearErrors, {
    addEndDate,
    endDate,
    endTime,
    startDate,
    startTime
  })

  return {
    errors: { ...endDateValidation, ...startTimeValidation },
    clearErrors
  }
}

export default validateScheduleForm
