import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { RedirectRenderPath } from 'components/shared'
import { PersonsContainer } from 'components/Persons'
import { DetailsContainer } from 'components/Persons/Details'
import { MonitoringCodes } from '../models'

const Persons = ({ viewDetail }) => (
  <Routes>
    <Route exact element={<PersonsContainer />} path="/" />
    {viewDetail ? <Route element={<DetailsContainer />} path=":id" /> : null}
    <Route element={<RedirectRenderPath renderPath="/persons/" />} path="*" />
  </Routes>
)

Persons.propTypes = {
  viewDetail: PropTypes.bool.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  Persons,
  `${MonitoringCodes.PAGE}Persons`
)
