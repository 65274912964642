import React, { memo, useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Box, Tabs } from '@mui/material'
import PropTypes from 'prop-types'

import { TabPanel } from 'components/ui'
import BpmnGojs from 'components/shared/BpmnGojs'
import { StyledLoader, StyledTab } from './styles'

const LifeCycleContent = ({ sourceFlow }) => {
  const { flows } = sourceFlow || {}
  const [tabValue, setTabvalue] = useState(0)
  const handleTabsChange = (_event, value) => setTabvalue(value)

  useEffect(() => {
    setTabvalue(0)
  }, [sourceFlow])

  return flows ? (
    <>
      <Tabs
        aria-label="source code tabs"
        indicatorColor="primary"
        textColor="primary"
        value={tabValue}
        variant="fullWidth"
        onChange={handleTabsChange}
      >
        {flows.map((item, index) => {
          const flowKey = item.key
          return (
            <StyledTab
              key={`sourceDialogTab-${flowKey}`}
              label={flowKey}
              value={index}
            />
          )
        })}
      </Tabs>
      <SwipeableViews axis="x" index={tabValue} onChangeIndex={setTabvalue}>
        {flows.map((item, index) =>
          index === tabValue ? (
            <TabPanel
              key={`sourceDialogContent-${item.key}`}
              {...{ index }}
              value={tabValue}
            >
              <Box>
                <BpmnGojs diagramCode={item} />
              </Box>
            </TabPanel>
          ) : (
            <div key="sourceDialogContent-empty" />
          )
        )}
      </SwipeableViews>
    </>
  ) : (
    <Box>
      <StyledLoader />
    </Box>
  )
}

LifeCycleContent.defaultProps = {
  sourceFlow: {}
}

LifeCycleContent.propTypes = {
  sourceFlow: PropTypes.instanceOf(Object)
}

export default memo(LifeCycleContent)
