const NAME = 'lead'

const actionTypes = {
  TRIGGER_OPERATION: `${NAME}/TRIGGER_OPERATION`,
  SET_REQUEST_RESULT: `${NAME}/SET_REQUEST_RESULT`
}

const actions = {
  triggerOperation: ({
    actionType,
    userId,
    target = null,
    query: { selectFields = '', expand = '', top = '', skip = '' },
    exportFields
  }) => ({
    type: actionTypes.TRIGGER_OPERATION,
    payload: {
      actionType,
      userId,
      target,
      query: { selectFields, expand, top, skip },
      exportFields
    }
  }),
  setRequestResult: ({ exportedFile, requestId }) => ({
    type: actionTypes.SET_REQUEST_RESULT,
    payload: {
      exportedFile,
      requestId
    }
  })
}

const initialState = {
  requestResult: {
    exportedFile: null,
    requestId: null
  }
}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_REQUEST_RESULT:
      return {
        ...state,
        requestResult: {
          requestId: payload.requestId,
          exportedFile: payload.exportedFile
        }
      }

    default:
      return state
  }
}

const getRequestResult = state => state[NAME].requestResult

const selectors = {
  getRequestResult
}

const lead = {
  namespace: NAME,
  actionTypes,
  actions,
  initialState,
  selectors,
  reducer
}

export default lead
