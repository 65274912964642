import { AppInsightsHelper } from '@tranzact/platform_react-components'

import { isNumber } from 'utils/dataTypes'
import { MonitoringCodes } from 'models/monitoring'

const appPrefix = 'lmkt'

export const userSettingsKey = 'user.settings'

export const setKey = (key, value) => {
  if (!key) return
  localStorage.setItem(
    `${appPrefix}.${key}`,
    !isNumber(value) ? JSON.stringify(value) : value
  )
}

export const getKey = key => {
  if (!key) return null
  return JSON.parse(localStorage.getItem(`${appPrefix}.${key}`))
}

const checkLocalStorage = () => {
  try {
    const testSetting = '__test_setting_'
    localStorage.setItem(testSetting, testSetting)
    localStorage.removeItem(testSetting)
    return true
  } catch (err) {
    // TODO: move this on startup to show a snackbar once is ready
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Repository_LocalStorage: ${err}`
    )
    return false
  }
}

export const isLocalStorageAvailable = checkLocalStorage()
