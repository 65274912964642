import styled from '@emotion/styled'
import { Chip, Grid } from '@mui/material'

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  > * {
    width: 100%;
  }
`

export const DetailsSection = styled.div`
  padding-right: ${props => `${props.theme.spacing(2)}`};
`

export const FieldsGrid = styled(Grid)`
  padding: ${props => props.theme.spacing(1, 0, 2, 2)};
`

export const StyledChip = styled(Chip)`
  margin: ${props => props.theme.spacing(0, 1, 1, 0)};
`

export const StyledGrid = styled(Grid)`
  & > div {
    word-break: break-word;
  }
`
