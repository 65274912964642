import { isValid } from 'date-fns'

function getValidFilterDate(date) {
  if (!date) return new Date()
  const currentDate = new Date(date)

  if (isValid(currentDate)) return currentDate
  const [, year, month, day] = /^(\d{4})(\d{2})(\d{2})$/.exec(date) || []

  if (year) return new Date(year, month - 1, day)
  return new Date()
}

export default getValidFilterDate
