import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import transformToExpression from 'utils/cron'
import dateStringToDate from 'utils/dateStringToDate'
import timeStringToDateTime from 'utils/timeStringToDateTime'
import SystemTimeZone from './systemTimeZone'

const { timeZone } = SystemTimeZone

const getEnabled = stopped => (stopped ? 'Disabled' : 'Active')

class Schedule {
  constructor(schedule) {
    if (!schedule) return
    this.id = schedule.id
    this.description = schedule.description
    this.type = schedule.type
    this.startDate = new Date(schedule.startDate)
    this.endDate = new Date(schedule.endDate)
    this.created = zonedTimeToUtc(schedule.created, timeZone)
    this.createdWithFormat = format(
      zonedTimeToUtc(schedule.created, timeZone),
      'MM/dd/yyyy HH:mm:ss'
    )
    this.deleted = schedule.deleted ? new Date(schedule.deleted) : null
    this.enabled = getEnabled(schedule.stopped)
    this.enabledOriginalValue = schedule.enabled
    this.stopped = schedule.stopped
    this.tableCid = schedule.cid
    this.tablePid = schedule.pid
    this.tableRecurrence = schedule.recurrence

    // TODO: Find a way to get the schedule data as a JSOn instead of text in order to not use JSON.parse
    if (schedule.commands && schedule.commands.length) {
      const { context, cronExpression } = schedule.commands[0]

      this.configuration = transformToExpression(cronExpression)

      this.data = context?.schedule ? JSON.parse(context.schedule) : null
    }
    if (this.data) {
      const { dates, details, recurrence } = this.data

      this.name = schedule.description
      this.selectedFilterName = details?.selectedFilterName
      this.selectedFilter = details?.selectedFilter
      this.notes = details?.notes
      this.jobType = details?.jobType
      this.distributeResults = details?.distributeResults
      this.numberOfTopResults = details?.numberOfTopResults
      this.distributionSystem = details?.distributionParameters.selected
      this.distributionCid = details?.distributionParameters.cid
      this.distributionPid = details?.distributionParameters.pid
      this.startDate = dates && dateStringToDate(schedule.startDate)?.date
      this.startTime = dates && timeStringToDateTime(dates.startTime)
      this.endDate =
        schedule.endDate && dateStringToDate(schedule.endDate)?.date
      this.endTime = dates && timeStringToDateTime(dates.endTime)
      this.addEndDate = dates?.addEndDate
      this.recurrenceOption = recurrence?.recurrenceOption
      this.hourlyWeekDays = recurrence?.hourlyWeekDays
      this.repeatingHours = recurrence?.repeatingHours
      this.repeatingDays = recurrence?.repeatingDays
      this.usesBetween = recurrence?.usesBetween
      this.betweenHourStart = recurrence?.betweenHourStart
      this.betweenHourEnd = recurrence?.betweenHourEnd
      this.weeklyDays =
        typeof recurrence?.weeklyDays === 'string'
          ? [recurrence?.weeklyDays]
          : recurrence?.weeklyDays
      this.monthlyMonths = recurrence?.monthlyMonths
      this.monthlyDays = recurrence?.monthlyDays
    }
  }
}

export default Schedule
