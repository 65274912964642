import React from 'react'
import PropTypes from 'prop-types'
import { Typography, ListSubheader } from '@mui/material'

import { DetailsSection, FieldsGrid, StyledGrid } from './styles'

const OverviewLayoutDetails = ({ sections }) => sections.map(({ name, fields }) => (
    <DetailsSection key={`section-${name}`}>
      <ListSubheader component="div">{name}</ListSubheader>
      <FieldsGrid container spacing={2}>
        {fields.map(({ key, value, width }) => (
          <StyledGrid key={`field-${key}`} item xs={width}>
            <Typography color="textSecondary" component="div" variant="caption">
              {key}
            </Typography>
            <Typography color="textPrimary" component="div" variant="body2">
              {value}
            </Typography>
          </StyledGrid>
        ))}
      </FieldsGrid>
    </DetailsSection>
  ))

OverviewLayoutDetails.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired
}

export default OverviewLayoutDetails
