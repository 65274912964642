import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Grid, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material'

import { RecurrenceType, ScheduleFormField } from 'models'
import Hourly from './Hourly'
import Daily from './Daily'
import Weekly from './Weekly'
import Monthly from './Monthly'
import FormControlLabel from './styles'

const {
  BetweenHourEnd,
  BetweenHourStart,
  DailyRepeatingDays,
  HourlyRepeatingHours,
  MonthlyDays,
  MonthlyMonths,
  RecurrenceOption,
  UsesBetween,
  WeeklyDays
} = ScheduleFormField

const Recurrence = ({ isReadonly }) => {
  const {
    control,
    formState: { submitCount },
    setValue,
    trigger,
    watch
  } = useFormContext()
  const watchedRecurrenceOption = watch(RecurrenceOption)

  const handleRecurrenceOptionChange = onChange => event => {
    onChange(event)

    setValue(BetweenHourEnd, '')
    setValue(BetweenHourStart, '')
    setValue(DailyRepeatingDays, null)
    setValue(HourlyRepeatingHours, null)
    setValue(MonthlyDays, [])
    setValue(MonthlyMonths, [])
    setValue(UsesBetween, false)
    setValue(WeeklyDays, [])

    if (submitCount > 0) {
      trigger([
        BetweenHourEnd,
        BetweenHourStart,
        DailyRepeatingDays,
        HourlyRepeatingHours,
        MonthlyDays,
        MonthlyMonths,
        WeeklyDays
      ])
    }
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <Controller
          control={control}
          name={RecurrenceOption}
          render={({ field }) => {
            const { name, onChange } = field

            return (
              <FormControl>
                <FormLabel>Recurrence</FormLabel>
                <RadioGroup
                  {...field}
                  onChange={handleRecurrenceOptionChange(onChange)}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        disabled={isReadonly}
                        inputProps={{ name }}
                      />
                    }
                    label="Once"
                    value={RecurrenceType.Once}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        disabled={isReadonly}
                        inputProps={{ name }}
                      />
                    }
                    label="Hourly"
                    value={RecurrenceType.Hourly}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        disabled={isReadonly}
                        inputProps={{ name }}
                      />
                    }
                    label="Daily"
                    value={RecurrenceType.Daily}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        disabled={isReadonly}
                        inputProps={{ name }}
                      />
                    }
                    label="Weekly"
                    value={RecurrenceType.Weekly}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        disabled={isReadonly}
                        inputProps={{ name }}
                      />
                    }
                    label="Monthly"
                    value={RecurrenceType.Monthly}
                  />
                </RadioGroup>
              </FormControl>
            )
          }}
        />
      </Grid>
      <Grid item xs={9}>
        {watchedRecurrenceOption === RecurrenceType.Once && (
          <p>This event will happen only once.</p>
        )}
        {watchedRecurrenceOption === RecurrenceType.Hourly && (
          <Hourly isReadonly={isReadonly} />
        )}
        {watchedRecurrenceOption === RecurrenceType.Daily && (
          <Daily isReadonly={isReadonly} />
        )}
        {watchedRecurrenceOption === RecurrenceType.Weekly && (
          <Weekly isReadonly={isReadonly} />
        )}
        {watchedRecurrenceOption === RecurrenceType.Monthly && (
          <Monthly isReadonly={isReadonly} />
        )}
      </Grid>
    </Grid>
  )
}

Recurrence.defaultProps = {
  isReadonly: false
}

Recurrence.propTypes = {
  isReadonly: PropTypes.bool
}

export default Recurrence
