import React from 'react'
import ReactDOM from 'react-dom/client'

import auth from 'services/auth'
import hub from 'services/hub'
import AppInsightMonitoring from 'services/appInsightMonitoring'
import { AppContainer } from './components/App'

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('plugins/whyDidYouRender')
  whyDidYouRender.default(React)
}

function buildApp(authContext, hasServerResponse, isAuthorized, userRole) {
  // eslint-disable-next-line global-require
  const store = require('store').default
  // eslint-disable-next-line global-require
  const { Provider } = require('react-redux')

  AppInsightMonitoring.init(authContext)

  return (
    <Provider store={store}>
      <AppContainer
        authContext={authContext}
        hasServerResponse={hasServerResponse}
        hubService={hub}
        isAuthorized={isAuthorized}
        userRole={userRole}
      />
    </Provider>
  )
}

auth(({ authContext, hasServerResponse, isAuthorized, userRole }) => {
  document.getElementById('root-loading').className = 'inactive'
  setTimeout(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'))
    root.render(
      buildApp(authContext, hasServerResponse, isAuthorized, userRole)
    )
  }, 2000)
})
