import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EntityHeader } from '@tranzact/platform_react-components'
import Skeleton from '@mui/material/Skeleton'

import { useAutocomplete, useFavorites } from 'hooks'
import { FavoritesDialogContainer } from 'components/shared'

const Header = ({
  clearedQuery,
  customFields,
  fields,
  fieldTypes,
  filters,
  filtersQuery,
  isDeleteFavoriteAllowed,
  isFavoriteListAllowed,
  isSaveAllowed,
  loadingFilters,
  loadingResults,
  operators,
  selectedFavoriteLabel,
  clearFilters,
  removeFilter,
  saveFilters,
  updateSearch,
  onClearedQuery,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const {
    continuationToken,
    favorites,
    loading,
    loadingMore,
    clearSearch,
    deleteFavorite,
    load,
    reset,
    search,
    selectForFilters
  } = useFavorites('Jobs')
  const [autocompleteProps, { clearError }] = useAutocomplete({
    clearedQuery,
    customFields,
    fields,
    fieldTypes,
    filterItems: filters,
    filtersQuery,
    loadingFilters,
    historyMetadata: { favorite: selectedFavoriteLabel },
    operators,
    title: selectedFavoriteLabel,
    clearFilters,
    removeFilter,
    saveFilters,
    onClearedQuery,
    onSearch: updateSearch
  })

  const isSaveEnabled =
    !loadingResults &&
    isSaveAllowed &&
    !selectedFavoriteLabel &&
    filtersQuery &&
    !autocompleteProps.autoCompleteTextError.error

  const toggleDialog = () => setOpenDialog(previous => !previous)

  useEffect(() => {
    if (selectedFavoriteLabel) clearError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFavoriteLabel])

  return (
    <>
      {loadingFilters ? (
        <Skeleton />
      ) : (
        <EntityHeader
          {...{
            autocompleteProps,
            favoriteProps: {
              cancelLabel: 'Cancel',
              customSearch: {
                onSearch: search,
                onClear: clearSearch
              },
              isFavoriteListAllowed,
              isLoading: loading,
              isSaveEnabled,
              items: favorites,
              load: load(),
              pagination: {
                onLoadMore: load(true),
                hasMore: !!continuationToken,
                isLoading: loadingMore
              },
              ...(isDeleteFavoriteAllowed
                ? {
                    secondaryActions: {
                      onDelete: deleteFavorite(favorites)
                    }
                  }
                : {}),
              onClose: reset,
              onSaveIconClick: toggleDialog,
              onSelect: selectForFilters
            },
            ...props
          }}
        />
      )}
      <FavoritesDialogContainer open={openDialog} onClose={toggleDialog} />
    </>
  )
}

Header.defaultProps = {
  isDeleteFavoriteAllowed: false,
  isSaveAllowed: true
}

Header.propTypes = {
  clearedQuery: PropTypes.string.isRequired,
  customFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fieldTypes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  filtersQuery: PropTypes.string.isRequired,
  isDeleteFavoriteAllowed: PropTypes.bool,
  isFavoriteListAllowed: PropTypes.bool.isRequired,
  isSaveAllowed: PropTypes.bool,
  loadingFilters: PropTypes.bool.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  operators: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  selectedFavoriteLabel: PropTypes.string.isRequired,
  clearFilters: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  saveFilters: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
  onClearedQuery: PropTypes.func.isRequired
}

export default Header
