import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  Select
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { ScheduleFormField } from 'models'
import MenuItem from './styles'

const Weekly = ({ isReadonly }) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext()
  const watchedWeeklyDays = watch(ScheduleFormField.WeeklyDays)

  const daysWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  const getArrangedEventDays = eventDays =>
    daysWeek.filter(day => eventDays.includes(day))

  const handleSelectedDays = onChange => e => {
    const eventCopy = {
      ...e,
      target: { ...e.target, value: getArrangedEventDays(e.target.value) }
    }

    onChange(eventCopy)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Repeat every week starting on:
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={ScheduleFormField.WeeklyDays}
          render={({ field }) => {
            const { name, onChange, ...otherProps } = field
            const error = errors[name]?.message
            const hasError = error !== undefined

            return (
              <FormControl fullWidth error={hasError} variant="outlined">
                <InputLabel>Days</InputLabel>
                <Select
                  {...otherProps}
                  multiple
                  disabled={isReadonly}
                  error={hasError}
                  inputProps={{ name }}
                  label="Days"
                  renderValue={selected => selected.join(', ')}
                  onChange={handleSelectedDays(onChange)}
                >
                  {daysWeek.map(day => (
                    <MenuItem key={day} value={day}>
                      <Checkbox checked={watchedWeeklyDays.indexOf(day) > -1} />
                      <ListItemText primary={day} />
                    </MenuItem>
                  ))}
                </Select>
                {hasError && <FormHelperText>{error}</FormHelperText>}
              </FormControl>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

Weekly.defaultProps = {
  isReadonly: false
}

Weekly.propTypes = {
  isReadonly: PropTypes.bool
}

export default Weekly
