import React from 'react'

import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Header } from './styles'

const Loader = () => <Header>
    <Box
      display="flex"
      justifyContent="space-around"
      marginBottom={1}
      paddingTop={1.2}
      width="100%"
    >
      <Skeleton height={30} width="20%" />
      <Skeleton height={30} width="20%" />
    </Box>
  </Header>

export default Loader
