import {
  BPMN_ACTIVITY_TYPE_ENUM,
  BPMN_EVENT_DIMENSION_TYPE,
  BPMN_EVENT,
  BPMN_EVENT_TYPE,
  BPMN_GATEWAY_TYPE
} from './constants'

let hasFailureFound = false
let handleNodes = null
let hasLightTheme = false
let hasTaskError = false

function handleGatewayNodes(nodes, gateway, currentNodeIndex) {
  let nodeIndex = currentNodeIndex
  const { flows } = gateway
  const gatewayNodes = []
  flows.forEach(item => {
    const { flow } = item
    const [flowNodes, flowNodeIndex] = handleNodes(flow, nodeIndex)
    gatewayNodes.push(...flowNodes)
    nodeIndex = flowNodeIndex
  })
  nodes.push(...gatewayNodes)
  return nodeIndex
}
function handleSubprocessNodes(subprocessesTasks, currentNodeIndex) {
  let nodeIndex = currentNodeIndex
  const nodes = []
  subprocessesTasks.forEach(subprocessTask => {
    const { subprocess, key } = subprocessTask
    const { flow } = subprocess
    const [subprocessNodes, subprocessNodeIndex] = handleNodes(
      flow,
      nodeIndex + 1, // skip link position
      key
    )
    nodes.push(...subprocessNodes)
    nodeIndex = subprocessNodeIndex
  })
  return nodes
}
function handleGateway(nodes, gateway, nodeIndex) {
  const { description, type } = gateway
  nodes.push({
    hasLightTheme,
    key: nodeIndex,
    category: 'gateway',
    gatewayType: BPMN_GATEWAY_TYPE[type] || BPMN_GATEWAY_TYPE.exclusive,
    ...(description ? { text: description } : {})
  })
}
function getComments(task) {
  const { comments } = task
  const regex = /^\[(detail|description)\]$/
  const match = comments.match(regex)
  if (match) return task[match[1]] || comments
  return comments
}
function handleActivity({ nodeIndex, nodes, task }) {
  const { comments, description, detail, hasError, ...otherProps } = task
  const { isSelected, subprocess, type, url } = otherProps
  const foundComments = comments && getComments(task)

  if (hasError !== undefined && !hasTaskError) hasTaskError = hasError
  nodes.push({
    hasLightTheme,
    category: subprocess ? 'subprocess' : 'activity',
    key: nodeIndex,
    taskType: BPMN_ACTIVITY_TYPE_ENUM[type] || 0,
    ...(foundComments ? { comments: foundComments } : {}),
    ...(description ? { text: description } : {}),
    ...(detail ? { detail } : {}),
    ...(hasError !== undefined ? { hasError } : {}),
    ...(subprocess ? { isGroup: true, isSubProcess: true } : {}),
    ...(url ? { url } : {}),
    ...(isSelected !== undefined ? { isSelected } : {})
  })
}
function handleEvent(task, nodes, nodeIndex) {
  const { description, event } = task
  const eventType = BPMN_EVENT[event]
  if (eventType) {
    nodes.push({
      hasLightTheme,
      key: nodeIndex,
      category: 'event',
      eventType: BPMN_EVENT_TYPE[event],
      eventDimension: BPMN_EVENT_DIMENSION_TYPE[event],
      ...(description ? { text: description } : {}),
      ...(eventType === BPMN_EVENT.end && hasFailureFound
        ? { hasFailed: true }
        : {})
    })
  }
}
function handleTasks(nodeLists, node, currentNodeIndex) {
  const [nodes, subprocessesTasks] = nodeLists
  let nodeIndex = currentNodeIndex
  node.tasks.forEach(task => {
    const { event, gateway, hasFailed, subprocess } = task
    nodeIndex += 1
    if (hasFailed && !hasFailureFound) hasFailureFound = true
    if (subprocess) subprocessesTasks.push({ key: nodeIndex, subprocess })
    if (gateway) {
      handleGateway(nodes, gateway, nodeIndex)
      nodeIndex = handleGatewayNodes(nodes, gateway, nodeIndex)
    } else if (event) handleEvent(task, nodes, nodeIndex)
    else handleActivity({ nodeIndex, nodes, task })
    if (BPMN_EVENT.distribution === BPMN_EVENT[event]) nodeIndex += 1 // skip link position
  })
  return nodeIndex
}
function getTask(groupKey) {
  return task => {
    if (groupKey) return { ...task, ...{ group: groupKey } }
    return task
  }
}
function getNodeDataArray(diagramCode, className) {
  hasFailureFound = false
  hasLightTheme = !className.includes('dark')
  hasTaskError = false
  const { flow } = diagramCode
  const [nodes, nodeIndex, subprocessesTasks] = handleNodes(flow, 0)
  const subprocessNodes = handleSubprocessNodes(subprocessesTasks, nodeIndex)
  if (hasTaskError) nodes[nodes.length - 1].hasError = true
  return [...nodes, ...subprocessNodes]
}

handleNodes = (flow, currentNodeIndex, groupKey) => {
  let nodeIndex = currentNodeIndex
  const nodes = []
  const subprocessesTasks = []
  if (flow && flow.length) {
    nodes.push(
      ...flow
        .reduce((result, node) => {
          nodeIndex = handleTasks([result, subprocessesTasks], node, nodeIndex)
          return result
        }, [])
        .map(getTask(groupKey))
    )
  }
  return [nodes, nodeIndex, subprocessesTasks]
}

export default getNodeDataArray
