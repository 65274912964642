import asJson from 'models/asJson'
import { Favorite } from 'models'
import Repository from './repository'

class Favorites extends Repository {
  resource = 'favorites'

  async find({ abortController, entity, lastContinuationToken, owner, top }) {
    return this.get({
      action: `${entity}/${owner}`,
      ...(abortController ? { signal: abortController.signal } : {}),
      params: {
        top,
        continuationToken: lastContinuationToken
      }
    }).then(response => {
      const { continuationToken, favorites } = response

      return {
        continuationToken,
        favorites: favorites.map(item => new Favorite(item))
      }
    })
  }

  findOne({ entity, name, owner }) {
    return this.get({
      action: `${entity}/${owner}/${name}`,
      mapTo: Favorite
    })
  }

  save({ entity, payload }) {
    return this.post({
      action: entity,
      payload,
      config: asJson
    })
  }

  async deleteOne({ entity, name, owner }) {
    return this.remove({
      action: `${entity}/${owner}/${name}`
    })
  }
}

export default Favorites
