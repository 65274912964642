const NAME = 'job'

const actionTypes = {
  CANCEL: `${NAME}/CANCEL`
}

const actions = {
  cancel: id => ({
    type: actionTypes.CANCEL,
    payload: { id }
  })
}

const job = {
  namespace: NAME,
  actionTypes,
  actions
}

export default job
