const NAME = 'results'

const actionTypes = {
  RESET: `${NAME}/RESET`,
  FETCHING: `${NAME}/FETCHING`,
  FETCHED: `${NAME}/FETCHED`,
  FETCH: `${NAME}/FETCH`,
  SET: `${NAME}/SET`,
  SET_COLUMNS: `${NAME}/SET_COLUMNS`,
  SET_SORT_BY: `${NAME}/SET_SORT_BY`,
  SET_SELECTED: `${NAME}/SET_SELECTED`,
  UPDATE_SORT: `${NAME}/UPDATE_SORT`,
  RESET_PAGE: `${NAME}/RESET_PAGE`,
  UPDATE_PAGE: `${NAME}/UPDATE_PAGE`,
  UPDATE_ROWS_PER_PAGE: `${NAME}/UPDATE_ROWS_PER_PAGE`
}

const actions = {
  reset: () => ({
    type: actionTypes.RESET
  }),
  fetching: () => ({
    type: actionTypes.FETCHING
  }),
  fetched: () => ({
    type: actionTypes.FETCHED
  }),
  set: ({ items, total }) => ({
    type: actionTypes.SET,
    payload: { items, total }
  }),
  setColumns: columns => ({
    type: actionTypes.SET_COLUMNS,
    payload: { columns }
  }),
  select: id => ({
    type: actionTypes.SET_SELECTED,
    payload: { id }
  }),
  setSortBy: by => ({
    type: actionTypes.SET_SORT_BY,
    payload: { by }
  }),
  updateSort: ({ by, isAsc }) => ({
    type: actionTypes.UPDATE_SORT,
    payload: { by, isAsc }
  }),
  updatePage: page => ({
    type: actionTypes.UPDATE_PAGE,
    payload: { page }
  }),
  resetPage: () => ({
    type: actionTypes.RESET_PAGE
  }),
  updateRowsPerPage: count => ({
    type: actionTypes.UPDATE_ROWS_PER_PAGE,
    payload: { count }
  })
}

export const initialState = {
  fetching: false,
  columns: [],
  items: [],
  total: 0,
  selectedItemId: null,
  pagination: {
    page: 0,
    rowsPerPage: 10
  },
  sort: {
    by: '',
    isAsc: false
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.RESET:
      return initialState

    case actionTypes.FETCHING:
      return { ...state, fetching: true }

    case actionTypes.FETCHED:
      return { ...state, fetching: false }

    case actionTypes.SET:
      return { ...state, items: payload.items, total: payload.total }

    case actionTypes.SET_COLUMNS:
      return { ...state, columns: payload.columns }

    case actionTypes.SET_SORT_BY:
      return { ...state, sort: { ...state.sort, by: payload.by } }

    case actionTypes.SET_SELECTED:
      return { ...state, selectedItemId: payload.id }

    case actionTypes.UPDATE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: payload.page }
      }

    case actionTypes.RESET_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: 0 }
      }

    case actionTypes.UPDATE_ROWS_PER_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, rowsPerPage: payload.count }
      }

    case actionTypes.UPDATE_SORT:
      return {
        ...state,
        sort: payload
      }

    default:
      return state
  }
}

const getFetching = state => state[NAME].fetching
const getResults = state => state[NAME].items
const getTotal = state => state[NAME].total
const getPage = state => state[NAME].pagination.page
const getRowsPerPage = state => state[NAME].pagination.rowsPerPage
const getPagination = state => state[NAME].pagination
const getSortBy = state => state[NAME].sort.by
const getSort = state => state[NAME].sort
const getColumns = state => state[NAME].columns
const getSortByColumnQueryName = state => {
  if (!state[NAME].columns.length) return ''

  let sortByColumn = state[NAME].columns.find(
    column => column.name === state[NAME].sort.by
  )

  if (!sortByColumn)
    sortByColumn = state[NAME].columns.length > 0 && state[NAME].columns[0]

  return sortByColumn && (sortByColumn?.queryColumnName || '')
}

const selectors = {
  getFetching,
  getResults,
  getPage,
  getRowsPerPage,
  getPagination,
  getSortBy,
  getSort,
  getTotal,
  getColumns,
  getSortByColumnQueryName
}

const results = {
  namespace: NAME,
  initialState,
  actionTypes,
  actions,
  reducer,
  selectors
}

export default results
