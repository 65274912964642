const FilterFieldType = {
  AzureStorageText: 'azurestoragetext',
  AzureStorageTextPath: 'azurestoragetextpath',
  Text: 'text',
  LongText: 'longtext',
  Field: 'field',
  Numeric: 'numeric',
  Boolean: 'boolean',
  Collection: 'collection',
  Guid: 'guid'
}

export default FilterFieldType
