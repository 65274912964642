import React, { Fragment } from 'react'
import { Link as MuLink, Card, CardContent } from '@mui/material'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'
import { MonitoringCodes } from '../models'

const Home = () => (
  <>
    <Card>
      <CardContent>Home sweet Home</CardContent>
    </Card>

    <MuLink
      href="https://reactjs.org"
      rel="noopener noreferrer"
      target="_blank"
    >
      Learn React
    </MuLink>
  </>
)

export default withAITracking(
  AppInsightsReactPlugin,
  Home,
  `${MonitoringCodes.PAGE}Home`
)
