import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

import { ScheduleFormField } from 'models'
import FormInput from '../../FormInput/FormInput'

const Daily = ({ isReadonly }) => (
  <Grid container spacing={2}>
    <Grid item xs={2}>
      Repeat every
    </Grid>
    <Grid item xs={2}>
      <FormInput
        disabled={isReadonly}
        fieldName={ScheduleFormField.DailyRepeatingDays}
        type="number"
      />
    </Grid>
    <Grid item xs={8}>
      days.
    </Grid>
    <Grid item xs={12}>
      * Week starts on sunday.
    </Grid>
  </Grid>
)

Daily.defaultProps = {
  isReadonly: false
}

Daily.propTypes = {
  isReadonly: PropTypes.bool
}

export default Daily
