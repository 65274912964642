import React, {
  useState,
  Suspense,
  useCallback,
  useEffect,
  useContext
} from 'react'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import {
  AppInsightsReactPlugin,
  AppInsightsHelper,
  useHotjar
} from '@tranzact/platform_react-components'

import AuthenticationContext from 'context/auth'
import UserSettingsContext from 'context/userSettings'
import { MonitoringCodes, NotificationsType } from 'models'
import NotificationsPopover from './Popover'
import NotificationButton from './Button'
import { StyledMenuItemTitle, StyledNotificationWrapper } from './styles'

const NotificationsContent = React.lazy(() => import('./Content/Content'))

const Notifications = ({
  allNotifications,
  userNotifications,
  newUserNotificationsTotal,
  newAllNotificationsTotal,
  loadNotifications,
  markAsRead,
  disable
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const { tagRecordingHotjar } = useHotjar()

  const { siteLayoutMiniSideBar } = useContext(UserSettingsContext)

  const {
    _user: { userName }
  } = useContext(AuthenticationContext)

  const handleClosePopover = () => setAnchorEl(null)
  const handleOpenPopover = useCallback(
    event => {
      const tag = `${MonitoringCodes.EVENT}NotificationsOpen`

      AppInsightsHelper?.trackEvent(tag)

      tagRecordingHotjar([tag])

      setAnchorEl(event.currentTarget)
    },
    [tagRecordingHotjar]
  )

  const disableUserNotification = id => disable(id, NotificationsType.User)
  const markAsReadUserNotification = id =>
    markAsRead(id, NotificationsType.User)

  useEffect(() => {
    loadNotifications(userName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const newCounter = newAllNotificationsTotal + newUserNotificationsTotal

  return (
    <StyledNotificationWrapper siteLayoutMiniSideBar={siteLayoutMiniSideBar}>
      <NotificationButton
        handleOpenPopover={handleOpenPopover}
        newCounter={newCounter}
      >
        {!siteLayoutMiniSideBar && (
          <StyledMenuItemTitle>Notifications</StyledMenuItemTitle>
        )}
      </NotificationButton>
      <NotificationsPopover anchorEl={anchorEl} onClose={handleClosePopover}>
        <Suspense fallback={<div />}>
          <NotificationsContent
            allNotificationsProps={{
              items: allNotifications,
              unreadTotal: newAllNotificationsTotal,
              close: handleClosePopover
            }}
            userNotificationsProps={{
              items: userNotifications,
              unreadTotal: newUserNotificationsTotal,
              close: handleClosePopover,
              onRead: markAsReadUserNotification,
              onRemove: disableUserNotification
            }}
          />
        </Suspense>
      </NotificationsPopover>
    </StyledNotificationWrapper>
  )
}

Notifications.defaultProps = {
  allNotifications: [],
  userNotifications: [],
  newUserNotificationsTotal: 0,
  newAllNotificationsTotal: 0
}

Notifications.propTypes = {
  allNotifications: PropTypes.arrayOf(Object),
  userNotifications: PropTypes.arrayOf(Object),
  newUserNotificationsTotal: PropTypes.number,
  newAllNotificationsTotal: PropTypes.number,
  loadNotifications: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  disable: PropTypes.func.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  React.memo(Notifications),
  `${MonitoringCodes.COMP}Notifications`
)
