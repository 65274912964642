import React from 'react'

const copyUsingNavigator = textToCopy =>
  navigator.clipboard.writeText(textToCopy).catch(err => {
    throw err || new DOMException(`Error on copy: ${err}`, 'NotAllowedError')
  })

const copyUsingDOM = textToCopy => {
  const span = document.createElement('span')
  span.textContent = textToCopy
  span.style.whiteSpace = 'pre'

  document.body.appendChild(span)

  const selection = window.getSelection()
  const range = window.document.createRange()
  selection.removeAllRanges()
  range.selectNode(span)
  selection.addRange(range)

  window.document.execCommand('copy')

  selection.removeAllRanges()
  window.document.body.removeChild(span)
}

const copyText = textToCopy =>
  navigator.clipboard
    ? copyUsingNavigator(textToCopy)
    : copyUsingDOM(textToCopy)

const onClick = ({ event, children, text, onCopy }) => {
  const elem = React.Children.only(children)

  copyText(text)

  if (onCopy) {
    onCopy(text)
  }

  if (elem && elem.props && typeof elem.props.onClick === 'function') {
    elem.props.onClick(event)
  }
}

const CopyToClipboard = ({ text, children, onCopy, ...otherProps }) => {
  const elem = React.Children.only(children)

  const handleOnClick = event => onClick({ event, children, text, onCopy })

  return React.cloneElement(elem, { ...otherProps, onClick: handleOnClick })
}

export default React.memo(CopyToClipboard)
