import { Schedule } from 'models'
import buildScheduleRequest from 'utils/buildScheduleRequest'
import buildProcessQueryRequest from 'utils/buildProcessQueryRequest'
import buildOdataQuery from 'utils/buildOdataQuery'
import Repository from './repository'

class Schedules extends Repository {
  resource = 'schedules'

  find({ end, filters, start }) {
    try {
      const filter = buildOdataQuery(filters, 'and')

      return this.get({
        query: `from=${start}&to=${end}${
          filter ? `&filter=${encodeURIComponent(filter)}` : ''
        }`,
        isArray: true,
        mapTo: Schedule
      })
    } catch {
      return []
    }
  }

  findById(id) {
    try {
      return this.get({
        action: `${id}`,
        isArray: false,
        mapTo: Schedule
      })
    } catch {
      return null
    }
  }

  save(schedule) {
    const payload = buildScheduleRequest({ schedule, buildProcessQueryRequest })
    return this.post({ payload })
  }

  stop(id) {
    this.update({
      action: `${id}/stop`,
      payload: {}
    })
  }

  start(id) {
    this.update({
      action: `${id}/start`,
      payload: {}
    })
  }
}

export default Schedules
