function parseOverviewFields(fields, minWidth = 4) {
  if (!fields || !fields.length) return []
  return fields.map(({ key, value, width }) => ({
    key,
    value: value || '-',
    width:
      width ||
      (value &&
        (value.length <= 25 ? minWidth : (value.length <= 50 && 8) || 12)) ||
      minWidth
  }))
}

export default parseOverviewFields
