import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Popover as MUIPopover, Container } from '@mui/material'

const Popover = ({ id, anchorEl, children, ...muiPopoverProps }) => (
    <MUIPopover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      id={`${id}-popover`}
      open={!!anchorEl}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...muiPopoverProps}
    >
      <Container>{children}</Container>
    </MUIPopover>
  )

Popover.defaultProps = { anchorEl: null, onClose: null }

Popover.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.element,
  onClose: PropTypes.func
}

export default memo(Popover)
