import styled from '@emotion/styled'

import { FormControlLabel, Grid, IconButton } from '@mui/material'

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 6px;
`

export const StyledFilterGrid = styled(Grid)`
  align-content: start;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const StyledIconButton = styled(IconButton)`
  height: 46px;
  width: 46px;
`
