import { connect } from 'react-redux'

import { ui } from 'store/modules'
import Schedules from './Schedules'

const { getEntity, getUserRole } = ui.selectors

const mapStateToProps = state => ({
  entity: getEntity(state),
  userRole: getUserRole(state)
})

const SchedulesContainer = connect(mapStateToProps)(Schedules)

export default SchedulesContainer
