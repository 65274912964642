const internalErrorMessage =
  'Internal error. Please, feel free to contact support if problem persists.'

const getCatchErrorMessage = error => {
  const errors = error?.response?.data?.errors || []

  if (errors.length) {
    const { code, description: errorDescription } = errors[0] || {}

    return code ? `${code}: ${errorDescription}` : internalErrorMessage
  }
  return error?.message || internalErrorMessage
}

export default getCatchErrorMessage
