import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { filters, leads, results, ui } from 'store/modules'
import Toolbar from './Toolbar'

const {
  getAdvancedFilter,
  getAll,
  getCustomFields,
  getCustomPresets,
  getDefault,
  getFetching,
  getPreset
} = filters.selectors
const { getTotal, getSort, getSortByColumnQueryName } = results.selectors
const { getRequestResult } = leads.selectors
const { getEntity } = ui.selectors

const { updatePreset } = filters.actions
const { triggerOperation } = leads.actions
const { showAlert } = ui.actions

const mapStateToProps = state => ({
    entity: getEntity(state),
    presets: getPreset(state),
    loading: getFetching(state),
    customFields: getCustomFields(state),
    total: getTotal(state),
    advancedFilter: getAdvancedFilter(state),
    defaultFilter: getDefault(state),
    allFilter: getAll(state),
    customPresetFilter: getCustomPresets(state),
    sort: getSort(state),
    columnQueryName: getSortByColumnQueryName(state),
    requestResult: getRequestResult(state)
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onChangeDate: updatePreset,
      triggerOperation,
      showAlert
    },
    dispatch
  )

const ToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(Toolbar)

export default ToolbarContainer
