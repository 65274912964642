import {
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel
} from '@microsoft/signalr'

import { AppInsightsHelper } from '@tranzact/platform_react-components'

import { MonitoringCodes } from 'models/monitoring'

const hub = {
  buildConnection(userName) {
    const url = `${process.env.REACT_APP_LARRY_URL}hubs/notifications?username=${userName}`
    const options = {
      transport: HttpTransportType.ServerSentEvents,
      logMessageContent: true,
      logger:
        process.env.NODE_ENV !== 'production' ? LogLevel.Trace : LogLevel.Error
    }

    const connection = new HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(LogLevel.Error)
      .build()

    // TODO: uncomment the configuration once the time configured in server is greater than 1 minute
    // connection.keepAliveIntervalInMilliseconds = 60000
    return connection
  },
  start({ connection, onStart, onError }) {
    connection
      .start()
      .then(() => {
        // eslint-disable-next-line no-console
        console.info('SignalR Connected')
        if (onStart) onStart()
      })
      .catch(err => {
        AppInsightsHelper?.trackError(
          `${MonitoringCodes.ERR}Services_Hub: ${err}`
        )
        // eslint-disable-next-line no-console
        console.error('SignalR Connection Error: ', err)

        if (onError) onError(err)
      })
  },
  close(connection) {
    if (!connection) {
      throw new TypeError('Error trying to close connection')
    }

    return connection.stop().catch(error => {
      throw new TypeError(error)
    })
  }
}

export default hub
