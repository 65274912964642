import { LeadOperationType } from 'models'
import buildOdataQuery from './buildOdataQuery'
import buildExportFIlter from './buildExportFilter'
import getFilterValueOption from './getFilterValueOption'

const buildLeadOperationRequest = ({
  actionType,
  userId,
  requestId = '',
  query: {
    select,
    expand,
    filters,
    presets,
    sort: { isAsc },
    columnQueryName,
    top = '',
    skip = ''
  },
  exportFields,
  target
}) => {
  const commandQuery = {
    Select: select,
    Expand: expand,
    Filter: buildOdataQuery(filters),
    Range: buildOdataQuery(presets),
    OrderBy: `${columnQueryName}${isAsc ? '' : ' desc'}`,
    Top: top,
    Skip: skip
  }

  const command = {
    Query: commandQuery,
    UserId: userId,
    RequestId: requestId
  }

  if (target) {
    command.Target = {
      Pid: target.pid,
      Cid: target.cid
    }
  }

  if (actionType === LeadOperationType.Export && exportFields) {
    command.Fields = exportFields.fields
    command.LeadFields = exportFields.leadFields
    command.PersonFields = exportFields.personFields
    command.OrderByField = columnQueryName
    command.OrderByType = isAsc ? '' : ' DESC'
    const updatedFilters = [...filters, ...presets].map(getFilterValueOption)
    command.Filters = updatedFilters.map(filter => buildExportFIlter(filter))
  }

  return command
}

export default buildLeadOperationRequest
