class Notification {
  constructor(rawNotification) {
    if (!rawNotification) return

    this.id = rawNotification.Id
    this.user = rawNotification.User
    this.requestId = rawNotification.RequestId
    this.message = rawNotification.Message
    this.hyperlink = rawNotification.Hyperlink
    this.dateTime = rawNotification.DateTime
    this.read = rawNotification.Read
    this.status = rawNotification.Status
    this.parameters = rawNotification.Parameters
      ? JSON.parse(rawNotification.Parameters)
      : null
    if (this.parameters) {
      this.linkId = this.parameters.jobid ?? this.parameters.id
      this.entity = this.parameters.entity ?? 'jobs'
      this.showLink = `/${this.entity}/${this.linkId}`
      this.type = this.parameters.type
      this.jobStatus = this.parameters.status
      this.showAlert = this.parameters.showalert
      this.messageWithId =
        this.entity === 'schedules'
          ? `${this.message}`
          : `${this.type} with ID: ${this.linkId} ${this.jobStatus}.`
    }
  }
}
export default Notification
