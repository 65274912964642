import { favorites, results } from 'store/modules'

const { actions } = favorites
const { actionTypes: resultsActionTypes } = results

const favoritesMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action)
    const { type: actionType } = action
    const { favorites: favoritesState } = getState()

    if (actionType === resultsActionTypes.SET) {
      const { selectedItemLabel } = favoritesState
      const { favorite: historyFavorite } = window.history.state?.state ?? {}

      if (selectedItemLabel !== historyFavorite)
        dispatch(actions.setSelectedLabel(historyFavorite || ''))
    }
  }
export default favoritesMiddleware
