const buildExportFilter = ({
  field: { entity, path },
  customField,
  value,
  value2,
  values,
  operator: { id }
}) => {
  const filter = {
    Path: path,
    Entity: entity,
    Field: customField,
    QueryOperator: id,
    Value: value,
    Value2: value2
  }

  if (values) {
    filter.Values = values.map(item => item.value || item)
  }

  return filter
}

export default buildExportFilter
