const ScheduleFormField = {
  Name: 'name',
  SelectedFilterName: 'selectedFilterName',
  SelectedFilter: 'selectedFilter',
  Notes: 'notes',
  JobType: 'jobType',
  DistributeResults: 'distributeResults',
  NumberOfTopResults: 'numberOfTopResults',
  DistributionSystem: 'distributionSystem',
  DistributionCid: 'distributionCid',
  DistributionPid: 'distributionPid',
  StartDate: 'startDate',
  EndDate: 'endDate',
  StartTime: 'startTime',
  EndTime: 'endTime',
  AddEndDate: 'addEndDate',
  RecurrenceOption: 'recurrenceOption',
  HourlyWeekDays: 'hourlyWeekDays',
  HourlyRepeatingHours: 'repeatingHours',
  UsesBetween: 'usesBetween',
  BetweenHourStart: 'betweenHourStart',
  BetweenHourEnd: 'betweenHourEnd',
  DailyRepeatingDays: 'repeatingDays',
  WeeklyDays: 'weeklyDays',
  MonthlyMonths: 'monthlyMonths',
  MonthlyDays: 'monthlyDays'
}

export default ScheduleFormField
