import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { EntityPagination } from '@tranzact/platform_react-components'

import { results } from 'store/modules'
// import { EntityPagination } from 'components/ui/Entity'

const { getTotal, getPage, getRowsPerPage } = results.selectors
const { updatePage, updateRowsPerPage } = results.actions

const mapStateToProps = state => ({
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  rowsTotal: getTotal(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onPageChange: updatePage,
      onRowsPerPageChange: updateRowsPerPage
    },
    dispatch
  )

const PaginationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityPagination)

// TODO: as many components share the same implementation for Pagination, a GeneralPaginationContainer component must be created to be used across Entity views
export default PaginationContainer
