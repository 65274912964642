import React from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { StyledErrorWrapper, StyledSlackButton } from './styles'
import { MonitoringCodes } from '../../models'

const Error = ({ description, Icon, hasHome, hasRetry, title }) => {
  const { search } = useLocation()

  const [, searchFrom] = search.split('from=')

  const handleRetry = () => {
    window.history.replaceState({ state: { title: searchFrom } }, '')

    window.location.reload()
  }

  return (
    <StyledErrorWrapper>
      <Box margin="auto">
        <div>
          <Icon />
        </div>
        <Typography marginBottom="10px" variant="h1">
          {title}
        </Typography>
        <Typography marginBottom="20px">{description}</Typography>
        {hasRetry ? (
          <Box mb={1.5}>
            <Button
              color="secondary"
              to={searchFrom}
              variant="contained"
              onClick={handleRetry}
            >
              Retry
            </Button>
          </Box>
        ) : null}
        {hasHome ? (
          <Box mb={1.5}>
            <Button
              color="secondary"
              component={RouterLink}
              to={process.env.REACT_APP_DEFAULT_PATH}
              variant="contained"
            >
              Go Home
            </Button>
          </Box>
        ) : null}
        <Box mb={3}>
          <StyledSlackButton
            href={process.env.REACT_APP_SLACK}
            rel="noopener"
            target="_blank"
            variant="contained"
          >
            Open slack
          </StyledSlackButton>
        </Box>
        <Box pb={3}>
          <Typography component="small" sx={{ fontSize: '.75rem' }}>
            UI version {process.env.REACT_APP_UI_VERSION}
          </Typography>
        </Box>
      </Box>
    </StyledErrorWrapper>
  )
}

Error.defaultProps = {
  hasHome: true,
  hasRetry: true
}

Error.propTypes = {
  description: PropTypes.instanceOf(Object).isRequired,
  Icon: PropTypes.instanceOf(Object).isRequired,
  hasHome: PropTypes.bool,
  hasRetry: PropTypes.bool,
  title: PropTypes.string.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  Error,
  `${MonitoringCodes.PAGE}Error`
)
