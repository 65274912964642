import React from 'react'
import PropTypes from 'prop-types'

import { CommonDetails } from 'components/shared'
import useScheduleDetails from 'hooks/useScheduleDetails'

export const Details = ({ entity, items, operators, showAlert }) => {
  const { tabs, schedule, loadInfo } = useScheduleDetails(operators, showAlert)

  return (
    <CommonDetails
      entity={entity}
      items={items}
      showAlert={showAlert}
      showAvatar={false}
      tabs={tabs}
      title={schedule?.name || ''}
      onLoadInfo={loadInfo}
    />
  )
}

Details.defaultProps = {
  items: []
}

Details.propTypes = {
  entity: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  operators: PropTypes.arrayOf(Object).isRequired,
  showAlert: PropTypes.func.isRequired
}

export default Details
