import axios from 'axios'
import Repository from './repository'

class GraphQL extends Repository {
  resource = 'graphql'

  async fetch({ query, variables }) {
    try {
      return await axios
        .post(`${process.env.REACT_APP_MACKEY_URL}graphql`, {
          query,
          variables
        })
        .then(({ data }) => data.data)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default GraphQL
