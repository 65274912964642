import { ui, notifications, leads } from 'store/modules'
import { NotificationsType, Notification, JobType } from 'models'

export const signalrRHubStartedAction = () => ({
  type: 'SIGNALR_HUB_STARTED'
})

export function parseNotification(rawNotification) {
  return new Notification(JSON.parse(rawNotification))
}

const hubMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type === ui.actionTypes.USER_LOGGED_IN) {
      const { hubService } = action.meta
      const connection = hubService.buildConnection(action.payload.userName)
      const startOptions = {
        connection,
        onStart: () => dispatch(signalrRHubStartedAction()),
        onError: error =>
          dispatch(
            ui.actions.showAlert({
              error,
              message: 'SignalR Connection Error',
              logOnly: true
            })
          )
      }

      connection.onclose(() => setTimeout(hubService.start(startOptions), 5000))

      connection.on('NotificationPush', message => {
        const { showLink, messageWithId, showAlert } =
          parseNotification(message)
        if (showAlert === 'true')
          dispatch(
            ui.actions.showAlert({
              message: messageWithId,
              showLink
            })
          )
        dispatch(
          notifications.actions.add(
            parseNotification(message),
            NotificationsType.All
          )
        )
      })
      connection.on('PrivateNotificationPush', message => {
        const notification = parseNotification(message)

        if (
          notification?.parameters?.type === JobType.Export &&
          notification?.parameters?.status === 'ENDED'
        ) {
          dispatch(
            leads.actions.setRequestResult({
              exportedFile: notification.hyperlink,
              requestId: notification.requestId
            })
          )
        }

        dispatch(
          notifications.actions.add(
            parseNotification(message),
            NotificationsType.User
          )
        )
      })

      hubService.start(startOptions)
    }

    return next(action)
  }

export default hubMiddleware
