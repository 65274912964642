export default [
  {
    name: 'Id',
    label: 'Lead Id'
  },
  {
    name: 'ReceivedAt',
    label: 'Received At'
  },
  {
    name: 'SourceCode',
    label: 'Source'
  },
  {
    name: 'Afid',
    label: 'Afid'
  },
  {
    name: 'ExternalId',
    label: 'External Id'
  },
  {
    name: 'Cid',
    label: 'Cid'
  },
  {
    name: 'Pid',
    label: 'Pid'
  },
  {
    name: 'Errors',
    label: 'Errors'
  },
  {
    name: 'State',
    label: 'State'
  },
  {
    name: 'Zipcode',
    label: 'Zipcode'
  },
  {
    name: 'Tags',
    label: 'Campaigns'
  }
]
