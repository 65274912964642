const getFiltersSearchQuery = ({ items, operators }) => {
  if (!items) return ''
  return Object.values(items)
    .map(filter =>
      Object.keys(filter)
        .filter(key => {
          const filterKey = filter[key]

          return (
            filterKey?.label ||
            (filterKey && (key === 'value' || key === 'operator')) ||
            (filterKey?.length && key === 'values')
          )
        })
        .map(key => {
          const filterKey = filter[key]

          if (key === 'operator' && operators.length) {
            return (
              filterKey?.label ||
              operators.find(({ id }) => id === filterKey.id).label
            ).toUpperCase()
          }
          if (key === 'value' && filter.value2)
            return `${filterKey},${filter.value2}`
          if (key === 'values')
            return filterKey.map(value => value?.label || value).join(',')
          if (key === 'field' && filter.customField)
            return `${filterKey.label}:${filter.customField}`
          return filterKey.label || filterKey
        })
        .join(' ')
    )
    .join(' AND ')
}

export default getFiltersSearchQuery
