import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { JobsContainer } from 'components/Jobs'
import { DetailsContainer } from 'components/Jobs/Details'
import { RedirectRenderPath } from 'components/shared'
import { MonitoringCodes } from '../models'

const Jobs = ({ viewDetail, viewErrors }) => (
  <Routes>
    <Route exact element={<JobsContainer />} path="/" />
    {viewDetail ? (
      <Route
        element={<DetailsContainer showErrors={viewErrors} />}
        path=":id"
      />
    ) : null}
    <Route element={<RedirectRenderPath renderPath="/jobs/" />} path="*" />
  </Routes>
)

Jobs.defaultProps = {
  viewErrors: false
}

Jobs.propTypes = {
  viewErrors: PropTypes.bool,
  viewDetail: PropTypes.bool.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  Jobs,
  `${MonitoringCodes.PAGE}Jobs`
)
