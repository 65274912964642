import React from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { Typography } from '@mui/material'
import { LockOutlined } from '@mui/icons-material'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { MonitoringCodes } from '../models'
import Error from './Error'

const Unauthorized = () => {
  const slackChannelSuffix =
    process.env.REACT_APP_LMKT_ENV === 'production' ? '' : '-qa'
  const description = (
    <>
      Try again or feel free to contact us via Slack at{' '}
      <Typography component="b" fontWeight="600">
        #lmkt-support{slackChannelSuffix}
      </Typography>
    </>
  )

  return (
    <Error
      Icon={LockOutlined}
      description={description}
      hasHome={false}
      title="Huh? Looks like your credentials are not valid"
    />
  )
}

export default withAITracking(
  AppInsightsReactPlugin,
  Unauthorized,
  `${MonitoringCodes.PAGE}Unauthorized`
)
