import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { v1 as uuid } from 'uuid'
import debounce from 'lodash.debounce'

import {
  AppInsightsReactPlugin,
  PrimaryButton,
  useHotjar
} from '@tranzact/platform_react-components'

import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import { LeadExportField, LeadOperationType, MonitoringCodes } from 'models'
import { useForm, useJobFileDownload } from 'hooks'
import { getKey, isLocalStorageAvailable, setKey } from 'services/localStorage'
import buildLeadOperationRequest from 'utils/buildLeadOperationRequest'
import { leadsRepository } from 'api'
import {
  StyledContainerGrid,
  StyledPaper,
  StyledChip,
  LoadingButtonContainer,
  StyledCircularProgress
} from './styles'

const INITIAL_STATE = {
  fields: null,
  leadFields: null
}

const CustomExport = ({
  open,
  customFields,
  closeDialog,
  filters,
  userName,
  sort,
  columnQueryName,
  presetFilters,
  requestResult
}) => {
  const [requesting, setRequesting] = useState(false)
  const [autocompleteValue, setAutocompleteValue] = useState('')
  const [currentRequestId, setCurrentRequestId] = useState('')

  // TODO: We should replace this when we define the standar error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const { identifyHotjar } = useHotjar()
  const { handleExport } = useJobFileDownload()

  const closeExportDialog = () => {
    closeDialog()
    setRequesting(false)
    if (HotjarMetadataHelper.turnOnFlags(['lmkt_leadAfterExportSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }
  }

  const debouncedCloseExportDialog = useMemo(
    () => debounce(closeExportDialog, 15000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const requestExport = async request => {
    try {
      setRequesting(true)
      await leadsRepository.export(request)
      debouncedCloseExportDialog()
    } catch {
      setRequesting(false)
    }
  }

  const leadFieldsFromFilters = filters
    .filter(filter => filter.customField)
    .map(filter => filter.customField)
    .filter((value, index, self) => self.indexOf(value) === index)

  const { hasErrors, handleChange, handleSubmit, values } = useForm({
    initialState: INITIAL_STATE,
    onSubmit: content => {
      if (isLocalStorageAvailable && getKey('leadexport')) {
        setKey('leadexport', {
          ...values
        })
      }

      const request = buildLeadOperationRequest({
        actionType: LeadOperationType.Export,
        userId: userName,
        query: { filters, presets: presetFilters, sort, columnQueryName },
        exportFields: { ...content, personFields: [] },
        requestId: currentRequestId
      })

      requestExport(request)
    },
    optionalFields: ['leadFields']
  })

  const handleToggleAll = ({ target: { checked } }) => {
    const newAddedValues = checked
      ? LeadExportField.map(field => field.name)
      : []
    handleChange({ target: { name: 'fields', value: newAddedValues } })
  }

  const handleToggle = ({ target: { name, checked } }) => {
    const addedFields = checked
      ? [...values.fields, name]
      : values.fields.filter(addedField => addedField !== name)
    handleChange({ target: { name: 'fields', value: addedFields } })
  }

  const handleChangeAutocomplete = (_, value) => {
    if (
      !value?.label ||
      values.leadFields.some(leadField => leadField === value.label)
    )
      return

    const addedLeadFields = values.leadFields
    handleChange({
      target: { name: 'leadFields', value: [...addedLeadFields, value.label] }
    })

    setAutocompleteValue('')
  }

  const handleInputChange = (_, value) => {
    if (
      customFields?.length &&
      customFields.some(leadField => leadField.label === value)
    ) {
      setAutocompleteValue('')
    } else {
      setAutocompleteValue(value)
    }
  }

  const handleDelete = value => () => {
    const addedLeadFields = values.leadFields.filter(
      leadField => leadField !== value
    )

    handleChange({
      target: { name: 'leadFields', value: [...addedLeadFields] }
    })
  }

  const handleClickCancel = () => {
    closeDialog()
  }

  const initializeLocalStorage = () => {
    if (isLocalStorageAvailable) {
      if (!getKey('leadexport')) {
        setKey('leadexport', {
          leadFields: [],
          fields: [],
          sendEmail: false
        })
      }

      return getKey('leadexport')
    }
    return {
      leadFields: [],
      fields: [],
      sendEmail: false
    }
  }

  useEffect(() => {
    if (
      requestResult.exportedFile &&
      open &&
      requestResult.requestId === currentRequestId
    ) {
      debouncedCloseExportDialog.cancel()
      handleExport(requestResult.exportedFile)()
      closeDialog()
      setRequesting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestResult])

  useEffect(() => {
    setCurrentRequestId(uuid())
  }, [open])

  useEffect(() => {
    if (open) {
      const currentValues = initializeLocalStorage()

      const filteredLeadFieldsFromFilter = leadFieldsFromFilters.filter(
        leadField =>
          !currentValues.leadFields.some(
            currentValueLeadField => currentValueLeadField === leadField
          )
      )

      handleChange({
        target: {
          name: 'leadFields',
          value: [...currentValues.leadFields, ...filteredLeadFieldsFromFilter]
        }
      })

      const currentLeadFields = currentValues.fields.filter(currentField =>
        LeadExportField.some(
          leadExportField => leadExportField.name === currentField
        )
      )

      handleChange({
        target: { name: 'fields', value: currentLeadFields }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, requesting, requestResult])

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle>Export</DialogTitle>
      {open && (
        <form noValidate onSubmit={handleSubmit}>
          <StyledContainerGrid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Basic Fields</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              values?.fields?.length === LeadExportField.length
                            }
                            color="primary"
                            disabled={requesting}
                            name="All"
                          />
                        }
                        label="Select All"
                        onChange={handleToggleAll}
                      />
                    </Grid>
                    {LeadExportField.map(({ name, label }) => (
                      <Grid key={name} item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(values.fields || []).some(
                                addedField => addedField === name
                              )}
                              color="primary"
                              disabled={requesting}
                              name={name}
                              onChange={handleToggle}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Add Additional Fields
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    getOptionLabel={field => field.label}
                    id="lead-fields"
                    inputValue={autocompleteValue}
                    options={customFields}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        disabled={requesting}
                        label="Field"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    onChange={handleChangeAutocomplete}
                    onInputChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledPaper component="ul" elevation={0}>
                    {(values?.leadFields || []).map(leadField => (
                      <li key={leadField}>
                        <StyledChip
                          color="primary"
                          disabled={requesting}
                          label={leadField}
                          onDelete={handleDelete(leadField)}
                        />
                      </li>
                    ))}
                  </StyledPaper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item>
                  <Button
                    disabled={requesting}
                    type="button"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButtonContainer>
                    <PrimaryButton
                      disabled={hasErrors || requesting}
                      label="Export"
                      type="submit"
                      variant="contained"
                    />
                    {requesting && <StyledCircularProgress size={24} />}
                  </LoadingButtonContainer>
                </Grid>
              </Grid>
            </Grid>
          </StyledContainerGrid>
        </form>
      )}
    </Dialog>
  )
}

CustomExport.defaultProps = {
  customFields: [],
  filters: [],
  columnQueryName: null,
  presetFilters: [],
  requestResult: {}
}

CustomExport.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  columnQueryName: PropTypes.string,
  customFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  filters: PropTypes.arrayOf(Object),
  open: PropTypes.bool.isRequired,
  presetFilters: PropTypes.arrayOf(Object),
  sort: PropTypes.instanceOf(Object).isRequired,
  userName: PropTypes.string.isRequired,
  requestResult: PropTypes.instanceOf(Object)
}

export default withAITracking(
  AppInsightsReactPlugin,
  CustomExport,
  `${MonitoringCodes.COMP}CustomExport`
)
