import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { jobs } from 'store/modules'
import JobActionsCell from './JobActionsCell'

const { cancel } = jobs.actions

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onClick: cancel }, dispatch)

const JobActionsCellContainer = connect(
  null,
  mapDispatchToProps
)(JobActionsCell)

export default JobActionsCellContainer
