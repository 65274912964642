import styled from '@emotion/styled'

export const ContentWrapper = styled.div`
  height: 100%;
  & > div {
    display: initial;
  }
`

export default ContentWrapper
