export const MonitoringKeys = {
  APPINSIGHT_KEY: process.env.REACT_APP_MONITORING_APPINSIGHT_KEY,
  HOTJAR_KEY: process.env.REACT_APP_MONITORING_HOTJAR_KEY,
  HOTJAR_VERSION: process.env.REACT_APP_MONITORING_HOTJAR_VERSION,
  NAMESPACE: process.env.REACT_APP_MONITORING_NAMESPACE
}

export const MonitoringCodes = {
  COMP: 'COMP_',
  EVENT: 'EVENT_',
  ERR: 'ERR_',
  PAGE: 'PAGE_'
}

export const userHotjarKey = 'lmkt.user.hotjar'

export const HotjarMetadata = {
  lmkt_userId: '',
  lmkt_email: '',
  lmkt_isHotjarReady: 'false',
  lmkt_autoCompleteSurvey: 'false',
  lmkt_favoritesSurvey: 'false',
  lmkt_leadSurvey: 'false',
  lmkt_leadAfterExportSurvey: 'false',
  lmkt_leadLifeCycleSurvey: 'false',
  lmkt_personSurvey: 'false',
  lmkt_schedulesSurvey: 'false',
  lmkt_schedulesSurveyAdd: 'false',
  lmkt_jobsSurvey: 'false',
  lmkt_helpSurvey: 'false'
}
