import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { ReactComponent as TranzactLogo } from 'assets/tranzact.svg'

const SiteCustomSidebarHeader = ({ isMiniSideBar, version }) => <Box position="absolute">
    <Box paddingRight={isMiniSideBar ? 0 : 2}>
      <TranzactLogo />
    </Box>
    {isMiniSideBar ? null : (
      <Typography component="h1" display="grid" lineHeight={1} variant="h6">
        LMKT
        <Typography component="small" sx={{ fontSize: '.75rem' }}>
          Version {version}
        </Typography>
      </Typography>
    )}
  </Box>

SiteCustomSidebarHeader.propTypes = {
  isMiniSideBar: PropTypes.bool.isRequired,
  version: PropTypes.string.isRequired
}

export default memo(SiteCustomSidebarHeader)
