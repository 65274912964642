import React, { memo, useContext } from 'react'

import { AppInsightsHelper, Tooltip } from '@tranzact/platform_react-components'

import UserSettingsContext from 'context/userSettings'
import { ThemeSwitch } from 'components/ui'
import { MonitoringCodes } from 'models'
import { NotificationsContainer } from 'components'
import { StyledCustomSidebarFooter } from './styles'

const SiteSidebarFooter = () => {
  const { lightTheme, toggleThemeMode } = useContext(UserSettingsContext)

  function handleSwitchTheme() {
    AppInsightsHelper?.trackEvent(
      `${MonitoringCodes.EVENT}SideBarChangeThemeMode`,
      { lightTheme }
    )
    toggleThemeMode()
  }

  return (
    <>
      <NotificationsContainer />
      <StyledCustomSidebarFooter>
        <Tooltip title="Change Theme">
          <div>
            <ThemeSwitch
              isLightTheme={lightTheme}
              onChange={handleSwitchTheme}
            />
          </div>
        </Tooltip>
      </StyledCustomSidebarFooter>
    </>
  )
}

export default memo(SiteSidebarFooter)
