import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  FormHelperText
} from '@mui/material'

import { ScheduleFormField } from 'models'
import MenuItem from './styles'

const { MonthlyDays, MonthlyMonths } = ScheduleFormField

const daysOfTheMonth = [...Array(31)].map((_, i) => i + 1)
const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

const Monthly = ({ isReadonly }) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext()
  const watchedMonthlyDays = watch(MonthlyDays)
  const watchedMonthlyMonths = watch(MonthlyMonths)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={MonthlyMonths}
          render={({ field }) => {
            const { name } = field
            const error = errors[name]?.message
            const hasError = error !== undefined

            return (
              <FormControl fullWidth error={hasError} variant="outlined">
                <InputLabel>Months</InputLabel>
                <Select
                  {...field}
                  multiple
                  disabled={isReadonly}
                  error={hasError}
                  inputProps={{ name }}
                  label="Months"
                  renderValue={selected => selected.join(', ')}
                >
                  {monthsShort.map(month => (
                    <MenuItem key={month} value={month}>
                      <Checkbox
                        checked={watchedMonthlyMonths.indexOf(month) > -1}
                      />
                      <ListItemText primary={month} />
                    </MenuItem>
                  ))}
                </Select>
                {hasError && <FormHelperText>{error}</FormHelperText>}
              </FormControl>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={MonthlyDays}
          render={({ field }) => {
            const { name } = field
            const error = errors[name]?.message
            const hasError = error !== undefined

            return (
              <FormControl fullWidth error={hasError} variant="outlined">
                <InputLabel>Days</InputLabel>
                <Select
                  {...field}
                  multiple
                  disabled={isReadonly}
                  error={hasError}
                  inputProps={{ name }}
                  label="Days"
                  renderValue={selected => selected.join(', ')}
                >
                  {daysOfTheMonth.map(day => (
                    <MenuItem key={day} value={day}>
                      <Checkbox
                        checked={watchedMonthlyDays.indexOf(day) > -1}
                      />
                      <ListItemText primary={day} />
                    </MenuItem>
                  ))}
                </Select>
                {hasError && <FormHelperText>{error}</FormHelperText>}
              </FormControl>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

Monthly.defaultProps = {
  isReadonly: false
}

Monthly.propTypes = {
  isReadonly: PropTypes.bool
}

export default Monthly
