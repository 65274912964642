import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { EntityActivity as CommonActivity } from '@tranzact/platform_react-components'
import { AlertsType } from 'models'
import { DetailsLayout } from 'components/layout'
import SiteContext from 'context/siteContext'

function groupActivities(activity) {
  if (!activity || !activity.length) return []

  return activity
    .slice()
    .sort((a, b) => b.date - a.date)
    .reduce((groups, item) => {
      const id = `
        ${item.date.getDate()}
        ${item.date.getMonth()}
        ${item.date.getFullYear()}
      `
      const existingGroup = groups.find(group => group.id === id)

      if (!existingGroup) {
        groups.push({ id, date: item.date, activities: [item] })
      } else {
        existingGroup.activities.push(item)
      }

      return groups
    }, [])
}

const Details = ({
  activityTypes,
  dateFilterEnabled,
  entity,
  piiMask,
  showAlert,
  showAvatar,
  subtitle,
  tabs,
  title,
  onFetchActivity,
  onLoadInfo
}) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { id: selectedId } = useParams()

  const [activity, setActivity] = useState([])
  const [fetchingDetail, setFetchingDetail] = useState(false)
  const [fetchingActivity, setFetchingActivity] = useState(false)
  const [activityFilter, setActivityFilter] = useState({
    date: null,
    activityTypes: null
  })
  const [, { setMainHeader, setLoading }] = useContext(SiteContext)

  const closeDetails = () => navigate(`/${entity}s/${search}`)

  async function fetchDetailInfo() {
    setFetchingDetail(true)
    try {
      await onLoadInfo(selectedId)
    } catch (error) {
      showAlert({
        error,
        message: `There was an error retrieving ${entity}  info`,
        variant: AlertsType.Error
      })
      closeDetails()
    } finally {
      setFetchingDetail(false)
    }
  }

  async function fetchActivityInfo(activityTypesFilter) {
    setFetchingActivity(true)
    try {
      if (onFetchActivity) {
        // TODO: if there is any side effect or communication between components that needs this info, then move it to a saga
        const newActivity = await onFetchActivity(selectedId, {
          activityTypes: activityTypesFilter,
          activityDate: activityFilter.date
        })
        setActivity(groupActivities(newActivity))
      }
    } catch (error) {
      showAlert({
        error,
        message: `There was an error retrieving ${entity} activity info`,
        variant: AlertsType.Error
      })
      closeDetails()
    } finally {
      setFetchingActivity(false)
    }
  }

  function handleDateFilterChange(value) {
    if (dateFilterEnabled)
      setActivityFilter(previous => ({ ...previous, date: value }))
  }

  const activityTypeFilter = activityTypes
    ? {
        types: activityTypes,
        onSelectedTypesChangeList: values => {
          setActivityFilter(previous => ({
            ...previous,
            activityTypes: values
          }))
        }
      }
    : null

  const setSiteMainHeader = useCallback(() => {
    setMainHeader({
      id: selectedId,
      piiMask,
      title,
      showAvatar,
      subtitle
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, title, subtitle])

  useEffect(() => {
    if (selectedId && onLoadInfo) fetchDetailInfo()
    setActivityFilter({
      date: null,
      activityTypes: null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])

  useEffect(() => {
    if (selectedId) fetchActivityInfo(activityFilter.activityTypes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityFilter])

  useEffect(() => {
    if (selectedId && ((onLoadInfo && !fetchingDetail) || !fetchingActivity)) {
      setSiteMainHeader()
      setLoading(false)
    } else setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, fetchingDetail, fetchingActivity, onLoadInfo])

  return (
    selectedId && (
      <DetailsLayout
        activityContent={
          onFetchActivity ? (
            <CommonActivity
              activityTypeFilter={activityTypeFilter}
              containerLoading={fetchingDetail}
              items={activity}
              loading={fetchingActivity}
              piiMask={piiMask}
              onDateFilterChange={handleDateFilterChange}
            />
          ) : null
        }
        loading={onLoadInfo ? fetchingDetail : fetchingActivity}
        selectedId={selectedId}
        tabs={tabs}
      />
    )
  )
}

Details.defaultProps = {
  activityTypes: null,
  dateFilterEnabled: false,
  piiMask: false,
  showAvatar: true,
  subtitle: null,
  tabs: null,
  title: null,
  onLoadInfo: null,
  onFetchActivity: undefined
}

Details.propTypes = {
  activityTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ),
  dateFilterEnabled: PropTypes.bool,
  entity: PropTypes.string.isRequired,
  piiMask: PropTypes.bool,
  showAlert: PropTypes.func.isRequired,
  showAvatar: PropTypes.bool,
  subtitle: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node
    })
  ),
  title: PropTypes.string,
  onFetchActivity: PropTypes.func,
  onLoadInfo: PropTypes.func
}

export default React.memo(Details)
