export default class Lead {
  constructor(rawLead) {
    if (!rawLead) return
    const {
      Distributions,
      Fields,
      Errors,
      Id,
      IsSuccessful,
      LastDistributionErrorDescription,
      Person,
      Source,
      Tags,
      Target,
      Timeline
    } = rawLead
    const firstDistribution = Distributions && Distributions[0]

    this.id = Id

    if (Person) {
      this.fullName = `${Person.FirstName} ${Person.LastName}`
      this.phone = Person.Phone && Person.Phone.PhoneNumber
      this.personId = Person.Id
    } else {
      this.personId = rawLead.PersonId
    }

    if (Source) {
      this.source = Source.Code
      this.afid = Source.Afid
    }

    if (Errors?.length) {
      this.errors = Errors.map(error => ({
        code: error.Code,
        name: error.Name,
        description: error.Description,
        type: error.Type
      }))
      this.lastError = `Reception: ${this.errors[0].description}`
    }

    if (LastDistributionErrorDescription && !IsSuccessful) {
      this.lastError = `Distribution: ${LastDistributionErrorDescription}`
    }

    if (firstDistribution?.IsSuccessful === false) {
      const { Exception, Response, Status } = firstDistribution
      const { Description } = firstDistribution.Errors[0] ?? {}
      const getXmlErrorMessage = error =>
        error?.startsWith('<?xml')
          ? /(?:(?<=Message=")[\w\s]+)/gi.exec(error)
          : error

      this.lastError = `Distribution: ${
        getXmlErrorMessage(Description || Exception || Response) || Status
      }`
    }

    this.distributions = Distributions?.map(distribution => ({
      id: distribution.Id,
      name: distribution.Name,
      status: distribution.Status,
      startDate:
        distribution.Timeline &&
        distribution.Timeline.Started &&
        // eslint-disable-next-line no-restricted-globals
        !isNaN(Date.parse(distribution.Timeline.Started))
          ? new Date(distribution.Timeline.Started)
          : new Date(),
      endDate:
        distribution.Timeline &&
        distribution.Timeline.Ended &&
        new Date(distribution.Timeline.Ended),
      isSuccessful: distribution.IsSuccessful,
      exception: distribution.Exception,
      request: distribution.Request,
      response: distribution.Response,
      errors:
        distribution.Errors && distribution.Errors.length
          ? distribution.Errors.map(error => ({
              code: error.Code,
              name: error.Name,
              description: error.Description
            }))
          : null
    }))
    this.isSuccessful = IsSuccessful

    if (Target) {
      this.target = Target.Campaign
      this.initiative = Target.Initiative
    }

    this.date =
      // eslint-disable-next-line no-restricted-globals
      Timeline?.Started && !isNaN(Date.parse(Timeline.Started))
        ? new Date(Timeline.Started)
        : new Date()

    this.startDate = this.date

    this.endDate = Timeline && Timeline.Ended && new Date(Timeline.Ended)

    this.tags = Tags?.filter(({ Name }) => Name).map(({ Name }) => Name) || []

    this.fields =
      Fields?.length &&
      Fields.map(field => ({
        id: field.Id,
        key: field.Key,
        value: field.Value
      }))
  }
}
