import React from 'react'
import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { StyledSwitch } from 'components/shared/ScheduleForm/styles'
import useOverviewScheduleSwitch from './useOverviewScheduleSwitch'

const OverviewScheduleSwitch = ({ schedule, onShowAlert }) => {
  const { checked, disabled, handleUpdate } = useOverviewScheduleSwitch(
    schedule,
    onShowAlert
  )

  return (
    <Grid
      item
      alignItems="center"
      display="grid"
      gridTemplateColumns="auto 1fr"
      xs={2}
    >
      <StyledSwitch
        checked={checked}
        color="primary"
        disabled={disabled}
        value={checked}
        onChange={handleUpdate}
      />
      <Typography>On</Typography>
    </Grid>
  )
}

OverviewScheduleSwitch.propTypes = {
  schedule: PropTypes.instanceOf(Object).isRequired,
  onShowAlert: PropTypes.func.isRequired
}

export default OverviewScheduleSwitch
