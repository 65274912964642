import { put, call, takeLatest } from 'redux-saga/effects'

import { sources, ui } from 'store/modules'
import {
  configurationsRepository as configRepository,
  flowsRepository as repository
} from 'api'

function* getError(error) {
  const message = `Oops!, something went wrong retrieving flow.`
  yield put(sources.actions.setHasFlowError())
  yield put(ui.actions.showAlert({ error, message }))
}

function* setFlow(result) {
  const { errors } = result

  if (errors) yield getError({ message: errors })
  else yield put(sources.actions.setFlow(result))
}

const getFlow = action =>
  function* getFlowGenerator({ payload }) {
    try {
      const { getSourceFlowColumnIds } = configRepository
      const columnIds = yield call([configRepository, getSourceFlowColumnIds])
      const newPayload = { ...payload, ...{ columnIds } }
      const result = yield call([repository, action], newPayload)

      yield setFlow(result)
    } catch (error) {
      yield getError(error)
    }
  }

export const getFlowById = getFlow(repository.getFlowById)
export const getLeadFlow = getFlow(repository.getLeadFlow)

const sourcesSaga = [
  takeLatest(sources.actionTypes.GET_FLOW_BY_ID, getFlowById),
  takeLatest(sources.actionTypes.GET_LEAD_FLOW, getLeadFlow)
]

export default sourcesSaga
