import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ui } from 'store/modules'
import App from './App'

const { userLogged, setUserRole } = ui.actions

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onUserLoggedIn: userLogged,
      setUserRole
    },
    dispatch
  )

const AppContainer = connect(null, mapDispatchToProps)(App)

export default AppContainer
