import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formatDistanceToNow } from 'date-fns'
import {
  OutlinedFlagRounded as StartIcon,
  FlagRounded as EndIcon
} from '@mui/icons-material'

import { BoldText } from 'components/ui'
import { CommonDetails } from 'components/shared'
import { jobsRepository, schedulesRepository } from 'api'
import { JobErrorCorrelativeType } from 'models'
import generateRawJobActivity from 'utils/generateRawJobActivity'
import Overview from '../Overview'

const JobErrors = React.lazy(() => import('./JobErrors'))

const ERROR_CORRELATIVE_DICTIONARY = {
  'Lead Distribution': {
    name: 'Lead Id',
    cellType: JobErrorCorrelativeType.LeadLink
  },
  'Lead Update': {
    name: 'Lead Id',
    cellType: JobErrorCorrelativeType.LeadLink
  },
  'Batch File': {
    name: 'Row',
    cellType: JobErrorCorrelativeType.Text
  }
}

const JOB_ACTIVITY_ICONS = {
  started: StartIcon,
  ended: EndIcon
}

export const fetchJobInfo = (setFoundInfo, normalize) => async id => {
  const jobInfo = await jobsRepository.findOne(id)
  const jobMessages = await jobsRepository.getMessages(id)
  jobInfo.messages = jobMessages

  if (jobInfo.scheduleId) {
    jobInfo.schedule = await schedulesRepository.findById(jobInfo.scheduleId)
  }

  setFoundInfo(jobInfo)

  return normalize(jobInfo)
}

const Details = ({ entity, items, showAlert, showErrors }) => {
  const [job, setJob] = useState(null)

  const fetch = fetchJobInfo(setJob, rawJob =>
    generateRawJobActivity(rawJob).map(rawJobActivity => {
      const { activityType, rawTitle, details, ...jobActivity } = rawJobActivity

      return {
        ...jobActivity,
        details: [
          {
            label: 'Status:',
            value: details.isSuccessful ? 'Accepted' : 'Rejected'
          }
        ],
        isSuccessful: details.isSuccessful,
        title: <BoldText text={rawTitle} />,
        icon: JOB_ACTIVITY_ICONS[activityType]
      }
    })
  )

  const tabs = job && [
    {
      label: 'Details',
      content: <Overview job={job} />
    }
  ]

  if (job?.messages?.length) {
    const errors = job.messages
      .filter(message => message.type === 'error')
      .map(message => ({
        order: Number.isNaN(message.id) ? 0 : Number.parseInt(message.id, 10),
        correlative: message.id,
        error: message.value
      }))
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1
        }
        if (a.order < b.order) {
          return -1
        }
        return 0
      })

    if (showErrors && errors.length) {
      const correlative = ERROR_CORRELATIVE_DICTIONARY[job.type]
      tabs.push({
        label: 'Errors',
        content: (
          <JobErrors
            correlativeCellType={correlative.cellType}
            correlativeName={correlative.name}
            items={errors}
          />
        )
      })
    }
  }

  return (
    <CommonDetails
      entity={entity}
      items={items}
      showAlert={showAlert}
      showAvatar={false}
      subtitle={`Created ${
        job &&
        formatDistanceToNow(job.created, {
          addSuffix: true,
          includeSeconds: true
        })
      }`}
      tabs={tabs}
      title={job?.type || ''}
      onFetchActivity={fetch}
    />
  )
}

Details.defaultProps = {
  items: [],
  showErrors: false
}

Details.propTypes = {
  entity: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  showAlert: PropTypes.func.isRequired,
  showErrors: PropTypes.bool
}

export default React.memo(Details)
