import { put, call, select, takeLatest } from 'redux-saga/effects'

import { ui, notifications } from 'store/modules'
import { notificationsRepository } from 'api'
import { NotificationsType } from 'models'

export function* readNotification({ payload, meta }) {
  const { id, updates } = payload
  const { type } = meta

  yield put(notifications.actions.update({ id, updates, type }))

  if (type === NotificationsType.User) {
    try {
      const userNotifications = yield select(
        notifications.selectors.getUserNotifications
      )
      const updatedItem = userNotifications.find(
        (notification) => notification.id === id
      )

      yield call([notificationsRepository, notificationsRepository.update], {
        payload: updatedItem,
      })
    } catch (error) {
      yield put(
        ui.actions.showAlert({
          error,
          message: `Oops!, something went wrong updating notification.`,
        })
      )
    }
  }
}

const notificationsSaga = [
  takeLatest(notifications.actionTypes.MARK_AS_READ, readNotification),
]

export default notificationsSaga
