import * as go from 'gojs'

import BpmnGoJsShapes from './shapes'
import { getDefaultLayout } from './helper'
import { getSequenceLinktemplate } from './templates/sequenceLink'
import { getActivityNodeTemplate } from './templates/activityNode'
import { getSubProcessGroupTemplate } from './templates/subProcessGroup'
import { getEventNodeTemplate } from './templates/eventNode'
import { getGatewayNodeTemplate } from './templates/gatewayNode'
import { ZoomSlider } from './extension/zoomslider/script'
/*
 *  Copyright (C) 1998-2020 by Northwoods Software Corporation. All Rights Reserved.
 */
BpmnGoJsShapes()

// Setup all of the Diagrams and what they need.
// This is called after the page is loaded.
function init() {
  const $ = go.GraphObject.make // for more concise visual tree definitions

  // ------------------------------------------  Template Maps  ----------------------------------------------

  // create the nodeTemplateMap, holding main view node templates nodeTemplateMap = new go.Map<string, go.Node>();
  const nodeTemplateMap = new go.Map()
  // for each of the node categories, specify which template to use
  nodeTemplateMap.add('activity', getActivityNodeTemplate($))
  nodeTemplateMap.add('event', getEventNodeTemplate($))
  nodeTemplateMap.add('gateway', getGatewayNodeTemplate($))
  // for the default category, "", use the same template that Diagrams use by default
  // this just shows the key value  simple TextBlock

  const groupTemplateMap = new go.Map()
  groupTemplateMap.add('subprocess', getSubProcessGroupTemplate($))

  // ------------------------------------------  Link Templates   ----------------------------------------------
  const linkTemplateMap = new go.Map()
  linkTemplateMap.add('', getSequenceLinktemplate($)) // default

  // ------------------------------------------the main Diagram----------------------------------------------

  const myDiagram = $(go.Diagram, {
    nodeTemplateMap,
    linkTemplateMap,
    groupTemplateMap,
    initialAutoScale: go.Diagram.Uniform,
    isReadOnly: true,
    model: $(go.GraphLinksModel, {
      linkKeyProperty: 'key' // this should always be set when using a GraphLinksModel
    }),
    layout: getDefaultLayout($)
  })
  myDiagram.toolManager.hoverDelay = 200
  setTimeout(() => {
    const loadZoomSlider = () => new ZoomSlider(myDiagram)
    loadZoomSlider()
  }, 0)
  return myDiagram
}

export default init
