import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { results } from 'store/modules'
import Results from './Results'

const { getFetching, getResults, getSort, getColumns } = results.selectors
const { select, updateSort } = results.actions

const mapStateToProps = state => ({
  loading: getFetching(state),
  results: getResults(state),
  sort: getSort(state),
  columns: getColumns(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSelectRow: select, onSort: updateSort }, dispatch)

const ResultsContainer = connect(mapStateToProps, mapDispatchToProps)(Results)

export default ResultsContainer
