import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { filters, leads, ui } from 'store/modules'
import Toolbar from './Toolbar'

const { getPreset, getFetching } = filters.selectors
const { getEntity } = ui.selectors

const { updatePreset } = filters.actions

const { triggerOperation } = leads.actions

const mapStateToProps = state => ({
    entity: getEntity(state),
    presets: getPreset(state),
    loading: getFetching(state)
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onChangeDate: updatePreset, triggerOperation }, dispatch)

const ToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(Toolbar)

export default ToolbarContainer
