class FlowTask {
  constructor(rawTask) {
    if (!rawTask) return
    const {
      Comments,
      Description,
      Detail,
      Event,
      HasError,
      IsPath,
      IsSelected,
      Key,
      Type,
      Url
    } = rawTask

    this.comments = Comments
    this.description = Description
    this.detail = Detail
    this.event = Event
    this.key = Key
    this.hasError = HasError
    this.type = Type
    this.url = Url
    this.isPath = IsPath ?? true
    this.isSelected = IsSelected
  }
}

export default FlowTask
