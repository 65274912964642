import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { results, filters, ui } from 'store/modules'
import Results from './Results'

const { actions: resultActions, selectors: resultSelectors } = results
const { getFetching, getResults, getSort, getColumns, getPagination } =
  resultSelectors
const { getUserRole } = ui.selectors
const { updateSort } = resultActions

const { getAll } = filters.selectors

const mapStateToProps = state => ({
  columns: getColumns(state),
  filters: getAll(state),
  loading: getFetching(state),
  paging: getPagination(state),
  results: getResults(state),
  userRole: getUserRole(state),
  sort: getSort(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSort: updateSort }, dispatch)

const ResultsContainer = connect(mapStateToProps, mapDispatchToProps)(Results)

export default ResultsContainer
