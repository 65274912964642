import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
`

export const StyledLinkContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
`
