import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { schedulesRepository } from 'api'

const useScheduleForm = ({ entity }) => {
  const navigate = useNavigate()
  const { search } = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')

  function handleClose() {
    setIsOpen(false)
    setTitle('')
    navigate(`/${entity.toLowerCase()}s/${search}`)
  }

  const openNewForm = () => {
    setIsOpen(true)
    setTitle('New Schedule')
  }

  async function onSchedule(schedule) {
    const scheduleCopy = { ...schedule }
    const filterNotLocked = {
      field: { label: 'IsLocked', path: 'IsLocked', type: 'boolean' },
      operator: {
        id: 'isfalse',
        label: 'EQUAL TO',
        query: '[path] eq [value]'
      },
      value: 'false'
    }

    scheduleCopy.selectedFilter.push(filterNotLocked)
    scheduleCopy.distributeResults =
      schedule.distributeResults === '' ? 'All' : schedule.distributeResults

    const response = await schedulesRepository.save(scheduleCopy)

    if (response.status === 201) handleClose()
  }

  return {
    isOpen,
    title,
    openNewForm,
    onClose: handleClose,
    onSchedule
  }
}

export default useScheduleForm
