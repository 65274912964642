import { AuthenticationContext, runWithAdal } from 'react-adal'
import axios from 'axios'

import { AppInsightsHelper } from '@tranzact/platform_react-components'

import { MonitoringCodes } from 'models/monitoring'
import { getGraphToken, SetApp } from '../utils/auth'

const adalConfig = {
  tenant: process.env.REACT_APP_AZURE_TENANT_ID,
  clientId: process.env.REACT_APP_AZURE_AD_APP_ID,
  redirectUri: process.env.REACT_APP_MAIN_URL,
  cacheLocation: 'localStorage'
}

export const authContext = new AuthenticationContext(adalConfig)

export class AuthService {
  constructor(context) {
    this.authContext = context
    this.isAuthorized = false
  }

  authenticate(app) {
    return runWithAdal(
      this.authContext,
      async () => {
        const { isAuthorized, authIds } = await this.authorize()
        return SetApp(app, isAuthorized, this.authContext, authIds)
      },
      false
    )
  }

  async authorize() {
    const params = {
      groupIds: [
        process.env.REACT_APP_AZURE_AUTHENTICATION_GROUP_ID,
        ...process.env.REACT_APP_AZURE_AUTHORIZATION_GROUP_IDS.split(',')
      ]
    }

    try {
      const token = await getGraphToken(this.authContext)
      const response = await axios.post(
        process.env.REACT_APP_GRAPH_MEMBERSHIP_GROUPS_URL,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      return {
        isAuthorized:
          response.data && response.data.value && !!response.data.value.length,
        authIds: response.data.value
      }
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Services_Auth: ${err}`
      )
      return false
    }
  }
}

const auth = app => {
  const authService = new AuthService(authContext)

  authService.authenticate(app)
}

export default auth
