const JobTypes = {
  None: 'None',
  All: 'All',
  Distribution: 'Lead Distribution',
  File: 'Batch File',
  Update: 'Lead Update',
  Export: 'Lead Export'
}

export default JobTypes
