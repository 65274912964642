import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import {
  RenderEntityViewContainer,
  RenderInformativeViewContainer,
  RedirectRenderPath
} from 'components/shared'
import { EntityType, routeRegexType } from 'models'

const { appPathRegex, entityRegex } = routeRegexType

const AppChildrenRoutes = ({ userRole }) => {
  const { pathname, search } = useLocation()
  const [, searchFrom] = search.split('from=')

  if (searchFrom === '/') {
    return (
      <Routes>
        <Route
          element={<Navigate to={process.env.REACT_APP_DEFAULT_PATH} />}
          path="*"
        />
      </Routes>
    )
  }
  if (appPathRegex.test(pathname)) {
    const [, routeEntity] = entityRegex.exec() || []
    const { viewList } = userRole.actions[routeEntity?.toLowerCase()] || {}

    return (
      <Routes>
        <Route
          exact
          element={<RedirectRenderPath renderPath="/not-found" />}
          path="sandbox"
        />
        <Route
          element={<RenderInformativeViewContainer moduleName="sandbox" />}
          path="sandbox/:lmktId"
        />
        <Route
          element={<RenderInformativeViewContainer moduleName="autocomplete" />}
          path="help/autocomplete"
        />
        <Route
          element={
            <RenderEntityViewContainer
              routeEntity={EntityType.Job}
              userRole={userRole}
            />
          }
          path="jobs/*"
        />
        <Route
          element={
            <RenderEntityViewContainer
              routeEntity={EntityType.Lead}
              userRole={userRole}
            />
          }
          path="leads/*"
        />
        <Route
          element={
            <RenderEntityViewContainer
              routeEntity={EntityType.Person}
              userRole={userRole}
            />
          }
          path="persons/*"
        />
        <Route
          element={
            <RenderEntityViewContainer
              routeEntity={EntityType.Schedule}
              userRole={userRole}
            />
          }
          path="schedules/*"
        />
        <Route
          element={
            <RenderEntityViewContainer
              routeEntity={EntityType.Source}
              userRole={userRole}
            />
          }
          path="sources/*"
        />
        {viewList ? (
          <Route
            element={<RedirectRenderPath renderPath={`${routeEntity}s`} />}
            path="*"
          />
        ) : (
          <Route
            element={
              <RenderInformativeViewContainer moduleName="accessDenied" />
            }
            path="access-denied"
          />
        )}
      </Routes>
    )
  }
  return (
    <Routes>
      <Route
        element={<RenderInformativeViewContainer moduleName="notFound" />}
        path="not-found"
      />
      <Route
        element={<RedirectRenderPath renderPath="/not-found" />}
        path="*"
      />
    </Routes>
  )
}

const AppRoutes = ({ userRole }) => (
  <Routes>
    <Route
      element={<Navigate to={process.env.REACT_APP_DEFAULT_PATH} />}
      path="/"
    />
    <Route element={<AppChildrenRoutes userRole={userRole} />} path="/*" />
    <Route element={<Navigate to="/" />} path="*" />
  </Routes>
)

AppRoutes.propTypes = {
  userRole: PropTypes.instanceOf(Object).isRequired
}

AppChildrenRoutes.propTypes = {
  userRole: PropTypes.instanceOf(Object).isRequired
}

export default AppRoutes
