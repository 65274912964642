import { put, takeLatest } from 'redux-saga/effects'

import { filters, favorites } from 'store/modules'

export function* selectForFilters({
  payload: { filters: favoriteFilters, label }
}) {
  yield put(favorites.actions.setSelectedLabel(label))
  yield put(
    filters.actions.set({
      items: favoriteFilters,
      normalizeKey: 'id'
    })
  )
}

const favoritesSaga = [
  takeLatest(favorites.actionTypes.SELECT_FOR_FILTERS, selectForFilters)
]

export default favoritesSaga
