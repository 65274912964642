import React from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { Typography } from '@mui/material'
import { LocationOffOutlined } from '@mui/icons-material'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { MonitoringCodes } from '../models'
import Error from './Error'

const NotFound = () => {
  const slackChannelSuffix =
    process.env.REACT_APP_LMKT_ENV === 'production' ? '' : '-qa'
  const description = (
    <>
      Go home or feel free to contact us via Slack at{' '}
      <Typography component="b" fontWeight="600">
        #lmkt-support{slackChannelSuffix}
      </Typography>
    </>
  )

  return (
    <Error
      Icon={LocationOffOutlined}
      description={description}
      hasRetry={false}
      title="Oops, you are in a wrong spot"
    />
  )
}

export default withAITracking(
  AppInsightsReactPlugin,
  NotFound,
  `${MonitoringCodes.PAGE}NotFound`
)
