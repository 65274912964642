import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { results, ui } from 'store/modules'
import Details from './Details'

const { getResults } = results.selectors
const { getEntity } = ui.selectors

const { showAlert } = ui.actions

const mapStateToProps = state => ({
  entity: getEntity(state),
  items: getResults(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showAlert }, dispatch)

const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(Details)

export default DetailsContainer
