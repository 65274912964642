import React from 'react'
import { format } from 'date-fns'

import { RecurrenceType } from 'models'
import getFiltersSearchQuery from 'utils/getFiltersSearchQuery'
import parseOverviewFields from 'utils/parseOverviewFields'
import OverviewScheduleSwitch from '../components/Schedules/Overview/OverviewScheduleSwitch'

const minParseWidth = 3

function getRecurrenceHourlyFields({
  betweenHourEnd,
  betweenHourStart,
  hourlyWeekDays,
  repeatingHours,
  usesBetween
}) {
  if (usesBetween) {
    return [
      {
        key: 'Every hour between',
        value: `${betweenHourStart} - ${betweenHourEnd}` || '-'
      }
    ]
  }
  return [
    ...(hourlyWeekDays.length
      ? [
          {
            key: 'Run on these days',
            value: hourlyWeekDays.join(', ')
          }
        ]
      : []),
    {
      key: 'Run every given hours',
      value: repeatingHours
    }
  ]
}

function getRecurrenceDailyFields({ repeatingDays }) {
  return [
    {
      key: 'Repeat every',
      value: `${repeatingDays} days`
    }
  ]
}

function getRecurrenceWeeklyFields({ weeklyDays }) {
  return [
    {
      key: 'Repeat every week starting on',
      value: weeklyDays.join(', ')
    }
  ]
}

function getRecurrenceMonthlyFields({ monthlyDays, monthlyMonths }) {
  return [
    {
      key: 'Months',
      value: monthlyMonths.join(', ')
    },
    {
      key: 'Days',
      value: monthlyDays.join(', ')
    }
  ]
}

function getRecurrenceFields(recurrenceOption, schedule) {
  if (recurrenceOption === RecurrenceType.Hourly)
    return getRecurrenceHourlyFields(schedule)
  if (recurrenceOption === RecurrenceType.Daily)
    return getRecurrenceDailyFields(schedule)
  if (recurrenceOption === RecurrenceType.Weekly)
    return getRecurrenceWeeklyFields(schedule)
  if (recurrenceOption === RecurrenceType.Monthly)
    return getRecurrenceMonthlyFields(schedule)
  return []
}

function getStatusSection(schedule, onShowAlert) {
  return {
    name: 'Status',
    fields: [
      {
        custom: (
          <OverviewScheduleSwitch
            schedule={schedule}
            onShowAlert={onShowAlert}
          />
        )
      }
    ]
  }
}

function getInformationSection(
  operators,
  { id, notes, numberOfTopResults, type, selectedFilter, selectedFilterName }
) {
  return {
    name: 'Information',
    gridFields: parseOverviewFields(
      [
        {
          key: 'Id',
          value: id
        },
        {
          key: 'Filter',
          value: getFiltersSearchQuery({
            items: selectedFilter,
            operators
          }),
          width: 12
        },
        {
          key: 'Schedule Type',
          value: type
        },
        {
          key: 'Favorite Filter',
          value: selectedFilterName || '-'
        },
        {
          key: 'Work with',
          value: numberOfTopResults
            ? `Only top ${numberOfTopResults} leads`
            : 'All leads'
        },
        {
          key: 'Additional notes',
          value: notes || '-'
        }
      ],
      minParseWidth
    )
  }
}

function getDistributionParamatersSection({
  distributionCid,
  distributionPid,
  distributionSystem
}) {
  return {
    name: 'Distribution parameters',
    gridFields: parseOverviewFields(
      [
        {
          key: 'System',
          value: distributionSystem
        },
        {
          key: 'CID',
          value: distributionCid
        },
        {
          key: 'PID',
          value: distributionPid
        }
      ],
      minParseWidth
    )
  }
}

function getScheduleSection({ endDate, endTime, startDate, startTime }) {
  return {
    name: 'Schedule',
    gridFields: parseOverviewFields(
      [
        {
          key: 'Start Date',
          value: format(startDate, 'MM/dd/yyyy')
        },
        {
          key: 'Start Time',
          value: format(startTime, 'HH:mm')
        },
        {
          key: 'End Date',
          value: endDate ? format(endDate, 'MM/dd/yyyy') : '-'
        },
        {
          key: 'End Time',
          value: endTime ? format(endTime, 'HH:mm') : '-'
        }
      ],
      minParseWidth
    )
  }
}

function getRecurrenceSection({ recurrenceOption, ...otherProps }) {
  return {
    name: 'Recurrence',
    gridFields: parseOverviewFields(
      [
        {
          key: 'Type',
          value: recurrenceOption
        },
        ...getRecurrenceFields(recurrenceOption, otherProps)
      ],
      minParseWidth
    )
  }
}

const useSchedulesDetailsOverview = (operators, schedule, onShowAlert) => {
  const sections = [
    getStatusSection(schedule, onShowAlert),
    getInformationSection(operators, schedule),
    getDistributionParamatersSection(schedule),
    getScheduleSection(schedule),
    getRecurrenceSection(schedule)
  ]

  return { sections }
}

export default useSchedulesDetailsOverview
