export const FilterOperator = {
  eq: 'Is Equal to',
  gt: 'Greater than',
  ge: 'Greater than or equal to',
  lt: 'Less than',
  le: 'Less than or equal to',
  ne: 'Not equal to',
  between: 'Betweem',
  notEmpty: 'Is not empty',
  in: 'IN',
  has: 'HAS',
  contains: 'Contains',
  startswith: 'Starts With',
  endswith: 'Ends With'
}

export default FilterOperator
