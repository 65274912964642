import { setKey } from 'services/sessionStorage'
import { OptionsTypes, buildDates } from 'utils/datePresets'
import getValidFilterDate from 'utils/getValidFiltersDate'

const useTimeframe = (onChangeDate, entity) => {
  const handleOnChangeDate = dateset => {
    const { selectedValue, from: value, to: value2 } = dateset

    const isDateRange = selectedValue === OptionsTypes.DateRange

    const { from, to } = buildDates({
      option: selectedValue,
      from: isDateRange ? getValidFilterDate(value) : null,
      to: isDateRange ? getValidFilterDate(value2) : null
    })

    setKey(`${entity}.presetDate`, { selectedValue, from, to })
    onChangeDate({ ...dateset, from, to })
  }
  return {
    handleOnChangeDate
  }
}

export default useTimeframe
