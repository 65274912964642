import React, { memo, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import {
  AppInsightsHelper,
  useHotjar
} from '@tranzact/platform_react-components'

import { leadsRepository } from 'api'
import { MonitoringCodes } from 'models'
import SiteContext from 'context/siteContext'
import LifeCycleContent from './Content'
import { RootGrid } from './styles'

const LifeCycle = props => {
  const {
    hasFlowError,
    items,
    sourceFlow,
    getFlowById,
    getLeadFlow,
    setFlow,
    setHasFlowError,
    updateAdvanced
  } = props

  const { leadId, sourcecode } = useParams()
  const [, { setMainHeader, setLoading }] = useContext(SiteContext)
  const { tagRecordingHotjar } = useHotjar()

  useEffect(() => {
    if (hasFlowError) {
      setHasFlowError(false)
      setLoading(false)
    }
  }, [hasFlowError, setHasFlowError, setLoading])

  useEffect(() => {
    if (leadId) setLoading(true)
    if (leadId && items?.length) {
      const tag = `${MonitoringCodes.EVENT}LeadsShowFlow`
      const item = items.find(({ id }) => id === leadId)

      const handleLeadFlow = ({ personId, source }) => {
        AppInsightsHelper?.trackEvent(tag, {
          selectedLeadId: leadId,
          selectedPersonId: personId,
          sourceId: source
        })

        tagRecordingHotjar([tag])

        setMainHeader({
          id: leadId,
          piiMask: false,
          showAvatar: false,
          title: leadId,
          subtitle: source
        })
        getLeadFlow({ personId, leadId, sourceId: source })
      }

      if (item) handleLeadFlow(item)
      else leadsRepository.findOne(leadId).then(handleLeadFlow)
    }
    return () => {
      if (leadId) setFlow({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, leadId])

  useEffect(() => {
    if (sourcecode) {
      const tag = `${MonitoringCodes.EVENT}SourcesShowFlow`

      AppInsightsHelper?.trackEvent(tag, {
        selectedSource: sourcecode
      })

      tagRecordingHotjar([tag])
      setLoading(true)
      setMainHeader({
        id: sourcecode,
        piiMask: false,
        showAvatar: false,
        title: sourcecode,
        subtitle: ''
      })
      getFlowById({ sourceId: sourcecode })
    }
    return () => {
      if (sourcecode) {
        setFlow({})
        updateAdvanced()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcecode])

  useEffect(() => {
    if (Object.keys(sourceFlow).length) setLoading(false)
  }, [sourceFlow, setLoading])

  return (
    <RootGrid>
      <LifeCycleContent {...{ sourceFlow }} />
    </RootGrid>
  )
}

LifeCycle.propTypes = {
  hasFlowError: PropTypes.bool.isRequired,
  items: PropTypes.instanceOf(Object).isRequired,
  sourceFlow: PropTypes.instanceOf(Object).isRequired,
  getLeadFlow: PropTypes.func.isRequired,
  getFlowById: PropTypes.func.isRequired,
  setFlow: PropTypes.func.isRequired,
  setHasFlowError: PropTypes.func.isRequired,
  updateAdvanced: PropTypes.func.isRequired
}

export default memo(LifeCycle)
