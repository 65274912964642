class AutoComplete {
  operators = []

  values = []

  constructor(rawFavorite) {
    if (!rawFavorite) return

    const { Operators, Values } = rawFavorite

    this.operators = Operators
    this.values = Values
  }
}

export default AutoComplete
