import { isValid, parseISO } from 'date-fns'

const dateStringToDate = date => {
  if (date && isValid(parseISO(date))) {
    const [year, month, day] = date.split('T')[0].split('-')

    return { date: new Date(year, month - 1, day), day, month, year }
  }

  return null
}

export default dateStringToDate
