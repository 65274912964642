import { connect } from 'react-redux'

import { filters } from 'store/modules'
import Form from './Form'

const { getOperators } = filters.selectors

const mapStateToProps = state => ({
  operators: getOperators(state)
})

const DetailsContainer = connect(mapStateToProps)(Form)

export default DetailsContainer
