import { createTheme } from '@mui/material/styles'

import {
  TranzactColor,
  TranzactThemeMuiComponents
} from '@tranzact/platform_react-components'

import { ThemeModes } from './variables'

const commonPalette = {
  error: {
    main: TranzactColor.Danger.Default
  },
  info: {
    main: TranzactColor.Info.Default
  },
  primary: {
    main: TranzactColor.Primary.Default
  },
  secondary: {
    main: TranzactColor.Secondary.Default
  },
  success: {
    main: TranzactColor.Success.Default
  },
  warning: {
    main: TranzactColor.Warning.Default
  }
}

const palettes = {
  [ThemeModes.Light]: {
    appNavBar: {
      background: '#FFF',
      onHoverLinkBackground: 'rgba(0,0,0,0.1)'
    },
    logo: {
      shadow: '#b3e5fc',
      store: TranzactColor.Primary.Default
    },
    dataTable: {
      headerBackground: 'rgba(0, 0, 0, 0.02) !important'
    },
    lowestBackground: '#f3f3f3',
    mainHeader: {
      background: '#FFF',
      color: '#000',
      onHoverLinkBackground: 'rgba(0,0,0,0.1)'
    },
    body: {
      backgroundColor: '#fafafa'
    },
    muiPaperRoot: {
      backgroundColor: '#fff'
    },
    muiSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    sideBar: {
      backgroundColor: '#16212F',
      backgroundLinkActive: '#8796ec',
      backgroundUserInitials: TranzactColor.Primary.Disabled,
      color: 'rgb(255, 255, 255)',
      colorDivider: '#3C4D69'
    }
  },
  [ThemeModes.Dark]: {
    appNavBar: {
      background: '#252526',
      onHoverLinkBackground: 'rgba(255, 255, 255, 0.1)'
    },
    logo: {
      shadow: '#3d4f56',
      store: TranzactColor.Primary.Disabled
    },
    dataTable: {
      headerBackground: 'rgba(0,0,0,.1)'
    },
    lowestBackground: '#383838',
    mainHeader: {
      color: '#fff',
      background: '#252526',
      onHoverLinkBackground: 'rgba(255, 255, 255, 0.1)'
    },
    body: {
      backgroundColor: '#303030'
    },
    muiPaperRoot: {
      backgroundColor: '#424242'
    },
    muiSelected: {
      backgroundColor: 'rgba(255, 255, 255, 0.16)'
    },
    sideBar: {
      backgroundColor: '#16212F',
      backgroundLinkActive: '#8796ec',
      backgroundUserInitials: TranzactColor.Primary.Disabled,
      color: 'rgb(255, 255, 255)',
      colorDivider: '#3C4D69'
    }
  }
}

const theme = (mode = ThemeModes.Light) =>
  createTheme({
    typography: {
      fontFamily: '"Open Sans", "Roboto","Helvetica","Arial",sans-serif'
    },
    palette: {
      mode,
      ...commonPalette
    },
    ...palettes[mode],
    components: {
      ...TranzactThemeMuiComponents,
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'unset',
            backgroundColor: palettes[mode].muiPaperRoot.backgroundColor,
            ...TranzactThemeMuiComponents.MuiPaper.styleOverrides.root
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          '#root > div > aside': {
            display: 'none'
          },
          '#root > div > aside.withHeader': {
            display: 'grid'
          },
          '.Mui-error + p': {
            color: '#f44336'
          },
          '.MuiAccordionDetails-root': {
            display: 'flex'
          },
          '.Mui-expanded': {
            marginBottom: '0 !important'
          },
          '.MuiListSubheader-root': {
            backgroundColor: `${palettes[mode].muiPaperRoot.backgroundColor} !important`
          },
          '.MuiTableRow-root': {
            verticalAlign: 'top',
            '& > div': {
              // ApplicationInsights withAItracking hoc workarround
              display: 'contents'
            }
          },
          '.MuiMenu-list > .Mui-selected': {
            backgroundColor: `${palettes[mode].muiSelected.backgroundColor} !important`
          },
          body: {
            backgroundColor: palettes[mode].body.backgroundColor
          }
        }
      }
    }
  })

export default theme
