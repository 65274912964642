import { useState } from 'react'

import {
  AppInsightsHelper,
  useHotjar
} from '@tranzact/platform_react-components'

import { favoritesRepository } from 'api'
import { MonitoringCodes } from 'models'
import getCatchErrorMessage from 'utils/getCatchErrorMessage'
import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import replaceRouteState from 'utils/replaceRouteState'

const useFavoriteDialog = ({
  filters,
  entity,
  setSelectedLabel,
  showAlert,
  onClose
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { tagRecordingHotjar, identifyHotjar } = useHotjar()

  const trackEvent = favorite => {
    const tag = `${MonitoringCodes.EVENT}${entity}sSaveFavorite`

    AppInsightsHelper?.trackEvent(tag, {
      favorite
    })

    tagRecordingHotjar([tag])

    // TODO: We should replace this when we define the standar error management
    // eslint-disable-next-line no-console
    const logger = process.env.NODE_ENV !== 'production' ? console.info : null

    if (HotjarMetadataHelper.turnOnFlags(['lmkt_favoritesSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }
  }

  const getPayload = favorite => ({
    ...favorite,
    filters: filters.map(filter => {
      const filterCopy = { ...filter }
      const { field, value: filterValue, values } = filterCopy

      delete field.suggestionValidations

      return {
        ...filterCopy,
        field: {
          ...field,
          valueOptions: field.valueOptions?.filter(
            ({ label }) => label === filterValue
          )
        },
        values
      }
    })
  })

  const close = () => {
    setErrorMessage('')
    onClose()
  }

  const save = async ({ name, description }) => {
    try {
      const favorite = {
        createdBy: 'common',
        description,
        name,
        owner: 'common'
      }
      const payload = getPayload(favorite)

      setLoading(true)

      await favoritesRepository.save({ entity: `${entity}s`, payload })

      close()
      setSelectedLabel(name)
      showAlert({
        message: `Favorite has been saved.`
      })
      replaceRouteState('favorite', name)
      trackEvent(favorite)
    } catch (error) {
      setErrorMessage(getCatchErrorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  return { errorMessage, loading, close, save }
}

export default useFavoriteDialog
