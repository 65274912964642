import React from 'react'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { Button } from '@mui/material'

import {
  AppInsightsHelper,
  AppInsightsReactPlugin,
  useHotjar
} from '@tranzact/platform_react-components'

import { JobStatus, MonitoringCodes } from 'models'
import StyledTableCell from './styles'

const JobActionsCell = ({
  row: { id, status },
  column: { name, align },
  onClick
}) => {
  const { tagRecordingHotjar } = useHotjar()

  const handleOnClick = e => {
    const tag = `${MonitoringCodes.EVENT}JobsStop`
    AppInsightsHelper?.trackEvent(tag, {
      id,
      name,
      status,
      align
    })

    tagRecordingHotjar([tag])

    e.stopPropagation()
    onClick(id)
  }

  return (
    <StyledTableCell key={name} align={align}>
      {status === JobStatus.Running && (
        <Button color="error" variant="contained" onClick={handleOnClick}>
          Stop
        </Button>
      )}
    </StyledTableCell>
  )
}

JobActionsCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string
  }).isRequired,
  column: PropTypes.shape({
    name: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  JobActionsCell,
  `${MonitoringCodes.COMP}JobsActionsCell`
)
