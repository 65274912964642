import {
  setHotjarMetadata,
  getHotjarMetadata,
  isHotjarStorageAvailable
} from '@tranzact/platform_react-components'
import { userHotjarKey } from 'models'
import { isValidEmail } from './dataTypes'

const exclusiveFlags = ['lmkt_isHotjarReady', 'lmkt_userId', 'lmkt_email']

const turnOfFlags = metadata => {
  const metadataCopy = { ...metadata }

  try {
    const keys = Object.keys(metadata).filter(
      element => !exclusiveFlags.includes(element)
    )

    keys.forEach(key => {
      metadataCopy[key] = 'false'
    })

    return metadataCopy
  } catch (error) {
    throw new Error(error.message)
  }
}

const Guard = () => {
  if (!isHotjarStorageAvailable)
    throw new Error('LocalStorage is not available')
}

class HotjarMetadataHelper {
  static getMetadata() {
    Guard()

    try {
      return getHotjarMetadata(userHotjarKey)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  static turnOnFlags(flags) {
    Guard()

    if (!flags || !Array.isArray(flags))
      throw new Error('You should provide a valid list of flags')

    try {
      const metadata = turnOfFlags(getHotjarMetadata(userHotjarKey))

      flags.forEach(flag => {
        metadata[flag] = 'true'
      })

      setHotjarMetadata(userHotjarKey, metadata)

      return true
    } catch (error) {
      throw new Error(error.message)
    }
  }

  static setCredentials(email, initialState) {
    Guard()

    if (!isValidEmail(email)) throw new Error(`Email ${email} is not valid`)

    try {
      setHotjarMetadata(userHotjarKey, {
        ...initialState,
        lmkt_userId: email.split('@')[0],
        lmkt_email: email,
        lmkt_isHotjarReady: 'true'
      })
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

export default HotjarMetadataHelper
