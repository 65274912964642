const getFilterValueOption = filter => {
  const valueOption = filter.field.valueOptions?.find(
    option =>
      (option?.operators
        ? option.operators.find(operator => operator === filter.operator.id)
        : true) && option.label.toLowerCase() === filter.value.toLowerCase()
  )
  if (valueOption) {
    const { extendedPath, queryOperator, value } = valueOption

    return {
      ...filter,
      extendedPath,
      queryOperator,
      value
    }
  }
  return filter
}

export default getFilterValueOption
