import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { EntityResults } from '@tranzact/platform_react-components'

import { Column } from 'models'
import { ResultsWrapper } from 'components/shared'
import fixResults from 'utils/fixResults'
import JobActionsCellContainer from './JobActionsCellContainer'

const Results = ({
  columns,
  results,
  isStopJobAllowed,
  paging,
  sort,
  viewDetail,
  onSelectRow,
  ...props
}) => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const customizedColumns = [...columns]
  customizedColumns.push(
    new Column({
      name: 'actions',
      label: 'Actions',
      isVisible: true,
      isSortable: false,
      isAsc: false,
      queryColumnName: '',
      defaultSort: false,
      align: 'center',
      cell: {
        type: 'custom'
      },
      customCell: isStopJobAllowed ? JobActionsCellContainer : null
    })
  )

  const handleOnSelect = id => {
    if (viewDetail) navigate(`${pathname}${id}${search}`)
  }

  const fixedResults = fixResults({
    sort,
    paging,
    results
  })

  return (
    <ResultsWrapper>
      <EntityResults
        {...props}
        stickyHeader
        columns={customizedColumns}
        results={fixedResults}
        sort={sort}
        onSelectRow={handleOnSelect}
      />
    </ResultsWrapper>
  )
}

Results.defaultProps = {
  results: [],
  onSelectRow: null
}

Results.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  isStopJobAllowed: PropTypes.bool.isRequired,
  paging: PropTypes.instanceOf(Object).isRequired,
  results: PropTypes.arrayOf(Object),
  sort: PropTypes.instanceOf(Object).isRequired,
  viewDetail: PropTypes.bool.isRequired,
  onSelectRow: PropTypes.func
}

export default Results
