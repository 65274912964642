import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { TableCell } from '@mui/material'

import { StyledLink } from '../Styled'

const FilterRowLink = ({ row: { sourcecode } }) => {
  const { pathname } = useLocation()

  const handleLinkClick = event => {
    event.stopPropagation()
  }

  return (
    <TableCell>
      <StyledLink
        to={`${pathname}lifecycle/${sourcecode}`}
        onClick={handleLinkClick}
      >
        {sourcecode}
      </StyledLink>
    </TableCell>
  )
}

FilterRowLink.propTypes = {
  row: PropTypes.shape({
    sourcecode: PropTypes.string.isRequired
  }).isRequired
}
export default FilterRowLink
