import axios from 'axios'
import { adalGetToken } from 'react-adal'
import { configurationsRepository } from 'api'

export function getGraphToken(authContext) {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      process.env.REACT_APP_GRAPH_URL,
      (message, token, msg) =>
        !msg ? resolve(token) : reject(new Error({ message, msg }))
    )
  })
}

export function addAxiosInterceptor(authContext) {
  axios.interceptors.request.use(
    async config => {
      const configClone = { ...config }
      // eslint-disable-next-line no-underscore-dangle
      const { name, unique_name: mail } = authContext?._user.profile ?? {}
      const token = await adalGetToken(
        authContext,
        process.env.REACT_APP_AZURE_AD_APP_GATEWAY_ID
      )
      if (token) {
        configClone.headers.Authorization = `Bearer ${token}`
        configClone.headers.AppName = 'Francis'
        configClone.headers.userName = name
          ?.normalize('NFD') // Normalization Form Canonical Decomposition
          .replace(/[\u0300-\u036f]/g, '')
        configClone.headers.userMail = mail
      }
      return configClone
    },
    err => Promise.reject(err)
  )
}

async function getRole({ authIds }) {
  const response = await configurationsRepository.findPermissions()
  const authenticationGroupId =
    process.env.REACT_APP_AZURE_AUTHENTICATION_GROUP_ID
  const roleId = authIds?.filter(x => x !== authenticationGroupId)
  // const roleId = ['176c36e2-7379-4f56-ad18-f52618cba84d']
  const role = response?.filter(x => x.azureGroupId === roleId[0])

  return {
    hasServerResponse: !!(response && response.length),
    role: role ? role[0] : null
  }
}

export async function SetApp(app, isAuthorized, authContext, authIds) {
  if (isAuthorized) addAxiosInterceptor(authContext)

  const { hasServerResponse, role } = await getRole({ authIds })

  app({
    authContext,
    hasServerResponse,
    isAuthorized,
    userRole: role
  })
}
