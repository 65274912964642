import React from 'react'
import PropTypes from 'prop-types'

const BoldText = ({ text }) => {
  const taggedTextCount = Math.floor((text.match(/\*/g) || []).length / 2)
  let ocurrences = 0
  const children = []
  let portion = ''
  let isTagged = false
  for (let i = 0; i < text.length; i += 1) {
    if (text[i] === '*' && ocurrences + 1 <= taggedTextCount * 2) {
      children.push(
        isTagged ? (
          <strong key={i}>{portion}</strong>
        ) : (
          <React.Fragment key={i}>{portion}</React.Fragment>
        )
      )
      isTagged = ocurrences % 2 === 0
      portion = ''
      ocurrences += 1
    } else {
      portion = `${portion}${text[i]}`
    }
  }

  if (portion) {
    children.push(
      isTagged ? (
        <strong key={text.length}>{portion}</strong>
      ) : (
        <React.Fragment key={text.length}>{portion}</React.Fragment>
      )
    )
  }

  return (
    <span>
      {children}
    </span>
  )
}

BoldText.propTypes = {
  text: PropTypes.string.isRequired
}

export default BoldText
