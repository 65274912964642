import { favorites, filters, results, ui } from 'store/modules'

const {
  SET,
  SAVE,
  SAVE_MANY,
  REMOVE,
  CLEAR,
  UPDATE_PRESET,
  UPDATE_ADVANCED,
  UPDATE_CUSTOM_PRESET
} = filters.actionTypes

const triggerActions = [
  SET,
  SAVE,
  SAVE_MANY,
  REMOVE,
  CLEAR,
  UPDATE_PRESET,
  UPDATE_ADVANCED,
  UPDATE_CUSTOM_PRESET,
  results.actionTypes.UPDATE_SORT,
  results.actionTypes.UPDATE_PAGE,
  results.actionTypes.UPDATE_ROWS_PER_PAGE
]

const filtersMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    const { meta, payload, type: actionType } = action
    next(action)

    if (triggerActions.includes(actionType) && (!meta || !meta.normalizeKey)) {
      if (
        actionType === CLEAR ||
        actionType === SAVE ||
        (actionType === SAVE_MANY && !window.history.state?.state?.favorite) ||
        actionType === REMOVE
      ) {
        dispatch(favorites.actions.setSelectedLabel(''))
      }
      if (
        actionType === SAVE ||
        actionType === SET ||
        actionType === REMOVE ||
        actionType === SAVE_MANY
      )
        dispatch(filters.actions.setFiltersQuery())
      if (actionType !== results.actionTypes.UPDATE_PAGE)
        dispatch(results.actions.resetPage())

      dispatch(filters.actions.apply())
    }

    if (actionType === ui.actionTypes.SET_ENTITY && payload?.entity) {
      dispatch(filters.actions.fetchDefault())
      dispatch(filters.actions.fetchCommonConfiguration())
      dispatch(filters.actions.fetchCustomFieldsConfiguration())
    }
  }

export default filtersMiddleware
