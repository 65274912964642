import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { SiteLayout } from '@tranzact/platform_react-components'

import UserSettingsContext from 'context/userSettings'
import AuthenticationContext from 'context/auth'
import { SiteProvider } from 'context/siteContext'
import { TheNavBarContainer } from 'components/layout/TheNavBar'
import { authContext } from 'services/auth'
import getGUIDValidation from 'utils/getGuidValidation'
import SiteSidebarFooter from './SiteSidebarFooter'
import SiteCustomMainHeader from './SiteCustomMainHeader'
import SiteCustomSidebarHeader from './SiteCustomSidebarHeader'
import { StyledContentWrapper } from './styles'

const Site = ({ children, entity, filterPreset, items, version }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { siteLayoutMiniSideBar, toggleSiteLayoutSideBarMode } =
    useContext(UserSettingsContext)
  const {
    _user: {
      profile: { name },
      userName
    }
  } = useContext(AuthenticationContext)

  const [id] = pathname.split('/').slice(-1)
  const hasErrorPage =
    pathname.includes('/not-found') || pathname.includes('/access-denied')
  const hasLifeCycleId = pathname.includes('/lifecycle/')
  const hasGuid = !hasLifeCycleId && getGUIDValidation(id)

  const filterPresetKeys = Object.keys(filterPreset)

  const getFilterPresetWithAction = () => {
    const setUrlSearch = search => {
      navigate(`${pathname}?search=${encodeURIComponent(search)}`)
    }

    return filterPresetKeys.reduce((result, key) => {
      const resultCopy = { ...result }

      resultCopy[key] = {
        ...filterPreset[key],
        onAction: setUrlSearch
      }
      return resultCopy
    }, {})
  }

  function handleLogout() {
    authContext.logOut()
  }

  return (
    <SiteProvider>
      <SiteLayout
        customMainHeader={
          hasGuid || hasLifeCycleId ? (
            <SiteCustomMainHeader id={id} items={items} />
          ) : null
        }
        customSidebarContent={<TheNavBarContainer />}
        customSidebarFooter={<SiteSidebarFooter />}
        customSidebarHeader={
          <SiteCustomSidebarHeader
            isMiniSideBar={siteLayoutMiniSideBar}
            version={version}
          />
        }
        isMainHeaderEnabled={entity}
        isMiniSideBar={siteLayoutMiniSideBar}
        isSidebarHeaderEnabled={!hasErrorPage}
        mainHeaderPreseLabel={filterPresetKeys.length ? 'Presets' : ''}
        mainHeaderPreset={getFilterPresetWithAction()}
        mainHeadertitle={`${entity}s`}
        toggleSideBarMode={toggleSiteLayoutSideBarMode}
        userItemProps={{
          fullName: name,
          isDefaultUserInfoEnabled: true,
          userName,
          onLogout: handleLogout
        }}
      >
        <StyledContentWrapper>{children}</StyledContentWrapper>
      </SiteLayout>
    </SiteProvider>
  )
}

Site.defaultProps = {
  items: []
}

Site.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  entity: PropTypes.string.isRequired,
  filterPreset: PropTypes.instanceOf(Object).isRequired,
  items: PropTypes.arrayOf(Object),
  version: PropTypes.string.isRequired
}

export default Site
