import evaluator from 'utils/evaluateInterpreterResult'
import validateQuery from 'utils/validateQuery'

export const getSearchFilteredOperators = ({
  field,
  fieldTypes,
  operators
}) => {
  const filteredOperators = []
  const filterTypeOperators = fieldTypes.find(t => t.type === field?.type)
    ?.operators

  if (filterTypeOperators) {
    filterTypeOperators.forEach(o => {
      const foundOperator = operators.find(x => x.id === o)

      if (foundOperator) filteredOperators.push(foundOperator)
    })
  }

  return filteredOperators
}

export const getSearchOperator = (fieldTypes, operators) => (str, field) => {
  const filteredOperators = getSearchFilteredOperators({
    field,
    fieldTypes,
    operators
  })

  if (!filteredOperators.length) return {}

  return (
    filteredOperators.find(
      o =>
        o.label.toLowerCase() === str.toLowerCase() &&
        field?.valueOptions?.find(option =>
          option.filteredOperators?.includes(o.id)
        )
    ) ||
    filteredOperators.find(o => o.label.toLowerCase() === str.toLowerCase())
  )
}

const getSearchFiltersValidation = ({
  entity,
  fields,
  operators,
  text,
  fieldTypes
}) => {
  const findField = str =>
    fields.find(field => field.label.toLowerCase() === str.toLowerCase())
  const result = evaluator({
    text,
    findField,
    findOperator: getSearchOperator(fieldTypes, operators)
  })
  const validationResults = []
  let message = ''
  let isValid = false
  const validFilters = []

  if (result?.successful) {
    const queries = result.data

    for (let i = 0; i < queries.length; i += 1) {
      const validationResult = validateQuery({
        qry: queries[i],
        entity
      })

      if (!validationResult.error) validFilters.push(queries[i])
      validationResults.push(validationResult)
    }
    const firstError = validationResults.find(({ error }) => error === true)

    if (!firstError) isValid = true
    else message = firstError?.message || ''
  } else message = result?.message || ''

  return { isValid, message, validFilters }
}

export default getSearchFiltersValidation
