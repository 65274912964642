import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import getNavigateTo from 'utils/getNavigateTo'

const RedirectRenderPath = ({ renderPath }) => {
  const { pathname, search } = useLocation()

  const navigateTo = getNavigateTo(pathname, renderPath, search)

  return <Navigate to={navigateTo} />
}

RedirectRenderPath.propTypes = {
  renderPath: PropTypes.string.isRequired
}

export default RedirectRenderPath
