import { useQuery } from '@tanstack/react-query'

import { graphQLRepository } from 'api'
import { PersonV2 } from 'models'
import PERSON_DETAILS_QUERY from 'models/queries/persons'

const useEntityDetails = personId => {
  const { data } = useQuery({
    queryKey: ['persons', personId],
    queryFn: personId
      ? async () =>
          graphQLRepository.fetch({
            query: PERSON_DETAILS_QUERY,
            variables: {
              wherePerson: {
                id: {
                  eq: personId
                }
              }
            }
          })
      : () => undefined
  })

  return { details: data ? new PersonV2(data.persons.nodes[0]) : undefined }
}

export default useEntityDetails
