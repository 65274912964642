import { AppInsightsHelper } from '@tranzact/platform_react-components'

import buildOdataQuery from 'utils/buildOdataQuery'
import { Lead, MonitoringCodes } from 'models'
import Repository from './repository'

const mainLeadFields = [
  'Id',
  'PersonId',
  'Timeline',
  'Source',
  'Target',
  'IsSuccessful'
]

const leadInfoSelectFields = [
  ...mainLeadFields,
  'Flow',
  'Fields',
  'Tags',
  'Errors',
  'Distributions',
  'LastDistributionErrorDescription'
]

const distributionCommand = {
  leadSelectFields: [...mainLeadFields, 'IsLocked'],
  distributionSelectFields: [
    'Id',
    'GroupId',
    'LeadId',
    'ExternalId',
    'Name',
    'Endpoint',
    'Status',
    'IsSuccessful',
    'Exception',
    'Request',
    'Response',
    'ParentId',
    'Timeline',
    'Errors'
  ]
}

const leadPersonCommand = {
  Select:
    'Id,Person,Timeline,Source,Target,Errors,IsSuccessful,LastDistributionErrorDescription',
  Expand: `Errors,Person($select=Address,Phone,Id,FirstName,LastName,Created)`
}

const buildLeadPersonCommand = pagination =>
  `Lead?$select=${leadPersonCommand.Select}&$expand=${leadPersonCommand.Expand}&$count=true&$top=${pagination.top}&$skip=${pagination.skip}&$orderby=${pagination.sortBy} ${pagination.desc}`

const buildLeadDistributionCommand = personId =>
  `Lead?$select=${distributionCommand.leadSelectFields.join(
    ','
  )}&$expand=Errors,Distributions($select=${distributionCommand.distributionSelectFields.join(
    ','
  )};$expand=Errors)&$filter=(PersonId eq ${personId})`

const buildLeadCommand = leadId =>
  `Lead?$select=${leadInfoSelectFields.join(
    ','
  )}&$expand=Fields,Errors,Tags&$filter=(Id eq ${leadId})`

class Leads extends Repository {
  resource = 'leads'

  async findOne(leadId) {
    try {
      const results = await this.get({
        action: 'command',
        params: {
          commandText: buildLeadCommand(leadId)
        },
        useGateway: true,
        mapTo: Lead,
        isArray: true
      })

      return results[0]
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Repository_Leads: ${err}`
      )

      return []
    }
  }

  async find(filters, pagination) {
    try {
      const leadFilters = buildOdataQuery(filters)

      return await this.get({
        action: 'command',
        params: {
          commandText: `${buildLeadPersonCommand(
            pagination
          )}&$filter=${leadFilters}`
        },
        mapTo: Lead,
        isArray: true,
        useGateway: true,
        isOdata: true
      })
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Repository_Leads: ${err}`
      )
      return []
    }
  }

  async findByPersonId(personId) {
    try {
      return await this.get({
        action: 'command',
        params: {
          commandText: buildLeadDistributionCommand(personId)
        },
        useGateway: true
      })
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Repository_Leads: ${err}`
      )
      this.handleError(err)
      return []
    }
  }

  save(payload) {
    return this.post({
      payload
    })
  }

  async findPersonId(leadId) {
    try {
      const results = await this.get({
        action: 'command',
        params: {
          commandText: `Lead?$select=PersonId&$filter=(Id eq ${leadId})`
        },
        useGateway: true,
        isArray: true
      })

      return results[0].PersonId
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Repository_Leads: ${err}`
      )
      return ''
    }
  }

  recalculate(payload) {
    try {
      this.update({ payload })
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Repository_Leads: ${err}`
      )
      this.handleError(err)
    }
  }

  export(payload) {
    return this.post({
      payload,
      action: 'export'
    })
  }
}

export default Leads
