import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import {
  EntityLayout,
  AppInsightsHelper,
  useHotjar
} from '@tranzact/platform_react-components'

import UserSettingsContext from 'context/userSettings'
import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import ToolbarContainer from './Toolbar'
import { HeaderContainer } from './Header'
import { ResultsContainer } from './Results'
import PaginationContainer from './Pagination/PaginationContainer'

const Leads = ({ entity, userRole }) => {
  const [clearedQuery, setClearedQuery] = useState('original')

  const {
    piiMask,
    actions: {
      lead: {
        createFavorite,
        deleteFavorite,
        listFavorites,
        shouldDistribute,
        shouldExport,
        shouldUpdate,
        viewSourceFlow,
        viewDetail
      },
      schedule: { addSchedule }
    }
  } = userRole

  const userSettingsContext = useContext(UserSettingsContext)

  const handleOnClearedQuery = () => {
    setClearedQuery('cleared')
    if (clearedQuery === 'cleared') setClearedQuery('original')
  }

  // TODO: We should replace this when we define the standar error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const { identifyHotjar } = useHotjar()

  useEffect(() => {
    if (HotjarMetadataHelper.turnOnFlags(['lmkt_leadSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }
  }, [identifyHotjar, logger])

  return (
    <EntityLayout
      header={
        <HeaderContainer
          clearedQuery={clearedQuery}
          isDeleteFavoriteAllowed={deleteFavorite}
          isFavoriteListAllowed={listFavorites}
          isSaveAllowed={createFavorite}
          placeholder="Search by Id"
          onClearedQuery={handleOnClearedQuery}
        />
      }
      results={
        <ResultsContainer
          {...{ piiMask, viewDetail, viewSourceFlow }}
          pagination={
            <PaginationContainer
              location="bottom"
              rowsName={entity}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          }
        />
      }
      toolbar={
        <ToolbarContainer
          addSchedule={addSchedule}
          appInsights={AppInsightsHelper}
          pagination={<PaginationContainer location="top" rowsName={entity} />}
          shouldDistribute={shouldDistribute}
          shouldExport={shouldExport}
          shouldUpdate={shouldUpdate}
        />
      }
      userSettingsContext={userSettingsContext}
    />
  )
}

Leads.propTypes = {
  entity: PropTypes.string.isRequired,
  userRole: PropTypes.instanceOf(Object).isRequired
}

export default Leads
