import { AppInsightsHelper } from '@tranzact/platform_react-components'
import { MonitoringCodes } from 'models/monitoring'
import buildOdataQuery from './buildOdataQuery'

const leadCommand = {
  Select: 'Id,Person,Timeline,Source,Target,Errors',
  Expand: 'Errors,Person($select=Address,Phone,Id,FirstName,LastName,Created)'
}

function validate(sortBy, whereFilters) {
  if (!sortBy || !whereFilters || !whereFilters.length) {
    const msg = '"WhereFilters" and "SortBy" are required.'
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_ProcessQueryRequest: ${msg}`
    )
    throw new Error(msg)
  }
}

const buildProcessQueryRequest = ({
  timeFilters,
  whereFilters,
  pagination: { sortBy, desc = 'desc', skip = '' },
  top
}) => {
  validate(sortBy, whereFilters)

  return {
    ...leadCommand,
    Filter: buildOdataQuery(whereFilters),
    Range: timeFilters && buildOdataQuery(timeFilters),
    OrderBy: `${sortBy}${desc === 'desc' ? ' ' : ''}${desc}`,
    Top: top,
    Skip: skip
  }
}

export default buildProcessQueryRequest
