import React from 'react'
import PropTypes from 'prop-types'

import LabelWrapper from './styles'

const Label = ({ value, className }) => <LabelWrapper className={className}>{value}</LabelWrapper>

Label.defaultProps = {
  className: null
}

Label.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string
}

export default Label
