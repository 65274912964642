import { v4 as uuid } from 'uuid'

import Filter from './filter'

class Favorite {
  constructor(rawFavorite) {
    if (!rawFavorite) return

    this.id = uuid()
    this.icon = rawFavorite.icon
    this.createdBy = rawFavorite.createdBy
    this.description = rawFavorite.description
    this.label = rawFavorite.name
    this.owner = rawFavorite.owner
    this.statement = rawFavorite.statement
    this.counter = 0
    this.filters =
      rawFavorite.filters && rawFavorite.filters.length
        ? rawFavorite.filters.map(filter => new Filter(filter))
        : []
  }
}

export default Favorite
