import React from 'react'
import PropTypes from 'prop-types'

import { EntityToolbar } from '@tranzact/platform_react-components'
import { setKey } from 'services/sessionStorage'

const Toolbar = ({ entity, presets, onChangeDate, ...otherProps }) => {
  const handleOnChangeDate = dateset => {
    const { selectedValue, from, to } = dateset

    setKey(`${entity}.presetDate`, { selectedValue, from, to })
    onChangeDate(dateset)
  }

  return (
    <EntityToolbar
      datePresetProps={{
        presets,
        onChangeDate: handleOnChangeDate
      }}
      options={[]}
      onChangeDate={handleOnChangeDate}
      {...otherProps}
    />
  )
}

Toolbar.propTypes = {
  entity: PropTypes.string.isRequired,
  presets: PropTypes.arrayOf(Object).isRequired,
  onChangeDate: PropTypes.func.isRequired
}

export default Toolbar
