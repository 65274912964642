import FlowTask from './flowTask'
import FlowGateway from './flowGateway'
import FlowSubprocess from './flowSubprocess'
import FeatureUrlType from './flowFeatureUrlType'

class FlowPath {
  getUrlWithColumnId = (columnIds, featureKey, featureUrl) => {
    const columns = columnIds[featureKey] ?? []
    let url = featureUrl.slice()

    columns.forEach(column => {
      url = url.replace('{columnId}', column ?? '')
    })
    return url
  }

  getDistributionUrl = params => {
    const { columnIds, detail, description, hasGateway } = params
    const { distribution } = FeatureUrlType
    const url = this.getUrlWithColumnId(columnIds, 'distribution', distribution)
    const pid = detail?.split(',')

    if (hasGateway) return url
    return url
      .replace('{pid}', (pid?.length === 1 && pid[0]) || '')
      .replace('{cid}', description ?? '')
  }

  getFeatureUrlSearch = (columnIds, featureKey, value) => {
    const featureUrl = FeatureUrlType[featureKey] ?? ''
    if (!featureUrl) return featureUrl
    const url = this.getUrlWithColumnId(columnIds, featureKey, featureUrl)

    return `${url}${value || ''}`
  }

  getGateway = params => {
    const { columnIds, gateway, pathKey, ...otherParams } = params
    const hasGateway = !!gateway
    const taskUrl =
      pathKey === 'distribution'
        ? this.getDistributionUrl({ columnIds, hasGateway, ...otherParams })
        : ''

    return {
      gateway: new FlowGateway({
        ...gateway,
        ...{ TaskUrl: taskUrl }
      })
    }
  }

  getTaskUrl = ({ featureUrl, gateway, ...otherParams }) => {
    const { columnIds, description, detail, pathKey } = otherParams
    const getUrl = () =>
      !gateway
        ? this.getDistributionUrl({ columnIds, description, detail })
        : ''
    const url = featureUrl || (pathKey === 'distribution' ? getUrl() : '')

    return { Url: url }
  }

  getSubprocess = subprocess => ({
    subprocess: new FlowSubprocess(subprocess)
  })

  getTask = (columnIds, featureKey, pathKey) => flowTask => {
    const { Detail, Description, ...otherParams } = flowTask
    const { Event, Gateway: gateway, Subprocess } = otherParams
    const featureUrl = this.getFeatureUrlSearch(
      columnIds,
      featureKey,
      featureKey === 'routing' ? flowTask.Detail : this.key
    )
    const flowTaskParams = { description: Description, detail: Detail, gateway }
    const parentParams = { columnIds, featureUrl, pathKey }

    return {
      ...new FlowTask({
        ...flowTask,
        ...(!Event
          ? this.getTaskUrl({ ...parentParams, ...flowTaskParams })
          : {})
      }),
      ...(gateway
        ? this.getGateway({ columnIds, pathKey, ...flowTaskParams })
        : {}),
      ...(Subprocess ? this.getSubprocess(Subprocess) : {})
    }
  }

  getFlow = (columnIds, pathKey) => flowFeature => {
    const { Key: featureKey, Tasks } = flowFeature

    return {
      key: featureKey,
      tasks: Tasks?.map(this.getTask(columnIds, featureKey, pathKey)) ?? []
    }
  }

  getFlowPath = columnIds => flowPath => {
    const { Key: pathKey } = flowPath

    return {
      key: pathKey,
      flow: flowPath?.Flow?.map(this.getFlow(columnIds, pathKey)) ?? []
    }
  }

  constructor(rawFlow) {
    if (!rawFlow) return
    const { columnIds, Key, Flows, Value } = rawFlow
    const { Errors } = Value || {}

    if (Errors) this.errors = Errors

    this.key = Key ?? ''
    this.flows = Flows?.map(this.getFlowPath(columnIds)) ?? []
  }
}

export default FlowPath
