import React, { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { EntityResults } from '@tranzact/platform_react-components'

import { ResultsWrapper } from 'components/shared'
import { StyledLink } from 'components/shared/Styled'

const Results = ({
  onSelectRow,
  piiMask,
  viewDetail,
  viewSourceFlow,
  ...props
}) => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const handleOnSelect = id => {
    if (viewDetail) navigate(`${pathname}${id}${search}`)
  }

  function getSourceTemplate({ id, source }) {
    const handleLinkClick = event => {
      event.stopPropagation()
    }

    return (
      <StyledLink
        to={`${pathname}lifecycle/${id}${search}`}
        onClick={handleLinkClick}
      >
        {source}
      </StyledLink>
    )
  }

  function normalizeProps() {
    const resultsLength = props.results.length

    if (viewSourceFlow && resultsLength) {
      const propsClone = { ...{}, ...props }
      propsClone.results = propsClone.results.map(result => ({
        ...result,
        ...{ source: getSourceTemplate(result) }
      }))
      return propsClone
    }
    return props
  }

  return (
    <ResultsWrapper>
      <EntityResults
        {...normalizeProps()}
        stickyHeader
        mask={piiMask}
        onSelectRow={handleOnSelect}
      />
    </ResultsWrapper>
  )
}

Results.defaultProps = {
  results: [],
  onSelectRow: () => {}
}

Results.propTypes = {
  piiMask: PropTypes.bool.isRequired,
  results: PropTypes.oneOfType([
    PropTypes.arrayOf(Object),
    PropTypes.arrayOf(String)
  ]),
  viewDetail: PropTypes.bool.isRequired,
  viewSourceFlow: PropTypes.bool.isRequired,
  onSelectRow: PropTypes.func
}

export default memo(Results)
