import React from 'react'
import PropTypes from 'prop-types'

import { useSite } from 'hooks'
import SiteContext from './siteContext'

const SiteProvider = ({ children }) => {
  const siteState = useSite()

  return (
    <SiteContext.Provider value={siteState}>{children}</SiteContext.Provider>
  )
}

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default SiteProvider
