import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'

export const RootGrid = styled(Grid)`
  display: flex;
  overflow: hidden;
  padding: 20px;
  height: 100%;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: ${props => props.theme.shadows[4]};
`

export const MainGrid = styled(Grid)`
  flex-grow: 0;
  background: ${({ theme }) => theme.muiPaperRoot.backgroundColor};
`

export const ContentGrid = styled(Grid)`
  overflow-y: auto;
  .react-swipeable-view-container > div {
    overflow: hidden !important;
  }
`

export const NoOverflowGrid = styled(Grid)`
  overflow: hidden;
`

export const TabsWrapper = styled.div`
  width: 100%;
`

export const SidebarGrid = styled(Grid)`
  display: flex;
  border-left: 1px solid ${props => props.theme.palette.divider};
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${props => props.theme.body.backgroundColor};
  flex-grow: 0;
  max-width: 45%;
  flex-basis: 45%;
`

export const StyledSwipeableViews = styled(SwipeableViews)`
  padding: 20px;
`
