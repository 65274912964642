import React from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { Typography } from '@mui/material'
import { SecurityOutlined } from '@mui/icons-material'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { MonitoringCodes } from '../models'
import Error from './Error'

const AccessDenied = () => {
  const slackChannelSuffix =
    process.env.REACT_APP_LMKT_ENV === 'production' ? '' : '-qa'
  const description = (
    <>
      Try again, go home or feel free to contact us via Slack at{' '}
      <Typography component="b" fontWeight="600">
        #lmkt-support{slackChannelSuffix}
      </Typography>
    </>
  )

  return (
    <Error
      Icon={SecurityOutlined}
      description={description}
      title="Yikes! You have no permission to access this page"
    />
  )
}

export default withAITracking(
  AppInsightsReactPlugin,
  AccessDenied,
  `${MonitoringCodes.PAGE}AccessDenied`
)
