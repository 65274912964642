export default {
  id: 'Id',
  isSuccessful: 'Status',
  source: 'Source Code',
  target: 'Target',
  initiative: 'Initiative',
  afid: 'Afid',
  startDate: 'Execute At',
  endDate: 'Duration',
  value: 'Value',
  externalId: 'External Id',
  prospectExternalId: 'Prospect External Id',
  prospectInteractionExternalId: 'Prospect Interaction External Id',
  distributionGroupId: 'Distribution Group',
  distributionId: 'Distribution Id',
  reason: 'Reason',
  url: 'Url',
  requestId: 'Request Id',
  productLine: 'Product Line',
  productType: 'Product Type',
  carrier: 'Carrier',
  product: 'Product',
  number: 'Number',
  policyEffectiveDate: 'Policy Effective Date',
  campaign: 'Campaign',
  planName: 'Plan Name',
  type: 'Type',
  outcome: 'Outcome',
  subtype: 'Subtype',
  sharedLeadId: 'Shared Lead Id',
  dialerId: 'Dialer Id',
  name: 'Name',
  originLeadId: 'Origin Lead Id'
}
