import Activities from './activities'
import AutoComplete from './autocomplete'
import ExportFile from './exportFile'
import Configurations from './configurations'
import Favorites from './favorites'
import Fields from './fields'
import Flows from './flows'
import GraphQL from './graphQL'
import Jobs from './jobs'
import Leads from './leads'
import Notifications from './notifications'
import Persons from './persons'
import Schedules from './schedules'
import SourceFlows from './sourceFlows'
import Version from './version'

const repositories = {
  activities: new Activities(),
  autocomplete: new AutoComplete(),
  configurations: new Configurations(),
  exportFile: new ExportFile(),
  favorites: new Favorites(),
  fields: new Fields(),
  flows: new Flows(),
  graphql: new GraphQL(),
  jobs: new Jobs(),
  leads: new Leads(),
  notifications: new Notifications(),
  persons: new Persons(),
  schedules: new Schedules(),
  sourceFlows: new SourceFlows(),
  version: new Version()
}

const RepositoryFactory = {
  get: name => repositories[name]
}

export default RepositoryFactory
