import React, { memo, useContext, useMemo } from 'react'
import { ReactDiagram } from 'gojs-react'
import PropTypes from 'prop-types'

import './styles.css'
import './customGojs/extension/zoomslider/style.css'
import UserSettingsContext from 'context/userSettings'
import init from './customGojs/diagramSetup'
import getNodeDataArray from './customGojs/nodesGenerator'
import getLinkDataArray from './customGojs/linksGenerator'
import { StyledContent } from './styles'

const BpmnGoJs = (props) => {
  const { diagramCode } = props
  const { lightTheme } = useContext(UserSettingsContext)
  const currentClass = useMemo(
    () =>
      lightTheme
        ? 'diagram-component'
        : 'diagram-component diagram-component_dark',
    [lightTheme]
  )

  return (
    <StyledContent>
      <ReactDiagram
        divClassName={currentClass}
        initDiagram={() => init()}
        {...(diagramCode && diagramCode.flow
          ? {
              linkDataArray: getLinkDataArray(diagramCode, currentClass),
              nodeDataArray: getNodeDataArray(diagramCode, currentClass)
            }
          : {})}
      />
    </StyledContent>
  )
}

BpmnGoJs.defaultProps = {
  diagramCode: {}
}

BpmnGoJs.propTypes = {
  diagramCode: PropTypes.shape({
    key: PropTypes.string,
    flow: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        tasks: PropTypes.arrayOf(PropTypes.instanceOf(Object))
      })
    )
  })
}

export default memo(BpmnGoJs)
