import { NotificationItem } from 'models'
import Repository from './repository'

class Notifications extends Repository {
  resource = 'notifications'

  newBaseUrl = process.env.REACT_APP_MACKEY_URL

  findByUserName(userName) {
    return this.get({
      query: `user=${userName}`,
      isArray: true,
      mapTo: NotificationItem
    })
  }
}

export default Notifications
