export default class Person {
  constructor(rawPerson) {
    if (!rawPerson) return

    this.id = rawPerson.Id
    this.firstName = rawPerson.FirstName
    this.lastName = rawPerson.LastName
    this.fullName = `${rawPerson.FirstName} ${rawPerson.LastName}`
    this.gender = rawPerson.Gender
    this.email = rawPerson.Email

    if (rawPerson.Address) {
      this.addressLine1 = rawPerson.Address.Line1
      this.addressLine2 = rawPerson.Address.Line2
      this.county = rawPerson.Address.County || ''
      this.city = rawPerson.Address.City
      this.state = rawPerson.Address.State
      this.zipcode = rawPerson.Address.Zipcode
    }

    if (rawPerson.Phone) {
      this.phone = rawPerson.Phone.PhoneNumber
    }

    this.addresses =
      rawPerson.Addresses &&
      rawPerson.Addresses.length &&
      rawPerson.Addresses.map(address => ({
        id: address.Id,
        addressLine1: address.Line1,
        addressLine2: address.Line2,
        county: address.County || '',
        city: address.City,
        state: address.State,
        zipcode: address.Zipcode,
        preferred: address.Preferred
      }))

    this.phones =
      rawPerson.Phones &&
      rawPerson.Phones.length &&
      rawPerson.Phones.map(phone => ({
        id: phone.Id,
        phoneNumber: phone.PhoneNumber,
        preferred: phone.Preferred
      }))

    this.fields =
      rawPerson.Fields &&
      rawPerson.Fields.length &&
      rawPerson.Fields.map(field => ({
        id: field.Id,
        key: field.Key,
        value: field.Value
      }))

    this.date = rawPerson.Created && new Date(rawPerson.Created)
    this.updateDate = rawPerson.Updated && new Date(rawPerson.Updated)
  }
}
