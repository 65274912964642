import { put, call, select, takeLatest } from 'redux-saga/effects'

import { jobs, ui, results } from 'store/modules'
import { cancelJob } from './api'

export function* cancel({ payload: { id } }) {
  try {
    const currentResults = yield select(results.selectors.getResults)

    const newResults = currentResults.map(result => {
      if (result.id !== id) return result

      const newResult = { ...result, status: 'Canceling' }

      return newResult
    })

    yield put(results.actions.set({ items: newResults, total: newResults.length }))

    yield call(cancelJob, id)

    yield put(
      ui.actions.showAlert({
        message: `Job cancelation has been started.`
      })
    )
  } catch (error) {
    yield put(
      ui.actions.showAlert({
        error,
        message: `Oops!, something went wrong cancelling running job.`
      })
    )
  }
}

const jobsSaga = [takeLatest(jobs.actionTypes.CANCEL, cancel)]

export default jobsSaga
