const { history, location } = window

const replaceRouteState = (key, value) => {
  history.replaceState(
    {
      ...{
        state: {
          ...(history.state?.state || {}),
          [key]: value
        }
      }
    },
    location.href
  )
}

export default replaceRouteState
