import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'

const Sandbox = () => {
  const { lmktId } = useParams()
  const { search } = useLocation()
  const [customer, setCustomer] = useState()

  const hasFilter = search.includes('hasFilter=true')

  useEffect(() => {
    const { ReactUtils, WTCustomerProfile } =
      window[process.env.REACT_APP_LMKT_APPLICATION_COMPONENTS_LIB_NAME]
    const customerProfile = new ReactUtils(WTCustomerProfile)

    customerProfile.mount(document.querySelector('#customerProfile'), {
      lmktId,
      hasFilter,
      onFilter: setCustomer
    })
  }, [hasFilter, lmktId])

  useEffect(() => {
    if (hasFilter) {
      const { ReactUtils, WTCustomerList } =
        window[process.env.REACT_APP_LMKT_APPLICATION_COMPONENTS_LIB_NAME]
      const customerList = new ReactUtils(WTCustomerList)

      customerList.mount(document.querySelector('#customerList'), {
        customer
      })
    }
  }, [customer, hasFilter, setCustomer])

  return (
    <Grid
      container
      display="grid !important"
      gridTemplateColumns="1fr auto"
      height="100%"
      margin="0 auto"
      maxWidth="1600px"
      p={2}
    >
      <Box
        item
        component={Grid}
        height="100%"
        sx={{ overflowY: 'auto' }}
        width="520px"
      >
        <div id="customerProfile" />
      </Box>
      <Box
        item
        component={Grid}
        height="100%"
        sx={{ overflowY: 'auto' }}
        width="345px"
      >
        <Box id="customerList" sx={{ height: '600px' }} />
      </Box>
    </Grid>
  )
}

export default Sandbox
