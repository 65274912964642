import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import debounce from 'lodash.debounce'

import { useHotjar } from '@tranzact/platform_react-components'

import getGUIDValidation from 'utils/getGuidValidation'
import getSearchFiltersValidation from 'utils/getSearchFiltersValidation'
import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'

const useAutocomplete = ({
  clearedQuery = '',
  customFields = [],
  entity,
  fields,
  fieldTypes,
  filterItems,
  filtersQuery = '',
  historyMetadata = {},
  loadingFilters = false,
  operators,
  title,
  clearFilters,
  removeFilter,
  saveFilters,
  onClearedQuery = () => null,
  onSearch
}) => {
  const [hasSuggestionsDisabled, setHasSuggestionsDisabled] = useState(false)
  const [autoCompleteTextError, setAutoCompleteTextError] = useState({
    error: false,
    errorMessage: ''
  })
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { identifyHotjar } = useHotjar()

  // TODO: We should replace this when we define the standard error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const clearError = () => {
    setAutoCompleteTextError({
      error: false,
      errorMessage: ''
    })
  }

  const handleGUIDSearch = guid => {
    onSearch(guid)
    navigate(`${pathname}?search=${guid}`)
    clearError()
  }

  const debouncedOnChange = debounce(handleGUIDSearch, 500)

  const handleSearchById = text => {
    const isGUID = getGUIDValidation(text)

    if (isGUID) debouncedOnChange(text)
    else debouncedOnChange.cancel()
    if (!isGUID && hasSuggestionsDisabled) onSearch('')
    if (!text) {
      clearError()
      navigate(pathname)
    }
    setHasSuggestionsDisabled(isGUID)
  }

  const handleSaveFilter = (text, { filters }) => {
    if (getGUIDValidation(text)) {
      handleGUIDSearch(text)
      setHasSuggestionsDisabled(true)
    } else if (filters.length) saveFilters(filters)
  }

  const getFiltersValidation = text => {
    const validation = { isValid: false, parameters: { filters: [] } }
    let errorMessage = ''

    if (getGUIDValidation(text) || text === '') {
      validation.isValid = true
      if (text === '') clearFilters()
    } else {
      const searchValidation = getSearchFiltersValidation({
        entity,
        fields,
        operators,
        text,
        fieldTypes
      })

      const { isValid: searchIsValid, message, validFilters } = searchValidation

      validation.parameters.filters.push(...validFilters)
      validation.isValid = searchIsValid
      errorMessage = message
    }

    setAutoCompleteTextError({
      error: !!errorMessage,
      errorMessage
    })

    if (HotjarMetadataHelper.turnOnFlags(['lmkt_autoCompleteSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }

    return validation
  }

  const autocompleteProps = {
    autoCompleteTextError,
    clearedQuery,
    customFields,
    fields,
    fieldTypes,
    filterBar: {
      items: filterItems,
      position: 'bottom',
      title: title || '',
      onRemoveItem: removeFilter
    },
    filtersQuery,
    hasHintDisabled: true,
    hasSuggestionsDisabled,
    historyMetadata,
    loadingFilters,
    operators,
    getFiltersValidation,
    onClearedQuery,
    onClickHelp: () => {
      window.open('/help/autocomplete', '_blank')
    },
    onSearch: handleSearchById,
    onSearchFilter: handleSaveFilter
  }

  return [autocompleteProps, { clearError }]
}

export default useAutocomplete
