import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { filters, results, ui } from 'store/modules'
import { Details } from './Details'

const { getOperators } = filters.selectors
const { getResults } = results.selectors
const { getEntity } = ui.selectors

const { showAlert } = ui.actions

const mapStateToProps = state => ({
  entity: getEntity(state),
  items: getResults(state),
  operators: getOperators(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ showAlert }, dispatch)

const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(Details)

export default DetailsContainer
