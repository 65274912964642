import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { EntityLayout } from '@tranzact/platform_react-components'

import UserSettingsContext from 'context/userSettings'
import { HeaderContainer } from './Header'
import Toolbar from './Toolbar'
import { ResultsContainer } from './Results'
import PaginationContainer from './Pagination'

const Sources = ({ entity, onSearch }) => {
  const userSettingsContext = useContext(UserSettingsContext)
  const [clearedQuery, setClearedQuery] = useState('original')

  const handleOnClearedQuery = () => {
    setClearedQuery('cleared')
    if (clearedQuery === 'cleared') setClearedQuery('original')
  }

  return (
    <EntityLayout
      header={
        <HeaderContainer
          clearedQuery={clearedQuery}
          placeholder="Search by name"
          onClearedQuery={handleOnClearedQuery}
          onSearch={onSearch}
        />
      }
      results={
        <ResultsContainer
          pagination={
            <PaginationContainer
              location="bottom"
              rowsName={entity}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          }
        />
      }
      toolbar={
        <Toolbar
          pagination={<PaginationContainer location="top" rowsName={entity} />}
        />
      }
      userSettingsContext={userSettingsContext}
    />
  )
}

Sources.propTypes = {
  entity: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
}

export default Sources
