import { createSelector } from 'reselect'

import { Filter } from 'models'
import getFiltersSearchQuery from 'utils/getFiltersSearchQuery'

const NAME = 'filters'

const actionTypes = {
  FETCHING: `${NAME}/FETCHING`,
  FETCHED: `${NAME}/FETCHED`,
  FETCH_DEFAULT: `${NAME}/FETCH_DEFAULT`,
  FETCH_COMMON_CONFIGURATION: `${NAME}/FETCH_COMMON_CONFIGURATION`,
  FETCH_CUSTOM_FIELDS_CONFIGURATION: `${NAME}/FETCH_CUSTOM_FIELDS_CONFIGURATION`,
  SET: `${NAME}/SET`,
  SET_DEFAULT: `${NAME}/SET_DEFAULT`,
  SET_FILTER_PRESET: `${NAME}/SET_FILTER_PRESET`,
  SET_FILTERS_QUERY: `${NAME}/SET_FILTERS_QUERY`,
  SET_PRESET: `${NAME}/SET_PRESET`,
  SET_CUSTOM_PRESETS: `${NAME}/SET_CUSTOM_PRESETS`,
  UPDATE_PRESET: `${NAME}/UPDATE_PRESET`,
  UPDATE_CUSTOM_PRESET: `${NAME}/UPDATE_CUSTOM_PRESET`,
  SET_ADVANCED: `${NAME}/SET_ADVANCED`,
  UPDATE_ADVANCED: `${NAME}/UPDATE_ADVANCED`,
  UPDATE_CONFIGURATION: `${NAME}/UPDATE_CONFIGURATION`,
  APPLY: `${NAME}/APPLY`,
  SAVE: `${NAME}/SAVE`,
  SAVE_MANY: `${NAME}/SAVE_MANY`,
  REMOVE: `${NAME}/REMOVE`,
  CLEAR: `${NAME}/CLEAR`,
  RESET: `${NAME}/RESET`
}

const actions = {
  fetching: () => ({
    type: actionTypes.FETCHING
  }),
  fetched: configurationType => ({
    type: actionTypes.FETCHED,
    payload: configurationType
  }),
  fetchDefault: entity => ({
    type: actionTypes.FETCH_DEFAULT,
    payload: { entity }
  }),
  fetchCommonConfiguration: () => ({
    type: actionTypes.FETCH_COMMON_CONFIGURATION
  }),
  fetchCustomFieldsConfiguration: () => ({
    type: actionTypes.FETCH_CUSTOM_FIELDS_CONFIGURATION
  }),
  set: ({ items, normalizeKey }) => ({
    type: actionTypes.SET,
    payload: items,
    meta: { normalizeKey, feature: NAME }
  }),
  setDefault: filters => ({
    type: actionTypes.SET_DEFAULT,
    payload: { filters }
  }),
  setFilterPreset: payload => ({
    type: actionTypes.SET_FILTER_PRESET,
    payload
  }),
  setFiltersQuery: () => ({
    type: actionTypes.SET_FILTERS_QUERY
  }),
  setPreset: presets => ({
    type: actionTypes.SET_PRESET,
    payload: { presets }
  }),
  setCustomPresets: customPresets => ({
    type: actionTypes.SET_CUSTOM_PRESETS,
    payload: { customPresets }
  }),
  updatePreset: ({ id, selectedValue, from, to }) => ({
    type: actionTypes.UPDATE_PRESET,
    payload: { id, selectedValue, from, to }
  }),
  updateCustomPreset: ({ id, value }) => ({
    type: actionTypes.UPDATE_CUSTOM_PRESET,
    payload: { id, value }
  }),
  setAdvanced: filter => ({
    type: actionTypes.SET_ADVANCED,
    payload: { filter }
  }),
  updateAdvanced: value => ({
    type: actionTypes.UPDATE_ADVANCED,
    payload: { value }
  }),
  updateConfiguration: payload => ({
    type: actionTypes.UPDATE_CONFIGURATION,
    payload
  }),
  apply: () => ({
    type: actionTypes.APPLY
  }),
  save: item => ({
    type: actionTypes.SAVE,
    payload: {
      item
    }
  }),
  saveMany: items => ({
    type: actionTypes.SAVE_MANY,
    payload: items.reduce((result, item) => {
      const resultCopy = { ...result }

      resultCopy[item.id] = new Filter(item)
      return resultCopy
    }, {})
  }),
  remove: id => ({
    type: actionTypes.REMOVE,
    payload: {
      id
    }
  }),
  clear: () => ({
    type: actionTypes.CLEAR
  }),
  reset: () => ({
    type: actionTypes.RESET
  })
}

export const initialState = {
  fetching: {
    common: true,
    custom: true,
    default: true
  },
  items: null,
  default: [],
  filterPreset: {},
  filtersQuery: '',
  presets: [],
  customPresets: [],
  advanced: null,
  configuration: {
    fields: [],
    fieldTypes: [],
    operators: [],
    customFields: []
  }
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCHING:
      return {
        ...state,
        fetching: {
          common: state.fetching.common,
          custom: true,
          default: true
        }
      }

    case actionTypes.FETCHED:
      return {
        ...state,
        fetching: { ...state.fetching, [payload]: false }
      }

    case actionTypes.SET:
      return { ...state, items: payload }

    case actionTypes.SET_PRESET:
      return { ...state, presets: payload.presets }

    case actionTypes.SET_CUSTOM_PRESETS:
      return { ...state, customPresets: payload.customPresets }

    case actionTypes.UPDATE_PRESET: {
      const presets = state.presets.map(preset => {
        if (preset.id !== payload.id) return preset

        return {
          ...preset,
          selectedValue: payload.selectedValue,
          filter: {
            ...preset.filter,
            value: payload.from,
            value2: payload.to
          }
        }
      })

      return {
        ...state,
        presets
      }
    }

    case actionTypes.UPDATE_CUSTOM_PRESET: {
      const customPresets = state.customPresets.map(customPreset => {
        if (customPreset.id !== payload.id) return customPreset

        return {
          ...customPreset,
          filter: { ...customPreset.filter, value: payload.value }
        }
      })

      return {
        ...state,
        customPresets
      }
    }

    case actionTypes.SET_DEFAULT:
      return { ...state, default: payload.filters }

    case actionTypes.SET_FILTER_PRESET:
      return { ...state, filterPreset: payload }

    case actionTypes.SET_FILTERS_QUERY:
      return {
        ...state,
        filtersQuery: getFiltersSearchQuery({
          items: state.items,
          operators: state.configuration.operators
        })
      }

    case actionTypes.SET_ADVANCED:
      return { ...state, advanced: payload.filter }

    case actionTypes.UPDATE_ADVANCED:
      return { ...state, advanced: { ...state.advanced, value: payload.value } }

    case actionTypes.UPDATE_CONFIGURATION:
      return { ...state, configuration: { ...state.configuration, ...payload } }
    case actionTypes.SAVE:
      return {
        ...state,
        items: { ...state.items, [payload.item.id]: payload.item }
      }
    case actionTypes.SAVE_MANY:
      return {
        ...state,
        items: payload
      }

    case actionTypes.REMOVE: {
      const { [payload.id]: _, ...restOfItems } = state.items

      return {
        ...state,
        items: Object.keys(restOfItems).length ? restOfItems : null
      }
    }

    case actionTypes.CLEAR:
      return {
        ...state,
        filtersQuery: '',
        items: null
      }

    case actionTypes.RESET:
      return {
        ...initialState,
        fetching: {
          common: state.fetching.common,
          custom: true,
          default: true
        },
        configuration: {
          customFields: [],
          fields: [],
          fieldTypes: state.configuration.fieldTypes,
          operators: state.configuration.operators
        }
      }

    default:
      return state
  }
}

const getFetching = state => Object.values(state[NAME].fetching).includes(true)
const getItems = state => state[NAME].items
const getFields = state => state[NAME].configuration.fields
const getFieldTypes = state => state[NAME].configuration.fieldTypes
const getFilterPreset = state => state[NAME].filterPreset
const getFiltersQuery = state => state[NAME].filtersQuery
const getOperators = state => state[NAME].configuration.operators
const getCustomFields = state =>
  state[NAME].configuration.customFields.map(item => ({
    label: item,
    fieldParent: `${state.ui.entity} Fields`
  }))
const getAdvanced = state => state[NAME].advanced
const getPreset = state => state[NAME].presets
const getCustomPresets = state => state[NAME].customPresets
const getDefault = state => state[NAME].default

const getAll = createSelector([getItems], items =>
  items ? Object.values(items) : []
)

const getHasItems = createSelector([getAll], items => items.length > 0)

const getAdvancedFilter = createSelector([getAdvanced], advanced =>
  advanced && advanced.value ? advanced : null
)

const selectors = {
  getFetching,
  getFields,
  getFieldTypes,
  getFilterPreset,
  getFiltersQuery,
  getOperators,
  getCustomFields,
  getAll,
  getAdvancedFilter,
  getPreset,
  getCustomPresets,
  getDefault,
  getHasItems
}

const filters = {
  namespace: NAME,
  initialState,
  actionTypes,
  actions,
  reducer,
  selectors
}

export default filters
