import React from 'react'
import PropTypes from 'prop-types'

import { EntityResults } from '@tranzact/platform_react-components'

import { ResultsWrapper, FilterRowLink } from 'components/shared'
import fixResults from 'utils/fixResults'
import { Filter } from 'models'

const Results = ({ columns, paging, results, sort, userRole, ...props }) => {
  const { viewFlow } = userRole.actions.source

  const fixedResults = fixResults({
    paging,
    results
  })

  const handleSelectRow = (_, event) => {
    event.stopPropagation()
  }

  function getNormalizedColumns() {
    if (viewFlow) {
      return columns.slice().map(column => {
        if (column.name === 'sourcecode') {
          return {
            ...column,
            cell: {
              ...column.cell,
              type: 'custom'
            },
            customCell: FilterRowLink
          }
        }
        return column
      })
    }
    return columns
  }

  return (
    <ResultsWrapper>
      <EntityResults
        {...props}
        stickyHeader
        columns={getNormalizedColumns()}
        results={fixedResults}
        sort={sort}
        onSelectRow={handleSelectRow}
      />
    </ResultsWrapper>
  )
}

Results.defaultProps = {
  filters: []
}

Results.propTypes = {
  filters: PropTypes.arrayOf(Filter),
  columns: PropTypes.arrayOf(Object).isRequired,
  paging: PropTypes.instanceOf(Object).isRequired,
  results: PropTypes.arrayOf(Object).isRequired,
  sort: PropTypes.instanceOf(Object).isRequired,
  userRole: PropTypes.instanceOf(Object).isRequired
}

export default Results
