import React from 'react'
import { Select, MenuItem, TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { LeadOperationFormField, MonitoringCodes } from 'models'
import { DistributionContainer, TargetField } from './styles'

const OperationDistributionForm = ({ values, handleChange }) => (
  <DistributionContainer>
    <FormControl fullWidth>
      <TargetField>
        <Select
          disabled
          fullWidth
          id="target-distribution-system-select"
          label="SYSTEM"
          value={0}
          variant="outlined"
        >
          <MenuItem value={0}>TZTLeads</MenuItem>
        </Select>
      </TargetField>
      <TargetField>
        <TextField
          fullWidth
          inputProps={{
            name: LeadOperationFormField.PID
          }}
          label="PID"
          value={values[LeadOperationFormField.PID]}
          variant="outlined"
          onChange={handleChange}
        />
      </TargetField>
      <TargetField>
        <TextField
          fullWidth
          inputProps={{
            name: LeadOperationFormField.CID
          }}
          label="CID"
          value={values[LeadOperationFormField.CID]}
          variant="outlined"
          onChange={handleChange}
        />
      </TargetField>
    </FormControl>
  </DistributionContainer>
)

OperationDistributionForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape(Object).isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  OperationDistributionForm,
  `${MonitoringCodes.COMP}LeadsDistributionForm`
)
