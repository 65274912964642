import { AppInsightsHelper } from '@tranzact/platform_react-components'
import { MonitoringKeys } from '../models'

const { APPINSIGHT_KEY, NAMESPACE } = MonitoringKeys

class AppInsightMonitoring {
  static init(authContext) {
    AppInsightsHelper.init({
      config: {
        enableAutoRouteTracking: true,
        extensionConfig: {},
        maxBatchInterval: 0
      },
      instrumentationKey: APPINSIGHT_KEY,
      namespace: NAMESPACE
    })

    const { _user } = authContext

    if (_user.userName) {
      AppInsightsHelper.setUser(_user.userName)
    }
  }
}

export default AppInsightMonitoring
