const getNormalizedActivityErrors = ({
  errors,
  isSuccessful,
  viewActivityWarning
}) => errors.reduce((result, error) => {
    const errorCopy = { ...error }

    if (isSuccessful && errorCopy.type === 'Error') {
      errorCopy.type = 'Warning'
      delete errorCopy.code
    }
    if (
      errorCopy.type !== 'Warning' ||
      (viewActivityWarning && errorCopy.type === 'Warning')
    )
      result.push(errorCopy)
    return result
  }, [])

export default getNormalizedActivityErrors
