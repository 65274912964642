import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Snackbar, Slide, Button } from '@mui/material'
import { AlertsType } from 'models'
import AlertContentWrapper from './AlertContentWrapper'

const SlideTransition = ({ ...props }) => <Slide {...props} direction="right" />

const Alerts = ({
  open,
  nextAlert,
  isProcessingQueue,
  show,
  hide,
  popAlert
}) => {
  useEffect(() => {
    if (!isProcessingQueue && nextAlert) show()
  }, [show, isProcessingQueue, nextAlert])

  const handleClose = (event, reason) => {
    // TODO: Could be a user setting
    if (reason === 'clickaway') {
      return
    }

    hide()
  }

  const processQueue = () => popAlert()
  const showLink = nextAlert?.showLink
  const action = showLink ? (
    <Button color="info" component={Link} size="small" to={showLink}>
      Show
    </Button>
  ) : null
  return (
    <Snackbar
      key={nextAlert ? nextAlert.id : undefined}
      TransitionComponent={SlideTransition}
      TransitionProps={{
        onExited: processQueue
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={4000}
      open={open}
      onClose={handleClose}
    >
      <AlertContentWrapper
        message={nextAlert && nextAlert.message}
        showAction={action}
        variant={nextAlert && nextAlert.variant}
        onClose={handleClose}
      />
    </Snackbar>
  )
}

Alerts.defaultProps = {
  open: false,
  isProcessingQueue: false,
  nextAlert: {
    showLink: true
  }
}

Alerts.propTypes = {
  open: PropTypes.bool,
  nextAlert: PropTypes.shape({
    id: PropTypes.number.isRequired,
    variant: PropTypes.oneOf(Object.values(AlertsType)),
    message: PropTypes.string.isRequired,
    showLink: PropTypes.string
  }),
  isProcessingQueue: PropTypes.bool,
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  popAlert: PropTypes.func.isRequired
}

export default Alerts
