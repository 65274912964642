import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { useHotjar } from '@tranzact/platform_react-components'

import { versionRepository } from 'api'
import AuthenticationContext from 'context/auth'
import { AlertsContainer } from 'components'
import { SiteLayout } from 'components/layout'
import { RedirectRenderPath } from 'components/shared'
import { UserSettingsState } from 'context/userSettings'
import { AppThemeProvider } from 'components/ui'
import { MonitoringKeys, HotjarMetadata } from 'models'
import { Maintenance, Unauthorized } from 'pages'
import AppRoutes from 'routes'
import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import { ContentWrapper } from './styles'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
})

const App = ({
  authContext,
  hubService,
  hasServerResponse,
  isAuthorized,
  userRole,
  setUserRole,
  onUserLoggedIn
}) => {
  const { HOTJAR_KEY, HOTJAR_VERSION } = MonitoringKeys

  const { initHotjar, identifyHotjar } = useHotjar()
  const [lmktVersion, setLmktVersion] = useState('')

  // TODO: We should replace this when we define the standar error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const userName = authContext?._user.userName

    onUserLoggedIn({ userName, hubService })

    setUserRole(userRole)

    if (initHotjar(HOTJAR_KEY, HOTJAR_VERSION, logger)) {
      HotjarMetadataHelper.setCredentials(userName, HotjarMetadata)

      const metadata = HotjarMetadataHelper.getMetadata()

      // eslint-disable-next-line camelcase
      const { lmkt_isHotjarReady, lmkt_userId } = metadata

      // eslint-disable-next-line camelcase
      if (JSON.parse(lmkt_isHotjarReady))
        identifyHotjar(lmkt_userId, metadata, logger)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const documentLoadInterval = setInterval(async () => {
      if (document.readyState === 'complete') {
        const result = await versionRepository.find()

        clearInterval(documentLoadInterval)
        setLmktVersion(result)
      }
    }, 3000)
    return () => {
      clearInterval(documentLoadInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthenticationContext.Provider value={authContext}>
      <QueryClientProvider client={queryClient}>
        <UserSettingsState>
          <AppThemeProvider>
            <BrowserRouter>
              <Routes>
                {!isAuthorized || !userRole ? (
                  <Route element={<Unauthorized />} path="/unauthorized" />
                ) : null}
                {isAuthorized ? (
                  (hasServerResponse && userRole && (
                    <Route
                      element={
                        <SiteLayout version={lmktVersion}>
                          <ContentWrapper>
                            <AppRoutes userRole={userRole} />
                          </ContentWrapper>
                          <AlertsContainer />
                        </SiteLayout>
                      }
                      path="*"
                    />
                  )) ||
                  (userRole ? (
                    <Route
                      element={
                        <Routes>
                          <Route
                            element={<Maintenance />}
                            path="/maintenance"
                          />
                          <Route
                            element={
                              <RedirectRenderPath renderPath="/maintenance" />
                            }
                            path="*"
                          />
                        </Routes>
                      }
                      path="*"
                    />
                  ) : (
                    <Route
                      element={
                        <RedirectRenderPath renderPath="/unauthorized" />
                      }
                      path="*"
                    />
                  ))
                ) : (
                  <Route
                    element={<RedirectRenderPath renderPath="/unauthorized" />}
                    path="*"
                  />
                )}
              </Routes>
            </BrowserRouter>
          </AppThemeProvider>
        </UserSettingsState>
      </QueryClientProvider>
    </AuthenticationContext.Provider>
  )
}

App.defaultProps = {
  userRole: null
}

App.propTypes = {
  authContext: PropTypes.instanceOf(Object).isRequired,
  hasServerResponse: PropTypes.bool.isRequired,
  hubService: PropTypes.instanceOf(Object).isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  userRole: PropTypes.instanceOf(Object),
  setUserRole: PropTypes.func.isRequired,
  onUserLoggedIn: PropTypes.func.isRequired
}

export default App
