class Activity {
  constructor(activity) {
    const {
      communication = null,
      date = null,
      details = null,
      errors = [],
      icon = null,
      id = null,
      parent = '',
      title = '',
      type = ''
    } = activity || {}

    this.communication = communication
    this.date = date
    this.details = details
    this.errors = errors
      ? [
          ...new Set(
            errors.map(error => {
              if (error.type === 'Warning') {
                return {
                  type: error.type,
                  exception: error.exception
                }
              }
              return error
            })
          )
        ]
      : []
    this.icon = icon
    this.id = id
    this.parent = parent
    this.title = title
    this.type = type
  }
}

export default Activity
