import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  Grid,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  FormHelperText
} from '@mui/material'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import { MenuItem } from '@tranzact/platform_react-components'

import { ScheduleFormField } from 'models'
import hoursOfTheDay from 'utils/hoursOfTheDay'
import { StyledTypography, FlexedMenuItem } from './styles'
import FormInput from '../../FormInput'

const {
  BetweenHourEnd,
  BetweenHourStart,
  HourlyRepeatingHours,
  HourlyWeekDays,
  UsesBetween
} = ScheduleFormField

const daysWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const hoursOfTheDayStart = hoursOfTheDay('00')
const hoursOfTheDayEnd = hoursOfTheDay('59')

const Hourly = ({ isReadonly }) => {
  const {
    control,
    formState: { errors, submitCount },
    trigger,
    watch
  } = useFormContext()
  const watchedHourlyWeekDays = watch(HourlyWeekDays)
  const watchedUsesBetween = watch(UsesBetween)

  const handleUseBetweenChange = onChange => event => {
    onChange(event)
    if (submitCount > 0)
      trigger([HourlyRepeatingHours, BetweenHourEnd, BetweenHourStart])
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={1}>
            <Controller
              control={control}
              name={UsesBetween}
              render={({ field }) => {
                const { name, value, onChange } = field

                return (
                  <Checkbox
                    {...field}
                    checked={value}
                    color="primary"
                    disabled={isReadonly}
                    inputProps={{ name }}
                    onChange={handleUseBetweenChange(onChange)}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <StyledTypography>Every hour between</StyledTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name={HourlyWeekDays}
          render={({ field }) => (
            <FormControl fullWidth variant="outlined">
              <InputLabel>Run on these days</InputLabel>
              <Select
                {...field}
                multiple
                disabled={isReadonly}
                hint="Leave blank to run all days"
                inputProps={{ name: field.name }}
                label="Run on these days"
                renderValue={selected => selected.join(', ')}
              >
                {daysWeek.map(day => (
                  <FlexedMenuItem key={day} value={day}>
                    <Checkbox
                      checked={watchedHourlyWeekDays.indexOf(day) > -1}
                    />
                    <ListItemText primary={day} />
                  </FlexedMenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      {!watchedUsesBetween ? (
        <Grid item xs={6}>
          <FormInput
            disabled={isReadonly}
            fieldName={HourlyRepeatingHours}
            label="Run every given hours:"
            placeholder="Ex: 1"
            type="number"
          />
        </Grid>
      ) : (
        <Grid item xs={6}>
          <Grid container>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={BetweenHourStart}
                  render={({ field }) => {
                    const { name } = field
                    const error = errors[name]?.message
                    const hasError = error !== undefined

                    return (
                      <FormControl
                        fullWidth
                        error={hasError}
                        variant="outlined"
                      >
                        <Select
                          {...field}
                          disabled={isReadonly}
                          error={hasError}
                          inputProps={{ name }}
                        >
                          {hoursOfTheDayStart.map(hour => (
                            <MenuItem key={hour} value={hour}>
                              {hour}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && <FormHelperText>{error}</FormHelperText>}
                      </FormControl>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <StyledTypography>and</StyledTypography>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={BetweenHourEnd}
                  render={({ field }) => {
                    const { name } = field
                    const error = errors[name]?.message
                    const hasError = error !== undefined

                    return (
                      <FormControl
                        fullWidth
                        error={hasError}
                        variant="outlined"
                      >
                        <Select
                          {...field}
                          disabled={isReadonly}
                          error={hasError}
                          inputProps={{ name }}
                        >
                          {hoursOfTheDayEnd.map(hour => (
                            <MenuItem key={hour} value={hour}>
                              {hour}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && <FormHelperText>{error}</FormHelperText>}
                      </FormControl>
                    )
                  }}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

Hourly.defaultProps = {
  isReadonly: false
}

Hourly.propTypes = {
  isReadonly: PropTypes.bool
}

export default Hourly
