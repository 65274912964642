import styled from '@emotion/styled'

import { Grid } from '@mui/material'

export const TargetField = styled.div`
  padding: 10px 10px 10px 5px;
`

export const StyledContainerGrid = styled(Grid)`
  padding-left: 24px;
  padding-right: 24px;
`

export const StyledItemGrid = styled(Grid)`
  margin-bottom: 20px;
`

export const DistributionContainer = styled.div`
  display: flex;
  flex-direction: row;
`
