import { AutoComplete } from 'models'
import Repository from './repository'

class Autocomplete extends Repository {
  resource = 'autocomplete'

  findValues = label =>
    this.get({
      action: `${label}`,
      mapTo: AutoComplete
    })
}

export default Autocomplete
