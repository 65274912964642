import React from 'react'

import { Grid } from '@mui/material'

import PropTypes from 'prop-types'

const Add = ({ details, dates, recurrence, handleSubmit }) => (
    <form noValidate id="schedule-form" onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {details}
        </Grid>
        <Grid item xs={12}>
          {dates}
        </Grid>
        <Grid item xs={12}>
          {recurrence}
        </Grid>
      </Grid>
    </form>
  )

Add.propTypes = {
  details: PropTypes.node.isRequired,
  recurrence: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dates: PropTypes.node.isRequired
}
export default Add
