import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useUserSettings } from 'hooks'
import UserSettingsContext from './userSettingsContext'

const initialUserSettings = {
  lightTheme: true,
  miniNavBar: false,
  miniSideBar: false,
  siteLayoutMiniSideBar: true
}

const UserSettingsState = ({ children }) => {
  const [userSettings, userSettingsSetters] =
    useUserSettings(initialUserSettings)
  const userSettingsValue = useMemo(
    () => ({
      ...userSettings,
      ...userSettingsSetters
    }),
    [userSettings, userSettingsSetters]
  )

  return (
    <UserSettingsContext.Provider value={userSettingsValue}>
      {children}
    </UserSettingsContext.Provider>
  )
}

UserSettingsState.propTypes = {
  children: PropTypes.node.isRequired
}

export default UserSettingsState
