export default {
  CertificateAdded: {
    event: 'certificateadded',
    description: 'Certificate Added'
  },
  LeadCreated: {
    event: 'leadcreated',
    description: 'Lead Created'
  },
  LeadUpdated: {
    event: 'leadupdated',
    description: 'Lead Updated'
  },
  DistributionCreated: {
    event: 'distributioncreated',
    description: 'Distribution Created'
  },
  PersonCreated: {
    event: 'personcreated',
    description: 'Person Created'
  },
  PolicyCreated: {
    event: 'policycreated',
    description: 'Policy Created'
  },
  PolicyRejected: {
    event: 'policyrejected',
    description: 'Policy Rejected'
  },
  PolicyIssued: {
    event: 'policyissued',
    description: 'Policy Issued'
  },
  PolicyPutOnHeld: {
    event: 'policyputonheld',
    description: 'Policy put on held'
  },
  ProbabilityAdded: {
    event: 'probabilityadded',
    description: 'Probability Added'
  },
  ProspectCreated: {
    event: 'prospectcreated',
    description: 'Prospect Created'
  },
  ProspectOpened: {
    event: 'prospectopened',
    description: 'Prospect Opened'
  },
  ProspectInteractionAdded: {
    event: 'prospectinteractionadded',
    description: 'Prospect Interaction Added'
  },
  ProspectClosed: {
    event: 'prospectclosed',
    description: 'Prospect Closed'
  },
  ApplicationAdded: {
    event: 'applicationadded',
    description: 'Application Added'
  },
  LeadShared: {
    event: 'sharingleadcompleted',
    description: 'Lead Shared'
  },
  LeadScrubbed: {
    event: 'leadscrubbed',
    description: 'Lead Scrubbed'
  }
}
