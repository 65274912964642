import React, { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  PersonRounded as PersonIcon,
  AssignmentIndRounded as AssignmentIndIcon,
  WorkRounded as WorkIcon,
  ScheduleRounded as ScheduleIcon,
  BubbleChartTwoTone as BubbleChartIcon
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import { SiteMenuLinkList } from '@tranzact/platform_react-components'

import UserSettingsContext from 'context/userSettings'

const TheNavBar = ({ userRole }) => {
  const { siteLayoutMiniSideBar } = useContext(UserSettingsContext)
  const { pathname, search } = useLocation()
  const lowerCasePath = pathname.toLowerCase()

  let viewLeads
  let viewJobs
  let viewSchedules
  let viewSources

  if (userRole) {
    const { lead, job, schedule, source } = userRole.actions
    viewLeads = lead.viewList
    viewJobs = job.viewList
    viewSchedules = schedule.viewList
    viewSources = source.viewList
  }

  const pages = [
    {
      title: 'Persons',
      icon: PersonIcon,
      href: '/persons/',
      enabled: true,
      customSearch: /(?:persons)/.test(lowerCasePath) ? search : '',
      pathPattern: '/persons/*'
    },
    {
      title: 'Leads',
      icon: AssignmentIndIcon,
      href: '/leads/',
      enabled: viewLeads,
      customSearch: /(?:leads)/.test(lowerCasePath) ? search : '',
      pathPattern: '/leads/*'
    },
    {
      title: 'Jobs',
      icon: WorkIcon,
      href: '/jobs/',
      enabled: viewJobs,
      customSearch: /(?:jobs)/.test(lowerCasePath) ? search : '',
      pathPattern: '/jobs/*'
    },
    {
      title: 'Schedules',
      icon: ScheduleIcon,
      href: '/schedules/',
      enabled: viewSchedules,
      customSearch: /(?:schedules)/.test(lowerCasePath) ? search : '',
      pathPattern: '/schedules/*'
    },
    {
      title: 'Sources',
      icon: BubbleChartIcon,
      href: '/sources/',
      enabled: viewSources,
      pathPattern: '/sources/*'
    }
  ].filter(({ enabled }) => enabled)

  return <SiteMenuLinkList isCollapsed={siteLayoutMiniSideBar} links={pages} />
}

TheNavBar.defaultProps = {
  userRole: null
}

TheNavBar.propTypes = {
  userRole: PropTypes.instanceOf(Object)
}

export default memo(TheNavBar)
