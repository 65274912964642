import { Typography } from '@mui/material'
import styled from '@emotion/styled'

export const StyledCustomSidebarFooter = styled.div`
  display: grid;
  text-align: center;
  & > div {
    margin: 0 auto;
  }
`

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.sideBar.color};
`

export const StyledContentWrapper = styled.div`
  height: 100%;
  & > div > div > div > div,
  > div > div > div.MuiGrid-container {
    padding: 0;
  }
`
