import { isNumber } from 'utils/dataTypes'

const appPrefix = 'lmkt'

export const setKey = (key, value) => {
  if (!key) return
  sessionStorage.setItem(
    `${appPrefix}.${key}`,
    !isNumber(value) ? JSON.stringify(value) : value
  )
}

export const getKey = key =>
  !!key && JSON.parse(sessionStorage.getItem(`${appPrefix}.${key}`))
