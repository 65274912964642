import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { results } from 'store/modules'
import Results from './Results'

const {
  getFetching: getResultsFetching,
  getColumns,
  getPagination,
  getResults,
  getSort
} = results.selectors
const { select, updateSort } = results.actions

const mapStateToProps = state => ({
  columns: getColumns(state),
  loading: getResultsFetching(state),
  paging: getPagination(state),
  sort: getSort(state),
  results: getResults(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSelectRow: select, onSort: updateSort }, dispatch)

const ResultsContainer = connect(mapStateToProps, mapDispatchToProps)(Results)

export default ResultsContainer
