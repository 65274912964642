class Source {
  constructor(raw) {
    if (!raw) return
    const {
      rowKey,
      sourceCode,
      campaign,
      afids,
      age,
      footprint,
      cid,
      target,
      priority,
      weight
    } = raw

    this.id = rowKey
    this.sourcecode = sourceCode
    this.campaign = campaign
    this.filterset = rowKey
    this.age = age
    this.afids = afids
    this.footprint = footprint
    this.cid = cid
    this.destination = target
    this.priority = priority
    this.weight = weight
  }
}

export default Source
