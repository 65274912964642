import { AppInsightsHelper } from '@tranzact/platform_react-components'
import { MonitoringCodes } from 'models/monitoring'

const validateQuery = ({ qry, entity }) => {
  if (!qry.field) {
    const msg = `Syntax error: '${qry.evaluated.keyword}' is not a valid ${entity} Property. Please check your query.`
    AppInsightsHelper?.trackError({
      error: new Error(`${MonitoringCodes.ERR}Utils_ValidateQuery: ${msg}`),
      severityLevel: 3
    })
    return {
      error: true,
      message: msg
    }
  }

  if (!qry.operator) {
    const msg = `Syntax error: '${qry.evaluated.operator}' is not a valid operator. Please check your query.`
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_ValidateQuery: ${msg}`
    )
    return {
      error: true,
      message: msg
    }
  }

  return { error: false, message: null }
}

export default validateQuery
