import { Job } from 'models'
import buildOdataQuery from 'utils/buildOdataQuery'
import Repository from './repository'

class Jobs extends Repository {
  newBaseUrl = process.env.REACT_APP_MACKEY_URL

  resource = 'jobs'

  findOne(id) {
    return this.get({
      action: id,
      mapTo: Job
    })
  }

  getMessages(id) {
    return this.get({
      action: `${id}/messages`,
      isArray: true
    })
  }

  find({ start, end, filters }) {
    const filter = buildOdataQuery(filters, 'and')

    return this.get({
      query: `from=${start}&to=${end}&level=0${
        filter ? `&filter=${encodeURIComponent(filter)}` : ''
      }`,
      isArray: true,
      mapTo: Job
    })
  }

  findSummary(start, end) {
    return this.get({ query: `from=${start}&to=${end}&level=0`, isArray: true })
  }

  cancel(id) {
    return this.update({
      action: `${id}/cancel`,
      payload: {}
    })
  }
}

export default Jobs
