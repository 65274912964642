import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { EntityLayout, useHotjar } from '@tranzact/platform_react-components'

import { ScheduleForm } from 'components/shared'
import UserSettingsContext from 'context/userSettings'
import useScheduleForm from 'hooks/useScheduleForm'
import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import Header from './Header'
import Toolbar from './Toolbar'
import { ResultsContainer } from './Results'
import PaginationContainer from './Pagination'

const Schedules = ({ entity, userRole }) => {
  const userSettingsContext = useContext(UserSettingsContext)

  const [clearedQuery, setClearedQuery] = useState('original')

  const {
    isLoading: isScheduleFormLoading,
    isOpen: isScheduleFormOpen,
    title: scheduleFormTitle,
    openNewForm: openNewScheduleForm,
    onClose: onScheduleFormClose,
    onSchedule
  } = useScheduleForm({
    entity
  })

  const {
    addSchedule,
    createFavorite,
    deleteFavorite,
    listFavorites,
    viewDetail
  } = userRole.actions.schedule

  // TODO: We should replace this when we define the standar error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const { identifyHotjar } = useHotjar()

  useEffect(() => {
    if (HotjarMetadataHelper.turnOnFlags(['lmkt_schedulesSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }
  }, [identifyHotjar, logger])

  const handleOnClearedQuery = () => {
    setClearedQuery('cleared')
    if (clearedQuery === 'cleared') setClearedQuery('original')
  }

  return (
    <>
      <EntityLayout
        header={
          <Header
            clearedQuery={clearedQuery}
            isDeleteFavoriteAllowed={deleteFavorite}
            isFavoriteListAllowed={listFavorites}
            isSaveAllowed={createFavorite}
            onClearedQuery={handleOnClearedQuery}
          />
        }
        results={
          <ResultsContainer
            pagination={
              <PaginationContainer
                location="bottom"
                rowsName={entity}
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
            }
            viewDetail={viewDetail}
          />
        }
        toolbar={
          <Toolbar
            isAddButtonActionAllowed={addSchedule}
            pagination={
              <PaginationContainer location="top" rowsName={entity} />
            }
            onClick={openNewScheduleForm}
          />
        }
        userSettingsContext={userSettingsContext}
      />
      {addSchedule && (
        <ScheduleForm
          isLoading={isScheduleFormLoading}
          open={isScheduleFormOpen}
          title={scheduleFormTitle}
          onClose={onScheduleFormClose}
          onSchedule={onSchedule}
        />
      )}
    </>
  )
}

Schedules.defaultProps = {
  userRole: null
}

Schedules.propTypes = {
  entity: PropTypes.string.isRequired,
  userRole: PropTypes.instanceOf(Object)
}

export default Schedules
