import React from 'react'
import PropTypes from 'prop-types'

import { EntityHeader } from '@tranzact/platform_react-components'

import { useAutocomplete } from 'hooks'

const Header = ({
  clearedQuery,
  customFields,
  fields,
  fieldTypes,
  filters,
  filtersQuery,
  loadingFilters,
  operators,
  clearFilters,
  removeFilter,
  saveFilters,
  onSearch,
  onClearedQuery,
  ...props
}) => {
  const [autocompleteProps] = useAutocomplete({
    clearedQuery,
    customFields,
    fields,
    fieldTypes,
    filterItems: filters,
    filtersQuery,
    historyMetadata: {},
    ignoreGUID: true,
    loadingFilters,
    operators,
    clearFilters,
    removeFilter,
    saveFilters,
    onClearedQuery,
    onSearch
  })

  return <EntityHeader {...{ autocompleteProps, ...props }} />
}

Header.propTypes = {
  clearedQuery: PropTypes.string.isRequired,
  customFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fieldTypes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  filtersQuery: PropTypes.string.isRequired,
  loadingFilters: PropTypes.bool.isRequired,
  operators: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  clearFilters: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  saveFilters: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClearedQuery: PropTypes.func.isRequired
}

export default Header
