import { useState } from 'react'

const initialState = {
  mainHeader: {
    headSubtitleContent: null,
    piiMask: true,
    showAvatar: true,
    subtitle: '',
    title: ''
  }
}
const { mainHeader: initialMainHeader } = initialState

export default () => {
  const [loading, setLoading] = useState(false)
  const [mainHeader, setMainHeader] = useState(initialMainHeader)

  return [
    { mainHeader, loading },
    {
      setLoading,
      setMainHeader
    }
  ]
}
