class NotificationItem {
  constructor(rawNotification) {
    if (!rawNotification) return

    this.id = rawNotification.id
    this.user = rawNotification.user
    this.message = rawNotification.message
    this.hyperlink = rawNotification.hyperlink
    this.dateTime = rawNotification.dateTime
    this.read = rawNotification.read
    this.status = rawNotification.status
    this.parameters = rawNotification.parameters
      ? JSON.parse(rawNotification.parameters)
      : null
    if (this.parameters) {
      this.linkId = this.parameters.jobid ?? this.parameters.id
      this.entity = this.parameters.entity ?? 'jobs'
      this.showLink = `/${this.entity}/${this.linkId}`
      this.type = this.parameters.type
      this.jobStatus = this.parameters.status
      this.showAlert = this.parameters.showalert
      this.messageWithId =
        this.entity === 'schedules'
          ? `${this.message}`
          : `${this.type} with ID: ${this.linkId} ${this.jobStatus}.`
    }
  }
}
export default NotificationItem
