import { format } from 'date-fns'

const generateRawJobActivity = job => {
  const started = {
    id: `${job.id}${1}`,
    parent: 'job',
    date: job.created,
    rawTitle: `New Job *started* successfully at *${format(
      job.created,
      'hh:mm:ss a'
    )}*`,
    type: 'job',
    activityType: 'started',
    details: {
      isSuccessful: true
    },
    errors: []
  }

  const activities = [started]

  if (job.status === 'Completed') {
    const ended = {
      id: `${job.id}${2}`,
      parent: 'job',
      date: job.ended,
      rawTitle: `Job *ended* at *${format(job.ended, 'hh:mm:ss a')}*`,
      errors: [],
      type: 'job',
      activityType: 'ended',
      details: {
        isSuccessful: true
      }
    }

    activities.push(ended)
  }

  return activities
}

export default generateRawJobActivity
