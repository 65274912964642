import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { results, filters } from 'store/modules'
import Results from './Results'

const { getFetching, getResults, getSort, getColumns, getPagination } =
  results.selectors
const { select, updateSort } = results.actions

const { getAll } = filters.selectors

const mapStateToProps = state => ({
  loading: getFetching(state),
  results: getResults(state),
  sort: getSort(state),
  columns: getColumns(state),
  paging: getPagination(state),
  filters: getAll(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSelectRow: select, onSort: updateSort }, dispatch)

const ResultsContainer = connect(mapStateToProps, mapDispatchToProps)(Results)

export default ResultsContainer
