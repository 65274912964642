import React, { useEffect } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  Grid,
  Checkbox,
  FormLabel,
  FormControlLabel,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

import { RecurrenceType, ScheduleFormField } from 'models'
import StyledDateTimePicker from './styles'

const { AddEndDate, EndDate, EndTime, RecurrenceOption, StartDate, StartTime } =
  ScheduleFormField

const DatePickerInput = ({ ownerState, ...otherProps }) => {
  const errorMessage = ownerState.slotProps.error?.message || ''

  return (
    <TextField
      {...otherProps}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  )
}

const Dates = ({ isReadonly }) => {
  const {
    control,
    formState: { errors, submitCount },
    setValue,
    trigger,
    watch
  } = useFormContext()

  const watchedAddEndDate = watch(AddEndDate)

  const watchedRecurrenceOption = watch(RecurrenceOption)

  const isRecurrenceOnce = watchedRecurrenceOption === RecurrenceType.Once

  const handleAddEndDateChange = onChange => event => {
    setValue(EndDate, null)
    setValue(EndTime, null)
    if (onChange) onChange(event)
    if (submitCount > 0) trigger([EndDate, EndTime])
  }

  const handleDateTimeChange = onChange => event => {
    onChange(event)
    if (submitCount > 0) trigger([StartTime, EndDate, EndTime])
  }

  useEffect(() => {
    if (isRecurrenceOnce) {
      setValue(EndDate, null)
      setValue(EndTime, null)
      setValue(AddEndDate, false)
    }
    if (submitCount > 0) trigger([EndDate, EndTime])
  }, [isRecurrenceOnce, setValue, submitCount, trigger])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormLabel>Schedule</FormLabel>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={StartDate}
            render={({ field }) => {
              const { name, onChange } = field

              return (
                <DatePicker
                  {...field}
                  disabled={isReadonly}
                  label="Start Date"
                  slotProps={{
                    error: errors[name],
                    inputAdornment: { position: 'start' },
                    name
                  }}
                  slots={{ textField: DatePickerInput }}
                  onChange={handleDateTimeChange(onChange)}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name={StartTime}
            render={({ field }) => {
              const { name, onChange } = field

              return (
                <StyledDateTimePicker
                  {...field}
                  ampm={false}
                  disabled={isReadonly}
                  label="Start Time"
                  slotProps={{
                    error: errors[name],
                    inputAdornment: { position: 'start' },
                    name
                  }}
                  slots={{ textField: DatePickerInput }}
                  onChange={handleDateTimeChange(onChange)}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={3}>
          {watchedAddEndDate && !isRecurrenceOnce && (
            <Controller
              control={control}
              name={EndDate}
              render={({ field }) => {
                const { name, onChange } = field

                return (
                  <DatePicker
                    {...field}
                    disablePast={!isReadonly}
                    disabled={isReadonly}
                    label="End Date"
                    slotProps={{
                      error: errors[name],
                      inputAdornment: { position: 'start' },
                      name
                    }}
                    slots={{ textField: DatePickerInput }}
                    onChange={handleDateTimeChange(onChange)}
                  />
                )
              }}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {watchedAddEndDate && !isRecurrenceOnce && (
            <Controller
              control={control}
              name={EndTime}
              render={({ field }) => {
                const { name, onChange } = field

                return (
                  <StyledDateTimePicker
                    {...field}
                    ampm={false}
                    disabled={isReadonly}
                    label="End Time"
                    slotProps={{
                      error: errors[name],
                      inputAdornment: { position: 'start' },
                      name
                    }}
                    slots={{ textField: DatePickerInput }}
                    onChange={handleDateTimeChange(onChange)}
                  />
                )
              }}
            />
          )}
        </Grid>
      </LocalizationProvider>
      {!isRecurrenceOnce ? (
        <Grid item xs={6}>
          <Controller
            control={control}
            name={AddEndDate}
            render={({ field }) => {
              const { name, value, onChange } = field

              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={value}
                      color="primary"
                      disabled={isReadonly}
                      inputProps={{ name }}
                      value="Add end date"
                      onChange={handleAddEndDateChange(onChange)}
                    />
                  }
                  label="Add end date"
                />
              )
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  )
}

DatePickerInput.propTypes = {
  ownerState: PropTypes.instanceOf(Object).isRequired
}

Dates.defaultProps = {
  isReadonly: false
}

Dates.propTypes = {
  isReadonly: PropTypes.bool
}

export default React.memo(Dates)
