import React from 'react'
import PropTypes from 'prop-types'
import {
  CreateRounded as CreateIcon,
  PlaylistAddCheckRounded as PlaylistAddCheckIcon,
  SendRounded as SendIcon,
  ThumbUp as ThumbUpIcon,
  Phone as PhoneIcon,
  Assignment as AssigmentIcon,
  Casino as CasinoIcon,
  PostAdd as PostAddIcon,
  Person as PersonIcon,
  Update as UpdateIcon,
  Share as ShareIcon,
  Delete as ScrubIcon
} from '@mui/icons-material'
import { formatDistanceToNow } from 'date-fns'
import { useParams } from 'react-router-dom'

import { BoldText } from 'components/ui'
import { CommonDetails } from 'components/shared'
import { activitiesRepository } from 'api'
import Overview from 'components/Persons/Overview'
import { LeadPersonActivityDetailLabel, PersonEvent } from 'models'
import calculateLeadPersonActivityDetailValue from 'utils/calculateLeadPersonActivityDetailValue'
import getNormalizedActivityErrors from 'utils/getNormalizedActivityErrors'
import { usePersonDetails } from 'hooks'

const IconDictionary = {
  lead: CreateIcon,
  certificate: PlaylistAddCheckIcon,
  distribution: SendIcon,
  bestcustomer: ThumbUpIcon,
  prospect: PhoneIcon,
  policy: AssigmentIcon,
  probability: CasinoIcon,
  application: PostAddIcon,
  person: PersonIcon,
  update: UpdateIcon,
  share: ShareIcon,
  scrub: ScrubIcon
}
const Details = ({ entity, items, showAlert, userRole }) => {
  const { id: selectedId } = useParams()
  const { details: person } = usePersonDetails(selectedId)

  const {
    piiMask,
    actions: {
      person: { viewActivityWarning }
    }
  } = userRole

  async function fetchLeadInfo(id, { activityTypes, activityDate }) {
    if (activityTypes && !activityTypes.length) return null

    const eventTypes = activityTypes ? activityTypes.join(',') : ''

    let fromDate
    let toDate

    if (activityDate) {
      if (Number.isNaN(activityDate.getTime())) return null
      const fromRawDate = new Date(
        activityDate.getFullYear(),
        activityDate.getMonth(),
        activityDate.getDate()
      )
      const toRawDate = new Date(
        activityDate.getFullYear(),
        activityDate.getMonth(),
        activityDate.getDate() + 1
      )

      fromDate = fromRawDate.toISOString()
      toDate = toRawDate.toISOString()
    }

    const activityInfo = await activitiesRepository.findByPerson(id, {
      eventTypes,
      fromDate,
      toDate
    })

    const activityList = activityInfo.map(
      ({
        title,
        date,
        details: { startDate, endDate, ...activityDetails },
        communication,
        errors,
        ...activity
      }) => {
        const fields = Object.keys(activityDetails).filter(
          field => activityDetails[field] !== undefined
        )
        const { isSuccessful } = activityDetails

        return {
          ...activity,
          isSuccessful,
          additionalInfo: !piiMask && communication,
          date: new Date(date),
          details: fields.map(field => ({
            label: `${LeadPersonActivityDetailLabel[field] || 'Unknown'}:`,
            value:
              calculateLeadPersonActivityDetailValue({
                details: activityDetails,
                field
              }) || '-',
            onClick: ['id', 'sharedLeadId', 'originLeadId'].includes(field)
              ? () => {
                  window.open(`/leads/${activityDetails[field]}`, '_blank')
                }
              : null
          })),
          errors: getNormalizedActivityErrors({
            errors,
            isSuccessful,
            viewActivityWarning
          }),
          expandableKeys: ['Warning'],
          icon: IconDictionary[activity.type],
          title: <BoldText text={title} />
        }
      }
    )

    return activityList
  }

  const tabs = person && [
    {
      label: 'Details',
      content: <Overview person={person} piiMask={piiMask} />
    }
  ]

  const activityTypes = []

  // eslint-disable-next-line no-restricted-syntax
  for (const event in PersonEvent) {
    if (Object.prototype.hasOwnProperty.call(PersonEvent, event)) {
      activityTypes.push({
        type: PersonEvent[event].event,
        description: PersonEvent[event].description
      })
    }
  }

  return (
    <CommonDetails
      dateFilterEnabled
      activityTypes={activityTypes}
      entity={entity}
      items={items}
      piiMask={piiMask}
      showAlert={showAlert}
      subtitle={`Added ${
        person &&
        formatDistanceToNow(person.date, {
          addSuffix: true,
          includeSeconds: true
        })
      }`}
      tabs={tabs}
      title={person?.fullName || ''}
      onFetchActivity={fetchLeadInfo}
      onLoadInfo={() => Promise.resolve()}
    />
  )
}

Details.defaultProps = {
  items: [],
  userRole: {
    piiMask: false
  }
}

Details.propTypes = {
  entity: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  showAlert: PropTypes.func.isRequired,
  userRole: PropTypes.instanceOf(Object)
}

export default React.memo(Details)
