import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Grid } from '@mui/material'

import { TabPanel } from 'components/ui'
import Loader from './Loader'
import ContentLoader from './ContentLoader'
import {
  StyledSwipeableViews,
  RootGrid,
  MainGrid,
  SidebarGrid,
  Header,
  ContentGrid,
  TabsWrapper
} from './styles'

const DetailsLayout = ({ loading, selectedId, activityContent, tabs }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  useEffect(() => {
    if (selectedId) setValue(0)
  }, [selectedId])

  return (
    <RootGrid container wrap="nowrap">
      <MainGrid
        container
        item
        direction="column"
        sx={{
          ...(activityContent
            ? { maxWidth: '55%', flexBasis: '55%' }
            : { maxWidth: '100%', flexBasis: '100%' })
        }}
        wrap="nowrap"
      >
        <Grid item>
          {loading ? (
            <Loader />
          ) : (
            <Header>
              {tabs && (
                <TabsWrapper>
                  <Tabs
                    aria-label="details tabs"
                    indicatorColor="primary"
                    value={value}
                    variant="fullWidth"
                    onChange={handleChange}
                  >
                    {tabs.map(({ label }, index) => (
                      <Tab key={`tab-${label}`} label={label} value={index} />
                    ))}
                  </Tabs>
                </TabsWrapper>
              )}
            </Header>
          )}
        </Grid>
        <ContentGrid item xs={12}>
          {loading ? (
            <ContentLoader />
          ) : (
            tabs && (
              <StyledSwipeableViews
                axis="x"
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                {tabs.map(({ content, label }, index) => (
                  <TabPanel
                    key={`tab-content-${label}`}
                    index={index}
                    value={value}
                  >
                    {content}
                  </TabPanel>
                ))}
              </StyledSwipeableViews>
            )
          )}
        </ContentGrid>
      </MainGrid>

      {activityContent && <SidebarGrid item>{activityContent}</SidebarGrid>}
    </RootGrid>
  )
}

DetailsLayout.defaultProps = {
  loading: false,
  activityContent: null,
  selectedId: '',
  tabs: null
}

DetailsLayout.propTypes = {
  loading: PropTypes.bool,
  selectedId: PropTypes.string,
  activityContent: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node
    })
  )
}

export default DetailsLayout
