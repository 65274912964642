import styled from '@emotion/styled'

import { Typography } from '@mui/material'
import { MenuItem } from '@tranzact/platform_react-components'

export const StyledTypography = styled(Typography)`
  margin-top: 10px;
  margin-left: 5px;
`
export const FlexedMenuItem = styled(MenuItem)`
  display: flex;
`
