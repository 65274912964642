import styled from '@emotion/styled'

const ResultsWrapper = styled.div`
  & .table-container {
    ${() => {
      const header = document
        .getElementsByTagName('main')[0]
        .querySelector('header')
      const topTableSection = document.querySelector('.top-section')
      const tableHeightDifference =
        (topTableSection?.getBoundingClientRect().height || 0) +
        (header?.getBoundingClientRect().height || 0) +
        52

      return `height: calc(100vh - ${
        tableHeightDifference > 0 ? tableHeightDifference : 0
      }px);`
    }}
    & ~ .results-pagination > div {
      margin-left: auto;
      padding-right: 24px;
    }
    & > table > thead > tr > th:first-of-type,
    > table > tbody > tr > td:first-of-type {
      padding-left: 24px;
    }
    & > table > thead > tr > th:last-of-type,
    > table > tbody > tr > td:last-of-type {
      padding-right: 24px;
    }
  }
`

export default ResultsWrapper
