import { Avatar, Grid } from '@mui/material'
import styled from '@emotion/styled'

export const NoOverflowGrid = styled(Grid)`
  overflow: hidden;
`

export const StyledGridHeaderContent = styled(Grid)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`

export const StyledGridTitleContainer = styled(Grid)`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
`

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  width: 200px;
`

export const StyledTitle = styled.div`
  line-height: 1;
  overflow: hidden;
  width: 100%;

  > :first-of-type {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const StyledWrapper = styled(Grid)`
  padding: 8.85px 24px;
`

export const StyledAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
  margin-right: ${props => `${props.theme.spacing(2)}`};
  font-size: ${props => props.theme.typography.h5.fontSize};
`
