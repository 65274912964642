import * as go from 'gojs'

import { defaultColor, failureColor, lightBlueColor } from '../constants'
import { getLMUrlCursor, nodeActivityTaskTypeConverter } from '../helper'

export function getTaskScriptIconTemplate($) {
  return $(
    go.Shape,
    {
      alignment: new go.Spot(0, 0, 7.5, 7.5),
      alignmentFocus: go.Spot.TopLeft,
      fill: defaultColor,
      stroke: lightBlueColor,
      width: 15,
      height: 15
    },
    new go.Binding('stroke', 'isCall', () => failureColor),
    new go.Binding('figure', 'taskType', nodeActivityTaskTypeConverter),
    getLMUrlCursor()
  ) // end Task Icon
}

export default { getTaskScriptIconTemplate }
