import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

import { useHotjar } from '@tranzact/platform_react-components'

const renderComponent = {
  accessDenied: lazy(() => import('pages/AccessDenied')),
  autocomplete: lazy(() => import('pages/AutoComplete')),
  notFound: lazy(() => import('pages/NotFound')),
  sandbox: lazy(() => import('pages/Sandbox'))
}

const RenderInformativeView = ({ moduleName, clearAll, setEntity }) => {
  const { manuallyTrackHotjar } = useHotjar()

  const Component = renderComponent[moduleName]

  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  manuallyTrackHotjar(window.location.href, logger)
  clearAll()
  setEntity('')

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

RenderInformativeView.propTypes = {
  moduleName: PropTypes.string.isRequired,
  clearAll: PropTypes.func.isRequired,
  setEntity: PropTypes.func.isRequired
}

export default RenderInformativeView
