import React from 'react'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { OverviewLayout } from 'components/layout'
import useSchedulesDetailsOverview from 'hooks/useSchedulesDetailsOverview'
import { MonitoringCodes, Schedule } from 'models'

const Overview = ({ operators, schedule, onShowAlert }) => {
  const { sections } = useSchedulesDetailsOverview(
    operators,
    schedule,
    onShowAlert
  )

  return <OverviewLayout sections={sections} />
}

Overview.propTypes = {
  schedule: PropTypes.instanceOf(Schedule).isRequired,
  operators: PropTypes.arrayOf(Object).isRequired,
  onShowAlert: PropTypes.func.isRequired
}

export default React.memo(
  withAITracking(
    AppInsightsReactPlugin,
    Overview,
    `${MonitoringCodes.COMP}SchedulesOverview`
  )
)
