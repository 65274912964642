import { FilterOperatorType } from 'models'

const filterOperatorTypes = Object.keys(FilterOperatorType).join('|')
const operatorRegex = new RegExp(
  `^(${filterOperatorTypes})[(]|\\s(${filterOperatorTypes})\\s`
)
const filterCleanerRegex = /(?:[\w/]+\(f:\sf\/)([\w\s('/,)]*)/ // Remove unnecesary data before filter
const filterNotCleanerRegex = /(?:[\w/]+\s\(f\/)([\w\s('/,]*[)])/
const filterSeparatedRegex = /([\w]*),\s([\w']*)\)/ // Get filter values separated by comma

const handleOperatorType = (filter, operator) => {
  if (filter.startsWith('NOT')) return `NOT ${FilterOperatorType[operator]}`
  return FilterOperatorType[operator]
}
export const buildFilter = text => {
  if (!text) return ''
  const [, cleanText] = filterCleanerRegex.exec(text) || []
  const label = (cleanText || text || '').trim()
  const [rawOperator, foundOperator] = operatorRegex.exec(label) || []
  const operator = foundOperator || rawOperator
  if (!operator) return label
  const operatorValue = handleOperatorType(label, operator.trim())
  const [, field, value] = filterSeparatedRegex.exec(label) || []
  const filter = field && `${field} ${operatorValue} ${value}`
  const alternativeFilter = label.replace(operator, ` ${operatorValue} `)
  const newFilter = (filter || alternativeFilter).trim()
  const [, cleanNotFilter] = filterNotCleanerRegex.exec(newFilter) || []
  return (cleanNotFilter || newFilter).split('/').join(' ')
}

export default { buildFilter }
