import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  EmojiPeople as PeopleIcon,
  PlayArrow as PlayArrowIcon,
  Refresh as RefreshIcon,
  ScheduleRounded as ScheduleIcon
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import { EntityToolbar, useHotjar } from '@tranzact/platform_react-components'

import { ScheduleForm } from 'components/shared'
import AuthenticationContext from 'context/auth'
import useScheduleForm from 'hooks/useScheduleForm'
import useTimeframe from 'hooks/useTimeframe'
import {
  MonitoringCodes,
  LeadOperationType,
  ToolbarMenuOption,
  LeadOperationFormField
} from 'models'
import CustomExport from './CustomExport'
import OperationForm from './OperationForm'

const query = {
  selectFields: 'Id,Person,Timeline,Source,Target,Errors',
  expand: 'Errors,Person($select=Address,Phone,Id,FirstName,LastName,Created)',
  top: '',
  skip: ''
}

const start = {
  [LeadOperationType.Export]({
    triggerOperation,
    userId,
    leadFields = [],
    fields = []
  }) {
    triggerOperation({
      actionType: LeadOperationType.Export,
      userId,
      query: {},
      exportFields: { fields, leadFields, personFields: [] }
    })
  },
  [LeadOperationType.Update]({ triggerOperation, userId, values }) {
    triggerOperation({
      actionType: LeadOperationType.Update,
      userId,
      query: { ...query, top: values[LeadOperationFormField.Quantity] }
    })
  },
  [LeadOperationType.Distribution]({ triggerOperation, userId, values }) {
    triggerOperation({
      actionType: LeadOperationType.Distribution,
      userId,
      query: { ...query, top: values[LeadOperationFormField.Quantity] },
      target: {
        pid: values[LeadOperationFormField.PID],
        cid: values[LeadOperationFormField.CID]
      }
    })
  }
}

const buildFilterList = ({
  customPresetFilter,
  defaultFilter,
  allFilter,
  advancedFilter
}) => {
  if (!advancedFilter) {
    const customPresets = customPresetFilter
      ? customPresetFilter
          .map(rawCustomPreset => rawCustomPreset.filter)
          .filter(filter => filter && filter.value)
      : []

    return [...customPresets, ...defaultFilter, ...allFilter]
  }
  return [advancedFilter]
}

const Toolbar = ({
  addSchedule,
  advancedFilter,
  allFilter,
  appInsights,
  columnQueryName,
  customFields,
  customPresetFilter,
  defaultFilter,
  entity,
  presets,
  shouldDistribute,
  shouldUpdate,
  shouldExport,
  sort,
  total,
  showAlert,
  requestResult,
  triggerOperation,
  onChangeDate,
  ...props
}) => {
  const [openForm, setOpenForm] = useState(false)
  const [openCustomExport, setOpenCustomExport] = useState(false)
  const [operationTitle, setOperationTitle] = useState('')
  const [operationType, setOperationType] = useState('')
  const {
    _user: { userName }
  } = useContext(AuthenticationContext)
  const { search } = useLocation()
  const { tagRecordingHotjar } = useHotjar()
  const {
    isOpen: isScheduleFormOpen,
    title: scheduleFormTitle,
    openNewForm: openNewScheduleForm,
    onClose: onScheduleFormClose,
    onSchedule,
    onUpdate
  } = useScheduleForm({ entity })
  const { handleOnChangeDate } = useTimeframe(onChangeDate, entity)

  const [, searchFilter] =
    /\?search=([\w\s:,@()-<?>[\]'#&]+)/i.exec(decodeURIComponent(search)) ?? []

  const filters = buildFilterList({
    customPresetFilter,
    defaultFilter,
    allFilter,
    advancedFilter
  })

  const presetFilters = presets.map(preset => preset.filter)

  const toggle = () => {
    setOpenForm(previous => !previous)
  }

  const toggleCustomExport = () => {
    setOpenCustomExport(previous => !previous)
  }

  const options = [
    new ToolbarMenuOption({
      id: '1',
      name: 'Start Distribution',
      icon: PlayArrowIcon,
      isAllowed: shouldDistribute && searchFilter,
      event: () => {
        toggle()
        setOperationTitle('Leads Distribution')
        setOperationType(LeadOperationType.Distribution)
      }
    }),
    new ToolbarMenuOption({
      id: '2',
      name: 'Start Update',
      icon: RefreshIcon,
      isAllowed: shouldUpdate && searchFilter,
      event: () => {
        toggle()
        setOperationTitle('Leads Update')
        setOperationType(LeadOperationType.Update)
      }
    }),
    new ToolbarMenuOption({
      id: '3',
      name: 'Export',
      icon: PeopleIcon,
      isAllowed: shouldExport,
      event: () => {
        const max = process.env.REACT_APP_EXPORT_MAX

        if (total > max) {
          const msg = `Export quantity can not be larger than ${process.env.REACT_APP_EXPORT_MAX}. Please contact our support channel for assistance.`

          const tag = `${MonitoringCodes.ERR}LeadsExport: ${msg}`

          appInsights?.trackError(tag)

          tagRecordingHotjar([tag])

          showAlert({
            message: msg
          })
        } else toggleCustomExport()
      }
    }),
    new ToolbarMenuOption({
      id: '4',
      name: 'Schedule',
      icon: ScheduleIcon,
      isAllowed: addSchedule && searchFilter,
      event: openNewScheduleForm
    })
  ]

  const handleTriggerOperation = values => {
    const tag = `${MonitoringCodes.EVENT}Leads${operationType}Start`
    appInsights?.trackEvent(tag, { triggerOperation, userName, values })

    tagRecordingHotjar([tag])

    start[operationType]({
      triggerOperation,
      userId: userName,
      values
    })
  }

  const handleCustomExportOperation = values => {
    const tag = `${MonitoringCodes.EVENT}Leads${LeadOperationType.Export}Start`

    appInsights?.trackEvent(tag, { triggerOperation, userName, values })

    tagRecordingHotjar([tag])

    start[LeadOperationType.Export]({
      ...values,
      triggerOperation,
      userId: userName
    })
  }

  return (
    <>
      <EntityToolbar
        datePresetProps={{
          presets,
          onChangeDate: handleOnChangeDate
        }}
        options={options}
        {...props}
      />
      <OperationForm
        closeDialog={toggle}
        open={openForm}
        operationType={operationType}
        title={operationTitle}
        onTriggerOperation={handleTriggerOperation}
      />

      <CustomExport
        closeDialog={toggleCustomExport}
        columnQueryName={columnQueryName}
        customFields={customFields}
        filters={filters}
        open={openCustomExport}
        presetFilters={presetFilters}
        requestResult={requestResult}
        sort={sort}
        userName={userName}
        onTriggerExport={handleCustomExportOperation}
      />
      {addSchedule ? (
        <ScheduleForm
          open={isScheduleFormOpen}
          searchFilter={searchFilter}
          selectedFilter={allFilter}
          title={scheduleFormTitle}
          onClose={onScheduleFormClose}
          onSchedule={onSchedule}
          onUpdate={onUpdate}
        />
      ) : null}
    </>
  )
}

Toolbar.defaultProps = {
  advancedFilter: null,
  allFilter: [],
  appInsights: undefined,
  customPresetFilter: null,
  defaultFilter: [],
  presets: [],
  requestResult: {},
  sort: null,
  shouldDistribute: true,
  shouldUpdate: true,
  shouldExport: true
}

Toolbar.propTypes = {
  addSchedule: PropTypes.bool.isRequired,
  allFilter: PropTypes.arrayOf(Object),
  advancedFilter: PropTypes.instanceOf(Object),
  appInsights: PropTypes.func,
  columnQueryName: PropTypes.string.isRequired,
  customFields: PropTypes.arrayOf(String).isRequired,
  customPresetFilter: PropTypes.arrayOf(Object),
  defaultFilter: PropTypes.arrayOf(Object),
  entity: PropTypes.string.isRequired,
  presets: PropTypes.arrayOf(Object),
  requestResult: PropTypes.instanceOf(Object),
  sort: PropTypes.instanceOf(Object),
  shouldDistribute: PropTypes.bool,
  shouldUpdate: PropTypes.bool,
  shouldExport: PropTypes.bool,
  total: PropTypes.number.isRequired,
  showAlert: PropTypes.func.isRequired,
  triggerOperation: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired
}

export default Toolbar
