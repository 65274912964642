import { Button } from '@mui/material'
import styled from '@emotion/styled'

import { TranzactColor } from '@tranzact/platform_react-components'

export const StyledErrorWrapper = styled.div`
  color: #fff;
  background-color: ${TranzactColor.Info.Active};
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100vh;
  text-align: center;
  width: 100vw;

  & .MuiButtonBase-root {
    width: 225px;
  }

  & svg {
    margin-bottom: 50px;
    transform: scale(3);
  }
`

export const StyledSlackButton = styled(Button)`
  background-color: ${TranzactColor.Dark.Default} !important;
`
