const JobStatus = {
  None: 'None',
  All: 'All',
  Completed: 'Completed',
  Running: 'Running',
  Canceling: 'Canceling',
  Canceled: 'Canceled',
  WithErrors: 'Completed with errors'
}

export default JobStatus
