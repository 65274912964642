import Filter from './filter'

class Preset {
  constructor(rawPreset) {
    if (!rawPreset) return

    this.id = rawPreset.id
    this.label = rawPreset.label
    this.nullOptionLabel = rawPreset.nullOptionLabel
    this.defaultValue = rawPreset.defaultValue
    this.format = rawPreset.format
    this.selectedValue = rawPreset.selectedValue
    this.data = rawPreset.data
    this.filter = new Filter(rawPreset.filter)
  }
}

export default Preset
