import { BPMN_EVENT } from './constants'

let handleLinks = null
let hasLightTheme = false

function handleNode({ nodes, nodeIndex, hasError, isPath }) {
  nodes.push({
    from: nodeIndex,
    hasLightTheme,
    to: nodeIndex + 1,
    isPath,
    ...(hasError !== undefined ? { hasError } : {})
  })
}
function getGatewayLink(nodeIndex) {
  return link => {
    const { from, hasError, isPath, isLastIndex } = link
    if (isLastIndex)
      return { from, hasLightTheme, hasError, isPath, to: nodeIndex + 1 }
    return link
  }
}
function handleGatewayNodes(nodes, gateway, currentNodeIndex) {
  let nodeIndex = currentNodeIndex
  const { flows } = gateway
  const links = flows
    .reduce((linkResult, item) => {
      const linksData = handleLinks(item, nodeIndex - 1)
      const [gatewayLinks, gatewayNodeIndex] = linksData
      const { flow, isPath } = item
      const { hasError } = flow[0].tasks[0]

      nodeIndex = gatewayNodeIndex + 1
      gatewayLinks[0].text = item.key
      gatewayLinks[0].from = currentNodeIndex
      gatewayLinks[0].isPath = isPath
      linkResult.push(...gatewayLinks, {
        from: nodeIndex,
        isLastIndex: true,
        isPath,
        ...(hasError ? { hasError } : {})
      })
      return linkResult
    }, [])
    .map(getGatewayLink(nodeIndex))
  if (flows.length === 1) handleNode({ nodes, nodeIndex })
  nodes.push(...links)
  return nodeIndex
}
function handleTasks(results, tasks, currentNodeIndex) {
  const [nodes, subprocesses] = results
  let nodeIndex = currentNodeIndex
  tasks.forEach(task => {
    const { event, gateway, subprocess, isPath, hasError } = task
    nodeIndex += 1
    if (subprocess) subprocesses.push(subprocess)
    if (gateway) nodeIndex = handleGatewayNodes(nodes, gateway, nodeIndex)
    else handleNode({ nodes, nodeIndex, hasError, isPath })
    if (BPMN_EVENT.distribution === BPMN_EVENT[event]) nodeIndex += 1 // skip link position
  })
  return nodeIndex
}
function handleSubprocessNodes(subprocesses, currentNodeIndex) {
  let nodeIndex = currentNodeIndex
  const nodes = []
  subprocesses.forEach(subprocess => {
    const linksData = handleLinks(subprocess, nodeIndex + 1)
    const [subprocessLinks, subprocessNodeIndex] = linksData
    nodes.push(...subprocessLinks)
    nodeIndex = subprocessNodeIndex
  })
  return nodes
}
function getLinkDataArray(flowPath, className) {
  hasLightTheme = !className.includes('dark')
  const [diagramLinks, nodeIndex, subprocesses] = handleLinks(flowPath, 0)
  const subprocessLinks = handleSubprocessNodes(subprocesses, nodeIndex)
  return [...diagramLinks, ...subprocessLinks]
}

handleLinks = (path, currentNodeIndex) => {
  const links = []
  const subprocesses = []
  const { flow, isPath } = path
  let nodeIndex = currentNodeIndex
  if (flow) {
    links.push(
      ...flow.reduce((result, node) => {
        const { tasks } = node
        nodeIndex = handleTasks(
          [result, subprocesses, isPath],
          tasks,
          nodeIndex
        )
        return result
      }, [])
    )
  }
  return [links, nodeIndex, subprocesses]
}
export default getLinkDataArray
