import { format, differenceInSeconds } from 'date-fns'

import dateStringToDate from './dateStringToDate'

const VALUES_DICTIONARY = {
  isSuccessful: value => (value ? 'Accepted' : 'Rejected'),
  startDate: value => format(value, 'HH:mm a'),
  endDate: (value, source) =>
    value ? `${differenceInSeconds(value, source.startDate)} sec.` : null,
  policyEffectiveDate: value => {
    if (!value) return null
    const { day, month, year } = dateStringToDate(value)

    return `${month}/${day}/${year}`
  }
}

const calculateLeadPersonActivityDetailValue = ({ details, field }) =>
  VALUES_DICTIONARY[field]
    ? VALUES_DICTIONARY[field](details[field], details)
    : details[field]

export default calculateLeadPersonActivityDetailValue
