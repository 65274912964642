import format from 'date-fns/format'
import { AppInsightsHelper } from '@tranzact/platform_react-components'
import { MonitoringCodes } from 'models/monitoring'
import { RecurrenceType } from 'models'

function validateHourlyConfig(usesBetween, betweenHourStart, betweenHourEnd) {
  if (usesBetween && (!betweenHourStart || !betweenHourEnd)) {
    const msg = 'Hour range is required for hourly recurrence'
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_CronExpression: ${msg}`
    )
    throw new Error(msg)
  }
}

function validateMonthlyConfig(monthlyDays, monthlyMonths) {
  if (!monthlyDays || !monthlyMonths) {
    const msg =
      'Monthly days and Montly months are required for monthly recurrence'
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_CronExpression: ${msg}`
    )
    throw new Error(msg)
  }
}

const parseCronObject = ({
  minutes,
  hours,
  dayOfMonth = '*',
  month = '*',
  dayOfWeek = '?',
  year = '*'
}) => `0 ${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`

const recurrenceMethods = {
  [RecurrenceType.Once]({ startDate, minutes, hours }) {
    return parseCronObject({
      minutes,
      hours,
      dayOfMonth: startDate.getDate(),
      month: format(startDate, 'MMM'),
      year: startDate.getFullYear()
    })
  },
  [RecurrenceType.Hourly]({
    minutes,
    hours,
    repeatingHours = '1',
    usesBetween,
    betweenHourStart,
    betweenHourEnd,
    hourlyWeekDays
  }) {
    validateHourlyConfig(usesBetween, betweenHourStart, betweenHourEnd)

    const betweenHours = []

    if (usesBetween) {
      betweenHours.push(parseInt(betweenHourStart.split(':')[0], 10))
      betweenHours.push(parseInt(betweenHourEnd.split(':')[0], 10))
    }

    return parseCronObject({
      minutes,
      hours: usesBetween
        ? `${betweenHours[0]}-${betweenHours[1]}`
        : `${hours}/${repeatingHours}`,
      dayOfWeek: hourlyWeekDays ? hourlyWeekDays.join(',') : '?',
      dayOfMonth: hourlyWeekDays ? '?' : '*'
    })
  },
  [RecurrenceType.Daily]({ minutes, hours, repeatingDays }) {
    if (!repeatingDays) {
      const msg = 'Repeating days is required for daily recurrence'
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Utils_CronExpression: ${msg}`
      )
      throw new Error()
    }

    return parseCronObject({
      minutes,
      hours,
      dayOfWeek: `1/${repeatingDays}`,
      dayOfMonth: '?'
    })
  },
  [RecurrenceType.Weekly]({ minutes, hours, weeklyDays }) {
    if (!weeklyDays) {
      const msg = 'Weekly days is required for weekly recurrence'
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Utils_CronExpression: ${msg}`
      )
      throw new Error(msg)
    }

    return parseCronObject({
      minutes,
      hours,
      dayOfWeek: weeklyDays.join(','),
      dayOfMonth: '?'
    })
  },
  [RecurrenceType.Monthly]({ minutes, hours, monthlyDays, monthlyMonths }) {
    validateMonthlyConfig(monthlyDays, monthlyMonths)

    return parseCronObject({
      minutes,
      hours,
      dayOfWeek: '?',
      dayOfMonth: monthlyDays.join(','),
      month: monthlyMonths.join(','),
      year: '*'
    })
  }
}

const buildCronExpression = ({
  startDate,
  startTime,
  recurrenceOption,
  ...recurrenceConfig
}) => {
  if (!startDate) {
    const msg = 'Start Date is required for cron expression builder'
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_CronExpression: ${msg}`
    )
    throw new Error(msg)
  }

  return recurrenceMethods[recurrenceOption]({
    ...recurrenceConfig,
    startDate,
    minutes: startTime.getMinutes(),
    hours: startTime.getHours()
  })
}

export default buildCronExpression
