import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ui } from 'store/modules'
import RenderInformativeView from './RenderInformativeView'

const { setEntity, clearAll } = ui.actions

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEntity,
      clearAll
    },
    dispatch
  )

const RenderInformativeViewContainer = connect(
  null,
  mapDispatchToProps
)(RenderInformativeView)

export default RenderInformativeViewContainer
