import React from 'react'
import PropTypes from 'prop-types'
import {
  LocalOfferOutlined as InfoIcon,
  PlaceOutlined as AddressIcon,
  MailOutlined as MailOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneIcon,
  TurnedInNotOutlined as AfidIcon,
  StoreOutlined as SourceIcon
} from '@mui/icons-material'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { IconButton } from '@mui/material'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { OverviewLayout } from 'components/layout'
import { Label } from 'components/ui'
import { Lead, Person, MonitoringCodes } from 'models'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import parseOverviewFields from 'utils/parseOverviewFields'

function generateLeadSections(lead, person, piiMask) {
  const tags =
    lead.tags.map(tag => <Label key={tag} className="info" value={tag} />) || []

  const sections = [
    {
      name: 'Source',
      fields: [
        {
          id: 1,
          icon: SourceIcon,
          primary: lead.source,
          mask: false
        },
        {
          id: 2,
          icon: AfidIcon,
          primary: lead.afid,
          secondary: 'Afid',
          mask: false
        }
      ]
    },
    {
      name: 'Information',
      fields: [
        {
          id: 3,
          icon: InfoIcon,
          primary: lead.id,
          mask: false
        },
        {
          id: 4,
          icon: LocalPhoneIcon,
          primary: formatPhoneNumber(person.phone),
          mask: piiMask
        },
        {
          id: 5,
          icon: AddressIcon,
          primary: person.addressLine1,
          secondary: `${person.state},${person.county} ${person.zipcode}`,
          mask: piiMask
        },
        {
          id: 6,
          icon: MailOutlinedIcon,
          primary: person.email,
          mask: false
        }
      ]
    }
  ]

  if (tags.length)
    sections.unshift({
      name: 'Campaigns',
      fields: [
        {
          icon: IconButton,
          id: 1,
          mask: false,
          primary: tags
        }
      ]
    })

  return sections
}

const Overview = ({ lead, person, piiMask }) => {
  const sections = generateLeadSections(lead, person, piiMask)
  const details =
    lead &&
    parseOverviewFields(lead.fields).sort((a, b) =>
      a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1
    )

  return (
    <OverviewLayout
      details={[
        ...details.filter(({ value }) => value && value !== '-'),
        ...details.filter(({ value }) => !value || value === '-')
      ]}
      piiMask={piiMask}
      sections={sections}
    />
  )
}

Overview.propTypes = {
  lead: PropTypes.instanceOf(Lead).isRequired,
  person: PropTypes.instanceOf(Person).isRequired,
  piiMask: PropTypes.bool.isRequired
}

export default React.memo(
  withAITracking(
    AppInsightsReactPlugin,
    Overview,
    `${MonitoringCodes.COMP}LeadsOverview`
  )
)
