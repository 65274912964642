import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'

import { useHotjar } from '@tranzact/platform_react-components'

import { EntityType } from 'models'
import RedirectRenderPath from '../RedirectRenderPath'

const renderComponent = {
  [EntityType.Person]: lazy(() => import('pages/Persons')),
  [EntityType.Lead]: lazy(() => import('pages/Leads')),
  [EntityType.Job]: lazy(() => import('pages/Jobs')),
  [EntityType.Schedule]: lazy(() => import('pages/Schedules')),
  [EntityType.Source]: lazy(() => import('pages/Sources'))
}

const RenderEntityView = ({
  entity,
  userRole,
  routeEntity,
  clearAll,
  setEntity
}) => {
  const { manuallyTrackHotjar } = useHotjar()

  const Component = renderComponent[routeEntity]

  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const { viewDetail, viewErrors, viewFlow, viewList, viewSourceFlow } =
    userRole.actions[routeEntity.toLowerCase()] || {}

  if (entity !== routeEntity && viewList) {
    clearAll()
    setEntity(routeEntity)
    manuallyTrackHotjar(window.location.href, logger)
  }

  return (
    <Suspense fallback={<div />}>
      {viewList ? (
        <Component
          viewDetail={viewDetail}
          viewErrors={viewErrors}
          viewFlow={viewFlow || viewSourceFlow}
        />
      ) : (
        <RedirectRenderPath renderPath="/access-denied" />
      )}
    </Suspense>
  )
}

RenderEntityView.propTypes = {
  entity: PropTypes.string.isRequired,
  routeEntity: PropTypes.string.isRequired,
  userRole: PropTypes.instanceOf(Object).isRequired,
  clearAll: PropTypes.func.isRequired,
  setEntity: PropTypes.func.isRequired
}

export default RenderEntityView
