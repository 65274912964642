import { useState, useEffect } from 'react'

import {
  getKey,
  setKey,
  userSettingsKey,
  isLocalStorageAvailable
} from 'services/localStorage'
import { isBoolean } from 'utils/dataTypes'

const {
  lightTheme: localLightTheme,
  miniNavBar: localMiniNavBar,
  miniSideBar: localMiniSideBar,
  siteLayoutMiniSideBar: localSiteLayoutMiniSideBar
} = !isLocalStorageAvailable ? {} : getKey(userSettingsKey) || {}

const useUserSettings = initialState => {
  const [lightTheme, setLightTheme] = useState(
    isBoolean(localLightTheme) ? localLightTheme : initialState.lightTheme
  )
  const [miniNavBar, setMiniNavBar] = useState(
    isBoolean(localMiniNavBar) ? localMiniNavBar : initialState.miniNavBar
  )
  const [miniSideBar, setMiniSideBar] = useState(
    isBoolean(localMiniSideBar) ? localMiniSideBar : initialState.miniNavBar
  )

  const [siteLayoutMiniSideBar, setSiteLayoutMiniSideBar] = useState(
    isBoolean(localSiteLayoutMiniSideBar)
      ? localSiteLayoutMiniSideBar
      : initialState.siteLayoutMiniSideBar
  )

  const toggleThemeMode = () =>
    setLightTheme(previousThemeMode => !previousThemeMode)
  const toggleNavBarMode = () =>
    setMiniNavBar(previousMiniNavBar => !previousMiniNavBar)

  const toggleSideBarMode = () =>
    setMiniSideBar(previousMiniSideBar => !previousMiniSideBar)

  const toggleSiteLayoutSideBarMode = () =>
    setSiteLayoutMiniSideBar(previousMiniSideBar => !previousMiniSideBar)

  useEffect(() => {
    if (isLocalStorageAvailable)
      setKey(userSettingsKey, {
        lightTheme,
        miniNavBar,
        miniSideBar,
        siteLayoutMiniSideBar
      })
  }, [lightTheme, miniNavBar, miniSideBar, siteLayoutMiniSideBar])

  return [
    { lightTheme, miniNavBar, miniSideBar, siteLayoutMiniSideBar },
    {
      toggleThemeMode,
      toggleNavBarMode,
      toggleSideBarMode,
      toggleSiteLayoutSideBarMode
    }
  ]
}

export default useUserSettings
