export const isNumber = value => 
  // eslint-disable-next-line no-restricted-globals
   typeof value === 'number' && isFinite(value)


export const isBoolean = value => typeof value === 'boolean'

export const isValidEmail = mail => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true
  }

  return false
}
