import { useState, useEffect, useCallback } from 'react'

import { isBoolean } from 'utils/dataTypes'

export default ({
  initialState = {},
  onSubmit,
  additionalValidations = () => {},
  optionalFields = []
}) => {
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)

  const clearError = fieldName => {
    setErrors(previousErrors => {
      const { [fieldName]: _, ...restOfPreviousErrors } = previousErrors
      return restOfPreviousErrors ? { ...restOfPreviousErrors } : {}
    })
  }

  const onAdditionalValidation = useCallback(() => {
    const result = additionalValidations(values)
    if (result?.errors && Object.keys(result.errors).length) {
      setErrors(previousErrors => ({
        ...previousErrors,
        ...result.errors
      }))
    }

    if (result?.clearErrors?.length) {
      result.clearErrors.forEach(name => {
        clearError(name)
      })
    }
  }, [values, additionalValidations])

  // TODO: For now will only validate if field is required ALWAYS
  //      in the future it should validate from an array of validations
  const validate = ({ name, value }) => {
    if (optionalFields?.length && optionalFields.some(field => field === name))
      return

    if (!value || (Array.isArray(value) && !value.length)) {
      setErrors(previousErrors => ({
        ...previousErrors,
        [name]: 'Is required'
      }))

      return
    }

    clearError(name)
  }

  const resetForm = () => {
    setValues(initialState)
    setErrors({})
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault()
    if (!hasErrors) {
      const result = await onSubmit(values)
      if (!result) resetForm()
    }
  }

  const handleChange = event => {
    if (!event) return

    const { target } = event

    if (target.type !== 'checkbox') validate(target)

    setValues(previousValues => ({
      ...previousValues,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    }))
  }

  const resetValue = ({ fieldName, shouldValidate = false }) => {
    const value = values[fieldName]
    const getNonBooleanValue = () => (Array.isArray(value) ? [] : '')
    const fieldValue = isBoolean(value) ? false : getNonBooleanValue()
    setValues(previousValues => ({
      ...previousValues,
      [fieldName]: fieldValue
    }))

    if (shouldValidate) validate({ name: fieldName, value: fieldValue })
  }

  useEffect(() => {
    setHasErrors(Object.keys(errors).length > 0)
  }, [errors])

  useEffect(() => {
    onAdditionalValidation()
  }, [values, onAdditionalValidation])

  return {
    values,
    errors,
    hasErrors,
    handleChange,
    handleSubmit,
    resetValue,
    resetForm,
    clearError
  }
}
