export default class PersonV2 {
  constructor(rawPerson) {
    if (!rawPerson) return

    const {
      id,
      firstName,
      lastName,
      gender,
      email,
      address,
      addresses,
      phone,
      phones,
      fields,
      created,
      updated
    } = rawPerson

    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.fullName = `${firstName} ${lastName}`
    this.gender = gender
    this.email = email

    if (address) {
      const { line1, line2, county, city, state, zipcode } = address

      this.addressLine1 = line1
      this.addressLine2 = line2
      this.county = county || ''
      this.city = city
      this.state = state
      this.zipcode = zipcode
    }

    if (phone) {
      this.phone = phone.phoneNumber
    }

    this.addresses =
      addresses &&
      addresses.length &&
      addresses.map(
        ({
          id: addressId,
          line1,
          line2,
          county,
          city,
          state,
          zipcode,
          preferred
        }) => ({
          id: addressId,
          addressLine1: line1,
          addressLine2: line2,
          county: county || '',
          city,
          state,
          zipcode,
          preferred
        })
      )

    this.phones =
      phones?.length &&
      phones.map(({ id: phoneId, phoneNumber, preferred }) => ({
        id: phoneId,
        phoneNumber,
        preferred
      }))

    this.fields =
      fields?.length &&
      fields.map(({ id: fieldId, key, value }) => ({
        id: fieldId,
        key,
        value
      }))

    this.date = created && new Date(created)
    this.updateDate = updated && new Date(updated)
  }
}
