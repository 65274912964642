import { useState, useCallback } from 'react'

import {
  AppInsightsHelper,
  useHotjar
} from '@tranzact/platform_react-components'

import { AlertsType, MonitoringCodes, RecurrenceType } from 'models'
import { schedulesRepository } from 'api'
import getUtcTimeZonedDiff from 'utils/getUtcTimeZonedDiff'

async function updateSchedule(schedule, stopped, tagRecordingHotjar) {
  try {
    const tag = `${MonitoringCodes.EVENT}SchedulesUpdate`

    AppInsightsHelper?.trackEvent(tag, {
      schedule: { ...schedule, stopped }
    })

    tagRecordingHotjar([tag])

    if (stopped) await schedulesRepository.start(schedule.id)
    else await schedulesRepository.stop(schedule.id)
  } catch (err) {
    throw new Error(err)
  }
}

const useOverviewScheduleSwitch = (schedule, onShowAlert) => {
  const { recurrenceOption, stopped, startDate, startTime } = schedule

  const [checked, setChecked] = useState(!stopped)
  const [disabled, setDisabled] = useState(false)
  const { tagRecordingHotjar } = useHotjar()

  const isScheduleDateValid = useCallback(() => {
    if (recurrenceOption !== RecurrenceType.Once) return true
    const date = new Date(startDate)
    date.setHours(startTime.getHours())
    date.setMinutes(startTime.getMinutes())
    date.setSeconds(startTime.getSeconds())

    return getUtcTimeZonedDiff(date) > 5
  }, [recurrenceOption, startDate, startTime])

  const handleUpdate = ({ target: { checked: targetChecked } }) => {
    if (!(isScheduleDateValid() || checked)) setDisabled(true)
    else {
      setChecked(targetChecked)
      setDisabled(true)

      updateSchedule(schedule, targetChecked, tagRecordingHotjar).then(
        () => {
          const alertPayload = {
            alertType: AlertsType.Success,
            message: `Schedule has been updated.`
          }
          onShowAlert(alertPayload)
          setDisabled(false)
        },
        () => {
          setChecked(!targetChecked)

          const alertPayload = {
            alertType: AlertsType.Error,
            error: true,
            message: `Error: Schedule update failed`
          }
          onShowAlert(alertPayload)
          setDisabled(false)
        }
      )
    }
  }

  return {
    checked,
    disabled: disabled || !(isScheduleDateValid() || checked),
    handleUpdate
  }
}

export default useOverviewScheduleSwitch
