class ToolbarMenuOption {
  constructor(rawOption) {
    if (!rawOption) return

    this.id = rawOption.id
    this.name = rawOption.name
    this.icon = rawOption.icon
    this.event = rawOption.event
    this.isAllowed = rawOption.isAllowed
  }
}

export default ToolbarMenuOption
