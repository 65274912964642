import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListSubheader,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material'

import { buildFilter } from 'utils/buildJobFilter'
import { Root, StyledChip } from './styles'
import OverviewLayoutDetails from './OverviewLayoutDetails'

const OverviewLayout = ({ sections, details }) => {
  const hasDetails = details && !!details.length
  const getChipTemplate = text => <StyledChip label={buildFilter(text)} />
  const getChips = primary => primary.split(/\s+and\s+/i).map(getChipTemplate)

  return (
    <Root>
      <div>
        {sections.map(({ name, fields, gridFields }, index) => (
          <React.Fragment key={`section-${name}`}>
            {gridFields?.length ? (
              <OverviewLayoutDetails
                sections={[{ name, fields: gridFields }]}
              />
            ) : (
              <List
                dense
                aria-labelledby={`${name}-overview-section`}
                subheader={
                  <ListSubheader id={`${name}-overview-section`}>
                    {name}
                  </ListSubheader>
                }
              >
                {fields.map(
                  ({
                    custom,
                    id,
                    primary,
                    secondary = null,
                    grouped = false,
                    icon: Icon,
                    mask,
                    chipped,
                    action
                  }) => (
                    <Fragment key={`section-field-${id}`}>
                      {!custom && (
                        <ListItem width="30%">
                          {Icon && (
                            <ListItemIcon>
                              <Icon color="primary" />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            inset={grouped}
                            primary={
                              mask
                                ? '*******'
                                : (chipped && getChips(primary)) || primary
                            }
                            secondary={mask ? '******' : secondary}
                          />
                          {action && (
                            <ListItemSecondaryAction>
                              {action}
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      )}
                      {custom && <ListItem>{custom}</ListItem>}
                    </Fragment>
                  )
                )}
              </List>
            )}

            {(hasDetails || index < sections.length - 1) && (
              <Divider sx={{ marginLeft: 0 }} variant="inset" />
            )}
          </React.Fragment>
        ))}
      </div>

      {hasDetails && (
        <OverviewLayoutDetails
          sections={[{ name: 'More Details', fields: details }]}
        />
      )}
    </Root>
  )
}

OverviewLayout.defaultProps = {
  details: []
}

OverviewLayout.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Object)
      ]),
      width: PropTypes.number
    })
  )
}

export default OverviewLayout
