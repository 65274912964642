import { Activity } from 'models'
import Repository from './repository'

class Activities extends Repository {
  resource = 'activity'

  findByLead(personid, leadid) {
    return this.get({
      action: `${personid}/leads/${leadid}`,
      isArray: true,
      mapTo: Activity
    })
  }

  findByPerson(personid, { eventTypes, fromDate, toDate }) {
    const paramList = []

    if (eventTypes) {
      paramList.push(`eventtypes=${eventTypes}`)
    }

    if (fromDate) {
      paramList.push(`fromdate=${fromDate}`)
    }

    if (toDate) {
      paramList.push(`todate=${toDate}`)
    }

    const params = paramList.length ? `?${paramList.join('&')}` : ''

    return this.get({
      action: `${personid}${params}`,
      isArray: true,
      mapTo: Activity
    })
  }
}

export default Activities
