import { FlowPath, Source } from 'models'
import Repository from './repository'

class Flows extends Repository {
  resource = 'flows'

  find() {
    return this.get({
      isArray: true,
      mapTo: Source
    })
  }

  getFlowById({ columnIds, sourceId }) {
    return this.get({
      action: `source/${sourceId}`,
      otherValues: { columnIds, Key: sourceId },
      mapTo: FlowPath,
      params: { sourceId }
    })
  }

  getLeadFlow(params) {
    const { columnIds, leadId, personId, sourceId } = params
    return this.get({
      action: 'lead',
      otherValues: { columnIds, Key: sourceId },
      mapTo: FlowPath,
      params: { leadId, personId }
    })
  }
}

export default Flows
