import * as go from 'gojs'

import {
  lightBlueColor,
  ActivityNodeWidth,
  ActivityNodeHeight,
  ActivityNodeStrokeWidth
} from '../constants'
import {
  getLMUrlCursor,
  getTextBlockColor,
  getPanelShapeColor,
  handleErrorColor,
  openLMUrl
} from '../helper'
import { getTooltipTemplate } from './tooltip'
import { getTaskScriptIconTemplate } from './taskScriptIcon'

export function getActivityNodeTemplate($) {
  const activityNodeTemplate = $(
    go.Node,
    'Spot',
    {
      locationObjectName: 'SHAPE',
      locationSpot: go.Spot.Center,
      resizable: false,
      resizeObjectName: 'PANEL',
      selectionAdorned: false // use a Binding on the Shape.stroke to show selection
    },
    {
      click: openLMUrl
    },
    new go.Binding('toolTip', 'comments', () => getTooltipTemplate($)),
    new go.Binding('itemArray', 'boundaryEventArray'),
    new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),
    // move a selected part into the Foreground layer, so it isn"t obscured by any non-selected parts
    new go.Binding('layerName', 'isSelected', s =>
      s ? 'Foreground' : ''
    ).ofObject(),
    $(
      go.Panel,
      'Auto',
      {
        name: 'PANEL',
        minSize: new go.Size(ActivityNodeWidth, ActivityNodeHeight),
        desiredSize: new go.Size(ActivityNodeWidth, ActivityNodeHeight)
      },
      new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
        go.Size.stringify
      ),
      $(
        go.Panel,
        'Spot',
        $(
          go.Shape,
          'RoundedRectangle', // the outside rounded rectangle
          {
            name: 'SHAPE',
            stroke: lightBlueColor,
            strokeWidth: ActivityNodeStrokeWidth,
            parameter1: 10, // corner size
            portId: '',
            fromLinkable: true,
            toLinkable: true,
            fromSpot: go.Spot.RightSide,
            toSpot: go.Spot.LeftSide
          },
          getLMUrlCursor(),
          getPanelShapeColor(),
          new go.Binding('stroke', 'hasError', handleErrorColor),
          new go.Binding('strokeWidth', 'isNotPath', () => 2)
        ),
        getTaskScriptIconTemplate($) // end main body rectangles spot panel
      ),
      $(
        go.TextBlock, // the center text
        {
          alignment: go.Spot.Center,
          textAlign: 'center',
          width: 100
        },
        new go.Binding('text').makeTwoWay(),
        getLMUrlCursor(),
        getTextBlockColor()
      ),
      $(
        go.TextBlock, // the center text
        {
          alignment: go.Spot.Bottom,
          font: 'bold 13px sans-serif',
          textAlign: 'center',
          margin: 25,
          maxLines: 1,
          overflow: go.TextBlock.OverflowEllipsis
        },
        new go.Binding('text', 'detail', s => `(${s})`).makeTwoWay(),
        getLMUrlCursor(),
        getTextBlockColor()
      )
    ) // end Auto Panel
  ) // end go.Node, which is a Spot Panel with bound itemArray
  return activityNodeTemplate
}

export default { getActivityNodeTemplate }
