import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import {
  AppInsightsHelper,
  EntityDetails,
  useHotjar
} from '@tranzact/platform_react-components'

import HotjarMetadataHelper from 'utils/hotjarMetadataHelper'
import {
  RecurrenceType,
  MonitoringCodes,
  ScheduleFormField,
  Filter
} from 'models'
import getCatchErrorMessage from 'utils/getCatchErrorMessage'
import Add from './Add'
import Details from './Details'
import Dates from './Dates'
import Recurrence from './Recurrence'
import { StyledAlert, StyledButton, StyledCircularProgress } from './styles'
import FormSchema from './Form.schema'

export const initialState = {
  name: '',
  selectedFilterName: '',
  selectedFilter: [],
  notes: '',
  jobType: 'Lead Distribution',
  distributeResults: 'All',
  numberOfTopResults: null,
  distributionSystem: 'TztLeads',
  distributionCid: '',
  distributionPid: '',
  enabled: 'Active',
  stopped: false,
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  addEndDate: false,
  created: null,
  recurrenceOption: RecurrenceType.Once,
  hourlyWeekDays: [],
  repeatingHours: null,
  usesBetween: false,
  betweenHourStart: '',
  betweenHourEnd: '',
  repeatingDays: null,
  weeklyDays: [],
  monthlyMonths: [],
  monthlyDays: []
}

const Form = ({
  open,
  operators,
  searchFilter,
  selectedFilter,
  title,
  onClose,
  onSchedule
}) => {
  const formMethods = useForm({
    defaultValues: initialState,
    resolver: FormSchema
  })
  const { handleSubmit: doSubmit, reset } = formMethods

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { identifyHotjar, tagRecordingHotjar } = useHotjar()

  // TODO: We should replace this when we define the standar error management
  // eslint-disable-next-line no-console
  const logger = process.env.NODE_ENV !== 'production' ? console.info : null

  const handleClose = (triggerOnClose = true) => {
    if (HotjarMetadataHelper.turnOnFlags(['lmkt_schedulesSurvey'])) {
      const metadata = HotjarMetadataHelper.getMetadata()
      identifyHotjar(metadata.lmkt_userId, metadata, logger)
    }
    if (triggerOnClose) onClose()
    reset(initialState)
  }

  const handleOnSchedule = async values => {
    setErrorMessage('')
    setIsLoading(true)
    try {
      await onSchedule(values)
      handleClose(false)
    } catch (error) {
      setErrorMessage(getCatchErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    doSubmit(data => {
      const tag = `${MonitoringCodes.EVENT}SchedulesAdd`

      AppInsightsHelper?.trackEvent(tag, { data })

      tagRecordingHotjar([tag])
      handleOnSchedule(data)
    })()
  }

  useEffect(() => {
    if (open) {
      setErrorMessage('')
      setIsLoading(false)

      const newDate = new Date()

      reset({
        ...initialState,
        [ScheduleFormField.SelectedFilter]: selectedFilter.map(filter => {
          const filterCopy = { ...(filter.rawFilter || filter) }
          const { field, value: filterValue, values } = filterCopy

          delete field.suggestionValidations
          return new Filter({
            ...filterCopy,
            field: {
              ...field,
              valueOptions: field.valueOptions?.filter(
                ({ label }) => label === filterValue
              )
            },
            values
          })
        }),
        [ScheduleFormField.StartDate]: newDate,
        [ScheduleFormField.StartTime]: newDate
      })

      if (HotjarMetadataHelper.turnOnFlags(['lmkt_schedulesSurveyAdd'])) {
        const metadata = HotjarMetadataHelper.getMetadata()
        identifyHotjar(metadata.lmkt_userId, metadata, logger)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedFilter])

  return (
    <EntityDetails maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            {title}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {errorMessage && (
          <StyledAlert severity="error">{errorMessage}</StyledAlert>
        )}
        <FormProvider {...formMethods}>
          <Add
            dates={<Dates isReadonly={isLoading} />}
            details={
              <Details
                isReadonly={isLoading}
                operators={operators}
                searchFilter={searchFilter}
              />
            }
            handleSubmit={handleSubmit}
            recurrence={<Recurrence isReadonly={isLoading} />}
          />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <StyledButton disabled={isLoading} onClick={handleClose}>
          Cancel
        </StyledButton>
        <StyledButton
          color="primary"
          disabled={isLoading}
          form="schedule-form"
          type="submit"
          variant="contained"
        >
          Submit
          {isLoading && <StyledCircularProgress size={24} />}
        </StyledButton>
      </DialogActions>
    </EntityDetails>
  )
}

Form.defaultProps = {
  searchFilter: '',
  selectedFilter: [],
  title: ''
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  operators: PropTypes.arrayOf(Object).isRequired,
  searchFilter: PropTypes.string,
  selectedFilter: PropTypes.arrayOf(Object),
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSchedule: PropTypes.func.isRequired
}

export default Form
