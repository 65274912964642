import * as go from 'gojs'

import {
  defaultColor,
  lightBlueColor,
  EventNodeSize,
  EventNodeStrokeWidthIsEnd,
  EventNodeInnerSize,
  EventNodeSymbolSize,
  BPMN_EVENT_DIMENSION_TYPE,
  darkColor
} from '../constants'
import {
  getPanelShapeColor,
  getCircleStrokeColor,
  nodeEventTypeConverter,
  handleErrorColor
} from '../helper'

function nodeEventDimensionSymbolFillConverter(s) {
  if (s < BPMN_EVENT_DIMENSION_TYPE.end) return defaultColor
  return lightBlueColor
}

function nodeEventDimensionSymbolStrokeConverter(s) {
  if (s.eventDimension < BPMN_EVENT_DIMENSION_TYPE.end) return lightBlueColor
  return s.hasLightTheme ? defaultColor : darkColor
}

export function getEventNodeTemplate($) {
  // ------------------------------------------  Event Node Template  ----------------------------------------------
  const eventNodeTemplate = $(
    go.Node,
    'Vertical',
    {
      locationObjectName: 'SHAPE',
      locationSpot: go.Spot.Center
    },
    // can be resided according to the user's desires
    { resizable: false, resizeObjectName: 'SHAPE' },
    $(
      go.Panel,
      'Spot',
      $(
        go.Shape,
        'Circle', // Outer circle
        {
          strokeWidth: 1,
          stroke: lightBlueColor,
          name: 'SHAPE',
          desiredSize: new go.Size(EventNodeSize, EventNodeSize),
          portId: '',
          fromLinkable: true,
          toLinkable: true,
          fromSpot: go.Spot.RightSide,
          toSpot: go.Spot.LeftSide
        },
        getPanelShapeColor(),
        new go.Binding('strokeWidth', 'eventDimension', s => s === BPMN_EVENT_DIMENSION_TYPE.end
            ? EventNodeStrokeWidthIsEnd
            : 3),
        new go.Binding('stroke', 'hasError', handleErrorColor),
        new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
          go.Size.stringify
        )
      ), // end main shape
      $(
        go.Shape,
        'Circle', // separation circle
        {
          alignment: go.Spot.Center,
          strokeWidth: 2,
          desiredSize: new go.Size(
            EventNodeInnerSize + 4,
            EventNodeInnerSize + 4
          )
        },
        new go.Binding(
          'visible',
          'eventDimension',
          s => s === BPMN_EVENT_DIMENSION_TYPE.end
        ),
        getPanelShapeColor(),
        getCircleStrokeColor()
      ),
      $(
        go.Shape,
        'Circle', // inner circle
        {
          alignment: go.Spot.Center,
          strokeWidth: 1,
          stroke: lightBlueColor,
          desiredSize: new go.Size(
            EventNodeInnerSize + 2,
            EventNodeInnerSize + 2
          ),
          fill: lightBlueColor
        },
        new go.Binding('stroke', 'hasError', handleErrorColor),
        new go.Binding('fill', 'hasError', handleErrorColor),
        new go.Binding(
          'visible',
          'eventDimension',
          s => s === BPMN_EVENT_DIMENSION_TYPE.end
        )
      ),
      $(
        go.Shape,
        'Circle', // hollow circle
        {
          alignment: go.Spot.Center,
          stroke: defaultColor,
          desiredSize: new go.Size(
            EventNodeInnerSize - 3,
            EventNodeInnerSize - 3
          )
        },
        new go.Binding(
          'visible',
          'eventDimension',
          s => s === BPMN_EVENT_DIMENSION_TYPE.end
        ),
        getPanelShapeColor(),
        getCircleStrokeColor()
      ),
      $(
        go.Shape,
        'NotAllowed',
        {
          alignment: go.Spot.Center,
          desiredSize: new go.Size(EventNodeSymbolSize, EventNodeSymbolSize),
          fill: lightBlueColor,
          stroke: lightBlueColor
        },
        new go.Binding('figure', 'eventType', nodeEventTypeConverter),
        new go.Binding(
          'fill',
          'eventDimension',
          nodeEventDimensionSymbolFillConverter
        ),
        new go.Binding('stroke', '', nodeEventDimensionSymbolStrokeConverter)
      )
    ), // end Auto Panel
    $(
      go.TextBlock,
      {
        alignment: go.Spot.Center,
        textAlign: 'center',
        margin: 5
      },
      new go.Binding('text').makeTwoWay()
    )
  ) // end go.Node Vertical
  return eventNodeTemplate
}

export default { getEventNodeTemplate }
