import styled from '@emotion/styled'
import { Tab } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export const StyledLoader = styled(Skeleton)`
  display: block;
  height: calc(100vh - 175px);
  width: 100%;
`

export const StyledTab = styled(Tab)`
  text-transform: capitalize;
`

export default { StyledLoader, StyledTab }
