import Repository from './repository'

class Version extends Repository {
  resource = 'version'

  async find() {
    try {
      const version = await this.get({})

      return version
    } catch (_) {
      return null
    }
  }
}

export default Version
