import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { filters, results, sources } from 'store/modules'
import LifeCycle from './LifeCycle'

const { actions, selectors } = sources
const { updateAdvanced } = filters.actions
const { getResults } = results.selectors
const { getSelectedFlow, getHasFlowError } = selectors
const { getFlowById, getLeadFlow, setFlow, setHasFlowError } = actions

const mapStateToProps = state => ({
  hasFlowError: getHasFlowError(state),
  sourceFlow: getSelectedFlow(state),
  items: getResults(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getFlowById, getLeadFlow, setFlow, setHasFlowError, updateAdvanced },
    dispatch
  )

const ResultsContainer = connect(mapStateToProps, mapDispatchToProps)(LifeCycle)

export default ResultsContainer
