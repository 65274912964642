import { put, takeLatest } from 'redux-saga/effects'

import { filters, results, ui, favorites } from 'store/modules'

export function* clearAll() {
  yield put(filters.actions.reset())
  yield put(favorites.actions.reset())
  yield put(results.actions.reset())
}

const uiSaga = [takeLatest(ui.actionTypes.CLEAR_ALL, clearAll)]

export default uiSaga
