import { ButtonBase } from '@mui/material'
import styled from '@emotion/styled'

export const StyledMenuIcon = styled.div`
  padding: 10px;
  display: flex;
`

export const StyledButtonBase = styled(ButtonBase)`
  padding: 0 24px 16px;
  justify-content: inherit;
  width: 100%;

  & > div {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;

    & > div:nth-of-type(1) {
      text-align: left;
    }
    & > div:nth-of-type(2) {
      justify-self: end;
    }
  }
`

export default StyledMenuIcon
