import styled from '@emotion/styled'
import { Button, CircularProgress, Grid, Switch } from '@mui/material'
import Alert from '@mui/material/Alert'

export const StyledAlert = styled(Alert)`
  margin-top: 1em;
  margin-bottom: 1em;
`

export const StyledButton = styled(Button)`
  padding: 6px 16px;
  border-radius: 4px;
`

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

export const StyledSwitch = styled(Switch)`
  &.MuiSwitch-root {
    width: 80px;
    height: 47px;
    padding: 10px;
  }
  .MuiSwitch-track {
    border-radius: 14px;
  }

  .MuiSwitch-thumb {
    width: 21px;
    height: 21px;
  }
  .MuiSwitch-switchBase.Mui-checked {
    left: 100%;
    transform: translateX(-100%);
  }

  .MuiSwitch-switchBase {
    position: absolute;
    padding: 13px;
  }
`

export const StyledSwitchGrid = styled(Grid)`
  display: grid;
  justify-content: end;
`
