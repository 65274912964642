const timeStringToDateTime = time => {
  if (time) {
    const arr = time.split(':')
    const hours = parseInt(arr[0], 10)
    const minutes = parseInt(arr[1], 10)

    return new Date(1, 1, 1900, hours, minutes, 0)
  }

  return null
}

export default timeStringToDateTime
