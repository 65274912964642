import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@mui/material'
import MaskedInput from 'react-text-mask'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import {
  AppInsightsReactPlugin,
  PrimaryButton
} from '@tranzact/platform_react-components'

import { useForm } from 'hooks'
import {
  LeadOperationType,
  LeadOperationFormField,
  MonitoringCodes
} from 'models'
import { StyledContainerGrid, StyledItemGrid } from './styles'
import Distribution from './Distribution'

const initialState = {
  quantity: '',
  target: '',
  pid: '',
  cid: ''
}

const quantityFormat = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

const QuantityTextMaskedInput = props => (
  <MaskedInput {...props} guide={false} mask={quantityFormat} />
)

const OperationForm = ({
  closeDialog,
  open,
  title,
  onTriggerOperation,
  operationType
}) => {
  const { values, handleChange, handleSubmit, resetForm } = useForm({
    initialState,
    onSubmit: submitValues => {
      onTriggerOperation(submitValues)
      closeDialog()
      resetForm()
    }
  })

  const cancel = () => {
    closeDialog()
    resetForm()
  }

  const handleQuantityChange = event => {
    const {
      target: { value }
    } = event

    handleChange({ target: { name: LeadOperationFormField.Quantity, value } })
  }

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{title}</DialogTitle>
      {open && (
        <form noValidate onSubmit={handleSubmit}>
          <StyledContainerGrid container>
            <StyledItemGrid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    inputComponent: QuantityTextMaskedInput
                  }}
                  label="Quantity"
                  value={values[LeadOperationFormField.Quantity]}
                  variant="outlined"
                  onChange={handleQuantityChange}
                />
                <FormHelperText>
                  Leave this empty for all filtered leads
                </FormHelperText>
              </FormControl>
            </StyledItemGrid>
            <StyledItemGrid item xs={12}>
              {operationType === LeadOperationType.Distribution && (
                <Distribution handleChange={handleChange} values={values} />
              )}
            </StyledItemGrid>
            <StyledItemGrid item xs={12}>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item>
                  <Button onClick={cancel}>Cancel</Button>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Start"
                    type="submit"
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </StyledItemGrid>
          </StyledContainerGrid>
        </form>
      )}
    </Dialog>
  )
}

OperationForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  operationType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onTriggerOperation: PropTypes.func.isRequired
}

export default withAITracking(
  AppInsightsReactPlugin,
  OperationForm,
  `${MonitoringCodes.COMP}LeadsOperationForm`
)
