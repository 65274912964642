import { all } from 'redux-saga/effects'
import {
  apiSaga,
  filtersSaga,
  favoritesSaga,
  notificationsSaga,
  uiSaga,
  jobsSaga,
  leadsSaga,
  sourcesSaga
} from './sagas'

export default function* rootSaga() {
  yield all([
    ...apiSaga,
    ...filtersSaga,
    ...favoritesSaga,
    ...notificationsSaga,
    ...uiSaga,
    ...jobsSaga,
    ...leadsSaga,
    ...sourcesSaga
  ])
}
