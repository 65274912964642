import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { favorites, filters, ui } from 'store/modules'
import FavoritesDialog from './FavoritesDialog'

const { setSelectedLabel } = favorites.actions
const { showAlert } = ui.actions

const { getAll } = filters.selectors
const { getEntity } = ui.selectors

const mapStateToProps = state => ({
  entity: getEntity(state),
  filters: getAll(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setSelectedLabel, showAlert }, dispatch)

const FavoritesDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoritesDialog)

export default FavoritesDialogContainer
