import { differenceInMinutes } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { SystemTimeZone } from 'models'

const { timeZone } = SystemTimeZone

const getUtcTimeZonedDiff = dateTime => {
  if (Number.isNaN(dateTime.getTime())) return NaN
  const zonedTime = utcToZonedTime(new Date(), timeZone)
  const diff = differenceInMinutes(dateTime, zonedTime)
  return diff
}

export default getUtcTimeZonedDiff
