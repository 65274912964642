import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'

import UserSettingsContext from 'context/userSettings'
import theme, { GlobalStyle } from 'theme'
import { ThemeModes } from 'theme/variables'

const AppThemeProvider = ({ children }) => {
  const { lightTheme } = useContext(UserSettingsContext)

  const currentTheme = useMemo(
    () => theme(lightTheme ? ThemeModes.Light : ThemeModes.Dark),
    [lightTheme]
  )

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default React.memo(AppThemeProvider)
