class Column {
  constructor(column) {
    if (!column) return

    this.name = column.name
    this.label = column.label
    this.isVisible = column.isVisible
    this.isSortable = column.isSortable
    this.isAsc = column.isAsc
    this.queryColumnName = column.queryColumnName
    this.defaultSort = column.defaultSort
    this.align = column.align
    this.minWidth = column.minWidth
    this.cell = column.cell
    this.customCell = column.customCell
  }
}

export default Column
