import { connect } from 'react-redux'

import { ui } from 'store/modules'
import Leads from './Persons'

const { getEntity, getUserRole } = ui.selectors

const mapStateToProps = state => ({
  entity: getEntity(state),
  userRole: getUserRole(state)
})

const PersonsContainer = connect(mapStateToProps)(Leads)

export default PersonsContainer
