import { FilterFieldType } from 'models'
import getFilterValueOption from './getFilterValueOption'

const { AzureStorageText, AzureStorageTextPath, Field, LongText, Text } =
  FilterFieldType
const FilterFieldTextType = [
  AzureStorageText,
  AzureStorageTextPath,
  Field,
  LongText,
  Text
]

const replaceAll = (string, oldValue, newValue) => string.split(oldValue).join(newValue)

const mapValue = (type, value = '', path = '', query = '') => {
  if (type === FilterFieldType.Guid)
    return query.replace('[path]', `${path}`).replace('[value]', value)
  return FilterFieldTextType.includes(type) ? `'${value}'` : value
}

const mapValues = (path, type, values) => {
  if (type === FilterFieldType.Guid) {
    return values ? values.map(value => `${path} eq ${value}`).join(' OR ') : ''
  }

  if (!values) {
    return ''
  }

  if (type === FilterFieldType.Guid) {
    return values.map(value => `${path} eq ${value}`).join(' OR ')
  }

  return values.map(value =>
    FilterFieldTextType.includes(type)
      ? `'${value.value || value}'`
      : value.value || value
  )
}

const mapFilter = ({
  prefix = '',
  filter: {
    field: { path, type },
    operator: { query },
    key,
    extendedPath,
    queryOperator,
    value,
    value2,
    values
  }
}) => {
  if (type === FilterFieldType.Guid) {
    return values?.length
      ? `(${mapValues(path, type, values)})`
      : mapValue(type, value, path, query)
  }

  let mappedFilter = replaceAll(query, '[path]', `${prefix}${path}`)
  mappedFilter = replaceAll(mappedFilter, '[value]', mapValue(type, value))
  mappedFilter = replaceAll(mappedFilter, '[value2]', mapValue(type, value2))
  mappedFilter = replaceAll(mappedFilter, '[queryOperator]', queryOperator)
  mappedFilter = replaceAll(
    mappedFilter,
    '[values]',
    mapValues(path, type, values)
  )
  mappedFilter = replaceAll(mappedFilter, '[customField]', `'${key}'`)

  return mappedFilter.concat(extendedPath ? ` ${extendedPath}` : '')
}

const resolve = (object, path) => path.split('.').reduce((previous, current) => previous ? previous[current] : null, object)

const groupBy = (list, property) =>
  list
    .filter(item => resolve(item, property))
    .reduce((groups, item) => {
      const key = resolve(item, property)

      const existingGroup = groups.find(group => group.key === key)

      if (!existingGroup) {
        groups.push({ key, filters: [item] })
      } else {
        existingGroup.filters.push(item)
      }

      return groups
    }, [])

const buildEntityQuery = (filtersByEntity, connector) => filtersByEntity.map(
    entity =>
      `${entity.key}/any(f: ${entity.filters
        .map(filter => mapFilter({ prefix: 'f/', filter }))
        .join(` ${connector} `)})`
  )

const buildCustomFieldQuery = (filtersByKey, connector) => filtersByKey.map(
    key =>
      `Fields/any(f: f/Key eq '${key.key}' ${connector} ${key.filters
        .map(filter => mapFilter({ filter }))
        .join(` ${connector} `)})`
  )

const buildSimpleQuery = filters => filters
    .filter(filter => !filter.field.entity && !filter.customField)
    .map(filter => mapFilter({ filter }))

const buildOdataQuery = (filters, connector = 'AND') => {
  const updatedFilters = filters?.map(getFilterValueOption)

  return updatedFilters?.length
    ? buildEntityQuery(groupBy(updatedFilters, 'field.entity'), connector)
        .concat(
          buildCustomFieldQuery(
            groupBy(updatedFilters, 'customField'),
            connector
          )
        )
        .concat(buildSimpleQuery(updatedFilters))
        .join(` ${connector} `)
    : ''
}

export default buildOdataQuery
