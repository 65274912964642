import React from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { Typography } from '@mui/material'
import { Build } from '@mui/icons-material'

import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'

import { MonitoringCodes } from '../models'
import Error from './Error'

const Maintenance = () => {
  const slackChannelSuffix =
    process.env.REACT_APP_LMKT_ENV === 'production' ? '' : '-qa'
  const description = (
    <>
      Try again in some minutes or feel free to contact us via Slack at{' '}
      <Typography component="b" fontWeight="600">
        #lmkt-support{slackChannelSuffix}
      </Typography>{' '}
      for more information
    </>
  )

  return (
    <Error
      Icon={Build}
      description={description}
      hasHome={false}
      title="We are currently under maintenance"
    />
  )
}

export default withAITracking(
  AppInsightsReactPlugin,
  Maintenance,
  `${MonitoringCodes.PAGE}Maintenance`
)
