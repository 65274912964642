import { AppInsightsHelper } from '@tranzact/platform_react-components'

import { Person, MonitoringCodes } from 'models'
import buildOdataQuery from 'utils/buildOdataQuery'
import Repository from './repository'

const personCommand = {
  Select:
    'Id,FirstName,LastName,MiddleName,Title,Gender,Email,Address,Phone,Created,Updated',
  Expand: `Addresses,Phones,Fields`
}

const buildPersonCommand = pagination =>
  `Person?$select=${personCommand.Select}&$expand=${personCommand.Expand}&$count=true&$top=${pagination.top}&$skip=${pagination.skip}&$orderby=${pagination.sortBy} ${pagination.desc}`

class Persons extends Repository {
  resource = 'person'

  async find(filters, pagination) {
    try {
      const personFilters = buildOdataQuery(filters)

      return await this.get({
        action: 'command',
        params: {
          commandText: `${buildPersonCommand(
            pagination
          )}&$filter=${personFilters}`
        },
        mapTo: Person,
        isArray: true,
        useGateway: true,
        isOdata: true
      })
    } catch (err) {
      AppInsightsHelper?.trackError(
        `${MonitoringCodes.ERR}Repository_Persons: ${err}`
      )
      return []
    }
  }
}

export default Persons
