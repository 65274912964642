import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { EntityResults } from '@tranzact/platform_react-components'

import { ResultsWrapper } from 'components/shared'
import fixResults from 'utils/fixResults'

const Results = ({ paging, results, sort, viewDetail, ...props }) => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const fixedResults = fixResults({
    sort,
    paging,
    results
  })

  const handleOnSelect = id => {
    if (viewDetail) navigate(`${pathname}${id}${search}`)
  }

  return (
    <ResultsWrapper>
      <EntityResults
        {...props}
        stickyHeader
        results={fixedResults}
        sort={sort}
        onSelectRow={handleOnSelect}
      />
    </ResultsWrapper>
  )
}

Results.propTypes = {
  results: PropTypes.arrayOf(Object).isRequired,
  paging: PropTypes.instanceOf(Object).isRequired,
  sort: PropTypes.instanceOf(Object).isRequired,
  viewDetail: PropTypes.bool.isRequired
}

export default Results
