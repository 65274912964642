import React from 'react'
import { FormControl, FormHelperText, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

const FormInput = ({
  disabled,
  fieldName,
  label,
  placeholder,
  readOnly,
  type,
  onClick
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const error = errors[fieldName]?.message
  const hasError = error !== undefined

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormControl fullWidth required error={hasError}>
          <TextField
            {...field}
            autoComplete="off"
            disabled={disabled}
            error={hasError}
            inputProps={{
              name: fieldName,
              type,
              readOnly,
              value: field?.value || ''
            }}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            onClick={onClick}
          />
          {hasError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

FormInput.defaultProps = {
  disabled: false,
  label: '',
  placeholder: '',
  readOnly: false,
  type: 'text',
  onClick: () => {}
}

FormInput.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func
}

export default React.memo(FormInput)
