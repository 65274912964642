import * as go from 'gojs'

import {
  darkColor,
  defaultColor,
  failureColor,
  lightBlueColor
} from './constants'

export function getLMUrlCursor() {
  return new go.Binding('cursor', 'url', url => (url ? 'pointer' : ''))
}
export function getTextBlockColor() {
  return new go.Binding('stroke', 'hasLightTheme', s =>
    s ? darkColor : defaultColor
  )
}
export function getPanelShapeColor() {
  return new go.Binding('fill', 'hasLightTheme', s =>
    s ? defaultColor : darkColor
  )
}
export function getCircleStrokeColor() {
  return new go.Binding('stroke', 'hasLightTheme', s =>
    s ? defaultColor : darkColor
  )
}

export const handleErrorColor = hasError =>
  hasError ? failureColor : lightBlueColor

// conversion functions used by data Bindings
export function nodeActivityTaskTypeConverter(s) {
  const tasks = [
    'Empty',
    'BpmnTaskService' // Custom gear symbol
  ]
  if (s < tasks.length) return tasks[s]
  return 'NotAllowed' // error
}

export function nodeEventTypeConverter(s) {
  // order here from BPMN 2.0 poster
  const tasks = [
    'NotAllowed',
    'Empty',
    'BpmnTaskMessage',
    'BpmnEventTimer',
    'BpmnTaskManual',
    'BpmnEventConditional'
  ]
  if (s < tasks.length) return tasks[s]
  return 'NotAllowed' // error
}

export function openLMUrl(_, obj) {
  const { url } = obj.part.data
  if (url) window.open(url, '_blank', 'noopener')
}

export function getDefaultLayout($) {
  return $(go.TreeLayout, {
    alignment: go.TreeLayout.AlignmentCenterSubtrees,
    arrangement: go.TreeLayout.ArrangementFixedRoots,
    setsPortSpot: false,
    setsChildPortSpot: false,
    layerSpacing: 80
  })
}
