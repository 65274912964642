import { Column, Preset } from 'models'

class DefaultConfiguration {
  constructor(configuration) {
    if (!configuration) return
    const {
      columns = [],
      customPresets = [],
      defaultFilters = [],
      fields = [],
      filterPreset = {},
      presets = []
    } = configuration

    this.columns = columns.map(item => new Column(item))
    this.customPresets = customPresets
    this.defaultFilters = defaultFilters
    this.fields = fields
    this.filterPreset = filterPreset ?? {}
    this.presets = presets.map(item => new Preset(item))
  }
}

export default DefaultConfiguration
