import React from 'react'
import PropTypes from 'prop-types'

import { EntityFavoritesDialog } from '@tranzact/platform_react-components'
import { useFavoriteDialog } from 'hooks'

const FavoritesDialog = ({
  entity,
  filters,
  open,
  setSelectedLabel,
  showAlert,
  onClose
}) => {
  const { errorMessage, loading, close, save } = useFavoriteDialog({
    entity,
    filters,
    setSelectedLabel,
    showAlert,
    onClose
  })

  return (
    <EntityFavoritesDialog
      cancelLabel="Cancel"
      errorMessage={errorMessage}
      isSaveDisabledByErrors={false}
      loading={loading}
      open={open}
      saveLabel="Submit"
      title="Save Favorite"
      validations={{ description: { required: false } }}
      onClose={close}
      onSave={save}
    />
  )
}

FavoritesDialog.propTypes = {
  filters: PropTypes.arrayOf(Object).isRequired,
  open: PropTypes.bool.isRequired,
  entity: PropTypes.string.isRequired,
  setSelectedLabel: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default FavoritesDialog
