const setYesNo = isTrue => (isTrue ? 'Yes' : 'No')

class Source {
  constructor(rawFlow) {
    if (!rawFlow) return
    const {
      distributionStructure,
      enableOverwrite,
      enablePhoneValidation,
      enableStateValidation,
      enableZipcodeValidation,
      key,
      tasks
    } = rawFlow

    this.hasAutomaticDistribution = setYesNo(distributionStructure?.automatic)
    this.hasPhoneValidation = setYesNo(enablePhoneValidation)
    this.hasStateValidation = setYesNo(enableStateValidation)
    this.hasZipcodeValidation = setYesNo(enableZipcodeValidation)
    this.id = key
    this.overWriteEnabled = setYesNo(enableOverwrite)
    this.tasks = tasks.map(task => task.type).join(', ')
  }
}

export default Source
