import React, { useState } from 'react'

import { schedulesRepository } from 'api'
import { StyledAlert } from 'components/shared/ScheduleForm/styles'
import Overview from 'components/Schedules/Overview'

const useScheduleDetails = (operators, showAlert) => {
  const [schedule, setSchedule] = useState()

  const fetchScheduleInfo = async id => {
    const scheduleInfo = await schedulesRepository.findById(id)

    setSchedule(scheduleInfo)
  }

  const tabs = schedule && [
    {
      label: 'Details',
      content: (
        <>
          {schedule?.deleted && (
            <StyledAlert severity="warning">
              Scheduling has failed. The job won&apos;t run. Please contact
              support with ID: {schedule.id}
            </StyledAlert>
          )}
          <Overview
            operators={operators}
            schedule={schedule}
            onShowAlert={showAlert}
          />
        </>
      )
    }
  ]

  return { tabs, schedule, loadInfo: fetchScheduleInfo }
}

export default useScheduleDetails
