import RepositoryFactory from './repositoryFactory'

export const autoCompleteRepository = RepositoryFactory.get('autocomplete')
export const exportFileRepository = RepositoryFactory.get('exportFile')
export const flowsRepository = RepositoryFactory.get('flows')
export const sourceFlowsRepository = RepositoryFactory.get('sourceFlows')
export const jobsRepository = RepositoryFactory.get('jobs')
export const leadsRepository = RepositoryFactory.get('leads')
export const configurationsRepository = RepositoryFactory.get('configurations')
export const distributionsRepository = RepositoryFactory.get('distributions')
export const notificationsRepository = RepositoryFactory.get('notifications')
export const personsRepository = RepositoryFactory.get('persons')
export const favoritesRepository = RepositoryFactory.get('favorites')
export const fieldsRepository = RepositoryFactory.get('fields')
export const schedulesRepository = RepositoryFactory.get('schedules')
export const activitiesRepository = RepositoryFactory.get('activities')
export const graphQLRepository = RepositoryFactory.get('graphql')
export const versionRepository = RepositoryFactory.get('version')
