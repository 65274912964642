const sortBy = ({ results, sort: { by, isAsc } }) => {
  const sortedResults = results.sort((a, b) => {
    if (a[by] > b[by]) {
      return isAsc ? 1 : -1
    }

    if (a[by] < b[by]) {
      return isAsc ? -1 : 1
    }

    return 0
  })

  return sortedResults
}

const getPage = ({ results, paging: { page, rowsPerPage } }) => {
  const startPosition = page * rowsPerPage
  const endPosition = (page + 1) * rowsPerPage

  return results.slice(
    startPosition,
    endPosition < results.length ? endPosition : results.length
  )
}

// TODO: Once the API allows Paging and Sorting, this util must be deleted
const fixResults = ({ sort, paging, results }) => {
  if (!sort && !paging) return results

  const rawResults = [...results]

  if (sort) {
    const sortedResults = sortBy({ results: rawResults, sort })

    return paging ? getPage({ results: sortedResults, paging }) : sortedResults
  }

  return getPage({ results: rawResults, paging })
}

export default fixResults
