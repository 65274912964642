import React, { useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import {
  FormControl,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormLabel
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

import { MenuItem } from '@tranzact/platform_react-components'

import {
  JobType as enumJobType,
  ScheduleFormField,
  ScheduleFormTopOptions
} from 'models'
import getFiltersSearchQuery from 'utils/getFiltersSearchQuery'
import Browser from './Browser'
import {
  StyledFormControlLabel,
  StyledFilterGrid,
  StyledIconButton
} from './styles'
import FormInput from '../FormInput'

const {
  DistributeResults,
  DistributionCid,
  DistributionPid,
  JobType,
  Name,
  Notes,
  NumberOfTopResults,
  SelectedFilter,
  SelectedFilterName
} = ScheduleFormField

const Details = ({ isReadonly, operators, searchFilter }) => {
  const {
    control,
    formState: { errors, submitCount },
    setValue,
    trigger,
    watch
  } = useFormContext()

  const [openBrowser, setOpenBrowser] = useState(false)

  const watchedDistributeResults = watch(DistributeResults)
  const watchedSelectedFilter = watch(SelectedFilter)
  const watchedSelectedFilterName = watch(SelectedFilterName)

  const getSearchFilter = () =>
    searchFilter ||
    (watchedSelectedFilter.length &&
      getFiltersSearchQuery({
        items: watchedSelectedFilter,
        operators
      })) ||
    ' '

  const handleOpenBrowserToggle = () => {
    if (!isReadonly) setOpenBrowser(!openBrowser)
  }

  const handleSelectedFilterChange = filter => {
    setValue(SelectedFilter, filter.filters)
    setValue(SelectedFilterName, filter.label)
    if (submitCount > 0) trigger(SelectedFilterName)
  }

  const handleDistributeResultsChange = onChange => event => {
    onChange(event)
    if (submitCount > 0) trigger(NumberOfTopResults)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel>Details</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            label="Filter"
            value={getSearchFilter()}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            disabled={isReadonly}
            fieldName={Name}
            label="Name"
            placeholder="Ex: New Monday Schedule"
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name={JobType}
            render={({ field }) => {
              const { name } = field
              const error = errors[name]?.message
              const hasError = !!error

              return (
                <FormControl fullWidth required error={hasError}>
                  <TextField
                    {...field}
                    fullWidth
                    select
                    disabled={isReadonly}
                    inputProps={{ name }}
                    label="Schedule Type"
                    variant="outlined"
                  >
                    <MenuItem value={enumJobType.Distribution}>
                      Lead Distribution
                    </MenuItem>
                    <MenuItem value={enumJobType.Update}>Lead Update</MenuItem>
                  </TextField>
                </FormControl>
              )
            }}
          />
        </Grid>
        {searchFilter || (isReadonly && !watchedSelectedFilterName) ? null : (
          <StyledFilterGrid item xs={4}>
            <FormInput
              readOnly
              disabled={isReadonly}
              fieldName={SelectedFilterName}
              placeholder="Select favorite filter..."
              onClick={handleOpenBrowserToggle}
            />
            <StyledIconButton
              disabled={isReadonly}
              onClick={handleOpenBrowserToggle}
            >
              <SearchIcon />
            </StyledIconButton>
          </StyledFilterGrid>
        )}
        <Grid item xs={6}>
          <FormInput
            disabled={isReadonly}
            fieldName={Notes}
            label="Additional notes"
            placeholder="Ex: New monday schedule"
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name={DistributeResults}
            render={({ field }) => {
              const { name } = field

              return (
                <FormControl>
                  <FormLabel>Work with...</FormLabel>
                  <RadioGroup
                    {...field}
                    row
                    onChange={handleDistributeResultsChange(field.onChange)}
                  >
                    <StyledFormControlLabel
                      control={
                        <Radio
                          color="primary"
                          disabled={isReadonly}
                          inputProps={{ name }}
                        />
                      }
                      label="All leads"
                      labelPlacement="end"
                      value={ScheduleFormTopOptions.All}
                    />
                    <StyledFormControlLabel
                      control={
                        <Radio
                          color="primary"
                          disabled={isReadonly}
                          inputProps={{ name }}
                        />
                      }
                      label="Only Top Leads"
                      labelPlacement="end"
                      value={ScheduleFormTopOptions.Top}
                    />
                  </RadioGroup>
                </FormControl>
              )
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {watchedDistributeResults === ScheduleFormTopOptions.Top && (
            <FormInput
              disabled={isReadonly}
              fieldName={NumberOfTopResults}
              label="Top leads"
              placeholder="Ex: 1"
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel>Distribution Parameters</FormLabel>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <TextField
              disabled
              label="System"
              value="TztLeads"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormInput
            disabled={isReadonly}
            fieldName={DistributionPid}
            label="PID"
            placeholder="Ex: HUM999"
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            disabled={isReadonly}
            fieldName={DistributionCid}
            label="CID"
            placeholder="Ex: BATCH"
          />
        </Grid>
      </Grid>
      <Browser
        open={openBrowser}
        onClose={handleOpenBrowserToggle}
        onSelectItem={handleSelectedFilterChange}
      />
    </>
  )
}

Details.defaultProps = {
  isReadonly: false,
  searchFilter: ''
}

Details.propTypes = {
  isReadonly: PropTypes.bool,
  operators: PropTypes.arrayOf(Object).isRequired,
  searchFilter: PropTypes.string
}

export default React.memo(Details)
