import { useDispatch } from 'react-redux'

import { exportFileRepository } from 'api'
import { AlertsType } from 'models'
import uiModule from 'store/modules/ui'

const { actions: uiActions } = uiModule

const useJobFileDownload = () => {
  const dispatch = useDispatch()

  const handleExport = filename => async () => {
    const csvContent = await exportFileRepository.download(filename)

    if (csvContent) {
      const urlData = `data:text/csv;charset=UTF-8,${encodeURIComponent(
        csvContent
      )}`

      const aLink = document.createElement('a')
      aLink.download = filename
      aLink.href = urlData

      const event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    } else {
      dispatch(
        uiActions.showAlert({
          message: `Oops!, something went wrong downloading file.`,
          variant: AlertsType.Error
        })
      )
    }
  }

  return { handleExport }
}

export default useJobFileDownload
