import {
  startOfDay,
  endOfDay,
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  subMinutes,
  subHours,
  subDays,
  subMonths,
  lastDayOfMonth,
  endOfMonth,
  format as formatFns
} from 'date-fns'

import { AppInsightsHelper } from '@tranzact/platform_react-components'

import { MonitoringCodes } from 'models/monitoring'

export const FormatTypes = {
  none: 'none',
  shortdate: 'shortdate'
}

export const Formats = {
  [FormatTypes.none]: 'None',
  [FormatTypes.shortdate]: 'Short Date'
}

export const OptionsTypes = {
  Last15Min: 'last15min',
  Last30Min: 'last30min',
  Last60Min: 'last60min',
  Last4Hours: 'last4hours',
  Today: 'today',
  Yesterday: 'yesterday',
  Last7Days: 'last7days',
  ThisWeek: 'thisweek',
  ThisMonth: 'thismonth',
  Last30Days: 'last30days',
  LastMonth: 'lastmonth',
  DateRange: 'daterange'
}

export const options = {
  [OptionsTypes.Last15Min]: 'Last 15 Minutes',
  [OptionsTypes.Last30Min]: 'Last 30 Minutes',
  [OptionsTypes.Last60Min]: 'Last 60 Minutes',
  [OptionsTypes.Last4Hours]: 'Last 4 Hours',
  [OptionsTypes.Today]: 'Today',
  [OptionsTypes.Yesterday]: 'Yesterday',
  [OptionsTypes.Last7Days]: 'Last 7 Days',
  [OptionsTypes.ThisWeek]: 'This Week',
  [OptionsTypes.ThisMonth]: 'This Month',
  [OptionsTypes.Last30Days]: 'Last 30 Days',
  [OptionsTypes.LastMonth]: 'Last Month',
  [OptionsTypes.DateRange]: 'Date Range'
}

function buildResult(format, from, to) {
  switch (format) {
    case FormatTypes.shortdate:
      return {
        from: formatFns(from, 'yyyyMMdd'),
        to: formatFns(to, 'yyyyMMdd')
      }
    default:
      return { from: from.toISOString(), to: to.toISOString() }
  }
}

const actions = {
  [OptionsTypes.Today]: ({ format, from }) =>
    buildResult(format, startOfDay(from), endOfDay(from)),

  [OptionsTypes.Last15Min]: ({ format, from }) =>
    buildResult(format, subMinutes(from, 15), from),

  [OptionsTypes.Last30Min]: ({ format, from }) =>
    buildResult(format, subMinutes(from, 30), from),

  [OptionsTypes.Last60Min]: ({ format, from }) =>
    buildResult(format, subMinutes(from, 60), from),

  [OptionsTypes.Last4Hours]: ({ format, from }) =>
    buildResult(format, subHours(from, 4), from),

  [OptionsTypes.Yesterday]: ({ format, from }) =>
    buildResult(
      format,
      startOfDay(subDays(from, 1)),
      endOfDay(subDays(from, 1))
    ),

  [OptionsTypes.Last7Days]: ({ format, from }) =>
    buildResult(format, startOfDay(subDays(from, 7)), endOfDay(from)),

  [OptionsTypes.ThisWeek]: ({ format, from }) =>
    buildResult(format, startOfISOWeek(from), endOfISOWeek(from)),

  [OptionsTypes.ThisMonth]: ({ format, from }) =>
    buildResult(
      format,
      startOfMonth(startOfDay(from)),
      endOfMonth(endOfDay(from))
    ),

  [OptionsTypes.Last30Days]: ({ format, from }) =>
    buildResult(format, subDays(startOfDay(from), 30), endOfDay(from)),

  [OptionsTypes.LastMonth]: ({ format, from }) =>
    buildResult(
      format,
      startOfMonth(subMonths(from, 1)),
      lastDayOfMonth(subMonths(from, 1))
    ),

  [OptionsTypes.DateRange]: ({ format, from, to }) =>
    buildResult(format, startOfDay(from), endOfDay(to))
}

export const buildDates = ({ format, option, from, to }) => {
  if (!actions[option]) {
    const msg = 'Date preset not found.'
    AppInsightsHelper?.trackError(
      `${MonitoringCodes.ERR}Utils_DatePresets: ${msg}`
    )
    throw new Error(msg)
  }
  if (option === OptionsTypes.DateRange)
    return actions[option]({ format, from, to })
  return actions[option]({ format, from: new Date() })
}
