import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { EntityFavoritesBrowser } from '@tranzact/platform_react-components'

import { useFavorites } from 'hooks'

const Browser = ({ open, onClose, onSelectItem }) => {
  const {
    continuationToken,
    favorites,
    loading,
    loadingMore,
    clearSearch,
    load,
    reset,
    search
  } = useFavorites('Leads')

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSelect = value => {
    const filtered = favorites.filter(x => x.id === value)

    onSelectItem(filtered[0])
  }

  useEffect(() => {
    if (open) load()()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <EntityFavoritesBrowser
      cancelLabel="Cancel"
      customSearch={{
        onSearch: search,
        onClear: clearSearch
      }}
      items={favorites}
      loadFavorites={load()}
      loading={loading}
      open={open}
      pagination={{
        onLoadMore: load(true),
        hasMore: !!continuationToken,
        isLoading: loadingMore
      }}
      onClose={handleClose}
      onSelect={handleSelect}
    />
  )
}

Browser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired
}

export default Browser
