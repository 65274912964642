import * as go from 'gojs'

import { defaultColor, failureColor, lightBlueColor } from '../constants'
import { getTextBlockColor } from '../helper'

export function getSequenceLinktemplate($) {
  const sequenceLinkTemplate = $(
    go.Link,
    {
      routing: go.Link.AvoidsNodes,
      curve: go.Link.J,

      corner: 10,
      reshapable: true,
      relinkableFrom: true,
      relinkableTo: true,
      toEndSegmentLength: 20
    },
    new go.Binding('points').makeTwoWay(),
    $(
      go.Shape,
      { stroke: lightBlueColor, strokeWidth: 2 },
      new go.Binding('stroke', 'hasError', hasError =>
        hasError ? failureColor : lightBlueColor
      ),
      new go.Binding('strokeWidth', 'isPath', isPath => (isPath ? 2 : 0.5))
    ),
    $(
      go.Shape,
      {
        toArrow: 'Triangle',
        scale: 1.2,
        fill: lightBlueColor,
        stroke: null
      },
      new go.Binding('fill', 'hasError', hasError =>
        hasError ? failureColor : lightBlueColor
      )
    ),
    $(go.Shape, {
      fromArrow: '',
      scale: 1.5,
      stroke: 'black',
      fill: defaultColor
    }),
    $(
      go.TextBlock,
      {
        name: 'Label',
        text: '',
        segmentOffset: new go.Point(-10, -10),
        visible: true
      },
      new go.Binding('text', 'text').makeTwoWay(),
      getTextBlockColor()
    )
  )

  return sequenceLinkTemplate
}

export default { getSequenceLinktemplate }
