const NAME = 'source'

const actionTypes = {
  GET_FLOW_BY_ID: `${NAME}/GET_FLOW_BY_ID`,
  GET_LEAD_FLOW: `${NAME}/GET_LEAD_FLOW`,
  SET_FLOW: `${NAME}/SET_FLOW`,
  SET_HAS_FLOW_ERROR: `${NAME}/SET_HAS_FLOW_ERROR`
}

const actions = {
  getLeadFlow: payload => ({
    type: actionTypes.GET_LEAD_FLOW,
    payload
  }),
  getFlowById: payload => ({
    type: actionTypes.GET_FLOW_BY_ID,
    payload
  }),
  setFlow: payload => ({
    type: actionTypes.SET_FLOW,
    payload
  }),
  setHasFlowError: (payload = true) => ({
    type: actionTypes.SET_HAS_FLOW_ERROR,
    payload
  })
}

const initialState = {
  hasFlowError: false,
  selectedFlow: {}
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FLOW:
      return { ...state, selectedFlow: payload }

    case actionTypes.SET_HAS_FLOW_ERROR:
      return { ...state, hasFlowError: payload }

    default:
      return state
  }
}

const getHasFlowError = state => state[NAME].hasFlowError
const getSelectedFlow = state => state[NAME].selectedFlow

const selectors = {
  getHasFlowError,
  getSelectedFlow
}

const source = {
  actionTypes,
  actions,
  initialState,
  namespace: NAME,
  reducer,
  selectors
}

export default source
