/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import * as go from 'gojs'

function breakUpBezier(
  startx,
  starty,
  c1x,
  c1y,
  c2x,
  c2y,
  endx,
  endy,
  fraction,
  curve1cp1,
  curve1cp2,
  midpoint,
  curve2cp1,
  curve2cp2
) {
  const fo = 1 - fraction
  const so = fraction
  const m1x = startx * fo + c1x * so
  const m1y = starty * fo + c1y * so
  const m2x = c1x * fo + c2x * so
  const m2y = c1y * fo + c2y * so
  const m3x = c2x * fo + endx * so
  const m3y = c2y * fo + endy * so
  const m12x = m1x * fo + m2x * so
  const m12y = m1y * fo + m2y * so
  const m23x = m2x * fo + m3x * so
  const m23y = m2y * fo + m3y * so
  const m123x = m12x * fo + m23x * so
  const m123y = m12y * fo + m23y * so
  curve1cp1.x = m1x
  curve1cp1.y = m1y
  curve1cp2.x = m12x
  curve1cp2.y = m12y
  midpoint.x = m123x
  midpoint.y = m123y
  curve2cp1.x = m23x
  curve2cp1.y = m23y
  curve2cp2.x = m3x
  curve2cp2.y = m3y
}
const FigureParameter = (() => {
  function FigureParameter(name, def, min, max) {
    if (min === undefined) min = 0.0
    if (max === undefined) max = Infinity
    this._name = name
    this._defaultValue = def
    this._minimum = min
    this._maximum = max
  }
  Object.defineProperty(FigureParameter.prototype, 'name', {
    /**
     * Gets or sets the name of the figure.
     */
    get() {
      return this._name
    },
    set(val) {
      if (typeof val !== 'string' || val === '')
        throw new Error('Shape name must be a valid string.')
      this._name = val
    },
    enumerable: true,
    configurable: true
  })
  Object.defineProperty(FigureParameter.prototype, 'defaultValue', {
    /**
     * Gets or sets the default value for the parameter.
     */
    get() {
      return this._defaultValue
    },
    set(val) {
      if (typeof val !== 'number' || isNaN(val))
        throw new Error(`The default value must be a real number, not: ${val}`)
      this._defaultValue = val
    },
    enumerable: true,
    configurable: true
  })
  Object.defineProperty(FigureParameter.prototype, 'minimum', {
    /**
     * Gets or sets the minimum value allowed for the figure parameter.
     */
    get() {
      return this._minimum
    },
    set(val) {
      if (typeof val !== 'number' || isNaN(val))
        throw new Error(`Minimum must be a real number, not: ${val}`)
      this._minimum = val
    },
    enumerable: true,
    configurable: true
  })
  Object.defineProperty(FigureParameter.prototype, 'maximum', {
    /**
     * Gets or sets the maximum value allowed for the figure parameter.
     */
    get() {
      return this._maximum
    },
    set(val) {
      if (typeof val !== 'number' || isNaN(val))
        throw new Error(`Maximum must be a real number, not: ${val}`)
      this._maximum = val
    },
    enumerable: true,
    configurable: true
  })
  /**
   * This static function gets a FigureParameter for a particular figure name.
   * @param {String} figurename
   * @param {number} index, currently must be either 0 or 1
   * @return {FigureParameter}
   */
  FigureParameter.getFigureParameter = (figurename, index) => {
    const arr = FigureParameter.definedParameters[figurename]
    if (!arr) return null
    return arr[index]
  }
  /**
   * This static function sets a FigureParameter for a particular figure name.
   * @param {String} figurename
   * @param {number} index, currently must be either 0 or 1
   * @param {FigureParameter} figparam
   */
  FigureParameter.setFigureParameter = (figurename, index, figparam) => {
    if (!(figparam instanceof FigureParameter))
      throw new Error(
        `Third argument to FigureParameter.setFigureParameter is not FigureParameter: ${figparam}`
      )
    if (
      figparam.defaultValue < figparam.minimum ||
      figparam.defaultValue > figparam.maximum
    ) {
      throw new Error(
        `defaultValue must be between minimum and maximum, not: ${figparam.defaultValue}`
      )
    }
    let arr = FigureParameter.definedParameters[figurename]
    if (!arr) {
      arr = []
      FigureParameter.definedParameters[figurename] = arr
    }
    arr[index] = figparam
  }
  FigureParameter.definedParameters = {}
  return FigureParameter
})()

function BpmnGoJsShapes() {
  const GeneratorEllipseSpot1 = new go.Spot(0.156, 0.156)
  const GeneratorEllipseSpot2 = new go.Spot(0.844, 0.844)
  const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3)
  const _CachedPoints = []

  function tempPoint() {
    const temp = _CachedPoints.pop()
    if (temp === undefined) return new go.Point()
    return temp
  }
  /**
   * @ignore
   * @param {Point} temp
   */
  function freePoint(temp) {
    _CachedPoints.push(temp)
  }

  go.Shape.defineFigureGenerator('Rectangle', (shape, w, h) => {
    const geo = new go.Geometry(go.Geometry.Rectangle)
    geo.startX = 0
    geo.startY = 0
    geo.endX = w
    geo.endY = h
    return geo
  })
  go.Shape.defineFigureGenerator('Square', (shape, w, h) => {
    const geo = new go.Geometry(go.Geometry.Rectangle)
    geo.startX = 0
    geo.startY = 0
    geo.endX = w
    geo.endY = h
    geo.defaultStretch = go.GraphObject.Uniform
    return geo
  })
  FigureParameter.setFigureParameter(
    'RoundedRectangle',
    0,
    new FigureParameter('CornerRounding', 5)
  )
  go.Shape.defineFigureGenerator('RoundedRectangle', (shape, w, h) => {
    let param1 = shape ? shape.parameter1 : NaN
    if (isNaN(param1) || param1 < 0) param1 = 5 // default corner
    param1 = Math.min(param1, w / 3)
    param1 = Math.min(param1, h / 3)
    const cpOffset = param1 * KAPPA
    const geo = new go.Geometry().add(
      new go.PathFigure(param1, 0, true)
        .add(new go.PathSegment(go.PathSegment.Line, w - param1, 0))
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            w,
            param1,
            w - cpOffset,
            0,
            w,
            cpOffset
          )
        )
        .add(new go.PathSegment(go.PathSegment.Line, w, h - param1))
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            w - param1,
            h,
            w,
            h - cpOffset,
            w - cpOffset,
            h
          )
        )
        .add(new go.PathSegment(go.PathSegment.Line, param1, h))
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0,
            h - param1,
            cpOffset,
            h,
            0,
            h - cpOffset
          )
        )
        .add(new go.PathSegment(go.PathSegment.Line, 0, param1))
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            param1,
            0,
            0,
            cpOffset,
            cpOffset,
            0
          ).close()
        )
    )
    if (cpOffset > 1) {
      geo.spot1 = new go.Spot(0, 0, cpOffset, cpOffset)
      geo.spot2 = new go.Spot(1, 1, -cpOffset, -cpOffset)
    }
    return geo
  })
  go.Shape.defineFigureGenerator('Border', 'RoundedRectangle') // predefined in 2.0
  go.Shape.defineFigureGenerator('Circle', (shape, w, h) => {
    const geo = new go.Geometry(go.Geometry.Ellipse)
    geo.startX = 0
    geo.startY = 0
    geo.endX = w
    geo.endY = h
    geo.spot1 = GeneratorEllipseSpot1
    geo.spot2 = GeneratorEllipseSpot2
    geo.defaultStretch = go.GraphObject.Uniform
    return geo
  })
  go.Shape.defineFigureGenerator('TriangleUp', (shape, w, h) => new go.Geometry()
      .add(
        new go.PathFigure(w, h)
          .add(new go.PathSegment(go.PathSegment.Line, 0, h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.5 * w, 0).close())
      )
      .setSpots(0.25, 0.5, 0.75, 1))
  go.Shape.defineFigureGenerator('Triangle', 'TriangleUp') // predefined in 2.0
  // The following functions are used by a group of regular figures that are defined below:
  FigureParameter.setFigureParameter(
    'ThinX',
    0,
    new FigureParameter('Thickness', 10)
  )
  go.Shape.defineFigureGenerator('ThinX', (shape, w, h) => {
    const geo = new go.Geometry()
    const fig = new go.PathFigure(0.1 * w, 0, true)
    geo.add(fig)
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.5 * w, 0.4 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0))
    fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.1 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.6 * w, 0.5 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.9 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.5 * w, 0.6 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.9 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.4 * w, 0.5 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.1 * h).close())
    return geo
  })
  go.Shape.defineFigureGenerator('NotAllowed', (shape, w, h) => {
    const geo = new go.Geometry()
    let cpOffset = KAPPA * 0.5
    let radius = 0.5
    const centerx = 0.5
    const centery = 0.5
    const fig = new go.PathFigure(centerx * w, (centery - radius) * h)
    geo.add(fig)
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        (centerx - radius) * w,
        centery * h,
        (centerx - cpOffset) * w,
        (centery - radius) * h,
        (centerx - radius) * w,
        (centery - cpOffset) * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        centerx * w,
        (centery + radius) * h,
        (centerx - radius) * w,
        (centery + cpOffset) * h,
        (centerx - cpOffset) * w,
        (centery + radius) * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        (centerx + radius) * w,
        centery * h,
        (centerx + cpOffset) * w,
        (centery + radius) * h,
        (centerx + radius) * w,
        (centery + cpOffset) * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        centerx * w,
        (centery - radius) * h,
        (centerx + radius) * w,
        (centery - cpOffset) * h,
        (centerx + cpOffset) * w,
        (centery - radius) * h
      )
    )
    // Inner circle, composed of two parts, separated by
    // a beam across going from top-right to bottom-left.
    radius = 0.4
    cpOffset = KAPPA * 0.4
    // First we cut up the top right 90 degree curve into two smaller
    // curves.
    // Since its clockwise, StartOfArrow is the first of the two points
    // on the circle. EndOfArrow is the other one.
    const startOfArrowc1 = tempPoint()
    const startOfArrowc2 = tempPoint()
    const startOfArrow = tempPoint()
    const unused = tempPoint()
    breakUpBezier(
      centerx,
      centery - radius,
      centerx + cpOffset,
      centery - radius,
      centerx + radius,
      centery - cpOffset,
      centerx + radius,
      centery,
      0.42,
      startOfArrowc1,
      startOfArrowc2,
      startOfArrow,
      unused,
      unused
    )
    const endOfArrowc1 = tempPoint()
    const endOfArrowc2 = tempPoint()
    const endOfArrow = tempPoint()
    breakUpBezier(
      centerx,
      centery - radius,
      centerx + cpOffset,
      centery - radius,
      centerx + radius,
      centery - cpOffset,
      centerx + radius,
      centery,
      0.58,
      unused,
      unused,
      endOfArrow,
      endOfArrowc1,
      endOfArrowc2
    )
    // Cut up the bottom left 90 degree curve into two smaller curves.
    const startOfArrow2c1 = tempPoint()
    const startOfArrow2c2 = tempPoint()
    const startOfArrow2 = tempPoint()
    breakUpBezier(
      centerx,
      centery + radius,
      centerx - cpOffset,
      centery + radius,
      centerx - radius,
      centery + cpOffset,
      centerx - radius,
      centery,
      0.42,
      startOfArrow2c1,
      startOfArrow2c2,
      startOfArrow2,
      unused,
      unused
    )
    const endOfArrow2c1 = tempPoint()
    const endOfArrow2c2 = tempPoint()
    const endOfArrow2 = tempPoint()
    breakUpBezier(
      centerx,
      centery + radius,
      centerx - cpOffset,
      centery + radius,
      centerx - radius,
      centery + cpOffset,
      centerx - radius,
      centery,
      0.58,
      unused,
      unused,
      endOfArrow2,
      endOfArrow2c1,
      endOfArrow2c2
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Move,
        endOfArrow2.x * w,
        endOfArrow2.y * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        (centerx - radius) * w,
        centery * h,
        endOfArrow2c1.x * w,
        endOfArrow2c1.y * h,
        endOfArrow2c2.x * w,
        endOfArrow2c2.y * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        centerx * w,
        (centery - radius) * h,
        (centerx - radius) * w,
        (centery - cpOffset) * h,
        (centerx - cpOffset) * w,
        (centery - radius) * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        startOfArrow.x * w,
        startOfArrow.y * h,
        startOfArrowc1.x * w,
        startOfArrowc1.y * h,
        startOfArrowc2.x * w,
        startOfArrowc2.y * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Line,
        endOfArrow2.x * w,
        endOfArrow2.y * h
      ).close()
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Move,
        startOfArrow2.x * w,
        startOfArrow2.y * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Line,
        endOfArrow.x * w,
        endOfArrow.y * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        (centerx + radius) * w,
        centery * h,
        endOfArrowc1.x * w,
        endOfArrowc1.y * h,
        endOfArrowc2.x * w,
        endOfArrowc2.y * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        centerx * w,
        (centery + radius) * h,
        (centerx + radius) * w,
        (centery + cpOffset) * h,
        (centerx + cpOffset) * w,
        (centery + radius) * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        startOfArrow2.x * w,
        startOfArrow2.y * h,
        startOfArrow2c1.x * w,
        startOfArrow2c1.y * h,
        startOfArrow2c2.x * w,
        startOfArrow2c2.y * h
      ).close()
    )
    freePoint(startOfArrowc1)
    freePoint(startOfArrowc2)
    freePoint(startOfArrow)
    freePoint(unused)
    freePoint(endOfArrowc1)
    freePoint(endOfArrowc2)
    freePoint(endOfArrow)
    freePoint(startOfArrow2c1)
    freePoint(startOfArrow2c2)
    freePoint(startOfArrow2)
    freePoint(endOfArrow2c1)
    freePoint(endOfArrow2c2)
    freePoint(endOfArrow2)
    geo.defaultStretch = go.GraphObject.Uniform
    return geo
  })
  go.Shape.defineFigureGenerator('BpmnTaskMessage', (shape, w, h) => {
    const geo = new go.Geometry()
    let fig = new go.PathFigure(0, 0.2 * h, true)
    geo.add(fig)
    fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.2 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.8 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.8 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.8 * h).close())
    fig = new go.PathFigure(0, 0.2 * h, false)
    geo.add(fig)
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.5 * w, 0.5 * h))
    fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.2 * h))
    return geo
  })
  go.Shape.defineFigureGenerator('BpmnEventConditional', (shape, w, h) => {
    const geo = new go.Geometry()
    const fig = new go.PathFigure(0.1 * w, 0, true)
    geo.add(fig)
    // Body
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, 0))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.9 * w, h))
    fig.add(new go.PathSegment(go.PathSegment.Line, 0.1 * w, h).close())
    const fig2 = new go.PathFigure(0.2 * w, 0.2 * h, false)
    geo.add(fig2)
    // Inside lines
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.2 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.4 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.4 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.6 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.6 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, 0.2 * w, 0.8 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.8 * w, 0.8 * h))
    return geo
  })
  go.Shape.defineFigureGenerator('BpmnEventTimer', (shape, w, h) => {
    const geo = new go.Geometry()
    const radius = 0.5
    const cpOffset = KAPPA * 0.5
    const fig = new go.PathFigure(w, radius * h, true)
    geo.add(fig)
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        radius * w,
        h,
        w,
        (radius + cpOffset) * h,
        (radius + cpOffset) * w,
        h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        0,
        radius * h,
        (radius - cpOffset) * w,
        h,
        0,
        (radius + cpOffset) * h
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        radius * w,
        0,
        0,
        (radius - cpOffset) * h,
        (radius - cpOffset) * w,
        0
      )
    )
    fig.add(
      new go.PathSegment(
        go.PathSegment.Bezier,
        w,
        radius * h,
        (radius + cpOffset) * w,
        0,
        w,
        (radius - cpOffset) * h
      )
    )
    const fig2 = new go.PathFigure(radius * w, 0, false)
    geo.add(fig2)
    // Hour lines
    fig2.add(new go.PathSegment(go.PathSegment.Line, radius * w, 0.15 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, radius * w, h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, radius * w, 0.85 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, 0, radius * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.15 * w, radius * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, w, radius * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.85 * w, radius * h))
    // Clock hands
    fig2.add(new go.PathSegment(go.PathSegment.Move, radius * w, radius * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.58 * w, 0.1 * h))
    fig2.add(new go.PathSegment(go.PathSegment.Move, radius * w, radius * h))
    fig2.add(new go.PathSegment(go.PathSegment.Line, 0.78 * w, 0.54 * h))
    return geo
  })
  go.Shape.defineFigureGenerator('Empty', () => new go.Geometry())
  const annotationStr =
    'M 150,0L 0,0L 0,300L -150,300L 0,300L 0,600L 150,600 M 800,0'
  const annotationGeo = go.Geometry.parse(annotationStr)
  annotationGeo.normalize()
  go.Shape.defineFigureGenerator('Annotation', (shape, w, h) => {
    const geo = annotationGeo.copy()
    // calculate how much to scale the Geometry so that it fits in w x h
    const { bounds } = geo
    const scale = Math.min(w / bounds.width, h / bounds.height)
    geo.scale(scale, scale)
    return geo
  })
  const gearStr =
    'F M 391,5L 419,14L 444.5,30.5L 451,120.5L 485.5,126L 522,141L 595,83L 618.5,92L 644,106.5' +
    'L 660.5,132L 670,158L 616,220L 640.5,265.5L 658.122,317.809L 753.122,322.809L 770.122,348.309L 774.622,374.309' +
    'L 769.5,402L 756.622,420.309L 659.122,428.809L 640.5,475L 616.5,519.5L 670,573.5L 663,600L 646,626.5' +
    'L 622,639L 595,645.5L 531.5,597.5L 493.192,613.462L 450,627.5L 444.5,718.5L 421.5,733L 393,740.5L 361.5,733.5' +
    'L 336.5,719L 330,627.5L 277.5,611.5L 227.5,584.167L 156.5,646L 124.5,641L 102,626.5L 82,602.5L 78.5,572.5' +
    'L 148.167,500.833L 133.5,466.833L 122,432.5L 26.5,421L 11,400.5L 5,373.5L 12,347.5L 26.5,324L 123.5,317.5' +
    'L 136.833,274.167L 154,241L 75.5,152.5L 85.5,128.5L 103,105.5L 128.5,88.5001L 154.872,82.4758L 237,155' +
    'L 280.5,132L 330,121L 336,30L 361,15L 391,5 Z M 398.201,232L 510.201,275L 556.201,385L 505.201,491L 399.201,537' +
    'L 284.201,489L 242.201,385L 282.201,273L 398.201,232 Z'
  const gearGeo = go.Geometry.parse(gearStr)
  gearGeo.normalize()
  go.Shape.defineFigureGenerator('BpmnTaskService', (shape, w, h) => {
    const geo = gearGeo.copy()
    // calculate how much to scale the Geometry so that it fits in w x h
    const { bounds } = geo
    const scale = Math.min(w / bounds.width, h / bounds.height)
    geo.scale(scale, scale)
    // text should go in the hand
    geo.spot1 = new go.Spot(0, 0.6, 10, 0)
    geo.spot2 = new go.Spot(1, 1)
    return geo
  })
  const handGeo = go.Geometry.parse(
    'F1M18.13,10.06 C18.18,10.07 18.22,10.07 18.26,10.08 18.91,' +
      '10.20 21.20,10.12 21.28,12.93 21.36,15.75 21.42,32.40 21.42,32.40 21.42,' +
      '32.40 21.12,34.10 23.08,33.06 23.08,33.06 22.89,24.76 23.80,24.17 24.72,' +
      '23.59 26.69,23.81 27.19,24.40 27.69,24.98 28.03,24.97 28.03,33.34 28.03,' +
      '33.34 29.32,34.54 29.93,33.12 30.47,31.84 29.71,27.11 30.86,26.56 31.80,' +
      '26.12 34.53,26.12 34.72,28.29 34.94,30.82 34.22,36.12 35.64,35.79 35.64,' +
      '35.79 36.64,36.08 36.72,34.54 36.80,33.00 37.17,30.15 38.42,29.90 39.67,' +
      '29.65 41.22,30.20 41.30,32.29 41.39,34.37 42.30,46.69 38.86,55.40 35.75,' +
      '63.29 36.42,62.62 33.47,63.12 30.76,63.58 26.69,63.12 26.69,63.12 26.69,' +
      '63.12 17.72,64.45 15.64,57.62 13.55,50.79 10.80,40.95 7.30,38.95 3.80,' +
      '36.95 4.24,36.37 4.28,35.35 4.32,34.33 7.60,31.25 12.97,35.75 12.97,' +
      '35.75 16.10,39.79 16.10,42.00 16.10,42.00 15.69,14.30 15.80,12.79 15.96,' +
      '10.75 17.42,10.04 18.13,10.06z '
  )
  handGeo.rotate(90, 0, 0)
  handGeo.normalize()
  go.Shape.defineFigureGenerator('BpmnTaskManual', (shape, w, h) => {
    const geo = handGeo.copy()
    // calculate how much to scale the Geometry so that it fits in w x h
    const { bounds } = geo
    const scale = Math.min(w / bounds.width, h / bounds.height)
    geo.scale(scale, scale)
    // guess where text should go (in the hand)
    geo.spot1 = new go.Spot(0, 0.6, 10, 0)
    geo.spot2 = new go.Spot(1, 1)
    return geo
  })
}

export default BpmnGoJsShapes
