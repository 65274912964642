import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ui } from 'store/modules'
import RenderEntityView from './RenderEntityView'

const { getEntity } = ui.selectors

const { setEntity, clearAll } = ui.actions

const mapStateToProps = state => ({
  entity: getEntity(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEntity,
      clearAll
    },
    dispatch
  )

const RenderEntityViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderEntityView)

export default RenderEntityViewContainer
