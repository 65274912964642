import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { filters, ui } from 'store/modules'
import Sources from './Sources'

const { showAlert } = ui.actions
const { updateAdvanced } = filters.actions
const { getEntity } = ui.selectors

const mapStateToProps = state => ({
  entity: getEntity(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSearch: updateAdvanced,
      showAlert
    },
    dispatch
  )

const SourcesContainer = connect(mapStateToProps, mapDispatchToProps)(Sources)

export default SourcesContainer
