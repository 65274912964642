import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { favoritesRepository } from 'api'
import favoriteModule from 'store/modules/favorites'
import uiModule from 'store/modules/ui'

const { actions: favoriteActions } = favoriteModule
const { actions: uiActions } = uiModule
let abortController = new AbortController()

const useFavorites = entity => {
  const dispatch = useDispatch()
  const [{ all: loading, more: loadingMore }, setLoading] = useState({
    all: false,
    more: false
  })
  const [{ all, filtered }, setFavorites] = useState({ all: [], filtered: [] })
  const [continuationToken, setContinuationToken] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const getFiltered = useCallback(
    items =>
      items.filter(({ label }) =>
        label.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [searchTerm]
  )

  const deleteFavorite = useCallback(
    favorites => id => {
      const { label } = favorites.find(({ id: itemId }) => itemId === id) || {}

      favoritesRepository
        .deleteOne({ entity, name: label, owner: 'common' })
        .then(() => {
          setFavorites(prev => {
            const items = [...prev.all].filter(item => item.id !== id)

            return {
              all: items,
              filtered: getFiltered(items)
            }
          })
        })
        .catch(error => {
          if (error.name !== 'CanceledError') {
            dispatch(
              uiActions.showAlert({
                error,
                message: `Oops!, something went wrong deleting favorite.`
              })
            )
          }
        })
    },
    [entity, dispatch, getFiltered]
  )

  const load = useCallback(
    fromLoadMore => {
      abortController = new AbortController()

      // render loading after immediate cancel due to abort
      setTimeout(() => {
        setLoading({ all: !fromLoadMore, more: !!fromLoadMore })
      }, 0)

      favoritesRepository
        .find({
          abortController,
          entity,
          owner: 'common',
          top: 50,
          lastContinuationToken: fromLoadMore ? continuationToken : null
        })
        .then(response => {
          setFavorites(prev => {
            const items = fromLoadMore
              ? [...prev.all, ...response.favorites]
              : response.favorites

            return {
              all: items,
              filtered: fromLoadMore ? getFiltered(items) : items
            }
          })
          setContinuationToken(response.continuationToken)
        })
        .catch(error => {
          if (error.name !== 'CanceledError') {
            dispatch(
              uiActions.showAlert({
                error,
                message: `Oops!, something went wrong fetching favorites.`
              })
            )
          }
        })
        .finally(() => {
          setLoading({ all: false, more: false })
        })
    },
    [continuationToken, entity, dispatch, getFiltered]
  )

  useEffect(() => {
    if (searchTerm) {
      setFavorites(prev => ({
        ...prev,
        filtered: getFiltered(prev.all)
      }))
      if (continuationToken) {
        abortController.abort()
        load(true)
      }
    }
  }, [searchTerm, continuationToken, load, getFiltered])

  const handleClearSearch = () => {
    // wait for rendering previous favorites before aborting to prevent infinity scroll to load more
    setTimeout(() => {
      setFavorites(prev => ({
        all: prev.all,
        filtered: prev.all
      }))
    }, 0)
    abortController.abort()
    setSearchTerm('')
  }

  const reset = () => {
    abortController.abort()
    setContinuationToken(null)
    setSearchTerm('')
    setFavorites({ all: [], filtered: [] })
  }

  const search = value => {
    if (value === '') handleClearSearch()
    else setSearchTerm(value)
  }

  const selectForFilters = useCallback(
    id => {
      const { filters, label } = all.find(({ id: itemId }) => itemId === id)

      dispatch(favoriteActions.selectForFilters({ filters, label }))
    },
    [all, dispatch]
  )

  const handleLoad = fromLoadMore => () => {
    abortController.abort()
    if (!fromLoadMore) {
      setContinuationToken(null)
      setSearchTerm('')
    }
    load(fromLoadMore)
  }

  return {
    continuationToken,
    loading,
    loadingMore,
    favorites: filtered,
    clearSearch: handleClearSearch,
    deleteFavorite,
    load: handleLoad,
    reset,
    search,
    selectForFilters
  }
}

export default useFavorites
