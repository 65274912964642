import styled from '@emotion/styled'

import { Chip, Grid, Paper, CircularProgress } from '@mui/material'

export const StyledContainerGrid = styled(Grid)`
  padding-left: 24px;
  padding-right: 24px;
  height: 500px;
`

export const StyledPaper = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 5px;
  margin: 0;
  height: 100px;
  overflow-x: auto;
`

export const StyledChip = styled(Chip)`
  margin: 5px;
`

export const LoadingButtonContainer = styled.div`
  position: relative;
  margin: 1;
`

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`
