import FlowTask from './flowTask'

class FlowGateway {
  getFlowTask = taskUrl => task => {
    const { Detail, Description } = task
    const pid = Detail?.split(',')
    const url = taskUrl
      ? {
          Url: taskUrl
            .replace('{pid}', (pid?.length === 1 && pid[0]) || '')
            .replace('{cid}', Description ?? '')
        }
      : {}

    return new FlowTask({ ...task, ...url })
  }

  getGatewayFlowTasks = taskUrl => flow => ({
    key: flow.Key,
    tasks: flow.Tasks?.map(this.getFlowTask(taskUrl)) ?? []
  })

  getGatewayFlow = taskUrl => gatewayflow => ({
    isPath: gatewayflow.IsPath,
    key: gatewayflow.Key,
    flow: gatewayflow.Flow?.map(this.getGatewayFlowTasks(taskUrl)) ?? []
  })

  constructor(rawGateway) {
    if (!rawGateway) return
    const { TaskUrl, Flows, Type } = rawGateway

    this.flows = Flows?.map(this.getGatewayFlow(TaskUrl)) ?? []
    this.type = Type
  }
}

export default FlowGateway
