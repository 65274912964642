import * as go from 'gojs'

import {
  GatewayNodeSymbolSize,
  lightBlueColor,
  GatewayNodeSize
} from '../constants'
import { getPanelShapeColor, getTextBlockColor } from '../helper'

// ------------------------------------------  Gateway Node Template   ----------------------------------------------
function nodeGatewaySymbolTypeConverter(s) {
  const tasks = [
    'NotAllowed',
    'Circle', // 1 - Inclusive
    'ThinX' // 2 - Exclusive  (exclusive can also be no symbol, just bind to visible=false for no symbol)
  ]
  if (s < tasks.length) return tasks[s]
  return 'NotAllowed' // error
}

// tweak the size of some of the gateway icons
function nodeGatewaySymbolSizeConverter(s) {
  const size = new go.Size(GatewayNodeSymbolSize, GatewayNodeSymbolSize)
  if (s === 2) {
    size.width = (size.width / 4) * 3
    size.height = (size.height / 4) * 3
  }
  return size
}

export function getGatewayNodeTemplate($) {
  const gatewayNodeTemplate = $(
    go.Node,
    'Vertical',
    {
      locationObjectName: 'SHAPE',
      locationSpot: go.Spot.Center
    },
    new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
      go.Point.stringify
    ),
    // move a selected part into the Foreground layer, so it isn't obscured by any non-selected parts
    new go.Binding('layerName', 'isSelected', s =>
      s ? 'Foreground' : ''
    ).ofObject(),
    // can be resided according to the user's desires
    { resizable: false, resizeObjectName: 'SHAPE' },
    $(
      go.Panel,
      'Spot',
      $(
        go.Shape,
        'Diamond',
        {
          stroke: lightBlueColor,
          strokeWidth: 3,
          name: 'SHAPE',
          desiredSize: new go.Size(GatewayNodeSize, GatewayNodeSize),
          portId: '',
          fromLinkable: true,
          toLinkable: true,
          fromSpot: go.Spot.NotLeftSide,
          toSpot: go.Spot.NotRightSide
        },
        new go.Binding('strokeWidth', 'isNotPath', () => 1),
        new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
          go.Size.stringify
        ),
        getPanelShapeColor()
      ), // end main shape
      $(
        go.Shape,
        {
          alignment: go.Spot.Center,
          stroke: lightBlueColor,
          strokeWidth: 1
        },
        new go.Binding('figure', 'gatewayType', nodeGatewaySymbolTypeConverter),
        new go.Binding('fill', 'gatewayType', s => s === 2 ? lightBlueColor : null),
        new go.Binding('strokeWidth', 'isNotPath', () => 3),
        new go.Binding(
          'desiredSize',
          'gatewayType',
          nodeGatewaySymbolSizeConverter
        )
      )
    ),
    $(
      go.TextBlock,
      {
        alignment: go.Spot.Center,
        textAlign: 'center',
        margin: 5
      },
      new go.Binding('text').makeTwoWay(),
      getTextBlockColor()
    )
  ) // end go.Node Vertical
  return gatewayNodeTemplate
}

export default getGatewayNodeTemplate
