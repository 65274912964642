import React, { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { EntityResults } from '@tranzact/platform_react-components'

import { ResultsWrapper } from 'components/shared'

const Results = ({ piiMask, viewDetail, ...props }) => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const handleOnSelect = id => {
    if (viewDetail) navigate(`${pathname}${id}${search}`)
  }

  return (
    <ResultsWrapper>
      <EntityResults
        {...props}
        stickyHeader
        mask={piiMask}
        onSelectRow={handleOnSelect}
      />
    </ResultsWrapper>
  )
}

Results.defaultProps = {
  results: []
}

Results.propTypes = {
  piiMask: PropTypes.bool.isRequired,
  results: PropTypes.oneOfType([
    PropTypes.arrayOf(Object),
    PropTypes.arrayOf(String)
  ]),
  viewDetail: PropTypes.bool.isRequired
}

export default memo(Results)
