import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import {
  LocalOfferOutlined as InfoIcon,
  SearchOutlined as FilterIcon,
  HowToRegOutlined as UserIcon,
  TimelineOutlined as SummaryIcon,
  BuildOutlined as ExecutionModeIcon,
  AlarmOutlined as DurationIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  SendOutlined as SendOutlinedIcon,
  CloudDownload as DownloadIcon
} from '@mui/icons-material'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { formatDistanceToNow } from 'date-fns'

import {
  AppInsightsHelper,
  AppInsightsReactPlugin,
  useHotjar
} from '@tranzact/platform_react-components'

import { OverviewLayout } from 'components/layout'
import { Job, JobType, MonitoringCodes } from 'models'
import { Label } from 'components/ui'
import { useJobFileDownload } from 'hooks'

const JOB_STATUS_DICTIONARY = {
  Running: 'info',
  'Completed with errors': 'error',
  Completed: 'success',
  Canceling: 'warning'
}

function getDuration(valueInMilliseconds) {
  const value = valueInMilliseconds / 1000
  let result = `${value.toFixed(0)} sec.`

  if (value > 59) result = `${(value / 60).toFixed(0)} min.`

  return result
}

function generateJobSections(job, handleExport, tagRecordingHotjar) {
  const jobStatus = job && (
    <Label className={JOB_STATUS_DICTIONARY[job.status]} value={job.status} />
  )

  const handleExportWithTracking = () => {
    const { filename } = job
    const tag = `${MonitoringCodes.EVENT}JobsDownload`

    AppInsightsHelper?.trackEvent(tag, { job })

    tagRecordingHotjar([tag])
    handleExport(filename)()
  }

  const sections = [
    {
      name: 'Status',
      fields: [
        {
          icon: IconButton,
          id: 1,
          mask: false,
          primary: jobStatus
        }
      ]
    },
    {
      name: 'Information',
      fields: [
        {
          id: 1,
          icon: InfoIcon,
          primary: job.id
        },
        {
          id: 2,
          icon: UserIcon,
          primary: job.user,
          secondary: `Started ${formatDistanceToNow(job.created, {
            addSuffix: true,
            includeSeconds: true
          })}`
        },
        {
          id: 3,
          icon: FilterIcon,
          primary: job.filter || 'No filter applied.',
          secondary: !!job.filter && 'Filter applied',
          chipped: true
        },
        {
          id: 4,
          icon: DescriptionOutlinedIcon,
          primary: job.filename || 'Not applicable.',
          secondary: 'File Name',
          action:
            job.filename && job.type === JobType.Export ? (
              <IconButton
                aria-label="delete"
                edge="end"
                onClick={handleExportWithTracking}
              >
                <DownloadIcon color="primary" />
              </IconButton>
            ) : null
        }
      ]
    },
    {
      name: 'Execution',
      fields: [
        {
          id: 5,
          icon: ExecutionModeIcon,
          primary: job.scheduleId
            ? `Schedule: ${job.schedule?.description}`
            : 'Manual',
          secondary: job.scheduleId ? `Id: ${job.scheduleId}` : null
        },
        {
          id: 6,
          icon: SendOutlinedIcon,
          primary:
            job.schedule && job.schedule.distributionCid
              ? job.schedule.distributionCid
              : 'Not Available',
          secondary: 'CID'
        },
        {
          id: 7,
          primary:
            job.schedule && job.schedule.distributionPid
              ? job.schedule.distributionPid
              : 'Not Available',
          secondary: 'PID',
          grouped: true
        },
        {
          id: 8,
          icon: DurationIcon,
          primary: getDuration(job.duration)
        }
      ]
    },
    {
      name: 'Processed Leads',
      fields: [
        {
          id: 9,
          icon: SummaryIcon,
          primary: job.totalProcessed,
          secondary: 'Total'
        },
        {
          id: 10,
          primary: job.totalSuccess,
          secondary: 'Successes',
          grouped: true
        },
        {
          id: 11,
          primary: job.totalFailures,
          secondary: 'Failures',
          grouped: true
        }
      ]
    }
  ]

  return sections
}

const Overview = ({ job }) => {
  const { tagRecordingHotjar } = useHotjar()
  const { handleExport } = useJobFileDownload()
  const sections = generateJobSections(job, handleExport, tagRecordingHotjar)

  return <OverviewLayout sections={sections} />
}

Overview.propTypes = {
  job: PropTypes.instanceOf(Job).isRequired
}

export default React.memo(
  withAITracking(
    AppInsightsReactPlugin,
    Overview,
    `${MonitoringCodes.COMP}JobsOverview`
  )
)
