import { call, put, select, takeLatest } from 'redux-saga/effects'
import { filters as filtersModule, leads, ui, results } from 'store/modules'

import { LeadOperationType } from 'models'

import buildLeadOperationRequest from 'utils/buildLeadOperationRequest'
import { distributeLeads, updateLeads } from './api'
import { getFilters } from './filters'

const leadAction = {
  [LeadOperationType.Distribution]: distributeLeads,
  [LeadOperationType.Update]: updateLeads
}

export function* triggerOperation({
  payload: {
    actionType,
    userId,
    target,
    query: { selectFields, expand, top, skip },
    exportFields
  }
}) {
  try {
    const allFilters = []
    const presets = []
    const advanced = yield select(filtersModule.selectors.getAdvancedFilter)
    if (!advanced) {
      const { filters, presets: filterPresets } = yield call(getFilters)

      allFilters.push(...filters.slice(1, filters.length))
      presets.push(...filterPresets)
    } else allFilters.push(advanced)
    const sort = yield select(results.selectors.getSort)
    const columnQueryName = yield select(
      results.selectors.getSortByColumnQueryName
    )

    const request = buildLeadOperationRequest({
      actionType,
      userId,
      target,
      query: {
        select: selectFields,
        expand,
        filters: allFilters,
        presets,
        sort,
        columnQueryName,
        top,
        skip
      },
      exportFields
    })

    yield call(leadAction[actionType], request)

    yield put(
      ui.actions.showAlert({
        message: `Action ${actionType} has been started.`
      })
    )
  } catch (error) {
    yield put(
      ui.actions.showAlert({
        error,
        message: `Oops!, something went wrong performing ${actionType}.`
      })
    )
  }
}

const leadsSaga = [
  takeLatest(leads.actionTypes.TRIGGER_OPERATION, triggerOperation)
]

export default leadsSaga
