import { v1 as uuid } from 'uuid'

function buildDisplayLabel(dictionary, template) {
  if (!template) return 'No template available.'

  let filterDisplayLabel = template

  Object.keys(dictionary).forEach(key => {
    if (dictionary[key])
      filterDisplayLabel = filterDisplayLabel.replace(
        `[${key}]`,
        dictionary[key]
      )
  })

  return filterDisplayLabel
}

class Filter {
  constructor(rawFilter) {
    if (!rawFilter) return
    const {
      customField,
      id,
      field,
      operator,
      value: rawValue,
      value2,
      values
    } = rawFilter

    this.customField = customField
    this.id = id || uuid()
    this.field = field
    this.operator = operator
    this.value = rawValue
    this.value2 = value2
    this.values =
      values?.map(v =>
        v?.label
          ? { label: v.label, value: v.value }
          : field.valueOptions
              ?.map(({ label, value }) => ({ label, value }))
              .find(({ label }) => label === v.trim && v.trim()) ||
            (v.trim && v.trim())
      ) || null
  }

  get displayLabel() {
    return buildDisplayLabel(
      {
        label: this.field.label,
        customField: this.customField,
        value: this.value,
        value2: this.value2,
        values:
          this.values && this.values.length
            ? this.values.map(item => item?.label || item)
            : null
      },
      this.operator.displayLabel
    )
  }
}

export default Filter
