import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@tranzact/platform_react-components'
import { Badge } from '@mui/material'
import { Notifications as NotificationsIcon } from '@mui/icons-material'

import { StyledButtonBase, StyledMenuIcon } from './styles'

const Button = ({ children, handleOpenPopover, newCounter }) => (
  <StyledButtonBase onClick={handleOpenPopover}>
    <Tooltip title="Notifications">
      <div>
        <NotificationsIcon />
        {children}
        <StyledMenuIcon>
          <Badge
            badgeContent={newCounter}
            color="info"
            invisible={!newCounter}
          />
        </StyledMenuIcon>
      </div>
    </Tooltip>
  </StyledButtonBase>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  handleOpenPopover: PropTypes.func.isRequired,
  newCounter: PropTypes.number.isRequired
}

export default React.memo(Button)
