import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Typography } from '@mui/material'
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import { AlertsType } from 'models'
import { Root, Message, StyledCloseIcon, StyledSnackbarContent } from './styles'

const variantIcon = {
  [AlertsType.Success]: CheckCircleIcon,
  [AlertsType.Warning]: WarningIcon,
  [AlertsType.Error]: ErrorIcon,
  [AlertsType.Info]: InfoIcon
}

const AlertContentWrapper = forwardRef(
  ({ variant, message, onClose, showAction }, ref) => {
    const Icon = variantIcon[variant]

    const messageToRender = useMemo(
      () =>
        variant ? (
          <Message id="client-alert">
            <Icon />
            <Typography variant="h4">{message}</Typography>
          </Message>
        ) : (
          message
        ),
      [message, variant]
    )

    const action = useMemo(
      () =>
        showAction || (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <StyledCloseIcon />
          </IconButton>
        ),
      [onClose, showAction]
    )

    return (
      <Root>
        <StyledSnackbarContent
          ref={ref}
          action={action}
          aria-describedby="client-alert"
          className={`${variant || ''}`}
          message={messageToRender}
        />
      </Root>
    )
  }
)

AlertContentWrapper.defaultProps = {
  variant: null,
  showAction: null
}

AlertContentWrapper.propTypes = {
  message: PropTypes.string.isRequired,
  showAction: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(AlertsType)),
  onClose: PropTypes.func.isRequired
}

export default React.memo(AlertContentWrapper)
