import { combineReducers } from 'redux'
import {
  notifications,
  alerts,
  ui,
  favorites,
  filters,
  results,
  sources,
  leads
} from './modules'

export default combineReducers({
  [notifications.namespace]: notifications.reducer,
  [alerts.namespace]: alerts.reducer,
  [ui.namespace]: ui.reducer,
  [favorites.namespace]: favorites.reducer,
  [filters.namespace]: filters.reducer,
  [results.namespace]: results.reducer,
  [sources.namespace]: sources.reducer,
  [leads.namespace]: leads.reducer
})
