import { connect } from 'react-redux'

import { ui } from 'store/modules'
import TheNavBar from './TheNavBar'

const { getUserRole } = ui.selectors

const mapStateToProps = state => ({
  userRole: getUserRole(state)
})

const TheNavBarContainer = connect(mapStateToProps, null)(TheNavBar)

export default TheNavBarContainer
