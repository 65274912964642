import React from 'react'
import PropTypes from 'prop-types'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { AppInsightsReactPlugin } from '@tranzact/platform_react-components'
import {
  LocalOfferOutlined as InfoIcon,
  PlaceOutlined as AddressIcon,
  MailOutlined as MailOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneIcon
} from '@mui/icons-material'

import { OverviewLayout } from 'components/layout'
import { MonitoringCodes, Person } from 'models'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import parseOverviewFields from 'utils/parseOverviewFields'

function generateLeadSections(person, piiMask) {
  const informationFields = [
    {
      id: 1,
      icon: InfoIcon,
      primary: person.id,
      mask: false
    },
    {
      id: 2,
      icon: LocalPhoneIcon,
      primary: formatPhoneNumber(person.phone),
      mask: piiMask
    }
  ]

  let idSequence = 3

  if (person.phones) {
    person.phones
      .filter(phone => !phone.preferred)
      .forEach(phone => {
        informationFields.push({
          id: idSequence,
          primary: formatPhoneNumber(phone.phoneNumber),
          mask: piiMask,
          grouped: true
        })
        idSequence += 1
      })
  }

  informationFields.push({
    id: idSequence,
    icon: AddressIcon,
    primary: person.addressLine1,
    secondary: `${person.state},${person.county} ${person.zipcode}`,
    mask: piiMask
  })

  if (person.addresses) {
    person.addresses
      .filter(address => !address.preferred)
      .forEach(address => {
        informationFields.push({
          id: idSequence,
          primary: address.addressLine1,
          secondary: `${address.state}, ${address.county} ${address.zipcode}`,
          mask: piiMask,
          grouped: true
        })
        idSequence += 1
      })
  }

  informationFields.push({
    id: idSequence,
    icon: MailOutlinedIcon,
    primary: person.email || '-',
    mask: false
  })

  return [
    {
      name: 'Information',
      fields: informationFields
    }
  ]
}

const Overview = ({ person, piiMask }) => {
  const sections = generateLeadSections(person, piiMask)
  const details =
    person &&
    parseOverviewFields(person.fields).sort((a, b) =>
      a.key.toLowerCase() < b.key.toLowerCase() ? 1 : -1
    )

  return (
    <OverviewLayout
      details={{
        ...details.filter(({ value }) => value && value !== '-'),
        ...details.filter(({ value }) => !value || value === '-')
      }}
      piiMask={piiMask}
      sections={sections}
    />
  )
}

Overview.propTypes = {
  person: PropTypes.instanceOf(Person).isRequired,
  piiMask: PropTypes.bool.isRequired
}

export default React.memo(
  withAITracking(
    AppInsightsReactPlugin,
    Overview,
    `${MonitoringCodes.COMP}PersonsOverview`
  )
)
